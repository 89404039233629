

var React = require('react');
var ReactDOM = require('react-dom');
var AppActions = require('./actions/actions.js');
var AppStore = require('./stores/stores.js');
var GlobalStore = require('../../../mainStores/Store.js');
var SpinerLoader = require('./components/spinerLoader.jsx');

var MapComponent = require('./components/mapComponent.jsx');
var Tabs = require('./components/tabs.jsx');
var Run = require('./components/Run.jsx');
// var MapsPlaces = require('../../mapsZones/Main');
var jsonState = 0;
var hostName = require('../../../api/hostName');
// import hostName from '../../../api/hostName'



var App = React.createClass({
    getInitialState: function () {
        return {
            show: false,
            showMaps: AppStore.getState().statusAnalitics === 'maps',
            showRun: AppStore.getState().statusAnalitics === 'route',
            childBox: {},
            image: AppStore.getState().image
        };
    },
    internalState:{
        isMounted: false
    },
    _onChangeCommonState: function(store) {
        // console.log('main.jsx!!!!!!!!');
        if (AppStore.getState().mainState.insetName !== undefined) {
            var box = {
                test: AppStore.getState().test,
                status:AppStore.getState().status,
                floor: AppStore.getState().mallFloors,
                mapLink: AppStore.getState().mapLink,
                statusLoad: AppStore.getState().loadStatusStatisitcs
            }
            // console.log('_onChangeCommonState', AppStore.getState().mainState.insetName);
            this.setState({
                show: AppStore.getState().mainState.insetName == 'MALL_MAP',
                childBox: box,
                showMaps: AppStore.getState().statusAnalitics === 'maps',
                showRun: AppStore.getState().statusAnalitics === 'route',
                image: AppStore.getState().image
            });
        }
    },

    onChangeMainState: function () {
        // if (this.internalState === true) {
        //     if (jsonState !== GlobalStore.getState().methodAnalitics) {
        //         this.setState({
        //             show: GlobalStore.getState().methodAnalitics === 'maps'
        //         });
        //     }
        // }
        // console.log('main.jsxmainmain', GlobalStore.getState().methodAnalitics);
    },
    componentDidMount: function () {
        this.internalState = true;
        // console.log('main.jsxmainmain', GlobalStore.getState());
        AppStore.addChangeListener(this._onChangeCommonState);
        GlobalStore.addChangeStoreModuleListener(this.onChangeMainState);
        // AppStore.addChangeListener(MapComponent._onChange);
        // GlobalStore.addChangeListener(AppActions.update);
        // AppActions.update();
    },
    componentWillUnmount: function () {
        this.internalState = false;
        GlobalStore.removeChangeStoreModuleListener(this._onChangeCommonState);
        AppStore.removeChangeListener(this._onChangeCommonState);
        // AppActions.loadMalls(AppStore.getState().linkLoadMall);
    },
    render: function() {
        // console.log('main.jsxmainmain', this.state.show);
        if (document.getElementById('extend-region') !== null) {
            var widthbox = document.getElementById('extend-region').clientWidth;
            var heightbox = (window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height) - 103;
            // console.log('renderrender', window.innerWidth, widthbox)
        }
        var component;
        if (this.state.show === true) {
            if (this.state.showMaps === true) {
                component = <div className='main-region'>
                    <Tabs/>
                    <MapComponent
                        getMode="PLACES" image={hostName.maps + this.state.image} panelAnalitics={true} prop={this.state.childBox}
                        width={widthbox}
                        height={heightbox}
                    />
                </div>
            }
            else if (this.state.showRun === true) {
                component = <div className='main-region'>
                    <Tabs/>
                    <Run getMode="PLACES" image={hostName.maps + this.state.image} prop={this.state.childBox} />
                </div>
            }
        }
        // console.log('main.jsxmainmain', this.state.show, component);
        return (
            <div className='extend-region' id='extend-region'>
                {component}
            </div>
        );
    }
});


module.exports = App;

// ReactDOM.render(
//     <App />,
//     document.getElementById('ReactBox')
// );
