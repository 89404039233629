var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var Autosuggest = require('react-autosuggest');
var Select = require('react-select').default;
var lang = require('../../../lang/func.js');


var Filter = React.createClass({
	getInitialState: function () {
		return {
			objFilter: AppStore.getState().filterObj,
			optionsA: AppStore.getState().optionsFilterObjectsA,
			optionsB: AppStore.getState().optionsFilterObjectsB,
			valueA: AppStore.getState().optionsFilterValueA,
			valueB: AppStore.getState().optionsFilterValueB,
			macAdress: AppStore.getState().macAdress,
			dataSelect: AppStore.getState().dataSelect,
            stateTypeSelect: AppStore.getState().statusTypeSelect,
            statusPointFrom: AppStore.getState().statusPointFrom,
            statusPointTo: AppStore.getState().statusPointTo,
            statusNotData: AppStore.getState().statusNotData
		};
	},
	_onChange: function(store) {
		if (this.internalState.isMounted = true) {
			this.setState({
            objFilter: AppStore.getState().filterObj,
            optionsA: AppStore.getState().optionsFilterObjectsA,
			optionsB: AppStore.getState().optionsFilterObjectsB,
			valueA: AppStore.getState().optionsFilterValueA,
			valueB: AppStore.getState().optionsFilterValueB,
			macAdress: AppStore.getState().macAdress,
			dataSelect: AppStore.getState().dataSelect,
                stateTypeSelect: AppStore.getState().statusTypeSelect,
                statusPointFrom: AppStore.getState().statusPointFrom,
                statusPointTo: AppStore.getState().statusPointTo,
                statusNotData: AppStore.getState().statusNotData
            });
		}
    },
    onChangeMac: function (e) {
    	// console.log('run', e.target.value);
    	AppActions.setValueMac(e.target.value)
    },
    internalState: {
		isMounted: false
	},
    componentDidMount: function () {
    	this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
    onSend: function () {
    	// console.log('run', AppStore.getState());
    	var data = {
    		filterObj: AppStore.getState().filterObj,
    		optionsFilterValueA: AppStore.getState().optionsFilterValueA,
    		optionsFilterValueB: AppStore.getState().optionsFilterValueB,
    		optionsSelected: AppStore.getState().optionsSelected,
    		optionsSelectedExclusion: AppStore.getState().optionsSelectedExclusion,
    		minDate: AppStore.getState().mainState.min_timestamp,
    		maxDate: AppStore.getState().mainState.max_timestamp,
    		statusTop: AppStore.getState().statusTop,
    		numSteps: AppStore.getState().numSteps,
    		statusStepsOptions: AppStore.getState().statusStepsOptions,
    		statusInvolvement: AppStore.getState().statusInvolvement,
    		macAdress: AppStore.getState().macAdress,
    		selectedObjects: AppStore.getState().mainState.selectedObjects,
    		statusTypeSelect: AppStore.getState().statusTypeSelect,
    		dataSelect: AppStore.getState().dataSelect
    	};
    	// console.log('run', data);
    	AppActions.setRequestRoute(data);
    },
    logChange: function (log) {
		// console.log('run', log);
		AppActions.getFilterSelectA(log);
	},
	log2Change: function (log) {
		AppActions.getFilterSelectB(log);
	},
	onFilter: function (e) {
		// console.log('filter', e.target);
		if (e.target === document.getElementById('fromAToBBox') || e.target === document.getElementById('fromAToB')) {
			AppActions.getStatusFilter('fromAToB');
		}
		else if (e.target === document.getElementById('fromABox') || e.target === document.getElementById('fromA')) {
			AppActions.getStatusFilter('fromA');
		}
		else if (e.target === document.getElementById('toABox') || e.target === document.getElementById('toA')) {
			AppActions.getStatusFilter('toA');
		}
		else if (e.target === document.getElementById('toAFromBox') || e.target === document.getElementById('toAFrom')) {
			AppActions.getStatusFilter('toAFrom');
		}
		else if (e.target === document.getElementById('startBox') || e.target === document.getElementById('start')) {
			AppActions.getStatusFilter('start');
		}
		else if (e.target === document.getElementById('finishBox') || e.target === document.getElementById('finish')) {
			AppActions.getStatusFilter('finish');
		}
		else if (e.target === document.getElementById('macAdress')) {
			AppActions.getStatusFilter('mac');
		}
		else if (e.target === document.getElementById('topBox') || e.target === document.getElementById('top')) {
			AppActions.getStatusFilter('top');
		}
	},
	selectTopDetail: function (e) {
		AppActions.setDataSelectTopDetail(e.target.value);
	},
	onClickPoint: function (e) {
		// console.log('filter', e.target);
		if (e.target === document.getElementById('pointFrom')) {
			AppActions.getPointStatus('from');
		}
		else if (e.target === document.getElementById('pointTo')) {
            AppActions.getPointStatus('to');
		}
    },
    componentWillReceiveProps: function (nextProps) {
		if (this.props !== nextProps) {
            this.setState({});
		}
    },
	render: function () {
		// console.log('filter',this.props.prop.statusLoad);
		var classNameFromAToB = 'noActiveFilter', classNameFromA = 'noActiveFilter',
		 classNameToA = 'noActiveFilter', classNameTop = 'noActiveFilter', 
		 classNameToAFrom = 'noActiveFilter', classNameStart = 'noActiveFilter', 
		 classNameFinish = 'noActiveFilter', componentSelectObjects, 
		 classNameButton = 'buttonDataToo', funcSelect = this.onSend, componentMac = null,
		componentPointFrom = null, componentPointTo = null, stylePointTo = null, stylePointFrom = null,
		funcOnclickPOint = this.onClickPoint;
        if (this.state.statusPointFrom === true) {
            stylePointFrom = {color: 'rgb(206,109,246)'};
		}
		if (this.state.statusPointTo === true) {
            stylePointTo = {color: 'rgb(206,109,246)'};
		}
		if (this.state.statusNotData === true || this.props.prop.statusLoad === false || this.props.prop.statusLoad === undefined) {
            stylePointTo = {opacity: '0.5', cursor: 'default'};
            stylePointFrom = {opacity: '0.5', cursor: 'default'};
            funcOnclickPOint = null;
		}
		if (this.state.stateTypeSelect === 'monitor') {
            componentPointFrom = <div onClick={this.onClickPoint} className="boxPointButton" style={{marginLeft: '6px',
                fontSize: '20px'}} ><i style={stylePointFrom} id="pointFrom" className="fa fa-location-arrow fa-1x" ariaHidden="true"/></div>
            componentPointTo = <div onClick={this.onClickPoint} className="boxPointButton" style={{marginLeft: '6px',
                fontSize: '20px'}} ><i style={stylePointTo} id="pointTo" className="fa fa-location-arrow fa-1x" ariaHidden="true"/></div>
		}
		if (this.state.objFilter.fromAToB === true) {
			classNameFromAToB = 'activeFilter';
			// console.log('filter', this.state.valueA, this.state.optionsB);
			if (this.state.valueA === null || this.state.valueB === null) {
				classNameButton = 'buttonNoData';
				funcSelect = null;
			}
			 else if (this.state.valueA === null && this.state.valueB === null) {
				classNameButton = 'buttonNoData';
				funcSelect = null;
			}
			componentSelectObjects = <div>
				<div className='from'>
					<Select name="form-field-name"
						multi={false}
						noResultsText={lang.getState().maps.route.the_list_is_loading}
						placeholder={lang.getState().maps.route.select}
						onChange={this.logChange}
    					value={this.state.valueA} 
    					options={this.state.optionsA}/>
				</div>
				{/*{componentPointFrom}*/}
				<div className='arrow'></div>
				<div className='to'>
					<Select name="form-field-name"
						multi={false}
						noResultsText={lang.getState().maps.route.the_list_is_loading}
						placeholder={lang.getState().maps.route.select}
						onChange={this.log2Change}
    					value={this.state.valueB}
    					 options={this.state.optionsB}/>
				</div>
                {/*{componentPointTo}*/}
			</div>
		}
		else if (this.state.objFilter.fromA === true) {
			classNameFromA = 'activeFilter';
			if (this.state.valueA === null) {
				classNameButton = 'buttonNoData';
				funcSelect = null;
			}
			componentSelectObjects = <div>
				<div className='from'>
					<Select name="form-field-name"
						multi={false}
						noResultsText={lang.getState().maps.route.the_list_is_loading}
						placeholder={lang.getState().maps.route.select}
						onChange={this.logChange}
    					value={this.state.valueA} options={this.state.optionsA}/>
				</div>
                {/*{componentPointFrom}*/}
				<div className='arrow'></div>
			</div>
		}
		else if (this.state.objFilter.mac === true) {
			// if (this.state.macAdress === '') {
			// 	classNameButton = 'buttonNoData';
			// 	funcSelect = null;
			// }
			componentSelectObjects = null;
			componentMac = <input onChange={this.onChangeMac} value={this.state.macAdress} type='text' />
		}
		else if (this.state.objFilter.toA === true) {
			classNameToA = 'activeFilter';
			if (this.state.valueA === null) {
				classNameButton = 'buttonNoData';
				funcSelect = null;
			}
			componentSelectObjects = <div>
				<div className='arrow'></div>
				<div className='from'>
					<Select name="form-field-name"
						multi={false}
						noResultsText={lang.getState().maps.route.the_list_is_loading}
						placeholder={lang.getState().maps.route.select}
						onChange={this.logChange}
    					value={this.state.valueA} options={this.state.optionsA}/>
				</div>
                {/*{componentPointTo}*/}
			</div>
		}
		else if (this.state.objFilter.toAFrom === true) {
			classNameToAFrom = 'activeFilter';
			if (this.state.valueA === null) {
				classNameButton = 'buttonNoData';
				funcSelect = null;
			}
			componentSelectObjects = <div>
				<div className='arrow'></div>
				<div className='from'>
					<Select name="form-field-name"
						multi={false}
						noResultsText={lang.getState().maps.route.the_list_is_loading}
						placeholder={lang.getState().maps.route.select}
						onChange={this.logChange}
    					value={this.state.valueA} options={this.state.optionsA}/>
				</div>
                {/*{componentPointFrom}*/}
				<div className='arrow'></div>
			</div>
		}
		else if (this.state.objFilter.start === true) {
			classNameStart = 'activeFilter';
			if (this.state.valueA === null) {
				classNameButton = 'buttonNoData';
				funcSelect = null;
			}
			componentSelectObjects = <div>
				<div className='from'>
					<Select name="form-field-name"
						multi={false}
						noResultsText={lang.getState().maps.route.the_list_is_loading}
						placeholder={lang.getState().maps.route.select}
						onChange={this.logChange}
    					value={this.state.valueA} options={this.state.optionsA}/>
				</div>
                {/*{componentPointTo}*/}
				</div>
		}
		else if (this.state.objFilter.finish === true) {
			classNameFinish = 'activeFilter';
			if (this.state.valueA === null) {
				classNameButton = 'buttonNoData';
				funcSelect = null;
			}
			componentSelectObjects = <div>
				<div className='from'>
					<Select name="form-field-name"
						multi={false}
						noResultsText={lang.getState().maps.route.the_list_is_loading}
						placeholder={lang.getState().maps.route.select}
						onChange={this.logChange}
    					value={this.state.valueA} options={this.state.optionsA}/>
				</div>
                {/*{componentPointTo}*/}
			</div>
		}
		else if (this.state.objFilter.top === true) {
			classNameTop = 'activeFilter';
			// if (this.state.valueA === null) {
			// 	classNameButton = 'buttonNoData';
			// 	funcSelect = null;
			// }
			componentSelectObjects = <select onChange={this.selectTopDetail} defaultValue={this.state.dataSelect} className='selectInSelect'>
							<option value='any_part'>{lang.getState().maps.route.second_panel.any_part}</option>
							<option value='begin'>{lang.getState().maps.route.second_panel.begin}</option>
							<option value='end'>{lang.getState().maps.route.second_panel.end}</option>
							<option value='full'>{lang.getState().maps.route.second_panel.full}</option>
						</select>;
		}
		// console.log('filter', this.state.optionsA, this.state.optionsB);

		return (
				<div className='filterBox'>
					<div className='filter'>
						<div className={classNameFromAToB} onClick={this.onFilter} id='fromAToBBox'>
							<div id='fromAToB'>A &#8594; B</div>
						</div>
						<div className={classNameFromA} onClick={this.onFilter} id='fromABox'>
							<div id='fromA'>A &#8594;</div>
						</div>
						<div className={classNameToA} onClick={this.onFilter} id='toABox'>
							<div id='toA'>&#8594; A</div>
						</div>
						<div className={classNameTop} style={{width: '120px'}} onClick={this.onFilter} id='topBox'>
							<div style={{textAlign: 'center', width: '115px'}} id='top'>{lang.getState().maps.route.second_panel.any}</div>
						</div>
                        {/*<div className={classNameToAFrom} onClick={this.onFilter} id='toAFromBox'>
						 <div id='toAFrom'></div>
						 </div>
						 <div className={classNameStart} onClick={this.onFilter} id='startBox'>
						 <div id='start'>Старт</div>
						 </div>
						 <div className={classNameFinish} onClick={this.onFilter} id='finishBox'>
						 <div id='finish'>Финишь</div>
						 </div>*/}
                        {componentMac}
						<button onClick={this.onFilter} id='macAdress' type='button'></button>
					</div>
					<div className='selectInFilter'>
						<div className='celectObjects'>
							{componentSelectObjects}
						</div>
						<button className={classNameButton} onClick={funcSelect} type='button'>{lang.getState().maps.route.second_panel.button_show_routes}</button>
					</div>
				</div>
			);
	}
});

module.exports = Filter;
