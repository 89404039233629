var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');

(function() {
  /**
   * Корректировка округления десятичных дробей.
   *
   * @param {String}  type  Тип корректировки.
   * @param {Number}  value Число.
   * @param {Integer} exp   Показатель степени (десятичный логарифм основания корректировки).
   * @returns {Number} Скорректированное значение.
   */
  function decimalAdjust(type, value, exp) {
    // Если степень не определена, либо равна нулю...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // Если значение не является числом, либо степень не является целым числом...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Сдвиг разрядов
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Обратный сдвиг
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  // Десятичное округление к ближайшему
  if (!Math.round10) {
    Math.round10 = function(value, exp) {
      return decimalAdjust('round', value, exp);
    };
  }
  // Десятичное округление вниз
  if (!Math.floor10) {
    Math.floor10 = function(value, exp) {
      return decimalAdjust('floor', value, exp);
    };
  }
  // Десятичное округление вверх
  if (!Math.ceil10) {
    Math.ceil10 = function(value, exp) {
      return decimalAdjust('ceil', value, exp);
    };
  }
})();


var Step = React.createClass({
	render: function () {
		if (this.props.id === 0) {
			// start
			if (this.props.prop.step.length > 1) {
				var totalPersent = 0
				for (var i = 0; i < this.props.prop.step.length; i++) {
					totalPersent = totalPersent + this.props.prop.step[i].persent;
				}
				totalPersent = Math.round10(totalPersent, -1);
				var itemsTitle = this.props.prop.step.length;
				var title = this.props.prop.step.map(function(prop, id) {
					if (itemsTitle === 2) {
						var positionY = id * 20 + 70;
					}
					else if (itemsTitle === 3) {
						var positionY = id * 20 + 60;
					}
					else if (itemsTitle === 4) {
						var positionY = id * 21 + 48;
					}
					else if (itemsTitle === 5) {
						var positionY = id * 18 + 48;
					}
					else if (itemsTitle === 6) {
						var positionY = id * 13 + 47;
					}
					return (<text key={id} style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} x="305" y={positionY} fill='#fff'>{prop.name}</text>);
				});
				var persentNode = this.props.prop.step.map(function(prop, id) {
					var positionY = id * 15 + 135;
					return (<text x="370" key={id} y={positionY} fill='rgb(102, 102, 102)' >{prop.name + ' ' + Math.round10(prop.persent, -1) + '%'}</text>);
				});
				return (
					<g className='RadiusSms'>
						<rect x="250" y="35"  width="110" height="85"  ></rect>
						{title}
						<image xlinkHref='/img/hotspotstats01arrows.png' className='radiusImageIcon' fill='green' x="380" y="55" height="50px" width="50px"></image>
						<text x="405" style={{textAnchor: 'middle', fontSize: '20px'}} y="40" fill='rgb(102, 102, 102)' >{totalPersent + '%'}</text>
						{persentNode}
					</g>
				);
			}
			else if (this.props.prop.step.length === 1) {
				return (
					<g className='RadiusSms'>
						<rect x="250" y="35"  width="110" height="85"  ></rect>
						<text x="305" y="82" style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} fill='#fff'>{this.props.prop.step[0].name}</text>
						<image xlinkHref='/img/hotspotstats01arrows.png' className='radiusImageIcon' fill='green' x="380" y="55" height="50px" width="50px"></image>
						<text x="405" style={{textAnchor: 'middle', fontSize: '20px'}} y="40" fill='rgb(102, 102, 102)' >{Math.round10(this.props.prop.step[0].persent, -1) + '%'}</text>
						<text x="370" y="135" fill='rgb(102, 102, 102)' >{this.props.prop.step[0].name + ' ' + Math.round10(this.props.prop.step[0].persent, -1) + '%'}</text>
					</g>
				);
			}
			// end
		}
		else if (this.props.id === 1) {
			if (this.props.prop.step.length > 1) {
				var totalPersent = 0
				var itemsTitle = this.props.prop.step.length;
				for (var i = 0; i < this.props.prop.step.length; i++) {
					totalPersent = totalPersent + this.props.prop.step[i].persent;
				}
				totalPersent = Math.round10(totalPersent, -1);
				var title = this.props.prop.step.map(function(prop, id) {
					if (itemsTitle === 2) {
						var positionY = id * 20 + 70;
					}
					else if (itemsTitle === 3) {
						var positionY = id * 20 + 60;
					}
					else if (itemsTitle === 4) {
						var positionY = id * 21 + 48;
					}
					else if (itemsTitle === 5) {
						var positionY = id * 18 + 48;
					}
					else if (itemsTitle === 6) {
						var positionY = id * 13 + 47;
					}
					return (<text style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} key={id} x="505" y={positionY} fill='#fff'>{prop.name}</text>);
				});
				var persentNode = this.props.prop.step.map(function(prop, id) {
					var positionY = id * 15 + 135;
					return (<text x="565" key={id} y={positionY} fill='rgb(102, 102, 102)' >{prop.name + ' ' + Math.round10(prop.persent, -1) + '%'}</text>);
				});
				return (
					<g className='RadiusSocial'>
						<rect x="450" y="35"  width="110" height="85"  ></rect>
						{title}
						<image xlinkHref='/img/hotspotstats01arrows.png' className='radiusImageIcon' fill='green' x="580" y="55" height="50px" width="50px"></image>
						<text x="605" style={{textAnchor: 'middle', fontSize: '20px'}} y="40" fill='rgb(102, 102, 102)' >{totalPersent + '%'}</text>
						{persentNode}
					</g>
				);
			}
			else if (this.props.prop.step.length === 1) {
				return (
					<g className='RadiusSocial'>
						<rect x="450" y="35"  width="110" height="85"  ></rect>
						<text x="505" y="82" style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} fill='#fff'>{this.props.prop.step[0].name}</text>
						<image xlinkHref='/img/hotspotstats01arrows.png' className='radiusImageIcon' fill='green' x="580" y="55" height="50px" width="50px"></image>
						<text x="605" style={{textAnchor: 'middle', fontSize: '20px'}} y="40" fill='rgb(102, 102, 102)' >{Math.round10(this.props.prop.step[0].persent, -1) + '%'}</text>
						<text x="570" y="135" fill='rgb(102, 102, 102)' >{this.props.prop.step[0].name + ' ' + Math.round10(this.props.prop.step[0].persent, -1) + '%'}</text>
					</g>
				);
			}
		}
		else if (this.props.id === 2) {
			// start
			if (this.props.prop.step.length > 1) {
				var totalPersent = 0
				var itemsTitle = this.props.prop.step.length;
				for (var i = 0; i < this.props.prop.step.length; i++) {
					totalPersent = totalPersent + this.props.prop.step[i].persent;
				}
				totalPersent = Math.round10(totalPersent, -1);
				var title = this.props.prop.step.map(function(prop, id) {
					if (itemsTitle === 2) {
						var positionY = id * 20 + 70;
					}
					else if (itemsTitle === 3) {
						var positionY = id * 20 + 60;
					}
					else if (itemsTitle === 4) {
						var positionY = id * 21 + 48;
					}
					else if (itemsTitle === 5) {
						var positionY = id * 18 + 48;
					}
					else if (itemsTitle === 6) {
						var positionY = id * 13 + 47;
					}
					return (<text key={id} style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} x="705" y={positionY} fill='#fff'>{prop.name}</text>);
				});
				var persentNode = this.props.prop.step.map(function(prop, id) {
					var positionY = id * 15 + 135;
					return (<text x="770" style={{fontSize: '14px'}} key={id} y={positionY} fill='rgb(102, 102, 102)' >{prop.name + ' ' + Math.round10(prop.persent, -1) + '%'}</text>);
				});
				return (
					<g className='RadiusSocial'>
						<rect x="650" y="35"  width="110" height="85"  ></rect>
						{title}
						<image xlinkHref='/img/hotspotstats01arrows.png' className='radiusImageIcon' fill='green' x="780" y="55" height="50px" width="50px"></image>
						<text x="805" style={{textAnchor: 'middle', fontSize: '20px'}} y="40" fill='rgb(102, 102, 102)' >{totalPersent + '%'}</text>
						{persentNode}
					</g>
				);
			}
			else if (this.props.prop.step.length === 1) {
				return (
					<g className='RadiusSocial'>
						<rect x="650" y="35"  width="110" height="85"  ></rect>
						<text x="705" y="82" style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} fill='#fff'>{this.props.prop.step[0].name}</text>
						<image xlinkHref='/img/hotspotstats01arrows.png' className='radiusImageIcon' fill='green' x="780" y="55" height="50px" width="50px"></image>
						<text x="805" style={{textAnchor: 'middle', fontSize: '20px'}} y="40" fill='rgb(102, 102, 102)' >{Math.round10(this.props.prop.step[0].persent, -1) + '%'}</text>
						<text x="770" y="135" fill='rgb(102, 102, 102)' >{this.props.prop.step[0].name + ' ' + Math.round10(this.props.prop.step[0].persent, -1) + '%'}</text>
					</g>
				);
			}
			// end
		}
	}
});

module.exports = Step;