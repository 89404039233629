
"use strict"
var React = require('react');
var ReactDOM = require('react-dom');
var AppActions = require('./actions/actions.js');
var AppStore = require('./stores/store.js');
var GlobalStore = require('../../mainStores/Store.js');

var HotSpot = require('./components/hotSpot.jsx');
var Tabs = require('./components/tabs.jsx');
var SmsAnalitics = require('./components/smsAnalitics.jsx');
var Social = require('./components/social.jsx');



var App = React.createClass({
    getInitialState: function () {
        return{
            showStatus: AppStore.getState().mainState.insetName === 'HOTSPOT',
            methodAnalitics: AppStore.getState().analiticsMethod
        };
    },
    _onChange: function () {
        this.setState({
            showStatus: AppStore.getState().mainState.insetName === 'HOTSPOT',
            methodAnalitics: AppStore.getState().analiticsMethod
        });
    },
    componentDidMount: function () {
        AppStore.addChangeListener(this._onChange);
        // нужно сделать привязку контрол панели к глобальному стору тот в свою очередь должен дергать стор этого молуля
        // AppActions.setFunnel('state');
    },
    componentWillUnmount: function () {
        AppStore.removeChangeListener(this._onChange);
    },
    render: function () {
        var component;
        var tabs;
        if (this.state.showStatus === true) {
            if (this.state.methodAnalitics === 'sms') {
                component = <div className='hotspotBox' >
                            <SmsAnalitics />
                        </div>
                tabs = <Tabs />
            }
            else if (this.state.methodAnalitics === 'connect') {
                component = <div className='hotspotBox' >
                            <HotSpot />
                        </div>
                tabs = <Tabs />

            }
            else if (this.state.methodAnalitics === 'social') {
                component = <div className='hotspotBox' >
                            <Social />
                        </div>
                tabs = <Tabs />

            }
        }
        else if (this.state.showStatus === false) {
            component = null;
            tabs = null
        }
        return (
            <div className='extend-region'>
                <div className='main-region'>
                    {tabs}
                    {component}
                </div>
            </div>
        );
    }
});


module.exports = App;