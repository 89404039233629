var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var getHeatColor = require('../tools/color.js');
var lang = require('../../../lang/func.js');
var ActionsMapsZones = require('../../../mapsZones/actions/Actions');

var ImgPreview = React.createClass({
	internalState: {
		styleActive: {
            border: '2px solid rgba(138,144,154, .9)',
			background: 'rgba(255,255,255,.5)',
			color: 'rgba(138,144,154, .9)'
		},
		styleDefault: {
            border: '2px solid rgba(255,255,255, .9)',
            background: 'rgba(0,0,0,.5)',
			color: 'rgba(255,255,255, .9)'
		}
	},

	setMapId: function () {
		AppActions.getFloorId(this.props.index);
		// console.log(ActionsMapsZones.selectFloor, ActionsMapsZones)
        // ActionsMapsZones.default.selectFloor(this.props.id)
	},
	render: function  () {
		if (this.props.prop.statusActive == false && AppStore.getState().selectedAreaIndex != null && this.props.mode !== 'router') {
			var distribution = AppStore.getState().routerItems[AppStore.getState().floorIndex].intersections[AppStore.getState().zoneId];
			var persentColor;
			this.props.id
			if (document.getElementById('preview' + this.props.id) !== null) {
				var x = document.getElementById('preview' + this.props.id).getBoundingClientRect().width;
				var xcorrect = (x - 40) / 2;
			}
			else if (document.getElementById('preview' + this.props.id) === null) {
				var xcorrect = 0;
			}
			for(var i = 0; i < distribution.floor_distribution.length; i++) {
				if (this.props.id == distribution.floor_distribution[i].floor){
					persentColor = distribution.floor_distribution[i].percent;
				}
			}
			var svgNode = <g>
								<circle cx='50%' cy='50%' fill={getHeatColor(persentColor / 100)} r={50 + 'px'} />
								<text fill='#000' x={xcorrect} y="70%" >{persentColor + '%'}</text>
						</g>
			var persent = <p className='persent'>100%</p>
		}
		else {
			var svgNode = null;
			var persent = null;
		}

		// console.log('floor', this.props.prop.statusClick)
		if (this.props.uuidPlanSet !== null) {
            this.internalState = {
                styleActive: {
                    border: '2px solid rgba(138,144,154, .9)',
                    background: 'rgba(255,255,255,.5)',
                    color: 'rgba(138,144,154, .9)'
                },
                styleDefault: {
                    border: '2px solid rgba(255,255,255, .9)',
                    background: 'rgba(0,0,0,.5)',
                    color: 'rgba(255,255,255, .9)'
                }
			}
            if (this.props.roomsTransition[this.props.id] !== undefined) {
                this.internalState.styleDefault.background = getHeatColor(this.props.roomsTransition[this.props.id].intersection_percent / 100)
            }
            else {
                this.internalState.styleDefault.background = 'rgba(0,0,0,.5)'
            }
            return (
                <div style={this.props.prop.statusActive ? this.internalState.styleActive : this.internalState.styleDefault} id={'preview' + this.props.id} onClick={this.props.prop.statusClick?  this.setMapId : null} className="itemPreview">
                    {/*<img id={'previewImg' + this.props.id} src={this.props.prop.link} style={this.props.prop.statusActive ? this.internalState.styleActive : this.internalState.styleDefault} />*/}
                    {/*<svg height={100 + '%'} width={100 + '%'}>*/}
                    {/*{svgNode}*/}
                    {/*</svg>*/}
                    <span className={'itemFloor'}>{this.props.id}</span>
                </div>
            );
		}
		else if (this.props.uuidPlanSet === null) {
            this.internalState = {
                styleActive: {
                    outline: '2px solid rgba(1, 58, 51, .5)'
                },
                styleDefault: {
                    outline: '1px solid rgba(1, 58, 51, .5)'
                }
            }
            return (
                <div id={'preview' + this.props.id} onClick={this.setMapId} className="itemPreviewOld">
                    <img id={'previewImg' + this.props.id} src={this.props.prop.link} style={this.props.prop.statusActive ? this.internalState.styleActive : this.internalState.styleDefault} />
                    <svg height={100 + '%'} width={100 + '%'}>
                        {svgNode}
                    </svg>
                    <p>{this.props.id + " " + lang.getState().maps.intersections.previews.floor} </p>
                </div>
            );
		}
	}
});



module.exports = ImgPreview;
