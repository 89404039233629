var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');

var Icons = React.createClass({
	getInitialState: function () {
		return {
			fill: 'rgba(74, 187, 137, .5)'};
	},
	setIndex: function () {
		// if (this.initalState.selectStatus == false) {
		// 	this.initalState.selectStatus = true;
		// }
		// else if (this.initalState.selectStatus == true) {
		// 	this.initalState.selectStatus = false;
		// }
		if (this.props.mode !== 'router') {
            if (this.props.prop.persentR > 0) {
                var data = {
                    indexZone: this.props.index,
                    data: this.props.prop,
                    zoneId: this.props.prop.zoneId
                };
                AppActions.getSelectZone(data);
            }
		}
		else if (this.props.mode === 'router') {
            if (this.props.prop.persentR > 0) {
                var data = {
                    indexZone: this.props.index,
                    data: this.props.prop,
                    zoneId: this.props.prop.zoneId
                };
                AppActions.getSelectZoneInRouter(data);
            }
		}
	},
	setDataRouter: function () {
        var data = {
            indexZone: this.props.index,
            data: this.props.prop,
            zoneId: this.props.prop.zoneId
        };
		AppActions.getDataRouter(data);
    },
	mouseLeave: function () {
		this.setState({fill: 'rgba(74, 187, 137, .5)'});
	},
	render: function () {
		// console.log('svgicons', this.props.mode);
		var funcOnClick = this.setIndex;
		if (this.props.mode === 'router') {
            funcOnClick = this.setDataRouter;
		}
		return (
			<circle fill='rgba(66, 66, 66, .8)' onClick={funcOnClick}
				className='icons' zIndex={10000} 
				cx={this.props.cx} cy={this.props.cy}  r={this.props.r} 
				style={{cursor: 'pointer'}}/>
		);
	}
});


module.exports = Icons;
