import Store from '../store/store';
import Api from '../api/Api';

var Actions;
Actions = {
    update(uuid){
        // console.log('actions!!!!!!!!!!', uuid);
        Store.getUuid(uuid);
        Store.setStatusLoad(true);
        Api.update(
            uuid,
            (state) => {
                Store.update(state);
                Store.setStatusLoad(false);
            },
            (error) => {
                alert('Ошибка Запрос на обновление данных закончился с ошибкой' + error);

            })
    },
    saveTrack(data){
        Store.setStatusLoad(true);
        Api.saveTrack(data,
            (state) => {
                Actions.update(Store.getState().globalUuid);
            },
            (error) => {
                alert('Ошибка Трек не сохранился' + error);
            })
    },
    startTrack(data){
        Api.startTrack(data,
            (state) => {

            },
            (err) => {
                alert('Ошибка Трек не был начат' + err);
            })
    },
    finishTrack(data, uuid, arr){
        Api.finishTrack(arr,
            data,
            (state) => {
                // console.log('actions', state);
                Actions.update(Store.getState().globalUuid);

                alert('Проход успешно завершен');
                Store.successLoad(true);
            },
            (err) => {
                alert('Ошибка Не был закончен' + err);
            })
    },
    selectFloor(e){
        Store.selectFloor(e);
    },
    getStatusNext(status) {
        Store.successLoad(status);
    },
    getNameSetZone(name){
        Store.getNameSetZone(name);
    },
    selectZoneSet(index){
        Store.selectZoneSet(index);
    },
    deleteZone(index){
        Store.deleteZone(index);
    },
    saveNameZone(index, name) {
        Store.saveNameZone(index, name);
    },
    saveNamePlace(index, name, list){
        Store.saveNamePlace(index, name, list);
    },
    getSerch(string){
        Api.setSerch(string,
            (data) => {
                // console.log('actions', data);
                Store.getListBrand(data);
            },
            (err) => {
                alert('Ошибка Не был закончен' + err);
            })
    },
    saveBrandInPlace(index, data) {
        Store.saveBrandInPlace(index, data)
    },
    updateListPlace(list){
        Store.updateListPlace(list);
    },
    updateListPoint(list){
        Store.updateListPoint(list);
    },
    saveMarkerPoint(index, name, list){
        Store.saveMarkerPoint(index, name, list);
    },
    resetDate(){
        Store.resetDate();
    },
    getCheckBoxIsOpen(index, status, list){
        Store.getCheckBoxIsOpen(index, status, list)
    }
    // deleteTracks(index){
    //     Store.deleteTracks(index);
    // }
};
export default Actions;
