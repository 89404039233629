module.exports = {
	UPDATE: 'UPDATE',
	GET_MALLS_LIST: 'GET_MALLS_LIST',
	SHOW_LIST_MALLS: 'SHOW_LIST_MALLS',
	SELECTED_MALL: 'SELECTED_MALL',
	GET_SELECT_MALL: 'GET_SELECT_MALL',
	GET_SEARCH: 'GET_SEARCH',
	SET_STATUS_SHOW_CALENDAR: 'SET_STATUS_SHOW_CALENDAR',
	HIDE_CALENDAR: 'HIDE_CALENDAR',
	GET_DATE_MAX: 'GET_DATE_MAX',
	GET_DATE_MIN: 'GET_DATE_MIN',
	SELECT_ON_MALLS: 'SELECT_ON_MALLS',
	SELECT_OFF_MALLS: 'SELECT_OFF_MALLS',
	GET_QUANTITY: 'GET_QUANTITY',
	SELECT_TODAY: 'SELECT_TODAY',
	SELECT_LAST_MONTH: 'SELECT_LAST_MONTH',
	SELECT_LAST_WEEK: 'SELECT_LAST_WEEK',
	SELECT_FULL_TIME: 'SELECT_FULL_TIME',
	SELECT_INTERVAL: 'SELECT_INTERVAL',
	SELECT_STATUS_LOAD_FALSE: 'SELECT_STATUS_LOAD_FALSE'
};