// import hostName from "../../api/hostName";

class GeometryVSDataManager {
    static setPlanSet(data){
        // console.log('setPlanSet', data)
        return data;
    }
    static setRooms(data){
        this['roomObj'] = {}
        this['roomObjInId'] = {};

        // console.log('setRooms', data)

        for (var i = 0; i < data.length; i++) {
            this.roomObj[data[i].marker] = data[i];
            this.roomObjInId[data[i].id] = data[i];
        }
        // console.log('GeometryVSDataManager',data, this.roomObj)
        // console.log('GeometryVSDataManager',data, this.roomObj)
        return this.roomObj;
    }
    static setFloors(data){
        // console.log('setFloors', data)
        this['floorObj'] = {};
        this.floorObj['obj'] = {}
        this.floorObj['arr'] = []


        for (var i = 0; i < data.length; i++) {
            // console.log('setFloors', data)
            this.floorObj['obj'][data[i].floor] = data[i].id
            this.floorObj['arr'].push(data[i])
        }

        // console.log('GeometryVSDataManager',this.floorObj)
        return this.floorObj
    }
    static setUneqUser(data){
        // console.log('setUneqUser', data)
        var obj = {}
        var maxCount = +data[0].value;
        for(var i = 0; i < data.length; i++) {
            if (maxCount < +data[i].value) {
                maxCount = +data[i].value;
            }
            for(var key in this.roomObj) {
                if (data[i].obj_id === this.roomObj[key].id) {
                    obj[key] = data[i].value
                }
            }
        }
        this['persentsCount'] = {};
        for (var j = 0; j < data.length; j++) {
            // console.log('setUneqUser', maxCount, +data[j].value);
            this.persentsCount[this.roomObjInId[data[j].obj_id].marker] = +data[j].value /  maxCount;
        }
        return obj;
    }
    static reportPersentWithRoom (marker) {
        // console.log('reportPersentWithRoom', marker, this.persentsCount[marker]);

        return this.persentsCount[marker]
    }
    static reportPersentWithZone (marker) {
        // console.log('reportPersentWithZone', marker, this.persentsCountZones);

        return this.persentsCountZones[marker]
    }
    static setUneqUserZones(data) {
        console.log('setUneqUserZones', data)


        var obj = {}
        if (data !== null) {
            var maxCount = +data[0].value;

            for(var i = 0; i < data.length; i++) {
                if (maxCount < +data[i].value) {
                    maxCount = +data[i].value;
                }
                for(var key in this.zonesObj.arr) {

                    if (data[i].obj_id === this.zonesObj.arr[key].id) {
                        obj[this.zonesObj.arr[key].zone_marker] = data[i].value
                    }
                }
            }
            this['persentsCountZones'] = {};
            for (var j = 0; j < data.length; j++) {
                // console.log('setUneqUserZones2', maxCount, data[j], this.zoneObjInId);
                this.persentsCountZones[this.zoneObjInId[data[j].obj_id].zone_marker] = +data[j].value /  maxCount;
            }
        }
        // console.log('setUneqUserZones2', this.persentsCountZones, this.zoneObjInId)
        return obj;
    }
    static setZones(data){
        // console.log('GeometryVSDataManager',data)
        this['zonesObj'] = {};
        this.zonesObj['obj'] = {};
        this.zonesObj['arr'] = [];
        this['zoneObjInId'] = {};
        if (data !== null) {
            for (var i = 0; i < data.length; i++) {
                this.zonesObj['obj'][data[i].zone_marker] = data[i];
                this.zonesObj['arr'].push(data[i])
                this.zoneObjInId[data[i].id] = data[i];
            }
        }

        // console.log('GeometryVSDataManager',this.zonesObj)
        return this.zonesObj.obj;
    }
    clear(){


    }
    static reportObjWithPlace(place_marker){
        // console.log(this.roomObj, place_marker)
        if (this.roomObj[place_marker] === undefined) {
            return undefined;
        }
        return this.roomObj[place_marker].id
    }

    static reportObjWithFloor(floor_number){
        // console.log('reportObjWithFloor', this.floorObj)
        if (this.floorObj === undefined) {
            return undefined;
        }
        return this.floorObj.obj[floor_number]
    }
    static reportIdWithZoneMarker (id) {
        // console.log('>>>>><<<<<<', this.zonesObj, id)
        for (var i = 0; i < this.zonesObj.arr.length; i++) {
            if (this.zonesObj.arr[i].id === id) {
                return this.zonesObj.arr[i].zone_marker;
            }
        }
    }
    static reportObjWithZone(zone_name){
        console.log('GeometryVSDataManager2', this.zonesObj.obj[zone_name]);
        if (this.zonesObj.obj[zone_name] !== undefined) {
            return this.zonesObj.obj[zone_name].id;
        }
        return null;
    }
}

module.exports = GeometryVSDataManager
