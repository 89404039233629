var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');

var ListItem = React.createClass({
	getInitialState:function () {
		return{
			statusSelectMall: false,
			style: {background: '#2f3949'}
		};
	},
	getSelectMall: function () {
		if (this.props.index !== undefined) {
			var data = {
				data:this.props.prop,
				index: this.props.index
			}
			return;
		}
		var data = this.props.prop;
		AppActions.getSelectMall(data);
	},
	render: function () {
		// console.log('listItem.jsx');
		// var listItem;
		// if (this.props.prop.indexSearch > 0) {
		// 	listItem = <li style={this.props.prop.style.style} onClick={this.getSelectMall} id={this.props.prop.value.id} className="mall">{this.props.prop.value.name}</li>
		// }
		// else if (this.props.prop.indexSearch <= 0) {
		// 	listItem = null;
		// }
		return(
			<li style={this.props.prop.style.style} onClick={this.getSelectMall} id={this.props.prop.value.data.id} className="mall">{this.props.prop.value.data.name}</li>
		);
	}
});

module.exports = ListItem;