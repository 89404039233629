var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var lang = require('../../../lang/func.js');

var Tabs = React.createClass({
	getInitialState: function () {
		return ({
			statusShowAnalitics: 'maps'
		});
	},
	onClickMaps: function () {
		if (this.state.statusShowAnalitics === 'route') {
			AppActions.showAnalitiscMethod('maps');
            setTimeout(() => {
                AppActions.showAnalitiscMethod('maps');
            }, 1)
		}
	},
	onClickRoute: function () {
		if (this.state.statusShowAnalitics === 'maps') {
			AppActions.showAnalitiscMethod('route');
		}
	},
	_onChange: function(store) {
		if (this.internalState.isMounted === true) {
			this.setState({
                statusShowAnalitics: AppStore.getState().statusAnalitics
            });
		}
    },
    internalState: {
		isMounted: false
	},
    componentDidMount: function () {
    	this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	render: function () {
		// console.log('rendernder', lang.getState())
		var classNameRouteButton = 'activeButton', classNameMapsButton = 'activeButton';
		if (this.state.statusShowAnalitics === 'route') {
			classNameMapsButton = 'notActiveButton';
		}
		else if (this.state.statusShowAnalitics === 'maps') {
			classNameRouteButton = 'notActiveButton';
		}
		return(
			<div className='tabMaps'>
				<button className={classNameMapsButton}onClick={this.onClickMaps} type='button'>{lang.getState().maps.intersections.title}</button>
                <button className={classNameRouteButton} onClick={this.onClickRoute} type='button'>{lang.getState().maps.route.title}<span className='statusDevRun'>Beta</span></button>
			</div>
			);
	}
});

module.exports = Tabs;
