var AppDispatcher = require('../dispatcher/dispatcher.js');
var AppConstants = require('../constants/constants.js');
var AppStores = require('../stores/store.js');

var Api = require('../api/api.js');

var stateJSON = 0;

function setCommonStateStore(state) {
    if (JSON.stringify(state) !== stateJSON) {
        ControlPanelActions.update(state);
        stateJSON = JSON.stringify(state);
    }
}

var Action = {
	update: function (state) {
		if (state.commonState.insetName !== undefined && state.commonState.max_timestamp !== undefined) {
			if (state.commonState.selectedObjects !== undefined) {
				if (JSON.stringify(state) !== stateJSON) {
			        Action.updatePanel(state);
			        stateJSON = JSON.stringify(state);
			    }
			}
		}
		if (state.commonState.insetName !== undefined) {
			if (AppStores.getState().mainState.hasOwnProperty('commonState') === false) {
				AppDispatcher.handleViewAction({
					actionType:AppConstants.UPDATE,
					state: state
				});
				Action.setFunnel(state);
				Action.setChart(state);
				Action.setSms(state);
				Action.getUniqueNumber(state);
				Action.getSocialAnalitics(state);
				Action.getChartsSocialAnalitics(state);
				if (JSON.stringify(state) !== stateJSON) {
			        Action.updatePanel(state);
			        stateJSON = JSON.stringify(state);
			    }
			}
			else if (AppStores.getState().mainState.commonState !== null && AppStores.getState().mainState.max_timestamp !== undefined && AppStores.getState().mainState.min_timestamp !== undefined) {
				if (AppStores.getState().mainState.max_timestamp.yyyymmdd() !== state.commonState.max_timestamp.yyyymmdd()) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					});
					Action.setFunnel(state);
					Action.setChart(state);
					Action.setSms(state);
					Action.getUniqueNumber(state);
					Action.getSocialAnalitics(state);
					Action.getChartsSocialAnalitics(state);
					// if (JSON.stringify(state) !== stateJSON) {
				 //        Action.updatePanel(state);
				 //        stateJSON = JSON.stringify(state);
				 //    }
				}
				if (AppStores.getState().mainState.min_timestamp.yyyymmdd() !== state.commonState.min_timestamp.yyyymmdd()) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					});
					Action.setFunnel(state);
					Action.setChart(state);
					Action.setSms(state);
					Action.getUniqueNumber(state);
					Action.getSocialAnalitics(state);
					Action.getChartsSocialAnalitics(state);
					// if (JSON.stringify(state) !== stateJSON) {
				 //        Action.updatePanel(state);
				 //        stateJSON = JSON.stringify(state);
				 //    }
				}
				if (AppStores.getState().mainState.selectedObjects !== state.commonState.selectedObjects) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					});
					Action.setFunnel(state);
					Action.setChart(state);
					Action.setSms(state);
					Action.getUniqueNumber(state);
					Action.getSocialAnalitics(state);
					Action.getChartsSocialAnalitics(state);
					// if (JSON.stringify(state) !== stateJSON) {
				 //        Action.updatePanel(state);
				 //        stateJSON = JSON.stringify(state);
				 //    }
				}
				if (state.commonState.insetName !== AppStores.getState().mainState.insetName) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					});
					Action.setFunnel(state);
					Action.setChart(state);
					Action.setSms(state);
					Action.getUniqueNumber(state);
					Action.getSocialAnalitics(state);
					Action.getChartsSocialAnalitics(state);
					// if (JSON.stringify(state) !== stateJSON) {
				 //        Action.updatePanel(state);
				 //        stateJSON = JSON.stringify(state);
				 //    }
				}
			}
		}
	},
	updatePanel: function (state) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.UPDATE_SIDE_PANEL,
			state: state
		});
		Action.setFunnel(state);
		Action.setChart(state);
		Action.setSms(state);
		Action.getUniqueNumber(state);
		Action.getSocialAnalitics(state);
		Action.getChartsSocialAnalitics(state);
	},
	selectAnaliticsMethod: function (method) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_ANALITICS_METHOD,
			method: method
		})
	},
	setFunnel: function (state) {
		if (state.commonState.insetName === 'HOTSPOT') {
			Api.createFunnelAnalitics(state,
				function (data) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.GET_FUNNEL,
						data: data
					})
				},
				function (err) {
					console.error(err);
				}
			);
		}
	},
	setChart: function (state) {
		if (state.commonState.insetName === 'HOTSPOT') {
			Api.createChatAnalitics(state,
				function (data) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.GET_CHART,
						data: data
					})
				},
				function (err) {
					console.error(err);
				}
			);	
		}
	},
	setSms: function (state) {
		if (state.commonState.insetName === 'HOTSPOT') {
			Api.createSmsAnalitics(state,
				function (data) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.GET_SMS,
						data: data
					})
				},
				function (err) {
					console.error(err);
				}
		);	
		}
	},
	getUniqueNumber:function (state) {
		if (state.commonState.insetName === 'HOTSPOT') {
			Api.getUniqueNumber(state,
				function (data) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.GET_UNIQUE_NUMBER,
						data: data
					})
				},
				function (err) {
					console.error(err);
				}
			);
		}
	},
	getSocialAnalitics: function (state) {
		if (state.commonState.insetName === 'HOTSPOT') {
			Api.getSocialAnalitics(state,
				function (data) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.GET_SOCIAL_ANALITICS,
						data: data
					})
				},
				function (err) {
					console.error(err);
				}
			);
		}
	},
	getChartsSocialAnalitics: function (state) {
		if (state.commonState.insetName === 'HOTSPOT') {
			Api.getSocialChartsAnalitics(state,
				function (data) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.GET_CHARTS_SOCIAL_ANALITICS,
						data: data
					})
				},
				function (err) {
					console.error(err);
				}
			);
		}
	}
}

module.exports = Action;


