var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');

var Step = require('./funnelStepItem.jsx');

var Funnel = React.createClass({
	render: function () {
		var steps = [];
		for (var i = 0; i < this.props.prop.steps.length; i++) {
			var dataSum2 = 0;
			var obj = {};
			obj.step = [];
			for (var j = 0; j < this.props.prop.steps[i].length; j++) {
				var dataSum = 0;
				for (var key in this.props.prop.details) {
					dataSum = dataSum + this.props.prop.details[key].script_stages_passed[i][this.props.prop.steps[i][j]];
				}
				// var persent = Math.round(((dataSum / this.props.prop.sumTotal) * 100) * 10) / 10;
				var persent = (dataSum / this.props.prop.sumTotal) * 100;
				// var persentFirst = (dataSum / this.props.prop.sumTotal) * 100;
				// persent = persentFirst.toPrecision(2);
				var objStep = {
					name: this.props.prop.steps[i][j],
					persent: persent,
					data: dataSum
				}
				obj.step.push(objStep);	
			}
		 	steps.push(obj);
		}
		var stepsNode = steps.map(function(prop, id) {
			return (<Step prop={prop} key={id} id={id} />);
		});
		var positionInternetStepX;
		var positionInternetStepXText;
		if (this.props.prop.steps.length === 0) {
			positionInternetStepX = 250;
			positionInternetStepXText = 305;
		}
		else if (this.props.prop.steps.length === 1) {
			positionInternetStepX = 450;
			positionInternetStepXText = 505;
		}
		else if (this.props.prop.steps.length === 2) {
			positionInternetStepX = 650;
			positionInternetStepXText = 705;
		}
		else if (this.props.prop.steps.length === 3) {
			positionInternetStepX = 850;
			positionInternetStepXText = 905;
		}
		return (
			<svg style={{width: '100%', height: '212px', textTransform: 'uppercase'}}>
				<g className='RadiusConnect'>
					<rect className='radiusConnectRect' x="50" y="35"  width="110" height="85" ></rect>
					<text x="105" y="70" style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} fill='#fff'>Подключения</text>
					<text x="105" y="90" style={{textAnchor: 'middle', fontSize: '20px'}} fill='#45d976' >{this.props.prop.sumTotal}</text>
					<image xlinkHref='/img/hotspotstats01arrows.png' className='radiusImageIcon' fill='green' x="180" y="55" height="50px" width="50px"></image>
					<text className='RadiusPersentItemStep' x="205" style={{textAnchor: 'middle', fontSize: '20px'}} y="40" fill='rgb(102, 102, 102)' >100%</text>
					<text x="170" y="135" fill='rgb(102, 102, 102)' >All connections</text>
				</g>
				{stepsNode}
				<g className='RadiusInternet'>
					<rect x={positionInternetStepX} y="35" width="110" height="85"  ></rect>
					<text style={{textAnchor: 'middle', fontSize: '12px', fontFamily: 'ProxmiaNovaSemibold'}} x={positionInternetStepXText} y="82" fill='#fff'>internet</text>
				</g>
			</svg>
		);
	}
});

module.exports = Funnel;