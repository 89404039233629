/**
 * Created by kirilldrozdov on 01.02.17.
 */
var tultipHotspot = require("./tultipBoxRu.jsx");
var translation = {

    "connections": {
        "tab_title": "Подключения",
        "unique_wifi_clients_per_day": "ДИНАМИКА УНИКАЛЬНЫХ ПОЛЬЗОВАТЕЛЕЙ Wi-Fi ПО ДНЯМ",
        "unique_all_clients": "Все пользователи",
        "unique_authorized_clients": "Прошедшие авторизацию",
        "average_clients_per_day": "В СРЕДНЕМ ПОЛЬЗОВАТЕЛЕЙ ЗА ДЕНЬ",
        "average_authorized_clients_per_day": "В СРЕДНЕМ АВТОРИЗОВАВШИХСЯ ЗА ДЕНЬ",
        "connections_per_day": "ДИНАМИКА ПОДКЛЮЧЕНИЙ К СЕТИ WIFI",
        "connections_trying": "Запуски подключений",
        "connections_passed": "Пройденные авторизации",
        "total_connections": "ПОДКЛЮЧЕНИЙ ЗА ПЕРИОД",
        "completed_authorizations": "ПРОЙДЕННЫХ АВТОРИЗАЦИЙ ЗА ПЕРИОД",
        "auth_schemes": "СТАТИСТИКА ПО СХЕМАМ АВТОРИЗАЦИИ",
        "count": "Кол-во",
        "funnelConnect": "Подключения"
    },
    "connectionsTultip": null,
    "sms": {
        "tab_title": "СМС",
        "total_sms": "ВСЕГО СМС",
        "total_numbers": "ВСЕГО НОМЕРОВ",
        "total_validated_numbers": "ВСЕГО ПОДТВЕРЖДЕННЫХ НОМЕРОВ",
        "download_report": "СКАЧАТЬ ОТЧЕТ ПО СМС В excel",
        "table_titles": ["ТЕЛЕФОН", "КОЛ-ВО СМС", "ПОДТВЕРЖДЕН"]
    },

    "social": {
        "tab_title": "Соц. Профили",
        "table_titles": ["АВАТАР", "ИМЯ", "ДАТА РОЖДЕНИЯ", "EMAIL", "ВРЕМЯ ПЕРВОГО ПОДКЛЮЧЕНИЯ", "ВРЕМЯ ПОСЛЕДНЕГО ПОДКЛЮЧЕНИЯ", "КОЛИЧЕСТВО ПОДКЛЮЧЕНИЙ", "ЧИСЛО ДРУЗЕЙ"]
    },

    "pages": {
        "tab_title": "Управление страницами",
        "schedule": {
            "title": "ПЛАНИРОВАНИЕ",
            "time_of_day": "Время суток",
            "days_of_week": "Дни недели",
            "period": "Период",
            "create_for_other_objects": "Создать подобное планирование для всех объектов",
            "button_always": "Всегда",
            "panel_time": "Время",
            "dayOfWeek": {
                "Monday": "Пн",
                "Tuesday": "Вт",
                "Wednesday": "Ср",
                "Thursday": "Чт",
                "Friday": "Пт",
                "Saturday": "Сб",
                "Sunday": "Вс"
            },
            "or": "или",
            "always": "всегда",
            "from": "с",
            "by": "по",
            "to": "до",
            "close": "Закрыть",
            "save": "Сохранить изменения"
        },
        "link": {
            "setting": "Настроить",
            "schedule": "Запланировать",
            "delete": "Удалить",
            "copy": "Копировать",
            "show": "Посмотреть",
            "settings_tip": "Что бы запланировать данную страницу необходимо настроить параметры авторизации",
            "schedule_tip": "Для редактирования шаблона лэндинга создайте копию"
        },
        "settings": {
            "title": "НАСТРОЙКИ",
            "constructor": "Конструктор",
            "button_continue": "Продолжить",
            "button_close": "Закрыть",
            "button_save": "Сохранить изменения",
            "button_preview": "Предварительный просмотр",
            "preparation": "Подготовка...",
            "titleAuth":"Выбор авторизации",
            "titlePerfom": "Настройки представления",
            "preview": {
                "title": "Настройки представления",
                "background": "Фон страницы",
                "logo": "Логотип",
                "addImage":"Добавить изображение",
                "orBackground":"или цвет фона",
                "text": "Текст",
                "text_color": "Цвет текста",
                "media_block": "Графический блок",
                "auth_button_color": "Цвет кнопки \"ВОЙТИ В ИНТЕРНЕТ\"",
                "constructor_link": "Для дополнительных настроек представления перейдите в"
            },
            "auth": {
                "title": "Выбор авторизации",
                "image":"Изображение",
                "link":"Ссылка",
                "titleName":"Заголовок",
                "discription":"Описание",
                "idPost":"ID поста",
                "apps":"Приложения",
                "message":"Сообщение",
                "repost":"Репост",
                "OnBehalfOfTheUser":"От имени пользователя",
                "sms": "Обязательная СМС авторизация",
                "button": "Кнопка \"Войти в интернет\"",
                "vk": "ВКонтакте",
                "vk_autopost": "Автопост",
                "fb": "Facebook",
                "fb_authpost": "Автопост",
                "ok": "Одноклассники",
                "password": "Авторизация по паролю",
                "internet_session_period": "Период интернет сессии (минуты)",
                "authorization_period": "Период запроса авторизации (минуты)",
                "traffic_limit": "Ограничения по трафику (МБ)",
                "redirect_url": "Ссылка редиректа",
                "constructor_link": "Для расширенных настроек авторизации перейдите в",
                "textNotAuth":{
                    "first":"На данной странице настроена более сложная схема авторизации. Для ее редактирования воспользуйтесь",
                    "constructor":"конструктором",
                    "second":"страниц. Также вы можете",
                    "reset":"сбросить",
                    "third":"установленные параметры и настроить заново."
                }
            }
        }
    },
    "calendar": {
        "today": "Сегодня",
        "yesterday": "Вчера",
        "last_7_days": "Последние 7 дней",
        "last_30_days": "Последние 30 дней",
        "this_month": "Этот месяц",
        "last_month": "Прошлый месяц",
        "custom_period": "Произвольный период",
        "applyLabel": "Применить",
        "cancelLabel": "Закрыть",
        "fromLabel": "С",
        "toLabel": "По",
        "weekLabel": "Неделя",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ]
    },
    "bigCalendar": "ru",
    "bigCalendarTolbar": {
        "previous_month": "Предыдущий месяц",
        "current_month": "Текущий месяц",
        "next_month": "Следующий месяц"
    },
    "sidebar": {
        "button_select_objects": "ВЫБРАТЬ ОБЪЕКТ(Ы)",
        "num_selected_objects": "Выбрано объектов:",
        "support": "Поддержка"
    },
    "empty_data" : "Данные отсутствуют",
    "profile": {
        "button_logout": "Выйти"
    },
    "cunstructor": {
        "create_new_page": "создать новую",
        "page_parameters": {
            "title": "Параметры страницы",
            "savePages":"Сохраненные страницы",
            "background_color": "Цвет фона",
            "background_image": "Фоновое изображение",
            "select_image": "Выбор изображения",
            "save":"Сохранить",
            "not_chosen":"Не выбрано",
            "all_objects":"Все объекты",
            "clear":"Очистить",
            "bind_to_object":"Привязать к объекту",
            "you_must_specify_the_authorization_parameters":"Необходимо задать параметры авторизации.",
            "questionDeletePage":"Удалить страницу?"
        },
        "add_block": "Добавить блок",
        "block_parameters": {
            "title": "Параметры блока",
            "selectBlock":"Выделите блок",
            "select_block": "Выделить блок",
            "background_image": "Фоновое изображение",
            "select_image": "Выбор изображения",
            "background_color": "Цвет фона",
            "font": "Шрифт",
            "block_text": "Текст блока",
            "block_link": "WEB ссылка (срабатывает при нажатии на блок)",
            "rounding": "Скругление (px)",
            "margin_top": "Отступ сверху (px)",
            "margin_bottom": "Отступ снизу (px)",
            "margin_left": "Отступ слева (px)",
            "margin_right": "Отступ справа (px)",
            "turnon_widget": "Включить виджет",
            "fit_the_whole": "Вместить целиком",
            "the_authorization_unit":"Авторизационный блок",
            "widget":"Виджет",
            "text":"Текст",
            "textBlock":"Текст блока"
        },
        "auth_parameters": {
            "is_auth_page": "Авторизационнай страница",
            "auth_parameters_page": {
                "button_title": "Параметры авторизации",
                "auth_layers_count": "Количество уровней авторизации",
                "layer": {
                    "sms": "SMS авторизация",
                    "sms_remember": "запомнить на (дни)",
                    "call": "Авторизация по звонку",
                    "cal_remember": "запомнить звонок на (дни)",
                    "button": "Кнопка \"войти в интернет\"",
                    "vk": {
                        "title": "Вход через Vkontakte",
                        "autopost": "Автопост",
                        "post": "От имени пользователя",
                        "repost": "Репост",
                        "message": "Сообщение",
                        "attachment": "Приложение",
                        "post_id": "ID поста"
                    },
                    "fb": {
                        "title": "Вход через Facebook",
                        "autopost": "Автопост",
                        "post_link": "Ссылка",
                        "post_title": "Заголовок",
                        "description": "Описание",
                        "add_image": "Добавить изображение",
                        "deleteImage": "Удалить изображение"
                    },
                    "ok": "Вход через Odnoklassniki",
                    "password": "Вход с паролем",
                    "password_parameter": "Пароль для входа",
                    "external": {
                        "title": "Сторонняя авторизация",
                        "external_url": "Внешний URL",
                        "confirm_url": "URL запроса проверки"
                    }
                }
            },
            "session_duration": "Максимальная длительность сессии (минуты)",
            "new_connection_in": "Длительность до следующего подключения (минуты)",
            "auth_period": "Период запроса авторизации (минуты)",
            "traffic_volume": "Максимальный объем трафика (мб)",
            "rdirect_url": "Ссылка редиректа",
            "close": "Закрыть",
            "levelAuth": "Уровень авторизации"
        },
        "button_internet_color": "Цвет фона кнопки \"Войти в интернет\"",
        "button_internet_color2":"по умолчанию: rgba(51,71,80,0.9)",
        "auth_panner_color": "Цвет фона панели авторизации",
        "auth_panner_color2":"по умолчанию: rgba(51,71,80,0.9)",
        "button_internet_text": "Текст на кнопке авторизации",
        "button_internet_text2":"по умолчанию: \"ВОЙТИ В ИНТЕРНЕТ\"",
        "skip_landing": "Пропускать отображение лэндинга (сразу переходить к авторизации)",
        "authorization_page":"Авторизационная страница",
        "edit": "Изменить",
        "backButton": "Вернуться назад",
        "titleEmpty": "Заголовок пустой...",
        "create": "Создать",
        "you_must_specify_the_name_of_the_page": "Необходимо указать название страницы",
        "level1": "1 Уровень",
        "level2": "2 Уровня",
        "level3": "3 Уровня",
        "mediaBox":"Медиатека",
        "uploadFiles":"Загрузить",
        "fileFormTitleFile":"Название",
        "tags_separated_by_commas":"Теги, через запятую",
        "cancel":"Отмена",
        "addFile":"Добавить",
        "addNewFile":"Добавить новый медиа файл"
    },
    "maps": {
        "title":"Карты",
        "route":{
            "title":"Маршруты",
            "select":"Выбрать...",
            "no_data":"Нет данных",
            "the_list_is_loading":"Идет загрузка списка…",
            "data_loading":"Идет загрузка...",
            "first_panel": {
                "type_point": {
                    "point":"По точкам",
                    "zone":"По зонам",
                    "floor":"По этажам"
                },
                "type_top":{
                    "top_5":"Топ 5",
                    "top_10":"Топ 10",
                    "top_20":"Топ 20"
                },
                "type_steps":{
                    "title":"шагов",
                    "arbitrarily":"произвольно",
                    "exactly":"ровно",
                    "range":"диапазон"
                },
                "engagement_threshold":"Порог вовлеченности",
                "input_it_contains":"содержит"
            },
            "second_panel":{
                "any":"А и B - любые",
                "any_part":"Любые",
                "begin":"А - место начала маршрута посетителя",
                "end":"Б - место окончания маршрута посетителя",
                "full":"А - место начала и Б - место конца",
                "button_show_routes":"Показать маршруты"
            },
            "routes":{
                "total_conversion":"Общая конверсия",
                "steps":"Шагов",
                "people_at_end_route":"Людей в конце маршрута",
                "floor":"Этаж"
            }
        },
        "intersections":{
            "title":"Пересечения",
            "previews":{
                "floor":"Этаж"
            },
            "button_show_names":"Отобразить названия",
            "button_show_count_visitors":"Отобразить количество посетителей",
            "button_hide_names":"Скрыть названия",
            "button_hide_count_visitors":"Скрыть количество посетителей",
            "right_panel":{
                "not_selected_lable":"Выделите зону для просмотра информации о пересечении с другими зонами.",
                "floor":"Этаж"
            },
            "background":"Отобразить подложку"
        }
    }

};


module.exports = translation;
