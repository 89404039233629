var AppDispatcher = require('../dispatcher/dispatcher.js');
var AppConstants = require('../constants/constants.js');
var AppStores = require('../stores/store.js');
var Api = require('../api/api.js');

var Action = {
	update: function (state) {
		if (state.commonState.insetName !== undefined) {
			if (AppStores.getState().mainState.hasOwnProperty('commonState') === false) {
				AppDispatcher.handleViewAction({
					actionType:AppConstants.UPDATE,
					state: state
				})
				Action.getMallsList(state.commonState);
			}
			else if (AppStores.getState().mainState.commonState !== null && AppStores.getState().mainState.max_timestamp !== undefined && AppStores.getState().mainState.min_timestamp !== undefined) {
				if (AppStores.getState().mainState.max_timestamp !== state.commonState.max_timestamp) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					})
					Action.getMallsList(state.commonState);
				}
				if (AppStores.getState().mainState.min_timestamp !== state.commonState.min_timestamp) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					})
					Action.getMallsList(state.commonState);
				}
				if (AppStores.getState().mainState.selectedObjects !== state.commonState.selectedObjects) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					})
					Action.getMallsList(state.commonState);
				}
				if (state.commonState.insetName !== AppStores.getState().mainState.insetName) {
					AppDispatcher.handleViewAction({
						actionType:AppConstants.UPDATE,
						state: state
					})
				}
			}
		}
	},
	getMallsList: function(state) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_STATUS_LOAD_FALSE
		});
		Api.getMallsList(state,
			function (data) {
				AppDispatcher.handleViewAction({
					actionType:AppConstants.GET_MALLS_LIST,
					data: data
				})
			},
			function (err) {
				console.error(err);
			}
		);
	},
	showListMalls: function() {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SHOW_LIST_MALLS
		})
	},
	selectedMall: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECTED_MALL
		})
	},
	getSelectMall: function (data) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.GET_SELECT_MALL,
			data: data
		})
	},
	getSearch: function (data) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.GET_SEARCH,
			data: data
		})
	},
	setStatusShowCalendar: function (status) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SET_STATUS_SHOW_CALENDAR,
			status: status
		})
	},
	hideCalendar: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.HIDE_CALENDAR
		})
	},
	getMaxDate: function (date) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.GET_DATE_MAX,
			date: date
		})
	},
	getMinDate: function (date) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.GET_DATE_MIN,
			date: date
		})
	},
	selectOnMalls: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_ON_MALLS
		})
	},
	selectOffMalls: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_OFF_MALLS
		})
	},
	getQuantity: function (data) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.GET_QUANTITY,
			data: data
		})
	},
	selectToday: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_TODAY
		})
	},
	selectLastMonth: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_LAST_MONTH
		})
	},
	selectLastWeek: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_LAST_WEEK
		})
	},
	selectFullTime: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_FULL_TIME
		})
	},
	selectInterval: function (name) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECT_INTERVAL,
			name: name
		})
	}
}

module.exports = Action;