import React from 'react';
import ReactDOM from 'react-dom';
import {ButtonToolbar, ButtonGroup, Button} from 'react-bootstrap';
import $ from "jquery";
import {Holst, Drawer} from './holst';
import DrowerHolst from '../lib/shopster-lib-js-maps/lib/drawerHolst';
import MapAndSvg from "./mapAndSvg";
var getHeatColor = require('../tools/color.js');
var GeometryVSDataManager = require('../../../tools/GeometryVSDataManager');

var JSONData = 0;
var JSONSvgElements = 0;
var JSONScale = 0;
var nameMarker = ''
class DrawerPlaces extends DrowerHolst {
    static initShapes(shapes) {
        //поправить
        this['shapes'] = {
            polygons: [],
            retailName: [],
            namePolygons:[],
            checkLis: [],
            objPolygon : {
                polygons: [],
                retailName: [],
                namePolygons: [],
                checkLis: []
            },
            roomsTransition: {},
            rooms:{},
            dataUneqUsers: {},
            showCounts: false,
            statusColorCount: false
        };
    }
    static initProps(props, func1, func2, onClickPoint){
        this['props'] = props;
        this['onClickPoint'] = func1;
    }
    static setStatusEdit(status) {
        this.shapes.statusEdit = status;
    }
    static componentWillReceiveProps(nextProps, func1, func2, onClickPoint) {
        // console.log('fasdasd', nextProps)
        if (nextProps.traksPlace !== undefined) {
            this.shapes.polygons = nextProps.traksPlace;
        }
        if (nextProps.retailNamePlace !== undefined) {
            this.shapes.retailName = nextProps.retailNamePlace;
        }
        if (nextProps.statusColorCount !== undefined) {
            this.shapes.statusColorCount = nextProps.statusColorCount
        }
        if (nextProps.namePolygonsPlace !== undefined) {
            this.shapes.namePolygons = nextProps.namePolygonsPlace;
        }
        if (nextProps.checkListPlace !== undefined) {
            this.shapes.checkLis = nextProps.checkListPlace
        }
        this.shapes.rooms = nextProps.rooms
        // console.log('componentWillReceiveProps', nextProps.roomsTransition)
        if (Object.keys(nextProps.roomsTransition).length === 0) {
            if (JSON.stringify(nextProps.roomsTransition) !== JSON.stringify(this.shapes.roomsTransition)) {
                this.shapes.selectedShape = null;
            }
        }
        this.shapes.showCounts = nextProps.showCounts
        this.shapes.dataUneqUsers = nextProps.dataUneqUsers
        this.shapes.roomsTransition = nextProps.roomsTransition
        this.shapes.objPolygon = {
            polygons: this.shapes.polygons,
            retailName: this.shapes.retailName,
            namePolygons: this.shapes.namePolygons,
            checkLis: this.shapes.checkLis
        }
        // debugger
    }
    static onClickPointPoly(e, data, indexArr){
        e.preventDefault();
        this.shapes.arrIndexPoly = indexArr;
        this.shapes.statusContextPoly = true;
        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        this.onClickPoint(true,e, data, indexArr)
        // this.setState({});
    }
    static holstOnClick(x, y, scale, event) {
        // this.shapes.statusDelete = false;
        // this.shapes.xCoor = x/scale;
        // this.shapes.yCoor = y/scale;
        // $(e.target).parents().filter('#boxContext').length === 0 && $(e.target).parents().filter('#selectShowGetOut').length === 0
        // if (this.shapes.markerItem === null) {
        //     if (event.nativeEvent.which === 1 && $(event.target).parents().filter('#boxContext').length === 0) {
        //         this.shapes.statusContextPoly = false;
        //         if (this.shapes.gridData !== null) {
        //             // this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null);
        //             // console.log('>>>>>>>', scale)
        //             x = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[0];
        //             y = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[1];
        //         }
        //         var mode = this.props.getMode;
        //         // console.log('holst', this.shapes.polygons);
        //         var p = [x/scale, y/scale];
        //
        //         if (this.shapes.selectedPointMoved) {
        //             return;
        //         }
        //
        //         if (this.shapes.selectedShape) {
        //             return;
        //         }
        //
        //         // var points = this.shapes.points;
        //         // var lines = this.shapes.lines;
        //         // var polygons = this.shapes.polygons;
        //
        //
        //         var isOpened = this.shapes.polygonIsOpened;
        //         var polygons = this.shapes.polygons;
        //         // console.log('holst', this.shapes.polygons, this.shapes.polygonIsOpened);
        //         if (isOpened) {
        //             var lastPolygon = polygons[polygons.length-1];
        //             var p0 = lastPolygon[0];
        //             var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
        //             // console.log('poly!!!!!!', polygons, this.shapes.polygonIsOpened);
        //             if (d < this.state.height * 0.013) {
        //                 if (polygons[polygons.length-1].length > 2) {
        //                     this.shapes.polygonIsOpened = false;
        //                     this.shapes.statusSvgCloseElem = true;
        //                 }
        //                 // this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]]);
        //             } else {
        //                 // console.log('poly!!!!!!', polygons);
        //                 polygons[polygons.length-1].push(p)
        //             }
        //         } else {
        //             // console.log('poly', polygons);
        //             polygons.push([p]);
        //             this.shapes.polygonIsOpened = true;
        //         }
        //         // this.setState({
        //         //     polygon:polygons
        //         // });
        //         this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
        //         // this.shapes.polygons = polygons;
        //         // console.log('poly>>>>>>>', this.shapes.polygons, this.shapes.polygonIsOpened);
        //         // this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]]);
        //
        //     }
        // }
        // this.emitChangeToModuleListeners();

    }
    static holstMouseMove(x, y, scale) {
        // console.log(x, y)
        this.shapes.coordArr = [Math.round(x/scale),Math.round(y/scale)]
        // this.setState({});

        // if (this.shapes.gridData !== null) {
        //     // this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null);
        //     // console.log('>>>>>>>', scale)
        //     x = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[0];
        //     y = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[1];
        //     this.shapes.coorXPoint = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[0];
        //     this.shapes.coorYPoint =  this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[1];
        // }
        // else if (this.shapes.gridData === null) {
        //     this.shapes.coorXPoint = x;
        //     this.shapes.coorYPoint = y;
        // }
        var mode = this.props.getMode;
        var p = [x/scale, y/scale];
        this.shapes.cursorPoint = p;

        if (this.shapes.selectedPoint) {
            this.shapes.selectedPointMoved = true;
        }

        this.shapes.cursorMoved = true;

        if (this.shapes.selectedPoint && this.shapes.selectedPointMoved) {
            var i = this.shapes.selectedPoint.is[0];
            var j = this.shapes.selectedPoint.is[1];
            this.shapes.polygons[i][j] = p;
            this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
        }

        var polygons = this.shapes.polygons;
        this.shapes.polygonCloseStatus = false;
        this.shapes.polyLineCloseStatus = false;
        var lastPolygon = polygons[polygons.length-1];
        if (lastPolygon !== undefined) {
            var p0 = lastPolygon[0];
            var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
            if (d < this.state.height * 0.013) {
                if (polygons[polygons.length-1].length > 2) {
                    this.shapes.polygonCloseStatus = true;
                }
            }
        }
    }
    static selectShape(shapeType, i) {
        console.log('selectShape');
        this.shapes.selectedShape = {
            shapeType: shapeType,
            i: i
        }
        if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
            this.props.onSelectPolygons(i, true);
        }
        this.shapes.cursorMoved = false;
    }
    static holstMouseUp(x, y, scale, e) {
        if (e.nativeEvent.which !== 3) {
            // console.log('holstMouseUp', this.shapes.selectedPointMoved)
            this.shapes.selectedPoint = null;
            this.shapes.selectedPointMoved = false;
            if (this.shapes.selectedShape && this.shapes.cursorMoved) {
                if (this.shapes.selectedPointMoved === false) {
                    if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
                        this.props.onSelectPolygons(0, false);
                    }
                    this.shapes.selectedShape = null;
                }
            }
            this.shapes.selectedPoint = null;
            this.shapes.selectedPointMoved = false;
            if (this.props.updateShapes) {
                this.shapes = this.props.updateShapes(this.shapes);
            }
        }
    }
    static trashClick() {
        this.shapes.statusDelete = true;
        // console.log('!@#!@#@!', this.emitChangeToModuleListeners);
        if (this.shapes.selectedShape) {
            // console.log('!@#!@#@!');
            var shape = this.shapes.selectedShape;
            this.shapes.polygons.splice(shape.i, 1);
            this.props.getRouters(this.shapes.polygons, [], true, shape.i);
            if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
                this.props.onSelectPolygons(0, false);
            }
            this.emitChangeToModuleListeners();
            this.shapes.selectedShape = null;
            if (this.props.updateShapes) {
                this.shapes = this.props.updateShapes(this.shapes);
            }
        }
        // console.log('!!!!', this.shapes.polyLIne)
    }
    static keyDown(event){
        console.log(event.which)
        if (event.which === 8) {
            if (this.shapes.polygonIsOpened === true) {
                // console.log(event.which, this.shapes.polygons[this.shapes.polygons.length - 1]);
                if (this.shapes.polygons[this.shapes.polygons.length - 1].length === 1) {
                    // console.log(event.which, this.shapes.polygons[this.shapes.polygons.length - 1]);
                    this.shapes.polygons.splice(this.shapes.polygons.length - 1, 1);
                    this.shapes.polygonIsOpened = false;
                    // this.setState({});
                    // console.log(event.which, this.shapes.polygons);
                    this.props.getRouters(this.shapes.polygons, [], false, null);
                    return;
                }
                // console.log('event.which', this.shapes.polygons);
                this.shapes.polygons[this.shapes.polygons.length - 1].splice(this.shapes.polygons[this.shapes.polygons.length - 1].length - 1, 1);
                this.props.getRouters(this.shapes.polygons, [], false, null);
                // this.setState({});

            }
        }
        if (event.which === 27) {
            var shape = this.shapes.selectedShape;
            this.shapes.polygons.splice(shape.i, 1);
            this.props.getRouters(this.shapes.polygons, [], false, null);
        }
    }
    static getSvgElementsForDraw(scale){
        // console.log('getSvgElementsForDraw');
        // if (this.props.changeShapes) {
        //     this.shapes = this.props.changeShapes(this.shapes);
        // }
        //
        // var stylePoint = {
        //     cursor: 'move'
        // }
        var styleLine = {
            cursor: 'pointer'
        }
        //
        var elements = [];
        var elementsDraw = [];
        var cp = this.shapes.cursorPoint;
        var mode = this.props.getMode;
        //
        //
        // // if (mode == 'POINT') {
        // //     elements.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="4" fill="rgba(0,0,0,0.2)"
        // //                           key={elements.length} style={stylePoint} />);
        // // }
        //
        // if (this.shapes.polygonCloseStatus==true) {
        //     elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
        //                               key={elements.length} style={stylePoint} />);
        // }
        // if (this.shapes.polyLineCloseStatus==true) {
        //     elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
        //                               key={elements.length} style={stylePoint} />);
        // }
        //
        // var fx = function(type, is) {
        //     return function(e) {
        //         if (e.nativeEvent.which === 1) {
        //             this.selectPoint(type, is);
        //             if (type == 'POINT') {
        //                 this.selectShape(type, is[0]);
        //             }
        //             e.stopPropagation()
        //         }
        //     };
        // }
        // var fx2 = function(type, is) {
        //     // console.log('!@!@!@!@', e.nativeEvent.which)
        //     return function(e) {
        //         // console.log('!@!@!@!@', e.nativeEvent.which)
        //         if (e.nativeEvent.which === 1) {
        //             this.selectPoint(type, is);
        //             if (type == 'POINT') {
        //                 this.selectShape(type, is[0]);
        //             }
        //             e.stopPropagation()
        //         }
        //     };
        //     // if (e.nativeEvent.which === 1) {
        //     //     return function(e) {
        //     //         this.selectPoint(type, is);
        //     //         if (type == 'POINT') {
        //     //             this.selectShape(type, is[0]);
        //     //         }
        //     //         e.stopPropagation()
        //     //     };
        //     // }
        // }
        //
        // var fg = function (p) {
        //     return function (e) {
        //         this.contextMenu(e,p);
        //     }
        // }
        //
        // var funcPoint = function (p, arr) {
        //     return function (e) {
        //         this.onClickPointPoly(e, p, arr)
        //     }
        // }
        //
        var fshape = function(type, i) {
            // console.log('fshape')
            return function(e) {
                this.selectShape(type, i);
            }
        }
        // function shapeF(type, i) {
        //     return function(e) {
        //         this.selectShape(type, i);
        //     }
        // }
        var polygons = this.shapes.polygons;
        // if (this.shapes.polygonIsOpened) {
        //     var lastPolygon = polygons[polygons.length-1];
        //     for (var j = 0; j < lastPolygon.length-1; j++) {
        //         var p1 = lastPolygon[j];
        //         var p2 = lastPolygon[j+1];
        //         elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
        //                                 stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
        //         elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
        //                                   key={elementsDraw.length} />);
        //     }
        //     var p1 = lastPolygon[lastPolygon.length-1];
        //     var p2 = cp;
        //     elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
        //                             stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
        //     elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
        //                               key={elementsDraw.length} />);
        //
        // }
        // var polyLIne = this.shapes.polyLIne;
        // // console.log('for', polyLIne);
        // if (this.shapes.polyLineIsOpened) {
        //     // console.log('for!!!!!!');
        //     var lastPolygon = polyLIne[polyLIne.length-1];
        //     for (var j = 0; j < lastPolygon.length-1; j++) {
        //         var p1 = lastPolygon[j];
        //         var p2 = lastPolygon[j+1];
        //         elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
        //                                 stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
        //         elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
        //                                   key={elementsDraw.length} />);
        //     }
        //     var p1 = lastPolygon[lastPolygon.length-1];
        //     var p2 = cp;
        //     elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
        //                             stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
        //     elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
        //                               key={elementsDraw.length} />);
        //
        // }
        for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
            // console.log('!!!!!!!!!!!!>>>');
            if (polygons[i][0] !==  undefined) {
                var path = 'M ';
                var p0 = polygons[i][0];
                var minCoorX = p0[0];
                var minCoorY = p0[1];
                var maxCoorX = p0[0];
                var maxCoorY = p0[1];
                path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                for (var j = 1; j < polygons[i].length; j++) {
                    var p = polygons[i][j];
                    if (minCoorX > p[0]) {
                        minCoorX = p[0];
                    }
                    if (maxCoorX < p[0]) {
                        maxCoorX = p[0];
                    }
                    if (minCoorY > p[1]) {
                        minCoorY = p[1];
                    }
                    if (maxCoorY < p[1]) {
                        maxCoorY = p[1];
                    }
                    var middleX = (maxCoorX + minCoorX) / 2;
                    var middleY = (minCoorY + maxCoorY) / 2;
                    path += ' ' + p[0]*scale + ',' + p[1]*scale;
                }
                path += ' Z'

                var componentName = null
                var componentRetailName = null;

                var alpha = 0.4;
                if (this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POLY' &&
                    this.shapes.selectedShape.i == String(i)) {
                    alpha = 0.6;

                }
                var fill2 = 'rgba(51,71,80,'+ alpha +')';
                var retailName = '';
                if (this.shapes.rooms[this.shapes.namePolygons[i]] !== undefined) {
                    retailName = this.shapes.rooms[this.shapes.namePolygons[i]].retail_name

                    var widthRatailName = retailName.length * 6.8  + 4
                    var coorX = middleX*scale - (widthRatailName / 2)


                    componentRetailName = <g style={{pointerEvents: 'none'}}>
                        {/*<defs>*/}
                        {/*/!*<filter x="0" y="0"  width="10px" height="1" id="solid">*!/*/}
                        {/*/!*<feFlood floodColor="rgba(0,0,0,.5)"/>*!/*/}
                        {/*/!*<feComposite in="SourceGraphic"/>*!/*/}
                        {/*/!*<feGaussianBlur in="SourceGraphic" stdDeviation="5"/>*!/*/}
                        {/*/!*</filter>*!/*/}
                        {/*<rect id="wrap" x={middleX*scale} y={middleY*scale - 10} width="200" height="80" fill={"rgba(0,0,0,.5)"}/>*/}
                        {/*</defs>*/}
                        <rect x={coorX} y={middleY*scale - 21} width={widthRatailName} height="15" fill={"rgba(255,255,255,.5)"}/>
                        <text style={{pointerEvents: 'none'}} fill="#000" fontWeight={200}  fontSize={12} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>
                            {`${retailName}`}
                        </text>
                    </g>
                    var widthMarkerName = (this.shapes.namePolygons[i].length + 1) * 6.8  + 4
                    var coorX2 = middleX*scale - (widthMarkerName / 2)
                    // console.log('@##@#2', this.shapes.namePolygons)
                    componentName = <g style={{pointerEvents: 'none'}}>
                        {/*<defs>*/}
                        {/*<filter x="0" y="0"  width="1" height="1" id="solid">*/}
                        {/*<feFlood floodColor="rgba(0,0,0,.5)"/>*/}
                        {/*<feComposite in="SourceGraphic"/>*/}
                        {/*</filter>*/}
                        {/*</defs>*/}
                        <rect x={coorX2} y={middleY*scale - 1} width={widthMarkerName} height="15" fill={"rgba(255,255,255,.5)"}/>
                        <text style={{pointerEvents: 'none'}} fill="#000" fontWeight={200} fontSize={12} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`${this.shapes.namePolygons[i]}`}</text>
                    </g>
                }
                fill2 = 'rgba(0,0,0,0.6)'
                // console.log('holstholst', this.shapes.checkLis[i], this.shapes.checkLis)

                if (this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POLY' &&
                    this.shapes.selectedShape.i == String(i)) {
                    fill2 = 'rgba(255,255,255,.3)'
                }
                // console.log('getSvgElementsForDraw', fill2);

                if (this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POLY' &&
                    this.shapes.selectedShape.i == String(i)) {
                    // console.log('><><><><><><><><><><><>')
                    // stylePoint = {
                    //     cursor: 'move'
                    // }
                    elementsDraw.push(<path stroke="rgba(150,0,0,0.6)" strokeWidth="1" fill={fill2}
                                            d={path} key={elements.length +'ffdfdf'} onMouseDown={fshape('POLY', i).bind(this)} style={styleLine}/>)
                    // for (var j = 1; j < polygons[i].length; j++) {
                    //     var p = polygons[i][j];
                    //     elementsDraw.push(<circle onContextMenu={funcPoint(p, [i,j]).bind(this)} cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                    //                               key={elements.length+'ffdfdf'} style={stylePoint} onMouseDown={fx('POLY', [i, j]).bind(this)}/>);
                    // }
                    // elementsDraw.push(<circle onContextMenu={funcPoint(p0, [i,0]).bind(this)} cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                    //                           key={elements.length+'ffdfdf'} style={stylePoint}
                    //                           onMouseDown={fx('POLY', [i, 0]).bind(this)}/>);
                    elementsDraw.push(componentRetailName)
                    // elementsDraw.push(componentName)
                }

            }
        }

        this.shapes.svgElementsDraw = elementsDraw;
        return {elem:elements, elemDraw: elementsDraw};
    }
    static getSvgElements(scale) {
        // console.log('getSvgElements', this.shapes.selectedShape)
        if (this.shapes.statusEdit === true) {
            var obj = {
                polygons: this.shapes.polygons,
                polyLIne:  this.shapes.polyLIne,
                gridData: this.shapes.gridData,
                polygonCloseStatus: this.shapes.polygonCloseStatus,
                polyLineCloseStatus: this.shapes.polyLineCloseStatus,
                stepItems: this.shapes.stepItems,
                polygonsObj: this.shapes.polygonsObj,
                points: this.shapes.points,
                lines: this.shapes.lines
            };
            if (JSONSvgElements !== JSON.stringify(obj)) {
                this.shapes.statusSvgCloseElem = true;
                setTimeout(() => {
                    this.shapes.statusSvgCloseElem = true;
                    setTimeout(() => {
                        this.shapes.statusSvgCloseElem = true;
                    }, 100)
                }, 100)
                JSONSvgElements = JSON.stringify(obj)
            }

            var objPolygon = {
                objPolygon: this.shapes.objPolygon,
                scale:scale,
                dataUneqUsers: this.shapes.dataUneqUsers,
                showCounts: this.shapes.showCounts
            }
            setTimeout(() => {
                JSONScale = 0
                setTimeout(() => {
                    JSONScale = 0
                }, 100)
            }, 10)
            // console.log('getSvgElements<<<<<<<<<<12', objPolygon, scale);
            if (JSONScale !== JSON.stringify(objPolygon)) {
                // console.log('getSvgElements<<<<<<<<<<12!!!', objPolygon);
                JSONScale = JSON.stringify(objPolygon);
                this.shapes.statusSvgCloseElem = false;
                // console.log('getSvgElements');

                // console.log('poly!!!!!!', this.shapes.polygonIsOpened);
                if (this.props.changeShapes) {
                    this.shapes = this.props.changeShapes(this.shapes);
                }

                var stylePoint = {
                    cursor: 'move'
                }
                var styleLine = {
                    cursor: 'pointer'
                }

                var elements = [];
                var elementsText = [];
                var elementsDraw = [];
                var cp = this.shapes.cursorPoint;
                var mode = this.props.getMode;

                // if (this.shapes.statusShowGrid === true) {
                //     if (this.shapes.gridData !== null) {
                //         let x = 0
                //         for (var u = 0; u < this.shapes.gridData.countX; u++) {
                //             x = x + this.shapes.gridData.scale * this.shapes.scaleGrid;
                //             elements.push(<line x1={x*scale} y1="0" x2={x*scale} y2="100%" style={{stroke:`rgba(0,0,0,.05)`,strokeWidth:1}} />)
                //         }
                //         let y = 0
                //         for (var r = 0; r < this.shapes.gridData.countY; r++) {
                //             y = y + this.shapes.gridData.scale * this.shapes.scaleGrid;
                //             elements.push(<line x1={0} y1={y*scale} x2={`100%`} y2={y*scale} style={{stroke:`rgba(0,0,0,.05)`,strokeWidth:1}} />)
                //         }
                //     }
                // }

                // if (mode == 'POINT') {
                //     elements.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="4" fill="rgba(0,0,0,0.2)"
                //                           key={elements.length} style={stylePoint} />);
                // }

                if (this.shapes.polygonCloseStatus==true) {
                    elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
                                              key={elements.length} style={stylePoint} />);
                }
                if (this.shapes.polyLineCloseStatus==true) {
                    elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
                                              key={elements.length} style={stylePoint} />);
                }

                var fx = function(type, is) {
                    return function(e) {
                        if (e.nativeEvent.which === 1) {
                            this.selectPoint(type, is);
                            if (type == 'POINT') {
                                this.selectShape(type, is[0]);
                            }
                            e.stopPropagation()
                        }
                    };
                }
                var fx2 = function(type, is) {
                    // console.log('!@!@!@!@', e.nativeEvent.which)
                    return function(e) {
                        // console.log('!@!@!@!@', e.nativeEvent.which)
                        if (e.nativeEvent.which === 1) {
                            this.selectPoint(type, is);
                            if (type == 'POINT') {
                                this.selectShape(type, is[0]);
                            }
                            e.stopPropagation()
                        }
                    };
                    // if (e.nativeEvent.which === 1) {
                    //     return function(e) {
                    //         this.selectPoint(type, is);
                    //         if (type == 'POINT') {
                    //             this.selectShape(type, is[0]);
                    //         }
                    //         e.stopPropagation()
                    //     };
                    // }
                }

                var fg = function (p) {
                    return function (e) {
                        this.contextMenu(e,p);
                    }
                }

                var funcPoint = function (p, arr) {
                    return function (e) {
                        this.onClickPointPoly(e, p, arr)
                    }
                }

                var fshape = function(type, i) {
                    // console.log('>>>>>>>>>>>')


                        return function(e) {
                            this.selectShape(type, i);
                        }

                }

                var polygons = this.shapes.polygons;
                if (this.shapes.polygonIsOpened) {
                    var lastPolygon = polygons[polygons.length-1];
                    for (var j = 0; j < lastPolygon.length-1; j++) {
                        var p1 = lastPolygon[j];
                        var p2 = lastPolygon[j+1];
                        elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                                stroke="rgb(255,0,0)" key={Math.random().toString(36).substr(2, 10)}/>);
                        elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                                  key={Math.random().toString(36).substr(2, 10)} />);
                    }
                    var p1 = lastPolygon[lastPolygon.length-1];
                    var p2 = cp;
                    elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                            stroke="rgb(255,0,0)" key={Math.random().toString(36).substr(2, 10)}/>);
                    elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                              key={Math.random().toString(36).substr(2, 10)} />);

                }


                function shapeF(type, i) {
                    return function(e) {
                        this.selectShape(type, i);
                    }
                }
                for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
                    // console.log('!!!!!!!!!!!!>>>', polygons[i][0]);
                    if (polygons[i][0] !==  undefined) {
                        var path = 'M ';
                        var p0 = polygons[i][0];
                        var minCoorX = p0[0];
                        var minCoorY = p0[1];
                        var maxCoorX = p0[0];
                        var maxCoorY = p0[1];
                        path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                        for (var j = 1; j < polygons[i].length; j++) {
                            var p = polygons[i][j];
                            if (minCoorX > p[0]) {
                                minCoorX = p[0];
                            }
                            if (maxCoorX < p[0]) {
                                maxCoorX = p[0];
                            }
                            if (minCoorY > p[1]) {
                                minCoorY = p[1];
                            }
                            if (maxCoorY < p[1]) {
                                maxCoorY = p[1];
                            }
                            var middleX = (maxCoorX + minCoorX) / 2;
                            var middleY = (minCoorY + maxCoorY) / 2;
                            path += ' ' + p[0]*scale + ',' + p[1]*scale;
                        }
                        path += ' Z'

                        var componentName = null
                        var componentRetailName = null;
                        var componentUneq = null;

                        var alpha = 0.4;
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            alpha = 0.6;
                        }
                        var fill2 = 'rgba(51,71,80,'+ alpha +')';
                        // console.log('fill2',this.shapes.rooms[this.shapes.namePolygons[i]])
                        // var retailName = '';
                        // if (this.shapes.rooms[this.shapes.namePolygons[i]] !== undefined) {
                        //     retailName = this.shapes.rooms[this.shapes.namePolygons[i]].retail_name
                        // }
                        // componentRetailName = <g>
                        //     <defs>
                        //         <filter x="0" y="0"  width="1" height="1" id="solid">
                        //             <feFlood floodColor="rgba(0,0,0,.5)"/>
                        //             <feComposite in="SourceGraphic"/>
                        //         </filter>
                        //     </defs>
                        //     <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{retailName}</text>
                        // </g>
                        // // console.log('@##@#2', this.shapes.namePolygons)
                        // componentName = <g>
                        //     <defs>
                        //         <filter x="0" y="0"  width="1" height="1" id="solid">
                        //             <feFlood floodColor="rgba(0,0,0,.5)"/>
                        //             <feComposite in="SourceGraphic"/>
                        //         </filter>
                        //     </defs>
                        //     <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={200} fontSize={10} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`(${this.shapes.namePolygons[i]})`}</text>
                        // </g>
                        fill2 = 'rgb(150,150,150)'
                        // console.log('holstholst', this.shapes.checkLis[i], this.shapes.checkLis)
                        if (this.shapes.checkLis[i] === true) {
                            var retailName = '';
                            if (this.shapes.rooms[this.shapes.namePolygons[i]] !== undefined) {
                                retailName = this.shapes.rooms[this.shapes.namePolygons[i]].retail_name

                                var widthRatailName = retailName.length * 6.8  + 4
                                var coorX = middleX*scale - (widthRatailName / 2)
                                if (this.shapes.dataUneqUsers[this.shapes.namePolygons[i]] !== undefined) {
                                    if (this.shapes.showCounts === true) {
                                        var widthUneq = this.shapes.dataUneqUsers[this.shapes.namePolygons[i]].length * 6.8  + 4;
                                        var coorUneq = middleX*scale - (widthUneq / 2)

                                        componentUneq = <g style={{pointerEvents: 'none'}}>
                                            {/*<defs>*/}
                                            {/*/!*<filter x="0" y="0"  width="10px" height="1" id="solid">*!/*/}
                                            {/*/!*<feFlood floodColor="rgba(0,0,0,.5)"/>*!/*/}
                                            {/*/!*<feComposite in="SourceGraphic"/>*!/*/}
                                            {/*/!*<feGaussianBlur in="SourceGraphic" stdDeviation="5"/>*!/*/}
                                            {/*/!*</filter>*!/*/}
                                            {/*<rect id="wrap" x={middleX*scale} y={middleY*scale - 10} width="200" height="80" fill={"rgba(0,0,0,.5)"}/>*/}
                                            {/*</defs>*/}
                                            <rect x={coorUneq} y={middleY*scale - 21} width={widthUneq} height="15" fill={"rgba(255,255,255,.5)"}/>
                                            <text style={{pointerEvents: 'none'}} fill="#000" fontWeight={200}  fontSize={12} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>
                                                {`${this.shapes.dataUneqUsers[this.shapes.namePolygons[i]]}`}
                                            </text>
                                        </g>
                                    }

                                }



                                componentRetailName = <g style={{pointerEvents: 'none'}}>
                                    {/*<defs>*/}
                                        {/*/!*<filter x="0" y="0"  width="10px" height="1" id="solid">*!/*/}
                                            {/*/!*<feFlood floodColor="rgba(0,0,0,.5)"/>*!/*/}
                                            {/*/!*<feComposite in="SourceGraphic"/>*!/*/}
                                            {/*/!*<feGaussianBlur in="SourceGraphic" stdDeviation="5"/>*!/*/}
                                        {/*/!*</filter>*!/*/}
                                        {/*<rect id="wrap" x={middleX*scale} y={middleY*scale - 10} width="200" height="80" fill={"rgba(0,0,0,.5)"}/>*/}
                                    {/*</defs>*/}
                                    <rect x={coorX} y={middleY*scale - 1} width={widthRatailName} height="15" fill={"rgba(255,255,255,.5)"}/>
                                    <text style={{pointerEvents: 'none'}} fill="#000" fontWeight={200}  fontSize={12} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>
                                        {`${retailName}`}
                                        </text>
                                </g>
                                var widthMarkerName = (this.shapes.namePolygons[i].length + 1) * 6.8  + 4
                                var coorX2 = middleX*scale - (widthMarkerName / 2)
                                // console.log('@##@#2', this.shapes.namePolygons)
                                componentName = <g style={{pointerEvents: 'none'}}>
                                    {/*<defs>*/}
                                        {/*<filter x="0" y="0"  width="1" height="1" id="solid">*/}
                                            {/*<feFlood floodColor="rgba(0,0,0,.5)"/>*/}
                                            {/*<feComposite in="SourceGraphic"/>*/}
                                        {/*</filter>*/}
                                    {/*</defs>*/}
                                    <rect x={coorX2} y={middleY*scale - 1} width={widthMarkerName} height="15" fill={"rgba(255,255,255,.5)"}/>
                                    <text style={{pointerEvents: 'none'}} fill="#000" fontWeight={200} fontSize={12} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`${this.shapes.namePolygons[i]}`}</text>
                                 </g>
                                fill2 = 'rgba(208, 216, 220,.75)'
                                var statusSelect = false;
                                // console.log(this.shapes.roomsTransition)
                                if (this.shapes.roomsTransition[this.shapes.namePolygons[i]] !== undefined) {
                                    // console.log(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent, getHeatColor(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent))
                                    fill2 = getHeatColor(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent / 100)
                                }
                                if (this.shapes.statusColorCount === true) {
                                    // console.log('')
                                    if (GeometryVSDataManager.reportPersentWithRoom(this.shapes.namePolygons[i]) !== undefined) {
                                        fill2 = getHeatColor(GeometryVSDataManager.reportPersentWithRoom(this.shapes.namePolygons[i]))
                                    }
                                }
                                if (this.shapes.selectedShape &&
                                    this.shapes.selectedShape.shapeType == 'POLY' &&
                                    this.shapes.selectedShape.i == String(i)) {
                                    // fill2 = 'rgba(0,0,255,0.6)'
                                    statusSelect = true;
                                    fill2 ='rgba(255,255,255,.3)'
                                }

                                elements.push(<path stroke="rgb(191,192,189)" strokeWidth="1" fill={fill2}
                                                    d={path} key={Math.random().toString(36).substr(2, 10)} onMouseDown={this.shapes.statusColorCount? null : fshape('POLY', i).bind(this)} style={styleLine}/>)
                            }
                            else {
                                // componentRetailName = <g>
                                //     <defs>
                                //         <filter x="0" y="0"  width="1" height="1" id="solid">
                                //             <feFlood floodColor="rgba(0,0,0,.5)"/>
                                //             <feComposite in="SourceGraphic"/>
                                //         </filter>
                                //     </defs>
                                //     <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{` ${retailName} `}</text>
                                // </g>
                                // console.log('@##@#2', this.shapes.namePolygons)
                                var widthMarkerName = (this.shapes.namePolygons[i].length + 1) * 6.8  + 4
                                var coorX2 = middleX*scale - (widthMarkerName / 2)
                                // console.log('@##@#2', this.shapes.namePolygons)
                                componentName = <g style={{pointerEvents: 'none'}}>
                                    {/*<defs>*/}
                                    {/*<filter x="0" y="0"  width="1" height="1" id="solid">*/}
                                    {/*<feFlood floodColor="rgba(0,0,0,.5)"/>*/}
                                    {/*<feComposite in="SourceGraphic"/>*/}
                                    {/*</filter>*/}
                                    {/*</defs>*/}
                                    <rect x={coorX2} y={middleY*scale - 1} width={widthMarkerName} height="15" fill={"rgba(255,255,255,.5)"}/>
                                    <text style={{pointerEvents: 'none'}} fill="#000" fontWeight={200} fontSize={12} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`${this.shapes.namePolygons[i]}`}</text>
                                </g>
                                fill2 = 'rgba(200,200,200, 1)'
                                var statusSelect = false;

                                if (this.shapes.selectedShape &&
                                    this.shapes.selectedShape.shapeType == 'POLY' &&
                                    this.shapes.selectedShape.i == String(i)) {
                                    // fill2 = 'rgba(0,0,255,0.6)'
                                    statusSelect = true;
                                }

                                elements.push(<path stroke="rgb(191,192,189)" strokeWidth="1" fill={fill2}
                                                    d={path} key={Math.random().toString(36).substr(2, 10)}/>)
                            }
                        }
                        else {
                            var statusSelect = false;
                            // console.log(this.shapes.roomsTransition)
                            if (this.shapes.roomsTransition[this.shapes.namePolygons[i]] !== undefined) {
                                // console.log(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent, getHeatColor(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent))
                                fill2 = getHeatColor(this.shapes.roomsTransition[this.shapes.namePolygons[i]].intersection_percent / 100)
                            }
                            if (this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType == 'POLY' &&
                                this.shapes.selectedShape.i == String(i)) {
                                // fill2 = 'rgba(0,0,255,0.6)'
                                statusSelect = true;
                            }

                            elements.push(<path stroke="rgb(191,192,189)" strokeWidth="1" fill={fill2}
                                                d={path} key={Math.random().toString(36).substr(2, 10)} />)
                        }


                        elementsText.push(componentRetailName)
                        // elementsText.push(componentName)
                        elementsText.push(componentUneq)
                    }
                }
                var elementAfterConcat = elements.concat(elementsText)

                //<<<<<<<<<<<<<<<kirdro dev >>>>>>>>>>>>>>>>>>>>>>>>>>>>

                this.shapes.svgElements = elementAfterConcat;

            }
        }
        return {elem:this.shapes.svgElements, elemDraw: elementsDraw};


    }


}

export default DrawerPlaces;
module.exports = DrawerPlaces
