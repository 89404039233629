'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _compareObjects = require('./compareObjects');

var _compareObjects2 = _interopRequireDefault(_compareObjects);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SectionTitle = function (_Component) {
  _inherits(SectionTitle, _Component);

  function SectionTitle() {
    _classCallCheck(this, SectionTitle);

    return _possibleConstructorReturn(this, (SectionTitle.__proto__ || Object.getPrototypeOf(SectionTitle)).apply(this, arguments));
  }

  _createClass(SectionTitle, [{
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps) {
      return (0, _compareObjects2.default)(nextProps, this.props);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props;
      var section = _props.section;
      var renderSectionTitle = _props.renderSectionTitle;
      var theme = _props.theme;
      var sectionKeyPrefix = _props.sectionKeyPrefix;

      var sectionTitle = renderSectionTitle(section);

      if (!sectionTitle) {
        return null;
      }

      return _react2.default.createElement(
        'div',
        theme(sectionKeyPrefix + 'title', 'sectionTitle'),
        sectionTitle
      );
    }
  }]);

  return SectionTitle;
}(_react.Component);

SectionTitle.propTypes = {
  section: _react.PropTypes.any.isRequired,
  renderSectionTitle: _react.PropTypes.func.isRequired,
  theme: _react.PropTypes.func.isRequired,
  sectionKeyPrefix: _react.PropTypes.string.isRequired
};
exports.default = SectionTitle;