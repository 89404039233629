module.exports = {
	UPDATE: 'UPDATE',
	SELECT_ANALITICS_METHOD: 'SELECT_ANALITICS_METHOD',
	GET_FUNNEL: 'GET_FUNNEL',
	GET_CHART: 'GET_CHART',
	GET_SMS: 'GET_SMS',
	GET_UNIQUE_NUMBER: 'GET_UNIQUE_NUMBER',
	GET_SOCIAL_ANALITICS: 'GET_SOCIAL_ANALITICS',
	UPDATE_SIDE_PANEL: 'UPDATE_SIDE_PANEL',
	GET_CHARTS_SOCIAL_ANALITICS: 'GET_CHARTS_SOCIAL_ANALITICS'
};