/**
 * Created by kirilldrozdov on 02.02.17.
 */
var tultipHotspot = require("./tultipBoxEn.jsx");
var translation = {

    "connections": {
        "tab_title": "Connections",
        "unique_wifi_clients_per_day": "UNIQUE Wi-Fi USERS DYNAMICS PER DAY",
        "unique_all_clients": "All users",
        "unique_authorized_clients": "Authorized users",
        "average_clients_per_day": "AVERAGE NUMBER OF USERS PER DAY",
        "average_authorized_clients_per_day": "AVERAGE NUMBER OF AUTHORIZED USERS PER DAY",
        "connections_per_day": "WIFI CONNECTIONS DYNAMICS",
        "connections_trying": "Connection attempts",
        "connections_passed": "Successful authorizations",
        "total_connections": "NUMBER OF CONNECTIONS FOR PERIOD",
        "completed_authorizations": "COMPLETED AUTHORIZATIONS FOR PERIOD",
        "auth_schemes": "AUTHORIZATION SCHEME STATISTICS",
        "count": "Count",
        "funnelConnect": "Connections"
    },
    "connectionsTultip": null,
    "sms": {
        "tab_title": "SMS",
        "total_sms": "TOTAL NUMBER OF SMS",
        "total_numbers": "TOTAL NUMBER OF NUMBERS",
        "total_validated_numbers": "TOTAL NUMBER OF VALIDATED NUMBERS",
        "download_report": "DOWNLOAD REPORT ON SMS IN excel",
        "table_titles": ["TELEPHONE", "NUMBER OF SMS", "VALIDATED"]
    },

    "social": {
        "tab_title": "Soc. Profiles",
        "table_titles": ["USERPIC", "NAME", "DATE OF BIRTH", "EMAIL", "FIRST CONNECTION TIME", "LAST CONNECTION TIME", "NUMBER OF CONNECTIONS", "NUMBER OF FRIENDS"]
    },
    "pages": {
        "tab_title": "Page management",
        "schedule": {
            "title": "PLANNING",
            "time_of_day": "Time of day",
            "days_of_week": "Days of week",
            "period": "Period",
            "create_for_other_objects": "Create similar planning for all objects",
            "button_always": "Always",
            "panel_time": "Time",
            "dayOfWeek": {
                "Monday": "Mon",
                "Tuesday": "Tue",
                "Wednesday": "Wed",
                "Thursday": "Thu",
                "Friday": "Fri",
                "Saturday": "Sat",
                "Sunday": "Sun"
            },
            "or": "or",
            "always": "always",
            "from": "from",
            "by": "by",
            "to": "to",
            "close": "Close",
            "save": "Save changes"
        },
        "link": {
            "setting": "Change settings",
            "schedule": "Schedule",
            "delete": "Delete",
            "copy": "Copy",
            "show": "Show",
            "settings_tip": "To schedule this page, you have to set authorization parameters",
            "schedule_tip": "Make a copy to edit the landing template"
        },
        "settings": {
            "title": "SETTINGS",
            "constructor": "Constructor",
            "button_continue": "Continue",
            "button_close": "Close",
            "button_save": "Save changes",
            "button_preview": "Preview",
            "preparation": "Preparing...",
            "titleAuth":"Select authorization",
            "titlePerfom": "Presentation settings",
            "preview": {
                "title": "Presentation settings",
                "background": "Page background",
                "logo": "Logo",
                "addImage":"Add image",
                "orBackground":"or background color",
                "text": "Text",
                "text_color": "Text color",
                "media_block": "Media block",
                "auth_button_color": "\"CONNECT TO INTERNET\" Button color ",
                "constructor_link": "For additional presentation settings go to"
            },
            "auth": {
                "title": "Select authorization",
                "image":"Image",
                "link":"Link",
                "titleName":"Title",
                "discription":"Description",
                "idPost":"Post ID",
                "apps":"Apps",
                "message":"Message",
                "repost":"Repost",
                "OnBehalfOfTheUser":"On behalf of the user",
                "sms": "Mandatory SMS authorization",
                "button": "\"Connect to Internet\" Button",
                "vk": "VKontakte",
                "callTitle":"Authorization by phone call",
                "callInput":"remember the call for (days)",
                "vk_autopost": "Autopost",
                "fb": "Facebook",
                "fb_authpost": "Autopost",
                "ok": "Odnoklassniki",
                "password": "Authorization by password",
                "internet_session_period": "Period of Internet session (minutes)",
                "authorization_period": "Period of authorization request (minutes)",
                "traffic_limit": "Traffic limits (MB)",
                "redirect_url": "Redirect URL",
                "constructor_link": "For advanced authorization settings go to",
                "textNotAuth":{
                    "first":"This page has a more complicated authorization scheme. To edit it, use",
                    "constructor":"the constructor",
                    "second":"for pages. You can also",
                    "reset":"reset",
                    "third":"the parameters and set them anew."
                }
            }
        }
    },
    "calendar": {
        "today": "Today",
        "yesterday": "Yesterday",
        "last_7_days": "Last 7 days",
        "last_30_days": "Last 30 days",
        "this_month": "This month",
        "last_month": "Last month",
        "custom_period": "Custom period",
        "applyLabel": "Apply",
        "cancelLabel": "Close",
        "fromLabel": "From",
        "toLabel": "To",
        "weekLabel": "Week",
        "daysOfWeek": [
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat"
        ],
        "monthNames": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ]
    },
    "bigCalendar": "en",
    "bigCalendarTolbar": {
        "previous_month": "Previous month",
        "current_month": "Current month",
        "next_month": "Next month",
    },
    "sidebar": {
        "button_select_objects": "SELECT OBJECT(S)",
        "num_selected_objects": "Number of selected objects:",
        "support": "Support"
    },
    "empty_data" : "No data",
    "profile": {
        "button_logout": "Log out"
    },
    "cunstructor": {
        "create_new_page": "create new",
        "page_parameters": {
            "title": "Page parameters",
            "savePages":"Saved pages",
            "background_color": "Background color",
            "background_image": "Background image",
            "select_image": "Select image",
            "save":"Save",
            "not_chosen":"Not selected",
            "all_objects":"All objects",
            "clear":"Clear",
            "bind_to_object":"Bind to object",
            "you_must_specify_the_authorization_parameters":"Authorization parameters must be specified.",
            "questionDeletePage":"Delete the page?"
        },
        "add_block": "Add block",
        "block_parameters": {
            "title": "Block parameters",
            "selectBlock":"Select block",
            "select_block": "Select block",
            "background_image": "Background image",
            "select_image": "Select image",
            "background_color": "Background color",
            "font": "Font",
            "block_text": "Block text",
            "block_link": "WEB link (redirects when you click on the block)",
            "rounding": "Rounding (px)",
            "margin_top": "Top margin (px)",
            "margin_bottom": "Bottom margin (px)",
            "margin_left": "Left margin (px)",
            "margin_right": "Right margin (px)",
            "turnon_widget": "Turn on widget",
            "fit_the_whole": "Fit whole",
            "the_authorization_unit":"Authorization block",
            "widget":"Widget",
            "text":"Text",
            "textBlock":"Block text"
        },
        "auth_parameters": {
            "is_auth_page": "Authorization page",
            "auth_parameters_page": {
                "button_title": "Authorization parameters",
                "auth_layers_count": "Number of authorization levels",
                "layer": {
                    "sms": "SMS authorization",
                    "sms_remember": "remember for (days)",
                    "call": "Authorization by phone call",
                    "cal_remember":"remember the call for (days)",
                    "button": "\"Connect to Internet\" Button",
                    "vk": {
                        "title": "Login with VKontakte",
                        "autopost": "Autopost",
                        "post": "On behalf of the user",
                        "repost": "Repost",
                        "message": "Message",
                        "attachment": "Attachment",
                        "post_id": "Post ID"
                    },
                    "fb": {
                        "title": "Login with Facebook",
                        "autopost": "Autopost",
                        "post_link": "Link",
                        "post_title": "Title",
                        "description": "Description",
                        "add_image": "Add image",
                        "deleteImage": "Delete image"
                    },
                    "ok": "Login with Odnoklassniki",
                    "password": "Login with password",
                    "password_parameter": "Password for authorization",
                    "external": {
                        "title": "External authorization",
                        "external_url": "External URL",
                        "confirm_url": "Confirmation URL",
                    }
                }
            },
            "session_duration": "Maximum session duration (minutes)",
            "new_connection_in": "Time until next connection (minutes)",
            "auth_period": "Period of authorization request (minutes)",
            "traffic_volume": "Maximum traffic volume (mb)",
            "rdirect_url": "Redirect URL",
            "close": "Close",
            "levelAuth": "Authorization level"
        },
        "button_internet_color": "\"Connect to Internet\" Button background color",
        "button_internet_color2":"default: rgba(51,71,80,0.9)",
        "auth_panner_color": "Authorization panel background color",
        "auth_panner_color2":"default: rgba(51,71,80,0.9)",
        "button_internet_text": "Authorization button text",
        "button_internet_text2":"default: \"CONNECT TO INTERNET\"",
        "skip_landing": "Skip landing (go directly to authorization)",
        "authorization_page":"Authorization page",
        "edit": "Edit",
        "backButton": "Back",
        "titleEmpty": "Empty title...",
        "create": "Create",
        "you_must_specify_the_name_of_the_page": "Page title must be specified",
        "level1": "1 Level",
        "level2": "2 Levels",
        "level3": "3 Levels",
        "mediaBox":"Multimedia library",
        "uploadFiles":"Upload",
        "fileFormTitleFile":"Title",
        "tags_separated_by_commas":"Tags separated by commas",
        "cancel":"Cancel",
        "addFile":"Add",
        "addNewFile":"Add new media file"
    },
    "maps": {
        "title":"Maps",
        "route":{
            "title":"Routes",
            "select":"Select...",
            "no_data":"No data",
            "data_loading":"Loading...",
            "first_panel": {
                "type_point": {
                    "point":"Using points",
                    "zone":"Using zones",
                    "floor":"Using floors"
                },
                "type_top":{
                    "top_5":"Top 5",
                    "top_10":"Top 10",
                    "top_20":"Top 20"
                },
                "type_steps":{
                    "title":"steps",
                    "arbitrarily":"random",
                    "exactly":"exactly",
                    "range":"range"
                },
                "engagement_threshold":"Engagement threshold",
                "input_it_contains":"contains"
            },
            "second_panel":{
                "any":"With any A and B",
                "any_part":"Any",
                "begin":"A - route starting point",
                "end":"B - route finishing point",
                "full":"A - route starting point and B - route finishing point",
                "button_show_routes":"Show routes"
            },
            "routes":{
                "total_conversion":"Total conversion",
                "steps":"Steps",
                "people_at_end_route":"People at the end of the route",
                "floor":"Floor"
            }
        },
        "intersections":{
            "title":"Intersections",
            "previews":{
                "floor":"Floor"
            },
            "button_show_names":"Show names",
            "button_hide_names":"Hide names",
            "button_hide_count_visitors":"Hide number of visitors",
            "button_show_count_visitors":"Show number of visitors",
            "right_panel":{
                "not_selected_lable":"Select zone to show information on intersection with other zones.",
                "floor":"Floor"
            },
            "background":"Show background"
        }
    }

};
module.exports = translation;

