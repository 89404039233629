var React = require('react');
var ReactDOM = require('react-dom');

var SpinerLoader = React.createClass({
	render: function() {
		return (
			<div className='spainerBox' >
				<div className='spiner' ></div>
			</div>
		);
	}
});

module.exports = SpinerLoader;