import React, {Component, PropTypes} from 'react';
import {Drawer} from './holst';
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup
} from 'react-bootstrap';
import Actions from '../actions/Actions';
import Store from '../store/store';
import {Tabs, Tab} from 'material-ui/Tabs';
import IconButton from 'material-ui/IconButton';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import ActionHeadLine from 'material-ui/svg-icons/action/view-headline';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
// From https://github.com/oliviertassinari/react-swipeable-views
import SwipeableViews from 'react-swipeable-views';
import {List, ListItem} from 'material-ui/List';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import ContentInbox from 'material-ui/svg-icons/content/inbox';
import ActionGrade from 'material-ui/svg-icons/action/grade';
import ContentSend from 'material-ui/svg-icons/content/send';
import ContentDrafts from 'material-ui/svg-icons/content/drafts';
import Divider from 'material-ui/Divider';
import ActionInfo from 'material-ui/svg-icons/action/info';
import Subheader from 'material-ui/Subheader';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import injectTapEventPlugin from 'react-tap-event-plugin';
import Select from 'react-select';
import _ from 'underscore';
// injectTapEventPlugin();


const styles = {
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  slide: {
    padding: 10
  }
};
const style = {
  // margin: 12,
  width: '100px'
};

const stylesButton = {
  smallIcon: {
    width: 36,
    height: 36
  },
  mediumIcon: {
    width: 48,
    height: 48
  },
  largeIcon: {
    width: 60,
    height: 60
  },
  small: {
    marginRight: 20
  },
  medium: {
    width: 96,
    height: 96,
    padding: 24
  },
  large: {
    width: 120,
    height: 120,
    padding: 30
  }
};

const styleListItem = {
  border: ''
}

class ComponentBoxHolst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.getMode,
      value: '',
      track: [],
      tracksItem: [],
      traksMask: [],
      trackMasksItem: [],
      stepMasks: [],
      uuid: Store.getState().uuid,
      dataTracks: Store.getState().dataTracks,
      slideIndex: 0,
      idSelectList: 0,
      stepTracksItem: [],
      intervalId: null,
      masks: [],
      secondItem: 0,
      detailModePolyline: 'tracking',
      objTracks: {
        tracking: {
          track: [],
          mask: []
        },
        navigation: {
          track: [],
          mask: []
        },
        wifi: {
          track: [],
          mask: []
        }
      },
      objPolygons: {
        polygons_in: [],
        polygons_out: [],
        poligons_bounds: []
      },
      dataPlans: null,
      globalUuid: 0,
      statusDetailPoligons: 'poligons_bounds',
      dataFloorItem: null,
      statusTimer: false,
      coorX: 0,
      coorY: 0,
      scale: 0,
      polygons_in: [],
      polygons_out: [],
      poligons_bounds: [],
      setZonName: '',
      setZoneList: [],
      statusShowPanelName: false,
      valueInputName: '',
      modeGrid: true,
      statusGridGrafics: false,
      placesList: [],
      statusShowPanel: true,
      floor: 0,
      statusSelectPOlygons: false,
      suggestions: [],
      valueSerchString: '',
      listBrand: [],
      listPoint: [],
      statusLoad: false,
      statusCheckBoxIsOpen: false,
      optionsMarker: Store.getState().listMarkers,
      objMarker: null
    }
    this.internalState = {
      track: [],
      indexTimer: 0,
      arr2: [],
      indexTimerSum: 0,
      indexTimerCount: 0,
      countIndexR: 0,
      secondItem: 0,
      arrTimer: [],
      indexSelectZone: 0,
      idTimeOut: 0,
      stepsDateItem: [],
      dateStart: 0
    }
  }
  componentDidMount() {
    // console.log('mainmain', global_plan_set_token);
    // if (global_plan_set_token !== '') {
    //     Actions.update(global_plan_set_token);
    // }
    Store.addChangeStoreModuleListener(this.handelStore.bind(this));
  }

  componentWillMount() {
      console.log(this.props.uuidForSet)
    if (this.props.uuidForSet !== '') {
      this.setState({value: this.props.uuidForSet});
        Actions.resetDate();
        Actions.update(this.props.uuidForSet);
    }
  }

  componentWillUnmount() {
    Store.removeChangeStoreModuleListener(this.handelStore.bind(this));
  }
  getMaxCoor(arr) {
    var max = arr[0][1];
    for (var i = 0; i < arr.length; i++) {
      if (max < arr[i][1]) {
        max = arr[i][1]
      }
    }
    return max;
  }
  getMinCoor(arr) {
    var min = arr[0][0];
    for (var i = 0; i < arr.length; i++) {
      if (min > arr[i][0]) {
        min = arr[i][0]
      }
    }
    return min;
  }

  handelStore() {
    // console.log('handelStore', Store.getState().dataTracks);
    if (this.state.idSelectList === 0 && Store.getState().dataTracks !== null || this.state.uuid !== Store.getState().uuid && Store.getState().dataTracks !== null) {
      var arr = [];
      var arr2 = [];
      var uuid = 0;
      var arr3 = [];
      var arr4 = [];
      // if (this.props.typetrack !== '') {
      //   if (Store.getState().dataTracks.places[0] !== undefined) {
      //     for (var i = 0; i < Store.getState().dataTracks.tracks.length; i++) {
      //       if (this.props.typetrack === Store.getState().dataTracks.tracks[i].track_type) {
      //         arr.push(Store.getState().dataTracks.tracks[i].steps);
      //         arr2.push(Store.getState().dataTracks.tracks[i].steps[0]);
      //         this.state.coorX = this.getMinCoor(Store.getState().dataTracks.tracks[i].steps);
      //         this.state.coorY = this.getMaxCoor(Store.getState().dataTracks.tracks[i].steps);
      //         // console.log('>>>>>>>>>>>>>>',Store.getState().dataTracks.tracks[0].steps[0][0], Store.getState().dataTracks.tracks[0].steps[0][1]);
      //         this.internalState.arrTimer = JSON.parse(JSON.stringify(Store.getState().dataTracks.tracks[i].steps_duration_mask));
      //         uuid = Store.getState().dataTracks.tracks[i].uuid
      //         arr3 = Store.getState().dataTracks.tracks[i].duration_mask;
      //         arr4 = Store.getState().dataTracks.tracks[i].steps_duration_mask;
      //         // console.log('>>>>>>>>>>>>>>!!!!!');
      //         break;
      //       }
      //     }
      //   }
      // } else {
      //   if (Store.getState().dataTracks.tracks[0] !== undefined) {
      //     arr.push(Store.getState().dataTracks.tracks[0].steps);
      //     arr2.push(Store.getState().dataTracks.tracks[0].steps[0]);
      //     this.state.coorX = this.getMinCoor(Store.getState().dataTracks.tracks[0].steps);
      //     this.state.coorY = this.getMaxCoor(Store.getState().dataTracks.tracks[0].steps);
      //     // console.log('>>>>>>>>>>>>>>',Store.getState().dataTracks.tracks[0].steps[0][0], Store.getState().dataTracks.tracks[0].steps[0][1]);
      //     this.internalState.arrTimer = JSON.parse(JSON.stringify(Store.getState().dataTracks.tracks[0].steps_duration_mask));
      //     uuid = Store.getState().dataTracks.tracks[0].uuid
      //     arr3 = Store.getState().dataTracks.tracks[0].duration_mask;
      //     arr4 = Store.getState().dataTracks.tracks[0].steps_duration_mask;
      //   }
      // }
      // console.log('>>>>>>>>>>>>>>',arr2);
      this.setState({
        tracksItem: arr,
        trackMasksItem: arr3,
        idSelectList: uuid,
        stepTracksItem: arr2,
        stepMasks: arr4,
        scale: Store.getState().dataFloorItem.scale,
        polygons_in: Store.getState().dataFloorItem.polygons_in,
        polygons_out: Store.getState().dataFloorItem.polygons_out,
        poligons_bounds: Store.getState().dataFloorItem.poligons_bounds
      });
    }

    // console.log('>>>>>>>>>>>>>>',Store.getState().dataFloorItem);
    this.setState({
      uuid: Store.getState().uuid,
      dataTracks: Store.getState().dataTracks,
      dataPlans: Store.getState().dataPlans,
      floor: Store.getState().floor,
      globalUuid: Store.getState().globalUuid,
      objPolygons: {
        polygons_in: Store.getState().polygons_in,
        polygons_out: Store.getState().polygons_out,
        poligons_bounds: Store.getState().poligons_bounds
      },
      dataFloorItem: Store.getState().dataFloorItem,
      setZoneList: Store.getState().setZoneList,
      placesList: Store.getState().placeList,
      listBrand: Store.getState().listBrand,
      listPoint: Store.getState().listPoint,
      statusLoad: Store.getState().statusLoad,
      optionsMarker: Store.getState().listMarkers
    });
    // console.log('>>>>>>>>>>>>>>!!!!',Store.getState().placeList);
    if (Store.getState().dataTracks !== null) {
      this.sortTracks(Store.getState().dataTracks);
      this.sortMask(Store.getState().dataTracks)
      if (Store.getState().statusFinishSteps === true) {
        this.selectNextTrack()
        Actions.getStatusNext(false);
      }
    }
  }
  selectNextTrack() {
    // console.log('next', this.state.dataTracks);
    var arr = [];
    var arr2 = [];
    this.internalState.stepsDateItem = []
    var arr3 = [];
    var uuid = 0;
    var coorX = 0;
    var coorY = 0;
    for (var i = 0; i < this.state.dataTracks.tracks.length; i++) {
      if (this.state.dataTracks.tracks[i].uuid === this.state.idSelectList) {
        if (this.state.dataTracks.tracks[i + 1] === undefined) {
          uuid = this.state.dataTracks.tracks[i].uuid;
          arr.push(this.state.dataTracks.tracks[i].steps);
          arr2.push(this.state.dataTracks.tracks[i].steps[0]);
          arr3 = this.state.dataTracks.tracks[i].steps_duration_mask;
          // coorX = this.state.dataTracks.tracks[i].steps[0][0];
          // coorY = this.state.dataTracks.tracks[i].steps[0][1];
          coorX = this.getMinCoor(this.state.dataTracks.tracks[i].steps);
          coorY = this.getMaxCoor(this.state.dataTracks.tracks[i].steps);
          this.internalState.arrTimer = JSON.parse(JSON.stringify(this.state.dataTracks.tracks[i].steps_duration_mask));
        } else if (this.state.dataTracks.tracks[i + 1] !== undefined) {
          arr.push(this.state.dataTracks.tracks[i + 1].steps);
          arr2.push(this.state.dataTracks.tracks[i + 1].steps[0]);
          arr3 = this.state.dataTracks.tracks[i + 1].steps_duration_mask;
          // coorX = this.state.dataTracks.tracks[i + 1].steps[0][0];
          // coorY = this.state.dataTracks.tracks[i + 1].steps[0][1];
          coorX = this.getMinCoor(this.state.dataTracks.tracks[i + 1].steps);
          coorY = this.getMaxCoor(this.state.dataTracks.tracks[i + 1].steps);
          this.internalState.arrTimer = JSON.parse(JSON.stringify(this.state.dataTracks.tracks[i + 1].steps_duration_mask));
          uuid = this.state.dataTracks.tracks[i + 1].uuid;
        }
      }
    }
    this.internalState.indexTimer = 0;
    this.internalState.arr2 = [];
    this.setState({
      tracksItem: arr,
      stepMasks: arr3,
      idSelectList: uuid,
      stepTracksItem: arr2,
      coorX: coorX,
      coorY: coorY
    });
  }
  timer() {
    // console.log('!!!!!!!', this.internalState.stepsDateItem);
    if (this.internalState.dateStart + (this.internalState.stepsDateItem.length + 1) * 1000 <= + new Date()) {
      this.internalState.stepsDateItem.push(+ new Date() - this.internalState.dateStart);
      // console.log(this.internalState.stepsDateItem.length - 1, this.internalState.dateStart)

      // this.internalState.indexTimerCount = this.internalState.indexTimerCount + 1;
      this.internalState.arr2 = this.state.stepTracksItem;
      if (this.state.stepMasks[this.internalState.indexTimer] === 1) {
        if (this.state.stepMasks[this.internalState.indexTimer + 1] === undefined) {
          if (this.internalState.indexTimer === this.state.tracksItem[0].length - 1) {
            // console.log('>>>>>>>>');
            // console.log(this.internalState.stepsDateItem, this.internalState.arr2);
            // console.log('>>>>>>>>', this.internalState.stepsDateItem);
            Actions.finishTrack(this.state.idSelectList, this.state.uuid, this.internalState.stepsDateItem);
            clearInterval(this.state.intervalId);
            this.setState({statusTimer: false});
          }
        }
        this.internalState.indexTimer = this.internalState.indexTimer + 1;
        this.internalState.arr2.push(this.state.tracksItem[0][this.internalState.indexTimer]);
        // this.internalState.stepsDateItem.push(+new Date())
        if (this.state.stepMasks[this.internalState.indexTimer + 1] > 1) {
          if (this.state.stepMasks[this.internalState.indexTimer + 1] % 3 === 0) {
            this.setState({
              secondItem: this.state.stepMasks[this.internalState.indexTimer + 1]
            });
          }

          // this.onTimer.bind(this);
        }
      } else if (this.state.stepMasks[this.internalState.indexTimer] > 1) {
        // console.log('!!!!!!!!!!');
        this.internalState.countIndexR = this.internalState.countIndexR + 1;
        // this.internalState.indexTimerSum = this.internalState.countIndexR + (this.state.stepMasks[this.internalState.indexTimer] + this.internalState.indexTimer);
        this.internalState.secondItem = this.state.stepMasks[this.internalState.indexTimer] - this.internalState.countIndexR;
        if (this.internalState.secondItem % 3 === 0) {
          this.internalState.arrTimer[this.internalState.indexTimer] = this.internalState.secondItem;
        }
        // console.log('!!!!!!!', this.internalState.arrTimer[this.internalState.indexTimer], this.state.stepMasks[this.internalState.indexTimer]);
        if (this.internalState.secondItem % 3 === 0) {
          this.setState({secondItem: this.internalState.secondItem});
        }
        // this.onTimerSecond.bind(this);
        if (this.internalState.countIndexR === this.state.stepMasks[this.internalState.indexTimer]) {
          this.internalState.secondItem = 0;
          this.internalState.countIndexR = 0;
          // console.log('>>>>>>>>');
          this.internalState.countIndexR = this.internalState.indexTimerSum;
          this.internalState.indexTimer = this.internalState.indexTimer + 1;
          this.internalState.arr2.push(this.state.tracksItem[0][this.internalState.indexTimer]);
          // this.internalState.stepsDateItem.push(+new Date())
          // console.log('!!!!!!!!!!',this.internalState.indexTimer, this.state.tracksItem[0].length - 1);
          if (this.internalState.indexTimer >= this.state.tracksItem[0].length - 1) {
            // console.log(this.internalState.stepsDateItem, this.internalState.arr2);
            // console.log('>>>>>>>>', this.internalState.stepsDateItem);
            Actions.finishTrack(this.state.idSelectList, this.state.uuid, this.internalState.stepsDateItem);
            clearInterval(this.state.intervalId);
            this.setState({statusTimer: false});

          }
          if (this.state.stepMasks[this.internalState.indexTimer + 1] > 1) {
            if (this.state.stepMasks[this.internalState.indexTimer + 1] % 3 === 0) {
              this.setState({
                secondItem: this.state.stepMasks[this.internalState.indexTimer + 1]
              });
            }

            // this.onTimer.bind(this);
          }
        }
      }
      if (this.internalState.arr2.length % 3 === 0) {
        this.setState({stepTracksItem: this.internalState.arr2});
      }
      // this.onTimerStepTrackItem.bind(this);
    }

  }
  stopTimer() {
    clearInterval(this.state.intervalId);
    this.internalState.arr2 = [];
    this.internalState.stepsDateItem = [];
    this.internalState.dateStart = 0;
    this.internalState.arrTimer[this.internalState.indexTimer] = this.state.stepMasks[this.internalState.indexTimer];
    this.internalState.indexTimer = 0;
    this.internalState.arr2.push(this.state.tracksItem[0][0]);
    this.internalState.countIndexR = 0;
    var secontItem = 0;
    if (this.state.stepMasks[0] > 1) {
      secontItem = this.state.stepMasks[0]
    }
    this.setState({secondItem: secontItem, statusTimer: false, stepTracksItem: this.internalState.arr2});
  }
  startTimer() {
    this.internalState.stepsDateItem = []
    this.internalState.dateStart = +new Date();
    this.internalState.arr2 = [];
    Actions.startTrack(this.state.idSelectList);
    var intervalId = setInterval(this.timer.bind(this), 50);
    this.setState({intervalId: intervalId, statusTimer: true});
  }
  sortMask(dataMasks) {
    var arr = [];
    var tracksData = JSON.parse(JSON.stringify(dataMasks));
    // for (var i = 0; i < tracksData.tracks.length; i++) {
    //   // console.log('>>>>>>>>>>>>>>',tracksData.tracks[i].duration_mask);
    //   arr.push(tracksData.tracks[i].duration_mask)
    // }
    this.setState({masks: arr});

  }
  sortTracks(dataTracks) {
    // console.log('!@!@!@!@!@!@!@!@!@!@!@!@!@');
    var arr = {
      tracking: {
        track: [],
        mask: []
      },
      navigation: {
        track: [],
        mask: []
      },
      wifi: {
        track: [],
        mask: []
      }
    };
    // var tracksData = JSON.parse(dataTracks.tracks);
    // for (var i = 0; i < dataTracks.tracks.length; i++) {
    //   // console.log('>>>>>>>>>>>>>>',dataTracks.tracks[i]);
    //   if (dataTracks.tracks[i].track_type === 'tracking') {
    //     arr.tracking.track.push(dataTracks.tracks[i].points);
    //     arr.tracking.mask.push(dataTracks.tracks[i].duration_mask);
    //   } else if (dataTracks.tracks[i].track_type === 'navigation') {
    //     arr.navigation.track.push(dataTracks.tracks[i].points);
    //     arr.navigation.mask.push(dataTracks.tracks[i].duration_mask);
    //   } else if (dataTracks.tracks[i].track_type === 'wifi') {
    //     arr.wifi.track.push(dataTracks.tracks[i].points);
    //     arr.wifi.mask.push(dataTracks.tracks[i].duration_mask);
    //   }
    //   // arr.push(dataTracks.tracks[i].points)
    // }
    this.setState({
      track: arr,
      objTracks: {
        tracking: {
          track: arr.tracking.track,
          mask: arr.tracking.mask
        },
        navigation: {
          track: arr.navigation.track,
          mask: arr.navigation.mask
        },
        wifi: {
          track: arr.wifi.track,
          mask: arr.wifi.mask
        }
      }
    });
  }
  setMode(newMode) {
    // console.log('??????????????????????????')
    this.setState({mode: newMode, statusSelectPOlygons: true})
  }
  getMode() {
    return this.state.mode;
  }
  updateShapes(shapes) {
    return shapes;
  }
  onChangeId(e) {
    this.setState({value: e.target.value, globalUuid: e.target.value});

  }
  getElementsRoute(elem, elemMask, deleteStatus) {
    // console.log('??????????????????????', this.state.setZoneList);
    if (this.state.mode === 'ZONE_SET') {
      var obj = {};
      for (var j = 0; j < this.state.setZoneList.length; j++) {
        if (this.state.setZoneList[j].statusSelect === true) {
          obj = this.state.setZoneList[j];
        }
      }
      var tempZoneSet = this.state.setZoneList;
      var srr = [];
      for (var y = 0; y < elem.length; y++) {
        // console.log('!!!!', obj);
        var obj2 = {
          uuid: '',
          coords: elem[y],
          name: ''
        };
        for (var f = 0; f < obj.zones.length; f++) {
          // console.log('companentBox', obj.zones[f], elem[y]);
          if (f === y) {
            var name = '';
            // console.log('companentBox', obj.zones[f].name);
            if (obj.zones[f].name !== undefined) {
              name = obj.zones[f].name
            }
            obj2 = {
              uuid: obj.zones[f].uuid,
              coords: elem[y],
              name: name
            };
          }
          // traks.push(this.state.setZoneList[r].zones[y].coords);
        }
        srr.push(obj2);
      }
      obj.zones = srr;
      for (var d = 0; d < tempZoneSet.length; d++) {
        if (tempZoneSet[d].statusSelect === true) {
          tempZoneSet[d] = obj;
        }
      }
      this.setState({setZoneList: tempZoneSet});

      // for (var r = 0; r < this.state.setZoneList.length; r++) {
      //     if (this.state.setZoneList[r].statusSelect === true) {
      //         console.log('!!!!', this.state.setZoneList[r]);
      //         // traks = this.state.setZoneList;
      //         for (var y = 0; y < elem.length; y++) {
      //             if ()
      //             traks.push(this.state.setZoneList[r].zones[y].coords);
      //         }
      //     }
      // }
    } else if (this.state.mode === 'POINT') {
      var arr = [];
      if (deleteStatus === true) {
        for (var i = 0; i < elem.length; i++) {
          var obj = {
            name: '',
            coords: elem[i],
            uuid: '',
            retail: null
          }
          for (var j = 0; j < this.state.listPoint.length; j++) {
            if (JSON.stringify(this.state.listPoint[j].coords) === JSON.stringify(elem[i])) {
              obj = {
                name: this.state.listPoint[j].name,
                coords: elem[i],
                uuid: this.state.listPoint[j].uuid,
                retail: this.state.listPoint[j].retail
              }
            }
          }
          arr.push(obj);
        }
      } else if (deleteStatus === false) {
        // console.log('!!!!!!!!!!!!');
        for (var i = 0; i < elem.length; i++) {
          var obj = {
            name: `I${i}`,
            coords: elem[i],
            uuid: '',
            retail: null
          }
          if (this.state.listPoint[i] !== undefined) {
            if (this.state.listPoint[i].name !== undefined) {
              obj = {
                name: this.state.listPoint[i].name,
                coords: elem[i],
                uuid: this.state.listPoint[i].uuid,
                retail: this.state.listPoint[i].retail
              }
            }
          }
          arr.push(obj);
        }
      }
      // this.setState({
      //     listPoint:arr
      // });
      Actions.updateListPoint(arr);

    } else if (this.state.mode === 'PLACES') {
      var obj = {};
      var tempZoneSet = [];
      var arrBeforJson = JSON.parse(JSON.stringify(this.state.placesList));
      // console.log('!!!!', this.state.placesList, arrBeforJson);
      for (var j = 0; j < elem.length; j++) {
        obj = {
          name: `P${j}`,
          coords: elem[j],
          uuid: '',
          retail: null,
          is_opened: false
        }
        for (var i = 0; i < arrBeforJson.length; i++) {
          if (i === j) {
            var isOpened = false;
            if (arrBeforJson[i].is_opened !== undefined) {
              isOpened = arrBeforJson[i].is_opened
            }
            obj = {
              name: arrBeforJson[i].name,
              coords: elem[j],
              uuid: arrBeforJson[i].uuid,
              retail: arrBeforJson[i].retail,
              is_opened: isOpened
            }
          }
        }
        tempZoneSet.push(obj)

      }
      Actions.updateListPlace(tempZoneSet);
      // this.setState({
      //     placesList:tempZoneSet
      // });

      // for (var r = 0; r < this.state.setZoneList.length; r++) {
      //     if (this.state.setZoneList[r].statusSelect === true) {
      //         console.log('!!!!', this.state.setZoneList[r]);
      //         // traks = this.state.setZoneList;
      //         for (var y = 0; y < elem.length; y++) {
      //             if ()
      //             traks.push(this.state.setZoneList[r].zones[y].coords);
      //         }
      //     }
      // }
    } else if (this.state.mode === 'POLYLINE') {
      // console.log('!!!!!!!????????', this.state.objTracks)
      if (this.state.detailModePolyline === 'tracking') {
        // console.log('!!!!!!!????????11111')
        this.setState({
          track: elem,
          traksMask: elemMask,
          objTracks: {
            tracking: {
              track: elem,
              mask: elemMask
            },
            navigation: {
              track: this.state.objTracks.navigation.track,
              mask: this.state.objTracks.navigation.mask
            },
            wifi: {
              track: this.state.objTracks.wifi.track,
              mask: this.state.objTracks.wifi.mask
            }
          }
        });
        // console.log('!!!!!!!', elemMask,this.state.objTracks)
      } else if (this.state.detailModePolyline === 'navigation') {
        // console.log('!!!!!!!????????2222')
        this.setState({
          track: elem,
          traksMask: elemMask,
          objTracks: {
            tracking: {
              track: this.state.objTracks.tracking.track,
              mask: this.state.objTracks.tracking.mask
            },
            navigation: {
              track: elem,
              mask: elemMask
            },
            wifi: {
              track: this.state.objTracks.wifi.track,
              mask: this.state.objTracks.wifi.mask
            }
          }
        });
        // console.log('!!!!!!!', elemMask,this.state.objTracks)
      } else if (this.state.detailModePolyline === 'wifi') {
        // console.log('!!!!!!!????????3333')
        var temp = this.state.objTracks.tracking.track
        // console.log('!!!!!!!????????3333', temp)
        this.setState({
          track: elem,
          traksMask: elemMask,
          objTracks: {
            tracking: {
              track: JSON.parse(JSON.stringify(temp)),
              mask: this.state.objTracks.tracking.mask
            },
            navigation: {
              track: this.state.objTracks.navigation.track,
              mask: this.state.objTracks.navigation.mask
            },
            wifi: {
              track: elem,
              mask: elemMask
            }
          }
        });
        // console.log('!!!!!!!', elemMask,this.state.objTracks)
      }
    } else if (this.state.mode === 'POLY') {
      if (this.state.statusDetailPoligons === 'poligons_out') {
        this.setState({
          objPolygons: {
            polygons_in: this.state.objPolygons.polygons_in,
            polygons_out: elem,
            poligons_bounds: this.state.objPolygons.poligons_bounds
          }
        });

      } else if (this.state.statusDetailPoligons === 'poligons_in') {
        this.setState({
          objPolygons: {
            polygons_in: elem,
            polygons_out: this.state.objPolygons.polygons_out,
            poligons_bounds: this.state.objPolygons.poligons_bounds
          }
        });
      } else if (this.state.statusDetailPoligons === 'poligons_bounds') {
        this.setState({
          objPolygons: {
            polygons_in: this.state.objPolygons.polygons_in,
            polygons_out: this.state.objPolygons.polygons_out,
            poligons_bounds: elem
          }
        });
      }
    }
    this.setState({statusSelectPOlygons: false});

    // this.internalState.track = elem;

  }
  deleteZone(index) {
    Actions.deleteZone(index);
  }
  onSave() {
    // console.log('!!!!!', this.state.uuid);
    var name1 = '';
    var name2 = '';
    var namePlace = _.uniq(this.state.placesList, (e) => {
      // console.log(e.name);
      if (e.name !== '') {
        name1 = e.name;
        return (e.name);
      } else {
        return (name1);
      }
      // console.log(e);
    })
    // console.log('!!!!!', namePlace);
    var namePOints = _.uniq(this.state.listPoint, (e) => {
      if (e.name !== '') {
        name2 = e.name;
        return (e.name);
      } else {
        return (name2);
      }
      // console.log(e);
    })
    // console.log('!!!!!', namePOints, this.state.listPoint);
    if (namePlace.length === this.state.placesList.length && namePOints.length === this.state.listPoint.length) {
      var arr = [];
      // console.log('>>>>>>', this.state.objTracks);
      for (var key in this.state.objTracks) {
        // console.log('>>>>>>', this.state.objTracks[key].track);
        for (var i = 0; i < this.state.objTracks[key].track.length; i++) {
          var uuidItems = '';
          var dt_start = null;
          var dt_finish = null;
          for (var j = 0; j < this.state.dataTracks.tracks.length; j++) {
            if (JSON.stringify(this.state.objTracks[key].track[i]) === JSON.stringify(this.state.dataTracks.tracks[j].points)) {
              uuidItems = this.state.dataTracks.tracks[j].uuid;
              dt_start = this.state.dataTracks.tracks[j].dt_start;
              dt_finish = this.state.dataTracks.tracks[j].dt_finish;
            }
          }
          var obj = {
            "uuid": uuidItems,
            "is_opened": false,
            "points": this.state.objTracks[key].track[i],
            "dt_start": dt_start,
            "dt_finish": dt_finish,
            "duration_mask": this.state.objTracks[key].mask[i],
            "track_type": key
          }
          arr.push(obj);
        }
      }
      // console.log('!!!!!', arr);
      // for (var i = 0; i < this.state.track.length; i++) {
      //     var uuidItems = '';
      //     var dt_start = null;
      //     var dt_finish = null;
      //     for (var j = 0; j < this.state.dataTracks.tracks.length; j++) {
      //         if (JSON.stringify(this.state.track[i]) === JSON.stringify(this.state.dataTracks.tracks[j].points)) {
      //             uuidItems = this.state.dataTracks.tracks[j].uuid;
      //             dt_start = this.state.dataTracks.tracks[j].dt_start;
      //             dt_finish = this.state.dataTracks.tracks[j].dt_finish;
      //         }
      //     }
      //     var obj = {
      //         "uuid": uuidItems,
      //         "is_opened": false,
      //         "points": this.state.track[i],
      //         "dt_start": dt_start,
      //         "dt_finish": dt_finish,
      //         "duration_mask": this.state.traksMask[i],
      //         "track_type": null
      //     }
      //     arr.push(obj);
      // }
      console.log('!!!!!', this.state.placesList);
      var objtracks = JSON.parse(JSON.stringify(this.state.dataTracks));
      objtracks.tracks = JSON.stringify(arr);
      objtracks['polygons_in'] = JSON.stringify(this.state.objPolygons.polygons_in);
      objtracks['polygons_out'] = JSON.stringify(this.state.objPolygons.polygons_out);
      objtracks['perimeter'] = JSON.stringify(this.state.objPolygons.poligons_bounds);
      objtracks['zone_sets'] = JSON.stringify(this.state.setZoneList);
      objtracks['places'] = JSON.stringify({name: "Places", uuid: '', zones: this.state.placesList})
      objtracks['device_installations'] = JSON.stringify({name: "device_installations", uuid: '', zones: this.state.listPoint})
      // objtracks['places'] = JSON.stringify(this.state.placesList);
      console.log('!!!!!', objtracks);
      Actions.saveTrack({data: objtracks, uuid: this.state.uuid});
    } else {
      if (namePlace.length !== this.state.placesList.length) {
        alert('В помещениях есть не уникальные и/или есть пустые маркеры исправьте это');
      }
      if (namePOints.length !== this.state.listPoint.length) {
        alert('В устройствах есть не уникальные и/или есть пустые названия исправьте это');
      }
    }

  }
  createId() {
    // this.setState({
    //     uuid: this.state.value,
    //     value: ''
    // });

  }
  deleteTrack(index) {
    // if (this.state.detailModePolyline === 'tracking') {
    //     var tracks = [];
    //     var traksMask = [];
    //     traksMask = this.state.objTracks.tracking.mask;
    //     tracks = this.state.objTracks.tracking.track;
    //     traksMask.splice(index, 1);
    //     tracks.splice(index, 1);
    //     this.setState({
    //         objTracks:{
    //             tracking: {
    //                 track: tracks,
    //                 mask: traksMask
    //             },
    //             navigation: {
    //                 track: this.state.objTracks.navigation.track,
    //                 mask: this.state.objTracks.navigation.mask
    //             },
    //             wifi:{
    //                 track: this.state.objTracks.wifi.track,
    //                 mask: this.state.objTracks.wifi.mask
    //             }
    //         }
    //     });
    // }
    // else if (this.state.detailModePolyline === 'navigation') {
    //     var tracks = [];
    //     var traksMask = [];
    //     traksMask = this.state.objTracks.navigation.mask;
    //     tracks = this.state.objTracks.navigation.track;
    //     traksMask.splice(index, 1);
    //     tracks.splice(index, 1);
    //     this.setState({
    //         objTracks:{
    //             tracking: {
    //                 track: this.state.objTracks.tracking.track,
    //                 mask: this.state.objTracks.tracking.mask
    //             },
    //             navigation: {
    //                 track: tracks,
    //                 mask: traksMask
    //             },
    //             wifi:{
    //                 track: this.state.objTracks.wifi.track,
    //                 mask: this.state.objTracks.wifi.mask
    //             }
    //         }
    //     });
    // }
    // else if (this.state.detailModePolyline === 'wifi') {
    //     var tracks = [];
    //     var traksMask = [];
    //     traksMask = this.state.objTracks.wifi.mask;
    //     tracks = this.state.objTracks.wifi.track;
    //     traksMask.splice(index, 1);
    //     tracks.splice(index, 1);
    //     this.setState({
    //         objTracks:{
    //             tracking: {
    //                 track: this.state.objTracks.tracking.track,
    //                 mask: this.state.objTracks.tracking.mask
    //             },
    //             navigation: {
    //                 track: this.state.objTracks.navigation.track,
    //                 mask: this.state.objTracks.navigation.mask
    //             },
    //             wifi:{
    //                 track: tracks,
    //                 mask: traksMask
    //             }
    //         }
    //     });
    // }
  }
  getPlan() {
    Actions.resetDate();
    Actions.update(this.state.value);
  }
  handleChange(value) {
    // console.log('ui', value);
    this.setState({slideIndex: value});
  };
  getDetailMode(status) {
    this.setState({detailModePolyline: status});
  };
  selectFloor(e) {
    // e.props.primaryText
    Actions.selectFloor(+ e.props.primaryText)
  }
  selectFloor2(event, index, value) {
    // console.log(value)
    Actions.selectFloor(value);
    this.setState({statusSelectPOlygons: true});

  }
  onNestedListToggle(e) {
    // console.log(e.props.primaryText);
    var arr = [];
    var arr2 = [];
    var arr3 = [];
    var coorX = 0;
    var coorY = 0;
    for (var i = 0; i < this.state.dataTracks.tracks.length; i++) {
      if (this.state.dataTracks.tracks[i].uuid === e.props.primaryText) {
        arr.push(this.state.dataTracks.tracks[i].steps);
        // console.log('>>>>>>>>>>>>>>',arr);
        // coorX = this.state.dataTracks.tracks[i].steps[0][0];
        // coorY = this.state.dataTracks.tracks[i].steps[0][1];
        coorX = this.getMinCoor(this.state.dataTracks.tracks[i].steps);
        coorY = this.getMaxCoor(this.state.dataTracks.tracks[i].steps);
        // console.log('>>>>>>>>>>>>>>',this.state.dataTracks.tracks[i].steps[0][0], this.state.dataTracks.tracks[i].steps[0][1]);
        arr2.push(this.state.dataTracks.tracks[i].steps[0]);
        arr3 = this.state.dataTracks.tracks[i].steps_duration_mask;
        this.internalState.arrTimer = JSON.parse(JSON.stringify(this.state.dataTracks.tracks[i].steps_duration_mask));
        // console.log('>>>>>>>>>>>>>>###',this.state.dataTracks.tracks[i].steps_duration_mask);
      }
    }
    this.internalState.indexTimer = 0;
    this.internalState.arr2 = [];
    this.internalState.stepsDateItem = []
    this.setState({
      tracksItem: arr,
      stepMasks: arr3,
      idSelectList: e.props.primaryText,
      stepTracksItem: arr2,
      coorX: coorX,
      coorY: coorY
    });

  }
  getDetailPolygins(status) {
    this.setState({statusDetailPoligons: status, statusSelectPOlygons: true});

  }
  getSetZoneName() {
    if (this.state.setZonName !== '') {
      Actions.getNameSetZone(this.state.setZonName);
      this.setState({setZonName: ''});
    }
  }
  selectZoneSet(index) {
    Actions.selectZoneSet(index);
    this.setState({statusSelectPOlygons: true});

  }
  selectZone(index, status) {
    // console.log(index);
    if (status === true) {
      if (this.state.valueInputName !== '') {
        this.setState({statusShowPanelName: false, valueInputName: '', statusSelectPOlygons: false});
      }
      this.internalState.indexSelectZone = index;
      var name = ''
      for (var i = 0; i < this.state.setZoneList.length; i++) {
        if (this.state.setZoneList[i].statusSelect) {
          if (this.state.setZoneList[i].zones[index].name !== undefined) {
            name = this.state.setZoneList[i].zones[index].name;
          }
        }
      }
      setTimeout(() => {
        this.setState({statusShowPanelName: true, valueInputName: name, statusSelectPOlygons: false});
      }, 1)
    } else if (status === false) {
      this.setState({statusShowPanelName: false, valueInputName: '', statusSelectPOlygons: false});
    }

  }
  selectPlaces(index, status) {
    // console.log("))))))))")
    if (status === true) {
      if (this.state.statusShowPanelName !== '') {
        this.setState({statusShowPanelName: false, valueInputName: '', statusSelectPOlygons: false, statusCheckBoxIsOpen: false});
      }
      this.internalState.indexSelectZone = index;
      var name = ''
      var isOpen = false;
      for (var i = 0; i < this.state.placesList.length; i++) {
        if (this.state.placesList[i].name !== undefined) {
          name = this.state.placesList[i].name;
        }
        if (this.state.placesList[i].is_opened !== undefined) {
          isOpen = this.state.placesList[i].is_opened;
        }
      }
      isOpen = this.state.placesList[index].is_opened;
      name = this.state.placesList[index].name;
      setTimeout(() => {
        this.setState({statusShowPanelName: true, valueInputName: name, statusSelectPOlygons: false, statusCheckBoxIsOpen: isOpen});
      }, 1)
    } else if (status === false) {
      this.setState({statusShowPanelName: false, valueInputName: '', statusSelectPOlygons: false, statusCheckBoxIsOpen: false});
    }
  }
  selectPoints(index, status) {
    // console.log('!!!!!!!!!!!')
    // console.log('?????????????2')
    if (status === true) {
      this.internalState.indexSelectZone = index;
      var name = ''
      for (var i = 0; i < this.state.listPoint.length; i++) {
        if (this.state.listPoint[i].name !== undefined) {
          name = this.state.listPoint[i].name;
        }
      }
      name = this.state.listPoint[index].name;
      this.setState({statusShowPanelName: true, valueInputName: name, statusSelectPOlygons: false});
    } else if (status === false) {
      this.setState({statusShowPanelName: false, valueInputName: ''});
    }
  }
  hidePanelZoneName() {
    this.setState({statusShowPanelName: false, valueInputName: '', statusSelectPOlygons: false});
  }
  onChangeInputName(e) {
    if (e.target.value.length < 60) {
      if (this.state.mode === 'ZONE_SET') {
        this.setState({valueInputName: e.target.value});
        Actions.saveNameZone(this.internalState.indexSelectZone, e.target.value);
      } else if (this.state.mode === 'PLACES') {
        this.setState({valueInputName: e.target.value});
        Actions.saveNamePlace(this.internalState.indexSelectZone, e.target.value, this.state.placesList);
      } else if (this.state.mode === 'POINT') {
        this.setState({valueInputName: e.target.value});
        Actions.saveMarkerPoint(this.internalState.indexSelectZone, e.target.value, this.state.listPoint);
      }
    }

  }
  onChangeCheckBox(e) {
    // console.log(e.target.checked)
    this.setState({statusCheckBoxIsOpen: e.target.checked});
    Actions.getCheckBoxIsOpen(this.internalState.indexSelectZone, e.target.checked, this.state.placesList);
  }
  saveNameZone() {
    Actions.saveNameZone(this.internalState.indexSelectZone, this.state.valueInputName);
    this.hidePanelZoneName();
  }
  onChangeZoneName(e) {
    // console.log(e.target.value);
    if (e.target.value.length < 30) {
      this.setState({setZonName: e.target.value});
    }

  }
  onScale(scale) {
    this.setState({scale: scale});

  }
  selectModeGrid() {
    if (this.state.modeGrid === true) {
      this.setState({statusGridGrafics: false});

    }
    this.setState({
      modeGrid: !this.state.modeGrid
    });

  }
  onTabButtonPanel() {
    this.setState({
      statusShowPanel: !this.state.statusShowPanel
    });

  }
  timeout() {
    // console.log('timeout');
    if (this.state.valueSerchString !== '') {
      Actions.getSerch(this.state.valueSerchString);
    }
  }
  onChahgeInputSerch(e) {
    // console.log(e.target.value);
    this.setState({valueSerchString: e.target.value});

    clearTimeout(this.internalState.idTimeOut);
    this.internalState.idTimeOut = setTimeout(this.timeout.bind(this), 800);
  }
  onSelectBrand(data) {
    // console.log(data);
    Actions.saveBrandInPlace(this.internalState.indexSelectZone, data);
    this.setState({valueSerchString: ''});

  }
  onChangeSelectSerch(e) {
    // console.log(e.data);
    if (e !== null) {
      Actions.saveBrandInPlace(this.internalState.indexSelectZone, e.data);
    } else {
      Actions.saveBrandInPlace(this.internalState.indexSelectZone, null);
    }
  }
  sortRetail(data) {
    var json = [];
    for (var i = 0; i < data.length; i++) {
      var obj = {
        value: data[i].id,
        label: data[i].name,
        data: data[i]
      }
      json.push(obj);
    }
    return json;
  }
  getGridGraf() {
    this.setState({
      statusGridGrafics: !this.state.statusGridGrafics
    });

  }
  onChangeMarkers(e) {
    this.setState({objMarker: e, statusSelectPOlygons: true});
    if (e !== null) {
      if (e.type === 'Places') {
        this.setMode('PLACES');
      } else if (e.type === 'IPoint') {
        this.setMode('POINT');
      }
      Actions.selectFloor(e.floor);
    }

  }
  render() {
    // console.log('!!!!!!!!!!!!!!!!!!!!!!!',this.state.objPolygons);

    var componentSpinnerLoader = null;
    var gridObj = null;
    var arrPoint = [];
    // console.log('@@@@@@@@', this.state.objTracks);
    const getOptions = (input) => {
      // console.log('!!!!!!!!',input)
      var this_ = this;
      if (input === '') {
        input = null;
        if (this.state.placesList[this.internalState.indexSelectZone].retail !== null) {
          input = this.state.placesList[this.internalState.indexSelectZone].retail.name
        }
      }
      return fetch(`/geometry/retail/?search=${input}`).then((response) => {
        // console.log(response)
        return response.json();
      }).then((json) => {
        // console.log(json);
        // if (input !== '') {
        //     var json2 = this_.sortRetail(json);
        //     return { options:json2};
        // }
        var json2 = this_.sortRetail(json);
        return {options: json2};
      });
    }
    // console.log(this.state.statusSelectPOlygons);
    var stylePanelSettings = 'block';
    if (this.state.statusShowPanel === false) {
      stylePanelSettings = 'none';
    }
    var scaleGlobal = 0;
    if (this.state.statusLoad === true) {
      componentSpinnerLoader = <div className="backgroundSpinner">
        <div className="loader">
          <div className="inner one"></div>
          <div className="inner two"></div>
          <div className="inner three"></div>
        </div>
      </div>
    }
    if (this.state.dataFloorItem !== null) {
      scaleGlobal = this.state.dataFloorItem.scale;
      if (this.state.modeGrid === true) {
        gridObj = {
          countX: this.state.dataFloorItem.width / (this.state.dataFloorItem.scale * 0.5),
          countY: this.state.dataFloorItem.height / (this.state.dataFloorItem.scale * 0.5),
          scale: this.state.dataFloorItem.scale
        }
      }
      // console.log('main', this.state.dataFloorItem.height / this.state.dataFloorItem.scale, this.state.dataFloorItem.width / this.state.dataFloorItem.scale);
    }
    var polygonsObj = {
      polygons_in: [],
      polygons_out: [],
      poligons_bounds: []
    };
    var componentPanelName = null;
    if (this.state.statusShowPanelName) {
      componentPanelName = <div className="boxPanelName">
        <FormGroup>
          <InputGroup bsSize="small">
            <FormControl value={this.state.valueInputName} onChange={this.onChangeInputName.bind(this)} type="text" placeholder="Имя зоны"/>
          </InputGroup>
        </FormGroup>
      </div>
    }
    var styleButtonStart = {
      position: 'absolute',
      top: this.state.coorY * this.state.scale + 105,
      left: this.state.coorX * this.state.scale
    }
    var nameButtonStart = 'СТАРТ';
    var funcStartButton = this.startTimer.bind(this);
    if (this.state.statusTimer === true) {
      nameButtonStart = 'СТОП'
      funcStartButton = this.stopTimer.bind(this);
    }
    var objButton = {
      nameButton: nameButtonStart,
      func: funcStartButton,
      coorX: this.state.coorX,
      coorY: this.state.coorY
    }

    var componentSteps = null;
    var statusMaterialButton = true;
    var traks = [];
    var traksMask = [];
    var componentFloor = null;
    var componentFloor2 = null;
    var styleStepBox = null;
    var widthStepBox = null
    if (this.state.dataFloorItem !== null) {
      // this.state.styleStepBox.width
      styleStepBox = {
        width: this.state.dataFloorItem.width / this.state.dataFloorItem.scale * 20,
        height: this.state.dataFloorItem.height / this.state.dataFloorItem.scale * 20
      }
      widthStepBox = this.state.dataFloorItem.width / this.state.dataFloorItem.scale * 20
      // console.log('main', widthStepBox);
    }
    if (this.state.dataPlans !== null) {
      var funcFloor = this.selectFloor;
      var floor = this.state.floor
      componentFloor = this.state.dataPlans.plans.map((props, id) => {
        // console.log('main', props);
        var style;
        var style2 = {
          height: '24px'
        };
        if (floor === props.floor) {
          style = {
            background: 'rgba(0,0,0,.5)',
            padding: '4px'
          }
        } else {
          style = {
            padding: '4px'
          };
        }
        return (<ListItem secondaryTextLines={1} style={style2} innerDivStyle={style} primaryTogglesNestedList={true} onNestedListToggle={funcFloor} key={id} primaryText={props.floor}/>)
      });
      componentFloor2 = this.state.dataPlans.plans.map((props, id) => {
        // console.log('main', props);
        return (<MenuItem value={props.floor} key={id} onNestedListToggle={funcFloor} primaryText={`${props.floor} Этаж`}/>)
      });
    }
    // console.log('main', this.state.detailModePolyline, this.state.objTracks.tracking.track);
    if (this.state.detailModePolyline === 'tracking') {
      traks = JSON.parse(JSON.stringify(this.state.objTracks.tracking.track));
      traksMask = JSON.parse(JSON.stringify(this.state.objTracks.tracking.mask));
    } else if (this.state.detailModePolyline === 'navigation') {
      traks = JSON.parse(JSON.stringify(this.state.objTracks.navigation.track));
      traksMask = JSON.parse(JSON.stringify(this.state.objTracks.navigation.mask));
    } else if (this.state.detailModePolyline === 'wifi') {
      traks = JSON.parse(JSON.stringify(this.state.objTracks.wifi.track));
      traksMask = JSON.parse(JSON.stringify(this.state.objTracks.wifi.mask));
    }
    // console.log("????????", this.state.detailModePolyline, traks)
    var componentButtonPolygons = null;
    var backgroundPolygons = {
      polygons: [],
      bounds: []
    };
    if (this.state.mode === 'POLY') {
      var modeSetMode = 'pointPoly';
        // console.log("????????", this.state.objPolygons)
      if (this.state.statusDetailPoligons === 'poligons_out') {
        for (var f = 0; f < this.state.objPolygons.polygons_out.length; f++) {
            traks.push(this.state.objPolygons.polygons_out[f].coords)
        }
        for (var f = 0; f < this.state.objPolygons.polygons_in.length; f++) {
            backgroundPolygons.polygons.push(this.state.objPolygons.polygons_in[f].coords)
        }
        for (var f = 0; f < this.state.objPolygons.poligons_bounds.length; f++) {
            backgroundPolygons.bounds.push(this.state.objPolygons.poligons_bounds[f].coords)
        }
        // backgroundPolygons.polygons = this.state.objPolygons.polygons_in;
        // backgroundPolygons.bounds = this.state.objPolygons.poligons_bounds;
      } else if (this.state.statusDetailPoligons === 'poligons_in') {
          for (var f = 0; f < this.state.objPolygons.polygons_out.length; f++) {
              backgroundPolygons.polygons.push(this.state.objPolygons.polygons_out[f].coords)
          }
          for (var f = 0; f < this.state.objPolygons.polygons_in.length; f++) {
              traks.push(this.state.objPolygons.polygons_in[f].coords)
          }
          for (var f = 0; f < this.state.objPolygons.poligons_bounds.length; f++) {
              backgroundPolygons.bounds.push(this.state.objPolygons.poligons_bounds[f].coords)
          }
        // traks = this.state.objPolygons.polygons_in;
        // backgroundPolygons.polygons = this.state.objPolygons.polygons_out;
        // backgroundPolygons.bounds = this.state.objPolygons.poligons_bounds;
      } else if (this.state.statusDetailPoligons === 'poligons_bounds') {
          for (var f = 0; f < this.state.objPolygons.polygons_out.length; f++) {
              backgroundPolygons.polygons.push(this.state.objPolygons.polygons_out[f].coords)
          }
          for (var f = 0; f < this.state.objPolygons.polygons_in.length; f++) {
              backgroundPolygons.bounds.push(this.state.objPolygons.polygons_in[f].coords)
          }
          for (var f = 0; f < this.state.objPolygons.poligons_bounds.length; f++) {
              traks.push(this.state.objPolygons.poligons_bounds[f].coords)
          }
        // traks = this.state.objPolygons.poligons_bounds;
        // backgroundPolygons.polygons = this.state.objPolygons.polygons_out;
        // backgroundPolygons.bounds = this.state.objPolygons.polygons_in;
      }
        // console.log("????????", traks)
      // console.log("????????", backgroundPolygons, this.state.objPolygons.poligons_bounds)
      componentButtonPolygons = <ButtonGroup bsSize="small">
        {this.state.statusDetailPoligons == 'poligons_bounds'
          ? <Button bsSize="small" bsStyle='primary' onClick={() => this.getDetailPolygins('poligons_bounds')}>poligons_perimeter</Button>
          : <Button bsSize="small" onClick={() => this.getDetailPolygins('poligons_bounds')}>poligons_perimeter</Button>}
        {this.state.statusDetailPoligons == 'poligons_in'
          ? <Button bsSize="small" bsStyle='primary' onClick={() => this.getDetailPolygins('poligons_in')}>poligons_in</Button>
          : <Button bsSize="small" onClick={() => this.getDetailPolygins('poligons_in')}>poligons_in</Button>}
        {this.state.statusDetailPoligons == 'poligons_out'
          ? <Button bsSize="small" bsStyle='primary' onClick={() => this.getDetailPolygins('poligons_out')}>poligons_out</Button>
          : <Button bsSize="small" onClick={() => this.getDetailPolygins('poligons_out')}>poligons_out</Button>}
      </ButtonGroup>
    }
    // console.log('main', traks, backgroundPolygons);
    // componentSteps = this.state.track.map((props,id) => {
    //     return();
    // })
    if (this.state.dataTracks !== null) {
      statusMaterialButton = false
      var funcOnClick = this.onNestedListToggle.bind(this);
      var idSelectList = this.state.idSelectList;
      // componentSteps = this.state.dataTracks.tracks.map((props, id) => {
      //   // console.log('>>>>>>>', this.props.typetrack, props.track_type);
      //   if (this.props.typetrack === props.track_type) {
      //     var style;
      //     var component = null;
      //     if (idSelectList === props.uuid) {
      //       style = {
      //         background: 'rgba(0,0,0,.5)'
      //       }
      //       if (props.dt_finish !== null && props.dt_start !== null) {
      //         style = {
      //           background: 'rgb(24,56,20)',
      //           color: '#fff'
      //         }
      //       }
      //     } else {
      //       style = null;
      //       if (props.dt_finish !== null && props.dt_start !== null) {
      //         style = {
      //           background: 'rgb(0,255,0)'
      //         }
      //       }
      //     }
      //     return (<ListItem rightIcon={component} innerDivStyle={style} primaryTogglesNestedList={true} onNestedListToggle={funcOnClick} key={id} primaryText={props.uuid}/>);
      //   } else {
      //     return (null);
      //   }
      // })
    }
    var statusButton = true,
      statusButtonSaveTrack = true,
      statusInput = false,
      statusbuttonCreateUuid = false;
    if (this.state.globalUuid !== '') {
      // statusButton = false;
    }
    if (this.state.value !== '') {
      statusButton = false;
    }
    if (this.state.track.length > 0) {
      statusButtonSaveTrack = false;
    }
    if (this.state.uuid !== '') {
      statusInput = true;
      statusbuttonCreateUuid = true;
    }
    if (this.state.value === '') {
      statusbuttonCreateUuid = true;
    }
    // console.log('!!!!!', this.state.placesList);
    var componentSerch = null;
    var mode = this.state.mode;
    var namePolygons = []
    var funcSelectZone = null;
    var buttonNodeComponent = null;
    var retailName = [];
    var pointNameList = [];
    var polygonsTraks = [];
    var componentCheckIsOpened = null;
    var checkList = [];
    if (mode === 'POLYLINE') {
      if (this.state.detailModePolyline === 'tracking' || this.state.detailModePolyline === 'navigation') {
        polygonsObj = this.state.objPolygons;
          // console.log('!!!!!', polygonsObj);
        // for (var i = 0; i < this.state.objPolygons.length; i++) {
        // }
        //   console.log('!!!!!>>>>>>>>>', this.state.objPolygons);
      }
      var modeSetMode = 'point';
      for (var r = 0; r < this.state.placesList.length; r++) {
        polygonsTraks.push(this.state.placesList[r].coords);
        // console.log(this.state.placesList[r])
        if (this.state.placesList[r].name === undefined) {
          namePolygons.push('');
        } else {
          namePolygons.push(this.state.placesList[r].name);
        }
        if (this.state.placesList[r].is_opened !== undefined) {
          checkList.push(this.state.placesList[r].is_opened);
        } else {
          checkList.push(false);
        }
        if (this.state.placesList[r].retail !== null && this.state.placesList[r].retail !== undefined) {
          retailName.push(this.state.placesList[r].retail.name);
        } else {
          retailName.push('');
        }
      }
      buttonNodeComponent = <ButtonGroup bsSize="small">
        {this.state.detailModePolyline == 'tracking'
          ? <Button bsSize="small" bsStyle='primary' onClick={() => this.getDetailMode('DEFAULT')}>tracking</Button>
          : <Button bsSize="small" onClick={() => this.getDetailMode('tracking')}>tracking</Button>}
        {this.state.detailModePolyline == 'navigation'
          ? <Button bsSize="small" bsStyle='primary' onClick={() => this.getDetailMode('DEFAULT')}>navigation</Button>
          : <Button bsSize="small" onClick={() => this.getDetailMode('navigation')}>navigation</Button>}
        {this.state.detailModePolyline == 'wifi'
          ? <Button bsSize="small" bsStyle='primary' onClick={() => this.getDetailMode('DEFAULT')}>wifi</Button>
          : <Button bsSize="small" onClick={() => this.getDetailMode('wifi')}>wifi</Button>}
      </ButtonGroup>
    }
    else if (mode === 'ZONE_SET') {
      funcSelectZone = this.selectZone.bind(this)
      traks = [];
      for (var i = 0; i < this.state.listPoint.length; i++) {
        // console.log(this.state.listPoint[i])
        arrPoint.push(this.state.listPoint[i].coords);
        if (this.state.listPoint[i].name === undefined) {
          pointNameList.push('');
        } else {
          pointNameList.push(this.state.listPoint[i].name);
        }
      }
      for (var r = 0; r < this.state.setZoneList.length; r++) {
        if (this.state.setZoneList[r].statusSelect === true) {
          // traks = this.state.setZoneList;
          for (var y = 0; y < this.state.setZoneList[r].zones.length; y++) {
            traks.push(this.state.setZoneList[r].zones[y].coords);
            if (this.state.setZoneList[r].zones[y].name !== undefined) {
              namePolygons.push(this.state.setZoneList[r].zones[y].name);
            } else {
              namePolygons.push('');
            }
          }
        }
      }
      // console.log('main',traks);
      traksMask = [];
      var statusDisabledInputGroup = false;
      if (this.state.dataPlans === null) {
        statusDisabledInputGroup = true;
      }
      var funcSelectZoneSet = this.selectZoneSet.bind(this);
      var funcDelete = this.deleteZone;
      var buttonNodeNames = this.state.setZoneList.map((props, id) => {
        // console.log('main', props.zone_set_name);
        function select(e) {
          if (e.target === document.getElementById(`boxButton${id}`)) {
            funcSelectZoneSet(id);
          }
        }
        function deleteZone() {
          funcDelete(id)
        }
        if (props.statusSelect === true) {
          return (
            <div key={id} id={`boxButton${id}`} className="btn btn-sm btn-primary zoneSetItem" style={{
              position: 'relative'
            }}>
              {props.name}
              <div className="remove" style={{
                position: 'absolute',
                top: '-9px',
                right: '-1px',
                color: 'black'
              }} onClick={deleteZone}>
                <i className="fa fa-times" ariaHidden="true"></i>
              </div>
            </div>
          )
        } else {
          return (
            <div onClick={select} key={id} id={`boxButton${id}`} className="btn btn-sm btn-default zoneSetItem" style={{
              position: 'relative'
            }}>
              {props.name}
              <div className="remove" style={{
                position: 'absolute',
                top: '-9px',
                right: '-1px',
                color: 'black'
              }} onClick={deleteZone}>
                <i className="fa fa-times" ariaHidden="true"></i>
              </div>
            </div>
          )
        }
      })
      polygonsObj = {
        polygons_in: [],
        polygons_out: [],
        poligons_bounds: []
      }
      backgroundPolygons = {
        polygons: [],
        bounds: []
      };
      var modeSetMode = 'pointPoly';
      buttonNodeComponent = <ButtonGroup bsSize="xsmall">
        <div>
          {buttonNodeNames}
          <div style={{
            width: '200px',
            float: 'left'
          }}>
            <InputGroup bsSize="small">
              <FormControl value={this.state.setZonName} onChange={this.onChangeZoneName.bind(this)} disabled={statusDisabledInputGroup} type="text" placeholder="Имя группы зон"/>
              <InputGroup.Button>
                <Button onClick={this.getSetZoneName.bind(this)} disabled={statusDisabledInputGroup}>
                  <i className="fa fa-plus" ariaHidden="true"></i>
                </Button>
              </InputGroup.Button>
            </InputGroup>
          </div>
          {componentPanelName}
        </div>
      </ButtonGroup>
    }
    else if (mode === 'PLACES') {
      var componentListBrand = null;
      var funcSelect = this.onSelectBrand.bind(this);
      if (this.state.listBrand.length > 0 && this.state.valueSerchString !== '') {
        componentListBrand = this.state.listBrand.map((props, id) => {
          // console.log(props);
          function select() {
            return funcSelect(props)
          }
          return (
            <div onClick={select} className="ItemListBrand">{props.name}</div>
          )
        })
      }
      // console.log('!@#!@#!@#!@#!@#', this.state.statusShowPanelName);
      if (this.state.statusShowPanelName) {
        var value = null;
        if (this.state.placesList[this.internalState.indexSelectZone].retail !== null) {
          value = this.state.placesList[this.internalState.indexSelectZone].retail.id
        }
        // console.log('>>>>>>>>', value);
        componentSerch = <div className="serch">
          {/*<input value={this.state.valueSerchString} onChange={this.onChahgeInputSerch.bind(this)} placeholder="Арендатор" type="text"/>*/}
          {/*<div className="listBoxBrand">*/}
          {/*{componentListBrand}*/}
          {/*</div>*/}
          <Select.Async name="form-field-name" placeholder="Арендаторы" style={{
            height: '20px'
          }} multi={false} cache={false} value={value} onChange={this.onChangeSelectSerch.bind(this)} loadOptions={getOptions}/>
        </div>
      }
      funcSelectZone = this.selectPlaces.bind(this)
      traks = [];
      // console.log("!!!!", this.state.listPoint)
      for (var i = 0; i < this.state.listPoint.length; i++) {
        // console.log(this.state.listPoint[i])
        arrPoint.push(this.state.listPoint[i].coords);
        if (this.state.listPoint[i].name === undefined) {
          pointNameList.push('');
        } else {
          pointNameList.push(this.state.listPoint[i].name);
        }
      }
      for (var r = 0; r < this.state.placesList.length; r++) {
        traks.push(this.state.placesList[r].coords);
        // console.log('><><><<<><><>',this.state.placesList[r].is_opened)
        if (this.state.placesList[r].is_opened !== undefined) {
          checkList.push(this.state.placesList[r].is_opened);
        } else {
          checkList.push(false);
        }
        if (this.state.placesList[r].name === undefined) {
          namePolygons.push('');
        } else {
          namePolygons.push(this.state.placesList[r].name);
        }
        if (this.state.placesList[r].retail !== null && this.state.placesList[r].retail !== undefined) {
          retailName.push(this.state.placesList[r].retail.name);
        } else {
          retailName.push('');
        }
        // for (var y = 0; y < this.state.placesList[r].zones.length; y++) {
        //     traks.push(this.state.setZoneList[r].zones[y].coords);
        //     if (this.state.setZoneList[r].zones[y].name !== undefined) {
        //         namePolygons.push(this.state.setZoneList[r].zones[y].name);
        //     }
        //     else {
        //         namePolygons.push('');
        //     }
        // }
      }
      // console.log('!!!!!!!!!!!!!!!!!!!!!!!', checkList);
      // console.log('main',this.state.placesList);
      traksMask = [];
      var statusDisabledInputGroup = false;
      if (this.state.dataPlans === null) {
        statusDisabledInputGroup = true;
      }

      polygonsObj = {
        polygons_in: [],
        polygons_out: [],
        poligons_bounds: []
      }
      if (this.state.statusShowPanelName) {
        componentPanelName = <div className="boxPanelName">
          <FormGroup>
            <InputGroup bsSize="small">
              <FormControl value={this.state.valueInputName} onChange={this.onChangeInputName.bind(this)} type="text" placeholder="Маркер Помещения"/>
            </InputGroup>
          </FormGroup>
        </div>
        componentCheckIsOpened = <div className="boxCheck">
          <FormGroup>
            <InputGroup bsSize="small">
              <label htmlFor="isOpened">Открыт</label>
              <input onChange={this.onChangeCheckBox.bind(this)} checked={this.state.statusCheckBoxIsOpen} type="text" id="isOpened" type="checkbox"/>
            </InputGroup>
          </FormGroup>
        </div>
      }
      backgroundPolygons = {
        polygons: [],
        bounds: []
      };
      var modeSetMode = 'pointPoly';
      buttonNodeComponent = <ButtonGroup bsSize="small">
        <div>
          {componentPanelName}
        </div>
      </ButtonGroup>
    }
     else if (mode === 'POINT') {
      funcSelectZone = this.selectPoints.bind(this)
      traks = [];
      for (var i = 0; i < this.state.listPoint.length; i++) {
        // console.log(this.state.listPoint[i])
        arrPoint.push(this.state.listPoint[i].coords);
        if (this.state.listPoint[i].name === undefined) {
          pointNameList.push('');
        } else {
          pointNameList.push(this.state.listPoint[i].name);
        }
      }
      // console.log(pointNameList, arrPoint)
      // console.log(arrPoint)
      for (var r = 0; r < this.state.placesList.length; r++) {
        traks.push(this.state.placesList[r].coords);
        // console.log(this.state.placesList[r])
        if (this.state.placesList[r].name === undefined) {
          namePolygons.push('');
        } else {
          namePolygons.push(this.state.placesList[r].name);
        }
        if (this.state.placesList[r].retail !== null && this.state.placesList[r].retail !== undefined) {
          retailName.push(this.state.placesList[r].retail.name);
        } else {
          retailName.push('');
        }
        // for (var y = 0; y < this.state.placesList[r].zones.length; y++) {
        //     traks.push(this.state.setZoneList[r].zones[y].coords);
        //     if (this.state.setZoneList[r].zones[y].name !== undefined) {
        //         namePolygons.push(this.state.setZoneList[r].zones[y].name);
        //     }
        //     else {
        //         namePolygons.push('');
        //     }
        // }
      }
      // console.log('main',traks);
      traksMask = [];
      var statusDisabledInputGroup = false;
      if (this.state.dataPlans === null) {
        statusDisabledInputGroup = true;
      }

      polygonsObj = {
        polygons_in: [],
        polygons_out: [],
        poligons_bounds: []
      }
      if (this.state.statusShowPanelName) {
        componentPanelName = <div className="boxPanelName">
          <FormGroup>
            <InputGroup bsSize="small">
              <FormControl value={this.state.valueInputName} onChange={this.onChangeInputName.bind(this)} type="text" placeholder="Маркер Дивайса"/>
            </InputGroup>
          </FormGroup>
        </div>
      }
      backgroundPolygons = {
        polygons: [],
        bounds: []
      };
      var modeSetMode = 'pointPoly';
      buttonNodeComponent = <ButtonGroup bsSize="small">
        <div>
          {componentPanelName}
        </div>
      </ButtonGroup>
    }
    const wellStyles = {
      maxWidth: 400,
      margin: '0 auto 10px'
    };
    var componentUuidSetPanel = null;
    if (this.props.uuidForSet === '') {
      componentUuidSetPanel = <div className="boxPanelUuidSet">
        <form>
          <div className="boxFormsInputUuid">
            <FormGroup bsSize="small">
              <ControlLabel>Получить план</ControlLabel>
              <FormControl value={this.state.value} onChange={this.onChangeId.bind(this)} type="text" placeholder="Введите uuid сет плана"/> {/*<Button onClick={} disabled={} bsStyle="primary" bsSize="small">Получить план</Button>*/}
            </FormGroup>
          </div>
          <div className="boxButtonUuid">
            <Button onClick={this.getPlan.bind(this)} bsStyle='primary' disabled={statusButton} bsSize="small">Получить</Button>
          </div>
        </form>
      </div>
    } else {
      componentUuidSetPanel = null;
    }
      // console.log("????????", backgroundPolygons, traks)
    // console.log('>>>>>>>>>>>>>>',this.state.stepTracksItem, this.state.tracksItem);
      return (
          <div id="boxComponent" style={{}}>

              <div>
                  <div style={styles.slide}>
                      <Drawer checkList={checkList} markerItem={this.state.objMarker} polygonsObj={polygonsObj} polygonsTraks={polygonsTraks} globaScale={scaleGlobal} statusShowGrid={this.state.statusGridGrafics} points={arrPoint} retailName={retailName} namesPoints={pointNameList} statusSelectMode={this.state.statusSelectPOlygons} gridScale={0.5} grid={gridObj} namePolygons={namePolygons} onSelectPolygons={funcSelectZone} statusMode={modeSetMode} statusPolygons={this.state.statusDetailPoligons} backgroundElem={backgroundPolygons} {...this.props} data={traksMask} getRouters={this.getElementsRoute.bind(this)} tracks={traks} deleteTrack={this.deleteTrack.bind(this)} getMode={this.getMode.bind(this)} updateShapes={this.updateShapes.bind(this)}/>
                  </div>

              </div>
          </div>
      )
  }
}
// ComponentBoxHolst.childContextTypes = {
//     muiTheme: React.PropTypes.object.isRequired,
// };
// ComponentBoxHolst.propTypes = {};
// ComponentBoxHolst.defaultProps = {};

export default ComponentBoxHolst;
