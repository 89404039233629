import React from 'react';
import ReactDOM from 'react-dom';
// import $ from "jquery";

var JSONData = 0;
var JSONSvgElements = 0;
var JSONScale = 0;

var DrowerHolst  = class DrowerHolstClass {

    static addChangeStoreModuleListener(callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) == -1) {
            this.moduleChangeStoreListeners.push(callback);
        }
    }
    static removeChangeStoreModuleListener (callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) != -1) {
            this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
        }
    }
    static emitChangeToModuleListeners () {
        // console.log('092009200444', this.moduleChangeStoreListeners)
        for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
            this.moduleChangeStoreListeners[i](this.shapes);
        }
    }
    static initShapes(shapes) {
        console.log('initShapes')
        this['shapes'] = {};
    }
    constructor(props) {
        console.log('constructor')
        this.state = {}
        this.initShapes(props.shapes);
    }

    static initProps(props, func){
        this['props'] = props;
    }

    static initState(state) {
        this['moduleChangeStoreListeners'] = []
        this['state'] = state;
    }

    // componentWillMount() {
    //     document.onkeydown = this.deletePoints.bind(this);
    //     this.shapes.statusSvgCloseElem = true;
    // }

    static componentWillReceiveProps(nextProps) {

    }

    static holstOnClick(x, y, scale, event) {


    }
    static holstMouseMove(x, y, scale) {

    }
    //убрать в кишки
    static selectPoint(shapeType, is) {
        console.log('selectPoint');
        this.shapes.selectedPoint = {
            shapeType: shapeType,
            is: is
        }
        // this.setState({});

    }
    static selectShape(shapeType, i) {

    }
    static holstMouseUp(x, y, scale) {

    }
    static trashClick() {

    }
    static contextMenu(e, e1){
        console.log('context', e1[0], e1[1]);
        e.preventDefault();
        this.shapes.statusContext = true;
        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        this.shapes.indexI = e1[0];
        this.shapes.indexJ = e1[1];
        // this.setState({
        // });

        // console.log('context', e1, e2);
    }
    static _getCoords(e) {
        var holst = document.getElementById('holst_svg')
        // var holst = ReactDOM.findDOMNode(this.refs.holst);
        var dim = holst.getBoundingClientRect();
        var x = (e.clientX - dim.left);
        var y = (e.clientY - dim.top);
        return [x, y];
    }
    static onClickContext(e, data){

    }
    static netRoundCoordinates(arrCoords, scale, gridStatus, scaleHolst, paramGrid){
        if (gridStatus === false) {
            return arrCoords;
        }
        else if (gridStatus === true) {
            var x = Math.round(arrCoords[0] / (scale *  paramGrid * scaleHolst)) * (scale *  paramGrid * scaleHolst);
            var y = Math.round(arrCoords[1] / (scale *  paramGrid * scaleHolst)) * (scale *  paramGrid * scaleHolst);
            // console.log('scale', scale);
            return [x,y];
        }
    }
    //переименовать keydown
    static keyDown(event){

    }
    //переопределить

    static onClickPointPoly(e, data, indexArr){
        console.log('onClickPointPoly')
        e.preventDefault();
        e.stopPropagation()
        this.shapes.arrIndexPoly = indexArr;
        this.shapes.statusContextPoly = true;
        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        // this.setState({});
    }
    //метод для полигонов
    static onClickContextMenuPoly(e,status){

    }
    //рассмотреть
    static onEnterPoint(){
        // console.log('!!!!!!!');
        this.shapes.statusEnnterPoints = true;
    }
    static onLeavePOint(){
        this.shapes.statusEnnterPoints = false;
    }
    //переименновать
    static getSvgElementsForDraw(scale){

    }
    static getSvgElements(scale) {

    }
    // static onChangeHolst(data) {
    //     if (JSON.stringify(data) !== JSON.stringify(JSONData)) {
    //         JSONData = data;
    //
    //     }
    // }
    static onClickBox(e,r){
        this.shapes.statusContext = false;
        this.shapes.statusContextPoly = false;
        this.shapes.markerItem = null;
        if (this.props.clearMarkerFind !== undefined) {
            this.props.clearMarkerFind(null);
        }
        // this.setState({});
    }
}

export default DrowerHolst;
