/**
 * Created by kirilldrozdov on 01.02.17.
 */
var ru = require('./ru.js');
var en = require('./en.js');
var tultipHotspot = require("./tultipBoxRu.jsx");
var tultipHotspotEn = require("./tultipBoxEn.jsx");
var discrRu = require('./discrRu');
var discrEn = require('./discrEn');
var state = {};

function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);


    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    console.log('setCookie')

    document.cookie = updatedCookie;
}

var funcBox = {
    moduleChangeStoreListeners: [],
    addChangeStoreModuleListener: function(callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) === -1) {
            this.moduleChangeStoreListeners.push(callback);
        }
    },
    removeChangeStoreModuleListener: function (callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) !== -1) {
            this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
        }
    },
    emitChangeToModuleListeners: function () {
        for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
            this.moduleChangeStoreListeners[i](state);
        }
    },
    getUserLang: function() {
        var userLang = navigator.language || navigator.userLanguage;
        if (userLang) {
            userLang = userLang.slice(0, 2);
            return userLang;
        } else {
          return 'en';
        }
    },
    updateCoockie: function (params) {
        console.log('func', params.language);
        var date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())
        var optionsData = {
            expires: date.toUTCString(),
            path: '/'
        };
        if (params.language !== undefined) {
            if (params.language !== 'ru' && params.language !== 'en') {
                setCookie('lang', 'en', optionsData);
            }
            else {
                setCookie('lang', params.language, optionsData);
            }
        }
        else if (params.language === undefined) {
            var userLang = this.getUserLang()
            if (['ru', 'en'].indexOf(userLang) >= 0) {
                setCookie('lang', userLang, optionsData)
            } else {
                setCookie('lang', 'en', optionsData)
            }
        }
        // console.log('func', params.language, document.cookie);
        this.emitChangeToModuleListeners();
    },
    getCoockie: function (cooc) {
        console.log('func', cooc);
        state = {};
        for (var i = 0; i < cooc.length; i++) {
            cooc[i].split('=');
            // console.log('func',cooc[i].split('='));
            if (cooc[i].split('=')[0] === 'lang' || cooc[i].split('=')[0] === ' lang') {
                if (cooc[i].split('=')[1] === 'ru') {
                    state = ru;
                    state.connectionsTultip = discrRu;
                }
                else if (cooc[i].split('=')[1] === 'en') {
                    state = en;
                    state.connectionsTultip = discrEn;
                }
            }
        }
        if (state.connectionsTultip === undefined) {
            state = ru;
            state.connectionsTultip = discrRu;
        }
        this.emitChangeToModuleListeners();
    },
    getState: function() {
        return state;
    }
};

module.exports = funcBox;
