var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var getHeatColor = require('../tools/color.js');
var lang = require('../../../lang/func.js');

var Panel = React.createClass({
	getInitialState: function () {
		return {
			activeStatus: false,
			routerItem: null,
			widthStaticPanel: null,
			value: null,
			name: null,
			svgNode: null,
			nameItemsRouter: {},
			loadStatus: false,
			idZone: null,
			svgGlobal: null,
			routersObj: AppStore.getState().routersObj,
            roomsTransition: AppStore.getState().roomsTransition,
            rooms: AppStore.getState().rooms,
            dataFloors: AppStore.getState().dataFloors,
            previewItem: AppStore.getState(). mapPreviewItem,
            itemRetailName: AppStore.getState().itemRetailName,
            uuidPlanSet: AppStore.getState().uuidPlanSet,
            zones:AppStore.getState().zones
		};
	},
	_onChange: function () {
		if (this.internalState.isMounted === true) {
			if (document.getElementById('statisticPanel') != null) {
				this.setState({widthStaticPanel: document.getElementById('statisticPanel').getBoundingClientRect().width,
					loadStatus: AppStore.getState().loadStatusStatisitcs
				});
			}
			if (AppStore.getState().mapSvg != null) {
				this.setState({
	        			routersObj: AppStore.getState().routerObj
	        		});

				for (var i = 0; i < AppStore.getState().mapSvg.length; i++) {

		        	if (i != AppStore.getState().selectedAreaIndex) {
		        		this.setState({
		        			activeStatus: false,
		        			routerItem: null,
		        			name: null
		        		});
		        	}
		        	else if (i == AppStore.getState().selectedAreaIndex) {
		        		this.setState({
		        			activeStatus: true,
		        			name: AppStore.getState().mapSvg[i].data.name,
		        			svgNode: AppStore.getState().mapSvg[i],
		        			nameItemsRouter: AppStore.getState().objRoutersName,
		        			idZone: AppStore.getState().mapSvg[i].id,
		        			svgGlobal: AppStore.getState().mapSvg
		        		});
		        		var arrRoutesElem = [];
		        		var value = AppStore.getState().mapSvg[i].data.distribution;
		        		// важно
		        		var maxPersent = null;
		        		for (var key in value) {
		        			if (maxPersent == null) {
		        				maxPersent = value[key].percent;
		        			}
		        			else if (maxPersent != null) {
		        				if (maxPersent < value[key].percent) {
		        					maxPersent = value[key].percent;
		        				}
		        			}
						}
						for (var keyy in value) {
		        			var persent = (value[keyy].percent / maxPersent) * 100;
						    value[keyy].relativelyPersent = persent;
						}
						var keys = Object.keys(value);
						var sortedKeys = keys.sort(function(a, b){
							if (value[a].relativelyPersent < value[b].relativelyPersent) {
								return 1;
							}
							if (value[a].relativelyPersent > value[b].relativelyPersent) {
								return -1;
							}
							return 0;
						});
						this.setState({
							routerItem: sortedKeys,
							value: value
						});
				        return;
		        	}
		        }
			}
			var roomsTransition = []
			if (AppStore.getState().uuidPlanSet !== null) {
                var value = JSON.parse(JSON.stringify(AppStore.getState().roomsTransition));
                // важно
                var maxPersent = null;
                for (var key in value) {
                    if (maxPersent == null) {
                        maxPersent = value[key].intersection_percent;
                    }
                    else if (maxPersent != null) {
                        if (maxPersent < value[key].intersection_percent) {
                            maxPersent = value[key].intersection_percent;
                        }
                    }
                }
                for (var keyy in value) {
                    var persent = (value[keyy].intersection_percent / maxPersent) * 100;
                    value[keyy].intersection_percent = persent;
                }
                var keys = Object.keys(value);
                var sortedKeys = keys.sort(function(a, b){
                    if (value[a].intersection_percent < value[b].intersection_percent) {
                        return 1;
                    }
                    if (value[a].intersection_percent > value[b].intersection_percent) {
                        return -1;
                    }
                    return 0;
                });
                roomsTransition = sortedKeys
			}

            // console.log('onChange', sortedKeys, keys, AppStore.getState().roomsTransition)
			// debugger
            this.setState({
                roomsTransition: roomsTransition,
                rooms: AppStore.getState().rooms,
                dataFloors: AppStore.getState().dataFloors,
                previewItem: AppStore.getState(). mapPreviewItem,
                itemRetailName: AppStore.getState().itemRetailName,
                uuidPlanSet: AppStore.getState().uuidPlanSet,
                zones:AppStore.getState().zones
            });

		}
    },
    componentDidMount: function () {
    	this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
    internalState: {
    	isMounted: null,
    	data: []
    },
    createColor: function (color, id) {
    	var id = [];
    	var obj = {
    		id: id,
    		color: color
    	};
    	this.internalState.data.push(obj);
    },
	render: function () {
		var PanelNode;
        // console.log('323232323233', this.state.roomsTransition, this.state.zones)
		if (this.state.routerItem != null || this.props.statusSelectPolygons === true) {

				var barsNodes = [];
				for (var i in this.state.routerItem) {
				    var key = this.state.routerItem[i];
				    var name = this.state.nameItemsRouter[key];
				    var value = this.state.value[key];
				    var width = (this.state.widthStaticPanel * 70 / 100) - ((this.state.widthStaticPanel * 70 / 100) * value.relativelyPersent / 100);
                    // console.log('323232323233', this.state.routerItem, this.state.routersObj)
				    if (this.state.routersObj[key] !== undefined) {
				    	if (this.state.routersObj[key].data.distribution[this.state.idZone] != undefined) {
							var backPersent = this.state.routersObj[key].data.distribution[this.state.idZone].percent;
							var floor = this.state.routersObj[key].floor;
					    }
					    else if (this.state.routersObj[key].data.distribution[this.state.idZone] == undefined) {
							var backPersent = 0;
							var floor = this.state.routersObj[key].floor;
					    }
					    var persentColor = value.percent / 100;
					    var color = getHeatColor(persentColor);
			
					    barsNodes.push(	<div key={key} className="boxBar">
					    					<svg className='svgBoxBar'>
						    					<g>
							    					<rect y='0' x='0' height="38" width={value.relativelyPersent + '%'} style={{fill: color}} />
													<text fill='rgb(97, 97, 97)' x="70" y="18" >{name}</text>
													<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>
													<text fill='rgb(135, 135, 135)' x="25" y="18">{value.percent + '%'}</text>
													<text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{backPersent + '%'}</text>
													<image xlinkHref="/img/icons/arrows.png" x="0" y="6" height="27px" width="27px" />
												</g>
						    				</svg>
					    				</div>);
					    }
				}
				// console.log('_onChangerender', this.state.roomsTransition)
				if (this.state.uuidPlanSet !== null) {
                    for (var i in this.state.roomsTransition) {
                        // console.log('_onChangerender', AppStore.getState().roomsTransition[this.state.roomsTransition[i]].reverse_intersection_percent, this.state.rooms[this.state.roomsTransition[i]])
                        if (AppStore.getState().roomsTransition[this.state.roomsTransition[i]] !== undefined) {
                            var persentColor = AppStore.getState().roomsTransition[this.state.roomsTransition[i]].intersection_percent / 100;
                            var color = getHeatColor(persentColor);
                            if (this.state.rooms[this.state.roomsTransition[i]] !== undefined) {
                                barsNodes.push(	<div key={barsNodes.length} className="boxBar">
                                    <svg className='svgBoxBar'>
                                        <g>
                                            <rect y='0' x='0' height="38" width={AppStore.getState().roomsTransition[this.state.roomsTransition[i]].intersection_percent.toFixed(1) + '%'} style={{fill: color}} />
                                            <text fill='rgb(97, 97, 97)' x="70" y="18" >{this.state.rooms[this.state.roomsTransition[i]].retail_name}</text>
                                            {/*<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>*/}
                                            <text fill='rgb(135, 135, 135)' x="25" y="18">{AppStore.getState().roomsTransition[this.state.roomsTransition[i]].intersection_percent.toFixed(1) + '%'}</text>
                                            <text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{AppStore.getState().roomsTransition[this.state.roomsTransition[i]].reverse_intersection_percent.toFixed(1) + '%'}</text>
                                            <image xlinkHref="/img/icons/arrows.png" x="0" y="6" height="27px" width="27px" />
                                        </g>
                                    </svg>
                                </div>);
                            }
                            else {

                                var statusActive = false;
                                for (var j = 0; j < this.state.previewItem.length; j++) {
                                    if (+this.state.roomsTransition[i] === this.state.previewItem[j].idFloors) {
                                        statusActive = this.state.previewItem[j].statusActive
                                    }
                                }
                                if (this.state.zones[this.state.roomsTransition[i]] !== undefined) {
                                    // console.log('323232323233', this.state.zones[this.state.roomsTransition[i]].name)
                                    if (statusActive === false) {
                                        barsNodes.push(	<div key={barsNodes.length} className="boxBar">
                                            <svg className='svgBoxBar'>
                                                <g>
                                                    <rect y='0' x='0' height="38" width={AppStore.getState().roomsTransition[this.state.roomsTransition[i]].intersection_percent.toFixed(1) + '%'} style={{fill: color}} />
                                                    <text fill='rgb(97, 97, 97)' x="70" y="18" >{`${this.state.zones[this.state.roomsTransition[i]].name}`}</text>
                                                    {/*<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>*/}
                                                    <text fill='rgb(135, 135, 135)' x="25" y="18">{AppStore.getState().roomsTransition[this.state.roomsTransition[i]].intersection_percent.toFixed(1) + '%'}</text>
                                                    <text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{AppStore.getState().roomsTransition[this.state.roomsTransition[i]].reverse_intersection_percent.toFixed(1) + '%'}</text>
                                                    <image xlinkHref="/img/icons/arrows.png" x="0" y="6" height="27px" width="27px" />
                                                </g>
                                            </svg>
                                        </div>);
                                    }
								}
								else {
                                    if (statusActive === false) {
                                        barsNodes.push(	<div key={barsNodes.length} className="boxBar">
                                            <svg className='svgBoxBar'>
                                                <g>
                                                    <rect y='0' x='0' height="38" width={AppStore.getState().roomsTransition[this.state.roomsTransition[i]].intersection_percent.toFixed(1) + '%'} style={{fill: color}} />
                                                    <text fill='rgb(97, 97, 97)' x="70" y="18" >{`${this.state.roomsTransition[i]} Этаж`}</text>
                                                    {/*<text fill='rgb(97, 97, 97)' x="70" y="32" >{'(' + lang.getState().maps.intersections.right_panel.floor + ' ' + floor + ')'}</text>*/}
                                                    <text fill='rgb(135, 135, 135)' x="25" y="18">{AppStore.getState().roomsTransition[this.state.roomsTransition[i]].intersection_percent.toFixed(1) + '%'}</text>
                                                    <text className='backPersent' fill='rgb(135, 135, 135)' x="25" y="32" >{AppStore.getState().roomsTransition[this.state.roomsTransition[i]].reverse_intersection_percent.toFixed(1) + '%'}</text>
                                                    <image xlinkHref="/img/icons/arrows.png" x="0" y="6" height="27px" width="27px" />
                                                </g>
                                            </svg>
                                        </div>);
                                    }
								}
                            }

                        }
                    }
				}

            	// console.log('_onChangerender>>>>>>', barsNodes)
				PanelNode = <div>

								<div className='titlePanel'>
									<h4>{this.state.itemRetailName}</h4>
								</div>

								<div style={{height: window.innerHeight, overflow: 'auto', width: 204 + 'px', float: 'left'}}>
									{barsNodes}
								</div>
								<img className='scale' src='/img/heat_map.png' />
							</div>
			}
			else {
				PanelNode = <p style={{padding: '10px'}}>
					{lang.getState().maps.intersections.right_panel.not_selected_lable}
				</p>
				return null;

			}
			if (this.state.uuidPlanSet !== null) {
                if (this.state.roomsTransition.length === 0) {
                    PanelNode = <p style={{padding: '10px'}}>
                        {lang.getState().maps.intersections.right_panel.not_selected_lable}
                    </p>
                    return null;
                }
			}
			if (this.state.routerItem != null || this.props.statusSelectPolygons === true) {
                return (
                    <div id='statisticPanel' className="statisticPanel" >
                        <div>
                            {PanelNode}
                        </div>
                    </div>
                );
			}
			else {
                return (
                    null
                );
			}
	}
});

module.exports = Panel;
