/**
 * Created by kirilldrozdov on 30.03.17.
 */
import $ from "jquery";

var Api = {
    // constructor(props){
    //     // super();
    //     this.internalState = {
    //         urlTrack: ''
    //     }
    // }
    getRequest(url, data1, success, error){
        // console.log('!!!!!', data1);
        	$.ajax({
        		url: url,
        		data: data1,
        		type: 'POST',
                dataType: 'JSON',
                crossDomain:true,
        		success: function(data) {
                   success(data);
                }.bind(this),
                error: function(xhr, status, err) {
                    error(err);
                    console.error(err);
                }.bind(this)
        	});
    },
    getRequestHeaders(url, success, error){
        $.ajax({
            url: url,
            type: 'GET',
            success: function(data) {
                // console.log('ajax', data);
                success(data);
            }.bind(this),
            error: function(xhr, status, err) {
                error(err);
                console.error(err);
            }.bind(this)
        });
    },
    update(data, success, error){
        var url = `https://maps.getshopster.net/geometry/plan_set_info/${data}/`;
        // console.log(url);
        function sorted(state) {
            console.log('>>>>api', state);
            // for (var i = 0; i < state.plans.length; i++) {
            //     state.plans[i].tracks.sort((a, b) => {
            //         if (+new Date(a .dt) < +new Date(b.dt)) {
            //             return -1;
            //         }
            //         if (+new Date(a.dt) > +new Date(b.dt)) {
            //             return 1;
            //         }
            //         return 0;
            //     });
            // }
            // state = {
            //     "plan_set_uuid": "...",
            //     "plan_set_name": "...",
            //     "plans": [
            //         {
            //             "floor": 1,
            //             "plan_uuid": "...",
            //             "places": [
            //                 {
            //                     "marker": "...",
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 1200]],
            //                     "title": "..."
            //                 }
            //             ],
            //             "zone_groups": [
            //                 {
            //                     "group_name": "...",
            //                     "zones": [
            //                         {
            //                             "zone_name": "...",
            //                             "coords": [[300, 400], [400, 500], [500, 600], [600, 1200]],
            //                         }
            //                     ]
            //                 }
            //             ],
            //             "polygons_in": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 1200]]
            //                 }
            //             ],
            //             "polygons_out": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 1200]]
            //                 }
            //             ],
            //             "perimeter": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 1200]]
            //                 }
            //             ]
            //         },
            //         {
            //             "floor": 2,
            //             "plan_uuid": "...",
            //             "places": [
            //                 {
            //                     "marker": "...",
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 1200]],
            //                     "title": "..."
            //                 }
            //             ],
            //             "zone_groups": [
            //                 {
            //                     "group_name": "...",
            //                     "zones": [
            //                         {
            //                             "zone_name": "...",
            //                             "coords": [[300, 700], [400, 700], [500, 800], [600, 1200]],
            //                         }
            //                     ]
            //                 }
            //             ],
            //             "polygons_in": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 600]]
            //                 }
            //             ],
            //             "polygons_out": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 1200]]
            //                 }
            //             ],
            //             "perimeter": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [600, 1500]]
            //                 }
            //             ]
            //         },
            //         {
            //             "floor": 3,
            //             "plan_uuid": "...",
            //             "places": [
            //                 {
            //                     "marker": "...",
            //                     "coords": [[300, 400], [400, 500], [500, 600], [400, 500]],
            //                     "title": "..."
            //                 }
            //             ],
            //             "zone_groups": [
            //                 {
            //                     "group_name": "...",
            //                     "zones": [
            //                         {
            //                             "zone_name": "...",
            //                             "coords": [[300, 1000], [400, 1000], [500, 1100], [400, 500]],
            //                         }
            //                     ]
            //                 }
            //             ],
            //             "polygons_in": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [500, 1600], [500, 2600]]
            //                 }
            //             ],
            //             "polygons_out": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [500, 1600], [500, 2600]]
            //                 }
            //             ],
            //             "perimeter": [
            //                 {
            //                     "coords": [[300, 400], [400, 500], [500, 600], [500, 1600], [500, 2600]]
            //                 }
            //             ]
            //         }
            //     ]
            // }
            // console.log(state.tracks);
            success(state);
        }
        this.getRequestHeaders(url, sorted, error);
    },
    saveTrack(data, success, error){
        // console.log(data);
        var url = `/geometry/save_tracks/${data.uuid}/`;

        this.getRequest(url, data.data,success, error);
    },
    startTrack(data, success, error){
        var url = `/geometry/start_track/${data}/`;
        this.getRequestHeaders(url, success, error);
    },
    finishTrack(state ,data, success, error){
        var objState = {
            timing: JSON.stringify(state)
        }
        // console.log(objState)
        var url = `/geometry/finish_track/${data}/`;
        this.getRequest(url, objState ,success, error);
    },
    setSerch(data, success, error){
        var url = `/geometry/retail/?search=${data}`;
        this.getRequestHeaders(url, success, error);
    }
};
export default Api;
