
var $ = require('jquery');

var Api = {
	internalState: {
		headers: {},
		requestHeaders: null,
		dataFunnel: [{
		    "title": "sms + vkontakte / facebook / odnoklassniki / instagram + button",
		    "steps": [
		        ["sms"],
		        ["vkontakte", "facebook", "odnoklassniki", "instagram"],
		        ["button"]
		    ],
		    "detalization": {
		        "1170": {
		            "sessions_started": 1000,
		            "script_stages_passed": [{
		                "sms": 500
		            }, {
		                "vkontakte": 200,
		                "facebook": 100,
		                "odnoklassniki": 4,
		                "instagram": 2,
		                "odnoklassniki": 44,
		                "instagram": 10
		            }, {
		                "button": 10
		            }]
		        },
		        "1123": {
		            "sessions_started": 10000,
		            "script_stages_passed": [{
		                "sms": 5000
		            }, {
		                "vkontakte": 1342,
		                "facebook": 989,
		                "odnoklassniki": 44,
		                "instagram": 10,
		                "odnoklassniki": 44,
		                "instagram": 10
		            }, {
		                "button": 197
		            }]
		        }
		    }
		}, {
		    "title": "sms + button",
		    "steps": [
		        ["sms"],
		        ["button"]
		    ],
		    "detalization": {
		        "1170": {
		            "sessions_started": 3000,
		            "script_stages_passed": [{
		                "sms": 500
		            }, {
		                "button": 10
		            }]
		        },
		        "1123": {
		            "sessions_started": 23000,
		            "script_stages_passed": [{
		                "sms": 5000
		            }, {
		                "button": 198
		            }]
		        }
		    }
		}],
		urlFunnel: {
			root: 'https://api-v2.getshopster.net/reports/hotspot/funnel?'
		},
		urlDataChart: {
			root: 'https://api-v2.getshopster.net/reports/hotspot/connections?',
			objects: 'objects=',
			from: '&from=',
			to: '&to=',
			interval: '&interval='
		},
		dataBarCharts: {
			1170: [ {"datetime": "2016-02-01T00:00:00", "sessions": 320, "successful_sessions": 122}, {"datetime": "2016-02-02T00:00:00", "sessions": 210, "successful_sessions": 82}],
			1171: [ {"datetime": "2016-02-01T00:00:00", "sessions": 320, "successful_sessions": 122}, {"datetime": "2016-02-02T00:00:00", "sessions": 210, "successful_sessions": 82}]
		},
		urlSms: {
			rootSms: 'https://api-v2.getshopster.net/reports/hotspot/sms?'
		},
		dataSms: {
			1170: [{'datetime': '2016-02-01T00:00:00', 'phone': '123'}, {'datetime': '2016-02-02T00:00:00', 'phone': '123'}],
			1123: [{'datetime': '2016-02-01T00:00:00', 'phone': '123'}, {'datetime': '2016-02-02T00:00:00', 'phone': '123'}]
		},
		urlPhone: {
			root: 'https://api-v2.getshopster.net/reports/hotspot/phones?'
		},
		dataUniqueNumber: {
			1170: ['79031084277', '79014317595'],
			1123: ['79045734277', '79035687595']
		},
		urlDataSocialTable: {
			root: 'https://api-v2.getshopster.net/reports/hotspot/social_profiles?'
		},
		socialData: {
			1170: [
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
			],
			1123: [
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
				{
					"avatar":"http://cs627524.vk.me/v627524338/149b0/O1d8z0mo8qk.jpg",
			         "birthday":"11.11.1992",
			         "email":null,
			         "name":"Алина Тамазова",
			         "gender":"female",
			         "profile_url":"http://vk.com/id135125338",
			         "provider":"vkontakte",
			         "provider_id":135125338,
			         "login_dates":[  
			            "2016-03-31T11:16:54.618",
			            "2016-04-05T10:15:29.457"
			         ],
			         "friends_count":115
				},
			]
		},
		urlChartsAnalitics: {
			root: 'https://api-v2.getshopster.net/reports/hotspot/social_auths?',
			interval: 'interval='
		}
	},
	// getRequest: function (id, url, success, error) {
	// 	var data;
	// 	$.ajax({
	// 		url: url,
	// 		data: data,
	// 		success: function(data) {
	// 			if (this.internalState.idMall == null || this.internalState.idMall == id) {
	// 				success(data);
	// 			}
	//         }.bind(this),
	//         error: function(xhr, status, err) {
	//             error(err);
	//             console.error(err);
	//         }.bind(this)
	// 	});
	// },
	getRequestHeaders: function (url, success, error) {
		console.log('ajax');
		// if (this.internalState.requestHeaders != null) {
		// 		this.internalState.requestHeaders.abort();
		// 		// this.internalState.requestHeaders = null;
		// 	}
		var data;
		this.internalState.requestHeaders = $.ajax({
			url: url,
			headers:  this.internalState.headers,
			data: data,
			success: function(data) {
				// console.log('api.js', data);
				success(data);
	        }.bind(this),
	        error: function(xhr, status, err) {
	            // error(err);
	            console.error(err);
	        }.bind(this)
		});
	},
	createFunnelAnalitics: function (state, success, error) {

		// this.internalState.headers = state.headers;
		this.internalState.headers['x-token'] = state.commonState.token;
		this.internalState.headers['x-username'] = state.commonState.username;
		var mollData = '';
		// console.log('api.js', state.panelState.dataMall);
		for (var i = 0; i < state.panelState.dataMall.length; i ++) {
			if (i < state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id + ',';
			}
			else if (i === state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id;
			}
			mollData = mollData + obj;
		}
		var url = this.internalState.urlFunnel.root
		+ this.internalState.urlDataChart.objects
		+ mollData
		+ this.internalState.urlDataChart.from
		+ state.panelState.minDate
		+ this.internalState.urlDataChart.to
		+ state.panelState.maxDate;
		// console.log('api.js', url);
		// console.log('api.js', state);
		if (state.panelState.dataMall.length !== 0) {
			this.getRequestHeaders(url, success, error);
		}
		// success(this.internalState.dataFunnel);
	},
	createChatAnalitics: function (state, success, error) {
		// this.internalState.headers = state.headers;
		this.internalState.headers['x-token'] = state.commonState.token;
		this.internalState.headers['x-username'] = state.commonState.username;
		var mollData = '';
		// console.log('api.js', state.panelState.dataMall.length, this.internalState.headers);
		for (var i = 0; i < state.panelState.dataMall.length; i ++) {
			if (i < state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id + ',';
			}
			else if (i === state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id;
			}
			mollData = mollData + obj;
		}
		var url = this.internalState.urlDataChart.root
		+ this.internalState.urlDataChart.objects
		+ mollData
		+ this.internalState.urlDataChart.from
		+ state.panelState.minDate
		+ this.internalState.urlDataChart.to
		+ state.panelState.maxDate
		+ this.internalState.urlDataChart.interval
		+ state.panelState.nameInterval;
		if (state.panelState.dataMall.length !== 0) {
			this.getRequestHeaders(url, success, error);
		}
		// success(this.internalState.dataBarCharts);
	},
	createSmsAnalitics: function (state, success, error) {
		// this.internalState.headers = state.headers;
		this.internalState.headers['x-token'] = state.commonState.token;
		this.internalState.headers['x-username'] = state.commonState.username;
		var mollData = '';
		for (var i = 0; i < state.panelState.dataMall.length; i ++) {
			if (i < state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id + ',';
			}
			else if (i === state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id;
			}
			mollData = mollData + obj;
		}
		var url = this.internalState.urlSms.rootSms
		+ this.internalState.urlDataChart.objects
		+ mollData
		+ this.internalState.urlDataChart.from
		+ state.panelState.minDate
		+ this.internalState.urlDataChart.to
		+ state.panelState.maxDate;
		// console.log('api.js', url);
		if (state.panelState.dataMall.length !== 0) {
			this.getRequestHeaders(url, success, error);
		}
		// success(this.internalState.dataSms);
		'https://api-v2.getshopster.net/reports/hotspot/sms?objects=208&from=2016-03-04&to=2016-04-04'
		'https://api-v2.getshopster.net/reports/hotspot/sms?objects=1421&from=2016-02-02&to=2016-04-07'
	},
	getUniqueNumber: function (state, success, error) {
		// this.internalState.headers = state.headers;
		this.internalState.headers['x-token'] = state.commonState.token;
		this.internalState.headers['x-username'] = state.commonState.username;
		var mollData = '';
		for (var i = 0; i < state.panelState.dataMall.length; i ++) {
			if (i < state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id + ',';
			}
			else if (i === state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id;
			}
			mollData = mollData + obj;
		}
		var url = this.internalState.urlPhone.root
		+ this.internalState.urlDataChart.objects
		+ mollData
		+ this.internalState.urlDataChart.from
		+ state.panelState.minDate
		+ this.internalState.urlDataChart.to
		+ state.panelState.maxDate;
		// console.log('api.js', url);
		if (state.panelState.dataMall.length !== 0) {
			this.getRequestHeaders(url, success, error);
		}
		'https://api-v2.getshopster.net/reports/hotspot/phones?objects=208&from=2016-03-05&to=2016-04-05'
		// success(this.internalState.dataUniqueNumber);
	},
	getSocialAnalitics: function (state, success, error) {

		this.internalState.headers['x-token'] = state.commonState.token;
		this.internalState.headers['x-username'] = state.commonState.username;
		var mollData = '';
		for (var i = 0; i < state.panelState.dataMall.length; i ++) {
			if (i < state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id + ',';
			}
			else if (i === state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id;
			}
			mollData = mollData + obj;
		}
		var url = this.internalState.urlDataSocialTable.root
		+ this.internalState.urlDataChart.objects
		+ mollData
		+ this.internalState.urlDataChart.from
		+ state.panelState.minDate
		+ this.internalState.urlDataChart.to
		+ state.panelState.maxDate;
		// console.log('api.js', url);
		if (state.panelState.dataMall.length !== 0) {
			this.getRequestHeaders(url, success, error);
		}
		// success(this.internalState.socialData);
	},
	getSocialChartsAnalitics: function (state, success, error) {
		// this.internalState.headers = state.headers;
		this.internalState.headers['x-token'] = state.commonState.token;
		this.internalState.headers['x-username'] = state.commonState.username;
		var mollData = '';
		// console.log('api.js', state.panelState.dataMall.length, this.internalState.headers);
		for (var i = 0; i < state.panelState.dataMall.length; i ++) {
			if (i < state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id + ',';
			}
			else if (i === state.panelState.dataMall.length - 1) {
				var obj = state.panelState.dataMall[i].id;
			}
			mollData = mollData + obj;
		}
		var url = this.internalState.urlChartsAnalitics.root
		+ this.internalState.urlChartsAnalitics.interval
		+ state.panelState.nameInterval
		+ '&'
		+ this.internalState.urlDataChart.objects
		+ mollData
		+ this.internalState.urlDataChart.from
		+ state.panelState.minDate
		+ this.internalState.urlDataChart.to
		+ state.panelState.maxDate;
		if (state.panelState.dataMall.length > 0) {
			this.getRequestHeaders(url, success, error);
		}
		'https://api-v2.getshopster.net/reports/hotspot/social_auths?interval=daily&objects=208&from=2016-03-02&to=2016-03-30'
		
		'https://api-v2.getshopster.net/reports/hotspot/social_auths?interval=daily&objects=208&from=2016-03-01&to=2016-04-01'
		// success(this.internalState.socialData);
	}
};

module.exports = Api;