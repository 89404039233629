var AppDispatcher = require('../mainDispatcher/Dispatcher.js');
var AppConstants = require('../constants/constants.js');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var MapActions = require('../modules/maps/js/actions/actions.js');

var state = {
	commonState: {},
	panelState: {},
	headers: {}
};

function setCommonState (action) {
	state.commonState.insetName = action.state.insetName;
	state.commonState.selectedObjects = action.state.selectedObjects[0];
	state.commonState.max_timestamp = new Date(action.state.max_timestamp);
	state.commonState.min_timestamp = new Date(action.state.min_timestamp);
	state.commonState.password = action.state.password;
	state.commonState.token = action.state.token;
	state.commonState.username = action.state.username;
};

function getStateControlPanel(action) {
	// console.log('Store.js', action.state);
};

var CHANGE_EVENT = 'change';

var AppStore = assign({}, EventEmitter.prototype, {
	moduleChangeStoreListeners: [],
	addChangeStoreModuleListener: function(callback) {
		if (this.moduleChangeStoreListeners.indexOf(callback) == -1) {
			this.moduleChangeStoreListeners.push(callback);
		}
	},
	removeChangeStoreModuleListener: function (callback) {
		if (this.moduleChangeStoreListeners.indexOf(callback) != -1) {
			this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
		}
	},
	emitChangeToModuleListeners: function () {
		for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
			this.moduleChangeStoreListeners[i](state);
		}
	},
	setStateControlPanel: function (data) {
		state.panelState.dataMall = data.dataMall;
		state.panelState.maxDate = data.maxDate;
		state.panelState.minDate = data.minDate;
		state.panelState.nameInterval = data.nameInterval;
		state.headers['x-token'] = data.mainState.token;
		state.headers['x-username'] = data.mainState.username;
		this.emitChangeToModuleListeners();
	},
	setCommonState: function (data) {
		if (data.max_timestamp !== undefined) {
			state.commonState.insetName = data.insetName;
			state.commonState.selectedObjects = data.selectedObjects[0];
			state.commonState.max_timestamp = new Date(data.max_timestamp);
			state.commonState.min_timestamp = new Date(data.min_timestamp);
			state.commonState.password = data.password;
			state.commonState.token = data.token;
			state.headers['x-token'] = data.token;
			state.headers['x-username'] = data.username;
			state.commonState.username = data.username;
		}
		this.emitChangeToModuleListeners();
	},
	getState: function() {
		return state;
	}
	// emitChange: function() {
	// 	this.emit(CHANGE_EVENT);
	// },
	// addChangeListener: function(callback) {
 //        this.on(CHANGE_EVENT, callback);
 //    },
 //    removeChangeListener: function(callback) {
 //        this.removeListener(CHANGE_EVENT, callback);
 //    },
 //    route: function(actionType) {
 //    	switch (actionType) {
 //    		case AppConstants.SET_COMMON_STATE:
 //    		return setCommonState;
 //    		case AppConstants.UPDATE_CONTROL_PANEL:
 //    		return getStateControlPanel;
 //    	}
 //    }
});

// AppStore.dispatcherIndex = AppDispatcher.register(function(payload){
// 	var func = AppStore.route(payload.action.actionType);
// 	if (func != null) {
// 		func(payload.action);
// 		AppStore.emitChange();
// 		AppStore.emitChangeToModuleListeners();
// 	};
// 	return true;
// });

module.exports = AppStore;