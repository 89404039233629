var AppDispatcher = require('../dispatcher/dispatcher.js');
var AppConstants = require('../constants/constants.js');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var MainStore = require('../../../../mainStores/Store.js');
var GeometryVSDataManager = require('../../../tools/GeometryVSDataManager');


var state = {
	mainState: {commonState: null},
	malls: null,
	mallFloors: null,
	mapId: null, // странная переменная, она вроде никогда не меняется. Это какая то константа? Она нужна?
	mapPreviewItem: [], // mapPreviewItems - множественное число, полагаю
	statusCreate: false,
	mapLink: null,
	mapLinks: null,
	area:[],
	monitor: [],
	transition: {},
	routerItems: [],
	mapRouter: [],
	mapSvg: [],
	indexMapFloor: 0,
	objRoutersName: {},
	floorIndex: 0,
	loadStatusStatisitcs: false,
	mallId: 0,
	zoneId: null,
	scale: [],
	routerObj: {},

	// >>> added by Alex
	selectedAreaIndex: null,
	// <<<
	statusAnalitics: 'maps',
	optionsSelect: [],
	optionsSelectExclusion: [],
	optionsSelected: [],
	optionsSelectedExclusion: [],
	statusCheck: false,
	statusCheckExclusion: false,
	statusStepsOptions: 'arbitrarily',
	filterObj: {
		fromAToB: true,
		fromA: false,
		toA: false,
		toAFrom: false,
		start: false,
		finish: false,
		mac: false,
		top: false
	},
	optionsFilterObjectsA: [],
	optionsFilterObjectsB: [],
	optionsFilterValueA: null,
	optionsFilterValueB: null,
	defaultOptions: [],
	statusTop: 5,
	numSteps: '1',
	runData: [],
	statusShowRouterBoard: true,
	routerData: [],
	routerGlobalData: [],
	statusTypeSelect: 'monitor',
	dataMonitor: [],
	dataArea: [],
	dataFloor: [],
	statusInvolvement: '30 second',
	macAdress: '',
	dataThreshold: [],
	dataSelect: 'any_part',
	statusNotData: false,
	statusPointFrom: false,
	statusPointTo: false,
	idSelectRouter: null,
	dataPlaceMaps: 'ca298da4-7765-4ea5-bc93-13192e2983d0',
	dataPlanSet: [],
	places: {},
	image:null,
	transitions:[],
	rooms: {},
	roomsTransition: {},
	statusLoadSelectPolygons: false,
	markers: [],
	floors: [],
	dataFloors: [],
	itemRetailName: null,
	dataUneqUsers: {},
	dataUneqUsersOfZones: {},
	objAnalitic: {},
	uuidPlanSet:null,
	optionTypeNewMaps: [
		{
			value: 'places',
			label: 'Помещения'
		}

	],
	zonesData: null,
	typeNewMaps: {
        value: 'places',
        label: 'Помещения'
	},
	zones:{},
	zonesObjData: {},
	statusColorCount: false,
	statusCheckBackground:false
};

 // MainStore.addChangeListener(AppStore.onChange);

function update(action) {
    // console.log('!!!!!!', action.state.panelState.dataMall);
    state.mainState.insetName = action.state.commonState.insetName;
    state.mainState.selectedObjects = action.state.commonState.selectedObjects;
    state.mainState.max_timestamp = action.state.commonState.max_timestamp;
    state.mainState.min_timestamp = action.state.commonState.min_timestamp;
    state.mainState.password = action.state.commonState.password;
    state.mainState.token = action.state.commonState.token;
    state.mainState.username = action.state.commonState.username;
    state.mainState.commonState = action.state.commonState.username;
    // console.log('????????', state.mainState.selectedObjects, action.state.commonState.selectedObjects);

    if (state.mainState.selectedObjects !== action.state.commonState.selectedObjects) {
        state.statusLoadSelectPolygons = false;
        state.optionTypeNewMaps = [
            {
                value: 'places',
                label: 'Помещения'
            }

        ];
        state.zonesData = null;
        state.zonesObjData = {}
        state.typeNewMaps = {
            value: 'places',
            label: 'Помещения'
        };
        state.zones = {};
        state.mapPreviewItem = [];
        state.transitions = {};
        state.roomsTransition = {}
	}
};



function loadMaps (action) {
	state.mallFloors = action.data.data;
	var internal = false;
	for (var j = 0; j < action.data.data.value.length; j++) {
        // console.log('loadload', action.data.data.value[j].floor, state.floorIndex);
		if (action.data.data.value[j].floor >= 0 && internal === false) {
            internal = true;
            state.floorIndex = j;
		}
	}
    internal = false;
	state.mapLink = action.data.links[state.floorIndex].link;
    // console.log('loadload', action.data);
	state.mapLinks = action.data.links;
	state.mapPreviewItem = [];
	state.statusCreate = action.status;

	for (var i = 0; i < action.data.links.length; i++) {
        // console.log('loadload', action.data.links[i].link);
		if (i == state.floorIndex) {
			var previewItem = {
				link: action.data.links[i].link,
				statusActive: true,
				index: action.data.links[i].index,
				idFloors: action.data.links[i].idLink,
                routers: []
			};
		}
		else {
			var previewItem = {
				link: action.data.links[i].link,
				statusActive: false,
				index: action.data.links[i].index,
				idFloors: action.data.links[i].idLink,
                routers:[]
			};
		}
		state.mapPreviewItem.push(previewItem);
	}

};

function getFloorId (action) {
	// if ()
    // console.log('!!!!!!', action.id);
    state.roomsTransition = {}
	state.mapId = action.id;
    state.idSelectRouter = null
	// for (var i = 0; i < state.mapLinks.length; i++) {
     //    console.log('!!!!!!', action.id, state.mapPreviewItem[i], i);
	// 	if (action.id == state.mapLinks[i].index) {
	// 		state.mapLink = state.mapLinks[i].link;
	// 		state.mapPreviewItem[i].statusActive = true;
	// 		state.indexMapFloor = action.id;
	// 		state.floorIndex = i;
	// 		if (state.mapSvg != null) {
	// 			selectedFloor();
	// 			// if (state.mapSvg.length > 0) {
	// 			// 	// selectedFloor();
	// 			// }
	// 		}
	// 		// >>> added by Alex
	// 		state.selectedAreaIndex = null;
	// 		// <<<
	// 	}
	// 	else {
	// 		state.mapPreviewItem[i].statusActive = false;
	// 	}
	// 	// console.log('!!!!!!', state.mapSvg);
	// }
    // console.log('!!!!!!', action.id, state.mapPreviewItem);
    var coords = [];
    var is_opened = [];
    var name = [];
    var retail = [];
    if(state.uuidPlanSet !== null) {
    	for (var j = 0; j < state.mapPreviewItem.length; j++) {
            // console.log('!!!!!!', action.id, state.mapPreviewItem[j]);
            state.indexMapFloor = action.id;
            if (action.id ===  state.mapPreviewItem[j].index) {
                state.floorIndex = j;
                state.mapPreviewItem[j].statusActive = true;
                // state.mapLink = state.mapPreviewItem[j].link
                state.selectedAreaIndex = null;

			}
			else {
                state.mapPreviewItem[j].statusActive = false;
			}
		}
        // console.log('!!!!!!', state.mapPreviewItem);
        for(var i = 0; i < state.dataPlanSet.plans[state.floorIndex].places.length; i++) {

            coords.push(state.dataPlanSet.plans[state.floorIndex].places[i].coords);
            is_opened.push(state.dataPlanSet.plans[state.floorIndex].places[i].is_opened);
            name.push(state.dataPlanSet.plans[state.floorIndex].places[i].name);
            retail.push(state.dataPlanSet.plans[state.floorIndex].places[i].retail);
        }
        state.image = state.dataPlanSet.plans[state.floorIndex].image;
        state.places = {
            coords: coords,
            is_opened: is_opened,
            name: name,
            retail: retail
        }
        state.zonesData = state.dataPlanSet.plans[state.floorIndex].zone_groups;
        state.optionTypeNewMaps = [];
        // console.log('getPlanSet',coords)

        if (coords.length > 0) {
            state.optionTypeNewMaps.push({
                value: 'places',
                label: 'Помещения'
            });
        }

        for (var k = 0; k < state.dataPlanSet.plans[state.floorIndex].zone_groups.length; k++) {
            // console.log('getPlanSet',action.data.plans[state.floorIndex].zone_groups[k])
            state.optionTypeNewMaps.push({
                value: state.dataPlanSet.plans[state.floorIndex].zone_groups[k].group_marker,
                label: state.dataPlanSet.plans[state.floorIndex].zone_groups[k].group_marker
            });
        }
        if (coords.length === 0) {
            state.typeNewMaps = state.optionTypeNewMaps[0];
        }
	}
	else {
        for (var i = 0; i < state.mapLinks.length; i++) {
            // console.log('!!!!!!', action.id, state.mapPreviewItem[i], i);


            if (action.id == state.mapLinks[i].index) {
                state.mapLink = state.mapLinks[i].link;
                state.mapPreviewItem[i].statusActive = true;
                state.mapPreviewItem[i]['statusClick'] = true;
                state.indexMapFloor = action.id;
                state.floorIndex = i;
                if (state.mapSvg != null) {
                    selectedFloor();
                    // if (state.mapSvg.length > 0) {
                    // 	// selectedFloor();
                    // }
                }
                // >>> added by Alex
                state.selectedAreaIndex = null;
                // <<<
            }
            else {
                state.mapPreviewItem[i].statusActive = false;
                state.mapPreviewItem[i]['statusClick'] = true;
            }
            // console.log('!!!!!!', state.mapSvg);
        }
	}

};

function getSelectRouter(action) {
    // console.log('>>>>>>>>',state.statusTypeSelect);
    if (state.statusTypeSelect === 'monitor') {
        state.idSelectRouter = action.data.id
        for (var i = 0; i < state.mapLinks.length; i++) {
            // console.log('!!!!!!', state.mapLinks[i]);
            if (action.data.floor == state.mapLinks[i].idLink) {
                state.mapId = state.mapLinks[i].index;
                state.mapLink = state.mapLinks[i].link;
                state.mapPreviewItem[i].statusActive = true;
                state.indexMapFloor = state.mapLinks[i].index;
                state.floorIndex = i;
                if (state.mapSvg != null) {
                    selectedFloor();
                    if (state.mapSvg.length > 0) {
                        // selectedFloor();
                    }
                }
                // >>> added by Alex
                state.selectedAreaIndex = null;
                // <<<
            }
            else {
                state.mapPreviewItem[i].statusActive = false;
            }
            // console.log('!!!!!!', state.mapSvg);
        }
	}
    // state.mapId = action.id;
}


function getRouterInfo (action) {
	state.routerItems = action.data;
	// state.optionsSelect = [];
	// state.optionsSelectExclusion = [];
	state.mapSvg = [];
	state.scale = [];
	// state.optionsFilterObjectsA = [];
	// state.optionsFilterObjectsB = [];
    // console.log('store', action.status);
	state.loadStatusStatisitcs = action.status;
	// for (var i = 0; i < action.data.length; i++) {
	// 	// console.log('store', action.data[i]);
	// 	if (action.data[i].area_ids !== null) {
	// 		for (var j = 0; j < action.data[i].area_ids.length; j++) {
	// 			var obj = {
	// 				value:action.data[i].intersections[action.data[i].area_ids[j]].name,
	// 				label:action.data[i].intersections[action.data[i].area_ids[j]].name,
	// 				id:action.data[i].area_ids[j]
	// 			};
	// 			state.optionsSelect.push(obj);
	// 			state.optionsSelectExclusion.push(obj);
	// 			state.optionsFilterObjectsA.push(obj);
	// 			state.optionsFilterObjectsB.push(obj);
	// 			state.defaultOptions.push(obj);
	// 		}
	// 	}
	// }
	// console.log('store', state.routerItems);
	createStatistics();
	createScale();
};

function selectedFloor () {
	state.mapSvg = state.mapPreviewItem[state.floorIndex].routers;
};

function createStatistics () {
    var arrRouters = [];
    var globalMaxCount;
    var maxCountFloors = [];
    if (state.routerItems != undefined) {
        for (var j = 0; j < state.routerItems.length; j++) {
            if (state.routerItems[j].intersections != null && state.routerItems[j].area_ids != null) {
                var id;
                var maxCount = state.routerItems[j].intersections[state.routerItems[j].area_ids[0]].count;
                for (var r = 0; r < state.routerItems[j].area_ids.length; r++) {
                    if (maxCount < state.routerItems[j].intersections[state.routerItems[j].area_ids[r]].count) {
                        maxCount = state.routerItems[j].intersections[state.routerItems[j].area_ids[r]].count;
                        id = state.routerItems[j].area_ids[r];
                    }
                }
                maxCountFloors.push(maxCount);
            }
            // console.log('store>>>>>', maxCount);
            maxCountFloors.push(maxCount);
        }
        // console.log('store', maxCountFloors)
        globalMaxCount = maxCountFloors[0];
        for (var h = 0; h < maxCountFloors.length; h++) {
            if (globalMaxCount < maxCountFloors[h]) {
                globalMaxCount = maxCountFloors[h];
            }
        }
        for (var j = 0; j < state.routerItems.length; j++) {
            if (state.routerItems[j].intersections != null && state.routerItems[j].area_ids != null) {
                
                state.mapRouter = [];
                for (var g = 0; g < state.routerItems[j].area_ids.length; g++) {
                    if (globalMaxCount > 0) {
                        state.routerObj[state.routerItems[j].area_ids[g]] = {
                                data: state.routerItems[j].intersections[state.routerItems[j].area_ids[g]],
                                floor: state.routerItems[j].floor
                            };
                        state.objRoutersName[state.routerItems[j].area_ids[g]] = state.routerItems[j].intersections[state.routerItems[j].area_ids[g]].name;
                        var persent = (state.routerItems[j].intersections[state.routerItems[j].area_ids[g]].count / globalMaxCount) * 0.1 * 100;
                        // console.log('mapSvg', state.routerItems[j].intersections)
                        var objRouter = {
                            persent: persent,
                            id: state.routerItems[j].area_ids[g],
                            point: state.routerItems[j].intersections[state.routerItems[j].area_ids[g]].point,
                            data: state.routerItems[j].intersections[state.routerItems[j].area_ids[g]],
                            routersFloor: state.routerItems[j],
                            selectStatus: false,
                            color: null
                        }
                        state.mapRouter.push(objRouter);
                    }
                    else if (globalMaxCount == 0) {
                        var objRouter = {
                            persent: 0,
                            id: state.routerItems[j].area_ids[g],
                            point: state.routerItems[j].intersections[state.routerItems[j].area_ids[g]].point,
                            data: state.routerItems[j].intersections[state.routerItems[j].area_ids[g]],
                            routersFloor: state.routerItems[j],
                            selectStatus: false,
                            color: null
                        }
                        state.mapRouter.push(objRouter);
                    }
                    // console.log('mapSvg', state.routerItems[j].intersections[state.routerItems[j].area_ids[g]])
                }
                if (state.mapPreviewItem[j] !== undefined) {
                    state.mapPreviewItem[j].routers = state.mapRouter;
				}
            }
            else if(state.routerItems[j].area_ids == null) {
                if (state.mapPreviewItem[j] !== undefined) {
                    state.mapPreviewItem[j].routers  = [];
				}
            }
        }
    }
	if (state.routerItems.length > 0) {
    	// console.log('mapSvg', state.mapPreviewItem[state.floorIndex].routers)
        state.mapSvg = state.mapPreviewItem[state.floorIndex].routers;
        state.statusNotData = false;
	}
	else if (state.routerItems.length === 0) {
        state.statusNotData = true;
	}
    for(var f = 0; f < state.mapPreviewItem.length; f++) {
        for (var d = 0; d < state.mapPreviewItem[f].routers.length; d++) {
            for (var s = 0; s < state.routerGlobalData.length; s++) {
            	if (state.routerGlobalData[s].id === state.mapPreviewItem[f].routers[d].id) {
                    state.mapPreviewItem[f].routers[d].data['ipoint_marker'] = state.routerGlobalData[s].ipoint_marker;
				}
                // console.log('loadloadd', state.routerGlobalData[s], state.mapPreviewItem[f].routers[d]);
            }
        }
    }
    // console.log('storerouter', state.mapRouter, state.mapSvg);
};

function createScale () {
	for (var i = 0; i < state.routerItems.length; i++) {
		state.scale.push(state.routerItems[i].scale);
	}
};

function get_heat_color(val) {
    var r = Math.min(Math.max(0, 1.5-Math.abs(1.0-4.0*(val-0.5))),1);
    var g = Math.min(Math.max(0, 1.5-Math.abs(1.0-4.0*(val-0.25))),1);
    var b = Math.min(Math.max(0, 1.5-Math.abs(1.0-4.0*val)),1);
    return 'rgba('+parseInt(r*255)+','+parseInt(g*255)+','+parseInt(b*255)+',0.5)';
}

function getSelectZoneStatus (action) {

	if (state.selectedAreaIndex == action.data.indexZone) {
		state.selectedAreaIndex = null;
		state.zoneId = null;
	} else {
		state.selectedAreaIndex = action.data.indexZone;
		state.zoneId = action.data.zoneId;
	}
};

function getSelectRouterInRouter(action) {
    if (state.selectedAreaIndex == action.data.indexZone) {
        state.selectedAreaIndex = null;
        state.zoneId = null;
    } else {
        state.selectedAreaIndex = action.data.indexZone;
        state.zoneId = action.data.zoneId;
    }
}

function getSelectMall (action) {
	// console.log("storestore",action.id);
	if (state.mallId != action.id) {
		state.statusCreate = false;
		state.mapLink= null;
		state.mapLinks = null;
		state.area = [];
		state.monitor = [];
		state.transition = {};
		state.routerItems = [];
		state.mapRouter = [];
		state.mapSvg = [];
		state.indexMapFloor = 0;
		state.objRoutersName = {};
		state.floorIndex = 0;
		state.mapPreviewItem = [];
		state.loadStatusStatisitcs = false;
		state.mallId = null;
        state.idSelectRouter = null;

		// >>> added by Alex
		state.selectedAreaIndex = null;
		state.mallId = action.id;
	}
};

function deactivateZone () {
	state.selectedAreaIndex = null;
	state.zoneId = null;
};

function setStatusLoad() {
	state.loadStatusStatisitcs = false;
    state.statusNotData = false;
};

function setAnaliticsMethod(action) {
	state.statusAnalitics = action.status;
    state.selectedAreaIndex = null;
    state.zoneId = null;
    state.transitions = {};
    state.roomsTransition = {};
};

function selectedOptions(action) {
	state.optionsSelected = [];
	// console.log('store', action.data);
	state.optionsSelected = action.data;
	var data = JSON.stringify(state.defaultOptions);
	state.optionsSelectExclusion = JSON.parse(data);
	for (var i = 0; i < action.data.length; i++) {
		for (var j = 0; j < state.optionsSelectExclusion.length; j++) {
			if (action.data[i].id === state.optionsSelectExclusion[j].id) {
				state.optionsSelectExclusion.splice(j, 1);
			}
			// console.log('store', action.data[i], state.optionsSelectExclusion[j]);
		}
	}
};

function selectedOptionsExclusion(action) {
	state.optionsSelectedExclusion = [];
	state.optionsSelectedExclusion = action.data
	var data = JSON.stringify(state.defaultOptions);
	state.optionsSelect = JSON.parse(data);
	// console.log('store', action.data, state.optionsSelected);
	for (var i = 0; i < action.data.length; i++) {
		for (var j = 0; j < state.optionsSelect.length; j++) {
			if (action.data[i].id === state.optionsSelect[j].id) {
				// console.log('store', action.data[i], state.optionsSelectExclusion[j]);
				state.optionsSelect.splice(j, 1);
			}
			// console.log('store', action.data[i], state.optionsSelectExclusion[j]);
		}
	}
}

function onCheckOptions(action) {
	if (action.data.elem === 'check') {
		state.statusCheck = action.data.check;
		if (action.data.check === false) {
			state.optionsSelected = [];
		}
	}
	else if (action.data.elem === 'checkExclusion') {
		state.statusCheckExclusion = action.data.check;
		if (action.data.check === false) {
			state.optionsSelectedExclusion = [];
		}
	}
};

function setStatusFilter(action) {
	if (action.status === 'fromAToB' && state.filterObj.fromAToB == false) {
		state.filterObj = {
			fromAToB: true,
			fromA: false,
			toA: false,
			toAFrom: false,
			start: false,
			finish: false,
			mac: false,
			top: false
		};
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
		state.dataSelect = 'any_part';
	}
	else if (action.status === 'fromA' && state.filterObj.fromA === false) {
		state.filterObj = {
			fromAToB: false,
			fromA: true,
			toA: false,
			toAFrom: false,
			start: false,
			finish: false,
			mac: false,
			top: false
		};
		state.statusStepsOptions = 'exactly';
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
		state.dataSelect = 'any_part';
	}
	else if (action.status === 'toA' && state.filterObj.toA === false) {
		state.filterObj = {
			fromAToB: false,
			fromA: false,
			toA: true,
			toAFrom: false,
			start: false,
			finish: false,
			mac: false,
			top: false
		};
		state.statusStepsOptions = 'exactly';
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
		state.dataSelect = 'any_part';
	}
	else if (action.status === 'toAFrom' && state.filterObj.toAFrom === false) {
		state.filterObj = {
			fromAToB: false,
			fromA: false,
			toA: false,
			toAFrom: true,
			start: false,
			finish: false,
			mac: false,
			top: false
		};
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
		state.dataSelect = 'any_part';
	}
	else if (action.status === 'start' && state.filterObj.start === false) {
		state.filterObj = {
			fromAToB: false,
			fromA: false,
			toA: false,
			toAFrom: false,
			start: true,
			finish: false,
			mac: false,
			top: false
		};
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
		state.dataSelect = 'any_part';
	}
	else if (action.status === 'finish' && state.filterObj.finish === false) {
		state.filterObj = {
			fromAToB: false,
			fromA: false,
			toA: false,
			toAFrom: false,
			start: false,
			finish: true,
			mac: false,
			top: false
		};
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
		state.dataSelect = 'any_part';
	}
	else if (action.status === 'mac' && state.filterObj.mac === false) {
		state.filterObj = {
			fromAToB: false,
			fromA: false,
			toA: false,
			toAFrom: false,
			start: false,
			finish: false,
			mac: true,
			top: false
		};
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
		state.dataSelect = 'any_part';
	}
	else if (action.status === 'top' && state.filterObj.top === false) {
		state.filterObj = {
			fromAToB: false,
			fromA: false,
			toA: false,
			toAFrom: false,
			start: false,
			finish: false,
			mac: false,
			top: true
		};
		state.statusStepsOptions = 'exactly';
		var data = JSON.stringify(state.defaultOptions);
		state.optionsFilterObjectsA = JSON.parse(data);
		state.optionsFilterObjectsB = JSON.parse(data);
		state.optionsFilterValueB = null;
	}
};

function setStepsOptions(action) {
	state.statusStepsOptions = action.status;
};

function setFilterSelect(action) {
	state.optionsFilterValueA = action.data;
	var data = JSON.stringify(state.defaultOptions);
	state.optionsFilterObjectsB = JSON.parse(data);
	for (var i = 0; i < state.optionsFilterObjectsB.length; i++) {
		if (action.data !== null) {
			if (action.data.id === state.optionsFilterObjectsB[i].id) {
				state.optionsFilterObjectsB.splice(i, 1);
			}
		}
	}
	// console.log('store', state.optionsFilterObjectsB.length, state.defaultOptions.length, state.optionsFilterObjectsA.length);
};

function setFilterSelectB(action) {
	state.optionsFilterValueB = action.data;
	var data = JSON.stringify(state.defaultOptions);
	state.optionsFilterObjectsA = JSON.parse(data);
	for (var i = 0; i < state.optionsFilterObjectsA.length; i++) {
		if (action.data !== null) {
			if (action.data.id === state.optionsFilterObjectsA[i].id) {
				state.optionsFilterObjectsA.splice(i, 1);
			}
		}
	}
};

function getTop(action) {
	state.statusTop = action.status;
};

function getNumSteps(action) {
	// console.log('store', action.status)
	state.numSteps = action.status;
};

function setRequestRoute(action) {
	state.runData = [];
	// console.log('store>>>>>>>>', action.data);
	if (action.data !== null) {
		for (var i = 0; i < action.data.length; i++) {
		var max = action.data[i][0].value;
		// console.log('store>>>>>>', action.data[i][0].value);
		for (var j = 0; j < action.data[i].length; j++) {
			if (action.data[i][j].value > max) {
				max = action.data[i][j].value;
			}
			// console.log('store', max, action.data[i][j].value);
			// console.log('store', action.data[i][j]);
		}
		// console.log('store', max);
		var obj, arr = [];
		for (var g = 0; g < action.data[i].length; g++) {
			obj = {
				persent: +(action.data[i][g].value / max).toFixed(2),
				floor: action.data[i][g].floor,
				id: action.data[i][g].id,
				name: action.data[i][g].name,
				value: action.data[i][g].value
			};
			arr.push(obj);
			// console.log('store', +(action.data[i][g].value / max).toFixed(2));
		}
		state.runData.push(arr);
	}
	state.runData = state.runData.sort(function (a, b) {
		// console.log('storesort', a, b);
		var minA = a[0].value;
		var minB = b[0].value;
		for (var i = 0; i < a.length; i++) {
			if (a[i].value < minA) {
				minA = a[i].value;
			}
		}
		for (var j = 0; j < b.length; j++) {
			if (b[j].value < minB) {
				minB = b[j].value;
			}
		}
		  if (minA < minB) {
		    return 1;
		  }
		  if (minA > minB) {
		    return -1;
		  }
		  return 0;
		});
	}
	else if (action.data === null) {
		state.runData = [];
	}
	state.statusShowRouterBoard = true;
	// console.log('store', state.runData);
};

function getArea(action) {

	// console.log('store', action.data);
	// state.routerData = action.data;
	function compare(a, b) {
	  if (a.name > b.name) {
		    return 1;
		  }
		  if (a.name < b.name) {
		    return -1;
		  }
		  // a must be equal to b
		  return 0;
	}
    // console.log('loadloadd', action.data);
	if (action.data !== null) {
        state.routerGlobalData = action.data.sort(compare);
        // action.data = action.data.sort(function (a, b) {
        // 	// console.log('storesort', a, b);
        // 	  if (a.Floor < b.Floor) {
        // 	    return -1;
        // 	  }
        // 	  if (a.Floor > b.Floor) {
        // 	    return 1;
        // 	  }
        // 	  return 0;
        // 	});
        // console.log('loadloadd', action.data);
        state.optionsSelect = [];
        state.optionsSelectExclusion = [];
        state.optionsFilterObjectsA = [];
        state.optionsFilterObjectsB = [];
        state.dataMonitor = [];
        state.dataArea = [];
        state.dataFloor = [];
        state.routerData = [];
        for (var i = 0; i < action.data.length; i++) {
            // console.log('store', action.data[i].type);
            if (action.data[i].type === 'floor') {

                state.dataFloor.push(action.data[i]);
            }
            else if (action.data[i].type === 'raw_zone') {
                state.dataArea.push(action.data[i]);
            }
            else if (action.data[i].type === 'monitor') {
                state.dataMonitor.push(action.data[i]);
            }
            // console.log('store', action.data[i].type);
        }
        state.dataFloor = state.dataFloor.sort(function (a, b) {
            if (+a.name > +b.name) {
                return 1;
            }
            if (+a.name < +b.name) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        state.defaultOptions = state.defaultOptions.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        state.dataArea = state.dataArea.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        state.dataMonitor = state.dataMonitor.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        if (state.statusTypeSelect === 'floor') {
            for (var i = 0; i < state.dataFloor.length; i++) {
                var obj = {
                    value:state.dataFloor[i].name,
                    label:state.dataFloor[i].name,
                    id:state.dataFloor[i].id
                };
                state.optionsSelect.push(obj);
                state.optionsSelectExclusion.push(obj);
                state.optionsFilterObjectsA.push(obj);
                state.optionsFilterObjectsB.push(obj);
                state.defaultOptions.push(obj);
                state.routerData.push(state.dataFloor[i]);
                // console.log('store', action.data[i].type);
            }
        }
        else if (state.statusTypeSelect === 'raw_zone') {
            for (var i = 0; i < state.dataArea.length; i++) {
                var obj = {
                    value:state.dataArea[i].name,
                    label:state.dataArea[i].name,
                    id:state.dataArea[i].id
                };
                state.optionsSelect.push(obj);
                state.optionsSelectExclusion.push(obj);
                state.optionsFilterObjectsA.push(obj);
                state.optionsFilterObjectsB.push(obj);
                state.defaultOptions.push(obj);
                state.routerData.push(state.dataArea[i]);
                // console.log('store', action.data[i].type);
            }
        }
        else if (state.statusTypeSelect === 'monitor') {
            for (var i = 0; i < state.dataMonitor.length; i++) {
                var obj = {
                    value:state.dataMonitor[i].name,
                    label:state.dataMonitor[i].name,
                    id:state.dataMonitor[i].id
                };
                state.optionsSelect.push(obj);
                state.optionsSelectExclusion.push(obj);
                state.optionsFilterObjectsA.push(obj);
                state.optionsFilterObjectsB.push(obj);
                state.defaultOptions.push(obj);
                state.routerData.push(state.dataMonitor[i]);
                // console.log('store', action.data[i].type);
            }
        }
	}


	// console.log('store', state.dataMonitor);
};

function getLoadStatus() {
	state.statusShowRouterBoard = false;
};

function setTypeSelect(action) {
	state.statusTypeSelect = action.data;
	state.optionsSelect = [];
	state.optionsSelectExclusion = [];
	state.optionsFilterObjectsA = [];
	state.optionsFilterObjectsB = [];
	state.defaultOptions = [];
	state.routerData = [];
	if (state.statusTypeSelect === 'floor') {
		for (var i = 0; i < state.dataFloor.length; i++) {
			var obj = {
				value:state.dataFloor[i].name,
				label:state.dataFloor[i].name,
				id:state.dataFloor[i].id
			};
			state.optionsSelect.push(obj);
			state.optionsSelectExclusion.push(obj);
			state.optionsFilterObjectsA.push(obj);
			state.optionsFilterObjectsB.push(obj);
			state.defaultOptions.push(obj);
			state.routerData.push(state.dataFloor[i]);
			// console.log('store', action.data[i].type);
		}
		state.dataFloor = state.dataFloor.sort(function (a, b) {
		  if (+a.name > +b.name) {
		    return 1;
		  }
		  if (+a.name < +b.name) {
		    return -1;
		  }
		  // a must be equal to b
		  return 0;
		});
		state.optionsFilterValueA = null;
		state.optionsFilterValueB = null;
		state.optionsSelected = [];
		state.optionsSelectedExclusion = [];
		state.statusCheck = false;
		state.statusCheckExclusion = false;
	}
	else if (state.statusTypeSelect === 'raw_zone') {
		for (var i = 0; i < state.dataArea.length; i++) {
			var obj = {
				value:state.dataArea[i].name,
				label:state.dataArea[i].name,
				id:state.dataArea[i].id
			};
			state.optionsSelect.push(obj);
			state.optionsSelectExclusion.push(obj);
			state.optionsFilterObjectsA.push(obj);
			state.optionsFilterObjectsB.push(obj);
			state.defaultOptions.push(obj);
			state.routerData.push(state.dataArea[i]);
			// console.log('store', action.data[i].type);
		}
		state.dataArea = state.dataArea.sort(function (a, b) {
		  if (a.name > b.name) {
		    return 1;
		  }
		  if (a.name < b.name) {
		    return -1;
		  }
		  // a must be equal to b
		  return 0;
		});
		state.optionsFilterValueA = null;
		state.optionsFilterValueB = null;
		state.optionsSelected = [];
		state.optionsSelectedExclusion = [];
		state.statusCheck = false;
		state.statusCheckExclusion = false;
		// console.log('store', state.defaultOptions.length);
	}
	else if (state.statusTypeSelect === 'monitor') {
		for (var i = 0; i < state.dataMonitor.length; i++) {
			var obj = {
				value:state.dataMonitor[i].name,
				label:state.dataMonitor[i].name,
				id:state.dataMonitor[i].id
			};
			state.optionsSelect.push(obj);
			state.optionsSelectExclusion.push(obj);
			state.optionsFilterObjectsA.push(obj);
			state.optionsFilterObjectsB.push(obj);
			state.defaultOptions.push(obj);
			state.routerData.push(state.dataMonitor[i]);
			// console.log('store', action.data[i].type);
		}
		state.dataMonitor = state.dataMonitor.sort(function (a, b) {
		  if (a.name > b.name) {
		    return 1;
		  }
		  if (a.name < b.name) {
		    return -1;
		  }
		  // a must be equal to b
		  return 0;
		});
		state.optionsFilterValueA = null;
		state.optionsFilterValueB = null;
		state.optionsSelected = [];
		state.optionsSelectedExclusion = [];
		state.statusCheck = false;
		state.statusCheckExclusion = false;				
	}
};

function clearDataRoute() {
	state.optionsSelect = [];
	state.optionsSelectExclusion = [];
	state.optionsFilterObjectsA = [];
	state.optionsFilterObjectsB = [];
	state.defaultOptions = [];
	state.routerData = [];
	state.optionsSelect = [];
	state.dataMonitor = [];
	state.dataArea = [];
	state.dataFloor = [];
	state.routerData = [];
	state.routerGlobalData = [];
	state.optionsFilterValueA = null;
	state.optionsFilterValueB = null;
	state.optionsSelected = [];
	state.optionsSelectedExclusion = [];
	state.statusCheck = false;
	state.statusCheckExclusion = false;
	// console.log('!@!@!@!@!@');
};

function setStatusInvolvement(action) {
	state.statusInvolvement = action.data;
	// console.log('store', state.statusInvolvement);
};

function setValueMac(action) {
	state.macAdress = action.data;
};

function clearThershold() {
	state.dataThreshold = [];
};

function getDataThershold(action) {
	state.dataThreshold = action.data;
	// console.log('store>>>', state.dataThreshold);
};

function setSelectTopDetail(action) {
	state.dataSelect = action.status;
};

function getStatusPoint(action) {
	if (action.status === 'from') {
        state.statusPointFrom = !state.statusPointFrom;
        state.statusPointTo = false;
	}
	else if (action.status === 'to') {
        state.statusPointTo = !state.statusPointTo;
        state.statusPointFrom = false;
	}
}

function getRouterData(action) {
    // console.log('!!!!!>>>>>>>>>>>');
    // state.optionsFilterObjectsA = [];
    // state.optionsFilterObjectsB = [];
    if (state.statusPointFrom === true || state.statusPointTo === true) {
        state.selectedAreaIndex = action.data.indexZone;
        state.zoneId = action.data.zoneId;
        if (state.statusPointFrom === true) {
            for (var i = 0; i < state.optionsFilterObjectsB.length; i++) {
                if (action.data !== null) {
                    if (action.data.zoneId === state.optionsFilterObjectsB[i].id) {
                        state.optionsFilterValueA = state.optionsFilterObjectsB[i];
                        state.optionsFilterObjectsB.splice(i, 1);
                    }
                }
            }
            state.statusPointFrom = false;
        }
        else if (state.statusPointTo === true) {
            for (var i = 0; i < state.optionsFilterObjectsA.length; i++) {
                if (action.data !== null) {
                    if (action.data.zoneId === state.optionsFilterObjectsA[i].id) {
                        state.optionsFilterValueB = state.optionsFilterObjectsA[i];
                        state.optionsFilterObjectsA.splice(i, 1);
                    }
                }
            }
            state.statusPointTo = false;
        }
    }
}

function getPlanSet(action) {
	state.dataPlanSet = action.data;
    var coords = [];
    var is_opened = [];
    var name = [];
    var retail = [];
    state.markers = []
	state.floors = []
	// console.log('getPlanSet1',action.data.plans);
    var tempStatus = false;
    state.mapPreviewItem = [];
    var obj3 = {};
    for (var g = 0; g < action.data.plans.length; g++) {
        if (action.data.plans[g].places.length > 0 || action.data.plans[g].zone_groups.length > 0) {
            // console.log('getPlanSet1',action.data.plans);

            if (tempStatus === false) {
                obj3 = {
                    idFloors: action.data.plans[g].floor,
                    index: g,
                    link: action.data.plans[g].image,
                    statusActive: true,
                    statusClick: true,
                    routers:[]
				}
                state.floorIndex = g;
                // state.mapPreviewItem[g].statusActive = true;
                // state.mapPreviewItem[g]['statusClick'] = true;
                tempStatus = true
            }
            else {
                obj3 = {
                    idFloors: action.data.plans[g].floor,
                    index: g,
                    link: action.data.plans[g].image,
                    statusActive: false,
                    statusClick: true,
                    routers:[]
                }
                // state.mapPreviewItem[g].statusActive = false;
                // state.mapPreviewItem[g]['statusClick'] = true;
            }
        }
        else {
            // state.mapPreviewItem[g].statusActive = false;
            // state.mapPreviewItem[g]['statusClick'] = false;
            obj3 = {
                idFloors: action.data.plans[g].floor,
                index: g,
                link: action.data.plans[g].image,
                statusActive: false,
                statusClick: false,
                routers:[]
            }
        }
        state.mapPreviewItem.push(obj3);
    }
    // console.log('getPlanSet2', state.mapPreviewItem);
	for(var i = 0; i < action.data.plans[state.floorIndex].places.length; i++) {

		coords.push(action.data.plans[state.floorIndex].places[i].coords);
		is_opened.push(action.data.plans[state.floorIndex].places[i].is_opened);
		name.push(action.data.plans[state.floorIndex].places[i].name);
		retail.push(action.data.plans[state.floorIndex].places[i].retail);
	}
	for (var f = 0; f < action.data.plans.length; f++) {
    	for (var d = 0; d < action.data.plans[f].zone_groups.length; d++) {
            // console.log('getPlanSet',state.zonesObjData, action.data.plans[f].zone_groups[d]);
            if (state.zonesObjData[action.data.plans[f].zone_groups[d].group_marker] === undefined) {
                state.zonesObjData[action.data.plans[f].zone_groups[d].group_marker] = []
			}
            state.zonesObjData[action.data.plans[f].zone_groups[d].group_marker] = state.zonesObjData[action.data.plans[f].zone_groups[d].group_marker].concat(action.data.plans[f].zone_groups[d].zones)
		}
	}
    // console.log('getPlanSet',state.zonesObjData);


	state.zonesData = action.data.plans[state.floorIndex].zone_groups;
	state.optionTypeNewMaps = [];
    // console.log('getPlanSet',coords)
	if (coords.length > 0) {
        state.optionTypeNewMaps.push({
            value: 'places',
            label: 'Помещения'
        });
	}

    for (var k = 0; k < action.data.plans[state.floorIndex].zone_groups.length; k++) {
        // console.log('getPlanSet',action.data.plans[state.floorIndex].zone_groups[k])
        state.optionTypeNewMaps.push({
            value: action.data.plans[state.floorIndex].zone_groups[k].group_marker,
            label: action.data.plans[state.floorIndex].zone_groups[k].group_marker
        });
	}
    if (coords.length === 0) {
        state.typeNewMaps = state.optionTypeNewMaps[0];
    }


	for(var j = 0; j < action.data.plans.length; j++) {
        state.floors.push(action.data.plans[j].floor)
		for(var g = 0; g < action.data.plans[j].places.length; g++) {
            state.markers.push(action.data.plans[j].places[g].name)
		}
	}
	state.image = action.data.plans[state.floorIndex].image;
    state.places = {
        coords: coords,
        is_opened: is_opened,
        name: name,
        retail: retail
	}
    if (state.objAnalitic.load !== undefined) {
        state.statusLoadSelectPolygons === false;
    }
    else {
        state.objAnalitic['load'] = ''
    }
    // console.log('getPlanSet2', state.places);

    // console.log('12getPlanSet',action.data)
}

function setDataSelectPolygon(actions) {
    state.transitions = {};
    // console.log('storesetDataSelectPolygonsetTimeout', actions.data)
    if (actions.data !== null) {
        for (var i = 0; i < actions.data.length; i++) {
            state.transitions[actions.data[i].second_obj_id] = actions.data[i];
        }
        // state.transitions = actions.data
        state.roomsTransition = {}
        // console.log('storesetDataSelectPolygonsetTimeout', state.zones[GeometryVSDataManager.reportIdWithZoneMarker(actions.item)].zone_marker)
        // GeometryVSDataManager.reportIdWithZoneMarker(actions.item);
        // console.log('storesetDataSelectPolygonsetTimeout>>>>>>>>', state.roomsTransition)
        for (var key in state.rooms) {
            // console.log('storesetDataSelectPolygonsetTimeout>>>>>', state.transitions, state.rooms[key])
            if (state.transitions[state.rooms[key].id] !== undefined) {
                state.roomsTransition[key] = state.transitions[state.rooms[key].id];
            }
        }

        for (var keyy in state.zones) {
            // console.log('storesetDataSelectPolygonsetTimeout>>>>>>>>', state.zones[keyy])
            if (state.transitions[state.zones[keyy].id] !== undefined) {
                state.roomsTransition[keyy] = state.transitions[state.zones[keyy].id];
            }
        }
        if (state.dataFloors !== null) {
            for (var keyy in state.dataFloors.obj) {
                if (state.transitions[state.dataFloors.obj[keyy]] !== undefined) {
                    // console.log('storesetDataSelectPolygonsetTimeout', state.transitions[state.dataFloors.obj[keyy]])
                    state.roomsTransition[keyy] = state.transitions[state.dataFloors.obj[keyy]];
                }
            }
        }

        if (state.typeNewMaps.value === 'places') {
            state.itemRetailName = state.rooms[actions.item].retail_name
        }
        else {
            state.itemRetailName = state.zones[GeometryVSDataManager.reportIdWithZoneMarker(actions.item)].name
        }
        // console.log('storesetDataSelectPolygonsetTimeout>>>>>>>>', state.roomsTransition,  state.transitions)
    }
    else {
        state.roomsTransition = {}
        state.itemRetailName = null;
    }
    setTimeout(function (){
        // console.log('storesetDataSelectPolygonsetTimeout')
        selectPolygons(false)
		AppStore.emitChange()
    }, 100)
    // console.log('storesetDataSelectPolygonsetTimeout', actions.item, state.rooms[actions.item], state.rooms)
    // console.log('storesetDataSelectPolygonsetTimeout', state.transitions, state.roomsTransition)
    // console.log('storesetDataSelectPolygon', state.roomsTransition)
}

function setRooms(actions) {
	// console.log('store', actions.data)
	state.rooms = actions.data;
}

function setFloor(actions) {
	state.dataFloors = actions.data;
	var obj = {};
	var arr = [];
	var obj2 = {};
	var arr2 = [];
    for (var i = 0; i < state.dataPlanSet.plans.length; i++) {
        // console.log('store>>>', GeometryVSDataManager.reportObjWithFloor(state.dataPlanSet.plans[i].floor))
        obj = {
        	floor: state.dataPlanSet.plans[i].floor,
			id: GeometryVSDataManager.reportObjWithFloor(state.dataPlanSet.plans[i].floor)
		}
		var status = false;
        if (i === 0) {
            status = true;
		}
        obj2 = {
            idFloors: state.dataPlanSet.plans[i].floor,
            index: i,
            link: state.dataPlanSet.plans[i].image,
        	statusActive: status,
            routers:[]
		}
        arr.push(obj);
        arr2.push(obj2)
    }
    state.mallFloors = {
		status:'ok',
		value: arr
	}


    state.mapPreviewItem = arr2;
    var tempStatus = false;
    for (var g = 0; g < state.dataPlanSet.plans.length; g++) {
        if (state.dataPlanSet.plans[g].places.length > 0 || state.dataPlanSet.plans[g].zone_groups.length > 0) {
            if (tempStatus === false) {
                state.floorIndex = g;
                state.mapPreviewItem[g].statusActive = true;
                state.mapPreviewItem[g]['statusClick'] = true;
                tempStatus = true
            }
            else {
                state.mapPreviewItem[g].statusActive = false;
                state.mapPreviewItem[g]['statusClick'] = true;
            }
        }
        else {
            state.mapPreviewItem[g].statusActive = false;
            state.mapPreviewItem[g]['statusClick'] = false;
        }
    }
    // console.log('getPlanSet2', state.mapPreviewItem);


    // console.log('store', state.dataFloors, state.mallFloors, state.dataPlanSet, state.mapPreviewItem)
}

function selectPolygons(actions) {
	state.statusLoadSelectPolygons = actions.status
}

function setUnekUsers(actions) {
    state.dataUneqUsers = actions.data;
    // console.log('setUnekUsers', action.data);
	if (state.objAnalitic.load !== undefined) {
        state.statusLoadSelectPolygons === false;
	}
	else {
        state.objAnalitic['load'] = ''
	}
    // console.log('setUnekUsers', state.dataUneqUsers)
}

function setUnekUsersOfZones(action) {
	state.dataUneqUsersOfZones = action.data;
    // console.log('setUnekUsersOfZones', action.data);
    if (state.objAnalitic.load !== undefined) {
        state.statusLoadSelectPolygons === false;
    }
    else {
        state.objAnalitic['load'] = ''
    }

}

function onChangeTypeNewMaps(action) {
    // console.log('onChangeTypeNewMaps', state.zonesData, action.data);
    state.transitions = {};
    state.roomsTransition = {}
	if (action.data === 'places') {
        state.typeNewMaps = {
            value: 'places',
            label: 'Помещения'
        };
	}
	else {
        state.typeNewMaps = {
            value: action.data,
            label: action.data
        };
    }
}

function setZones(action) {
    // console.log('store', action.data)
    state.statusNotData = false;
    // console.log('store2', action.data);
    state.zones = action.data;
}

function getLoadAnalitic() {
	state.objAnalitic = {}
    state.statusLoadSelectPolygons === true;
}

function getUuidPlanSet(action) {
	state.uuidPlanSet = action.data.plan_set_uuid;
}

function getStatusShowColotCount(action) {
	state.statusColorCount = action.status;
    state.transitions = {};
    state.roomsTransition = {}
}

function onCheckBacground(action) {
	state.statusCheckBackground = action.status;
}

var CHANGE_EVENT = 'change';

var AppStore = assign({}, EventEmitter.prototype, {
	getState: function() {
		return state;
	},
	emitChange: function() {
		this.emit(CHANGE_EVENT);
	},
	addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },
    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },
    route: function(actionType) {
    	switch (actionType) {
    		case AppConstants.UPDATE_MAIN_STATE:
			return update;
    		case AppConstants.LOAD_MALL_ERROR:
    		return loadErr;
    		case AppConstants.SET_MAPS:
    		return loadMaps;
    		case AppConstants.GET_FLOOR_ID:
    		return getFloorId;
    		case AppConstants.SET_TRNSITION:
    		return getRouterInfo;
    		case AppConstants.CREATE_STATISTICS:
    		return createStatistics;
    		case AppConstants.SET_SELECT_ZONE:
    		return getSelectZoneStatus;
    		case AppConstants.SELECTED_NEW_MALL:
    		return getSelectMall;
    		case AppConstants.DEACTIVE_SELECT_ZONE:
    		return deactivateZone;
    		case AppConstants.GET_STATUS_LOAD_TRANSITION:
    		return setStatusLoad;
    		case AppConstants.SHOW_ANALITICSmETHOD:
    		return setAnaliticsMethod;
    		case AppConstants.SELECTED_OPTIONS:
    		return selectedOptions;
    		case AppConstants.SELECTED_OPTIONS_EXCLUSION:
    		return selectedOptionsExclusion;
    		case AppConstants.ONCHECK_OPTIONS:
    		return onCheckOptions;
    		case AppConstants.GET_STEPS_OPTIONS:
    		return setStepsOptions;
    		case AppConstants.GET_STATUS_FILTER:
    		return setStatusFilter;
    		case AppConstants.GET_FILTER_SELECT:
    		return setFilterSelect;
    		case AppConstants.GET_FILTER_SELECT_B:
    		return setFilterSelectB;
    		case AppConstants.GET_TOP:
    		return getTop;
    		case AppConstants.GET_NUM_STEPS:
    		return getNumSteps;
    		case AppConstants.SET_REQUEST_ROUTE:
    		return setRequestRoute;
    		case AppConstants.GET_LOAD_STATUS:
    		return getLoadStatus;
    		case AppConstants.SET_AREA:
    		return getArea;
    		case AppConstants.SET_TYPE_SELECT:
    		return setTypeSelect;
    		case AppConstants.SET_STATUS_INVOLVEMENT:
    		return setStatusInvolvement;
    		case AppConstants.SET_VALUE_MAC:
    		return setValueMac;
    		case AppConstants.SET_LOAD_THERSHOLD:
    		return clearThershold;
    		case AppConstants.GET_THERSHOLD_DATA:
    		return getDataThershold;
    		case AppConstants.CLEAR_DATA_ROUTE:
    		return clearDataRoute;
    		case AppConstants.SET_SELECT_TOP_DETAIL:
    		return setSelectTopDetail;
			case AppConstants.GET_STATUS_POINT:
				return getStatusPoint;
            case AppConstants.GET_ROUTER_DATA:
                return getRouterData;
			case AppConstants.GET_SELECT_ZONE_IN_ROUTER:
				return getSelectRouterInRouter;
			case AppConstants.GET_SELECT_ROUTER:
				return getSelectRouter;
			case AppConstants.GET_DATA_PLAN_SET:
				return getPlanSet;
			case AppConstants.SET_DATA_SELECT_POLYGON:
				return setDataSelectPolygon;
			case AppConstants.SET_ROOM:
				return setRooms;
			case AppConstants.SELECT_POLYGON:
				return selectPolygons;
			case AppConstants.SET_FLOOR:
				return setFloor;
			case AppConstants.SET_UNEK_USERS:
				return setUnekUsers;
			case AppConstants.SET_UNEK_USERS_OF_ZONES:
				return setUnekUsersOfZones;
			case AppConstants.SET_ZONE:
				return setZones;
			case AppConstants.STATUS_LOAD_ANALITIC:
				return getLoadAnalitic
			case AppConstants.GET_UUID_PLAN_SET:
				return getUuidPlanSet;
			case AppConstants.ON_CHANGE_SELECT_TYPE_NEW_MAPS:
				return onChangeTypeNewMaps;
			case AppConstants.SHOW_COLOR_COUNT:
				return getStatusShowColotCount;
			case AppConstants.ON_CHECK_BACKGROUND:
				return onCheckBacground;
    	}
    }
});

AppDispatcher.register(function(payload){
	var func = AppStore.route(payload.action.actionType);
	if (func != null) {
		func(payload.action);
		AppStore.emitChange();
	};
	return true;
});


module.exports = AppStore; 
