var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');

var Tabs = React.createClass({
	getInitialState: function () {
		if (AppStore.getState().analiticsMethod === 'sms') {
			return{
				styleButtonSMS: {
					background: '#45b976',
					color: '#fff'
				},
				styleButtonConnect: null,
				styleButtonSocial: null
			};
		}
		else if (AppStore.getState().analiticsMethod === 'connect') {
			return{
				styleButtonSMS: null,
				styleButtonConnect: {
					background: '#45b976',
					color: '#fff'
				},
				styleButtonSocial: null
			};
		}
		else if (AppStore.getState().analiticsMethod === 'social') {
			return{
				styleButtonSMS: null,
				styleButtonConnect: null,
				styleButtonSocial: {
					background: '#45b976',
					color: '#fff'
				}
			};
		}
	},
	openNewTab: function () {	
		window.open('https://cms.wifizone.me/cms/' + AppStore.getState().mainState.token,'_blank');
	},
	setMethod: function (e) {
		if (e.target === document.getElementById('sms')) {
			AppActions.selectAnaliticsMethod('sms');
			this.setState({
				styleButtonSMS: {
					background: '#45b976',
					color: '#fff'
				},
				styleButtonConnect: null,
				styleButtonSocial: null
			});
		}
		else if (e.target === document.getElementById('connect')) {
			AppActions.selectAnaliticsMethod('connect');
			this.setState({
				styleButtonSMS: null,
				styleButtonConnect: {
					background: '#45b976',
					color: '#fff'
				},
				styleButtonSocial: null
			});
		}
		else if (e.target === document.getElementById('social')) {
			AppActions.selectAnaliticsMethod('social');
			this.setState({
				styleButtonSMS: null,
				styleButtonConnect: null,
				styleButtonSocial: {
					background: '#45b976',
					color: '#fff'
				}
			});
		}
	},
	render: function () {
		return (
			<div className='tabs'>
				<div className='nav-sub tabsNavSub'>
					<ul>
						<li onClick={this.setMethod} style={this.state.styleButtonConnect}><a onClick={this.setMethod} id='connect'>Подключения</a></li>
						<li onClick={this.setMethod} style={this.state.styleButtonSMS}><a onClick={this.setMethod} id='sms'>СМС</a></li>
						<li onClick={this.setMethod} style={this.state.styleButtonSocial}><a onClick={this.setMethod} id='social'>Соц. профили</a></li>
						<li onClick={this.setMethod}><a onClick={this.openNewTab} id='social'>Управление страницами</a></li>
					</ul>
				</div>
			</div>
		);
	}
});

module.exports = Tabs;