import React from 'react';
import ReactDOM from 'react-dom';
import {ButtonToolbar, ButtonGroup, Button} from 'react-bootstrap';
import $ from "jquery";
import HolstDraw from './holstDraw';
        
var imageUrl = 0;

// export class DrawManager extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             mode: 'DEFAULT',
//         }
//     }
//     setMode(newMode) {
//         this.setState({
//             mode: newMode,
//         })
//     }
//     getMode() {
//         return this.state.mode;
//     }
//     updateShapes(shapes) {
//         return shapes;
//     }
//     render() {
//         var mode = this.state.mode;
//         return (
//             <div style={{
//
//             }}>
//                 <ButtonToolbar>
//                     <ButtonGroup bsSize="xsmall">
//                         {mode=='POINT' ? <Button bsStyle='primary'
//                                                  onClick={() => this.setMode('DEFAULT')}>Point</Button> :
//                             <Button onClick={() => this.setMode('POINT')}>Point</Button>}
//                         {mode=='LINE' ? <Button bsStyle='primary'
//                                                 onClick={() => this.setMode('DEFAULT')}>Line</Button> :
//                             <Button onClick={() => this.setMode('LINE')}>Line</Button>}
//                         {mode=='POLY' ? <Button bsStyle='primary'
//                                                 onClick={() => this.setMode('DEFAULT')}>Polygon</Button> :
//                             <Button onClick={() => this.setMode('POLY')}>Polygon</Button>}
//                     </ButtonGroup>
//                 </ButtonToolbar>
//
//                 <Drawer {...this.props} getMode={this.getMode.bind(this)} updateShapes={this.updateShapes.bind(this)}/>
//
//             </div>
//         )
//     }
// }

var JSONData = 0;
var JSONSvgElements = 0;
var JSONScale = 0;

//Drawer используется для прохождения треков
export class Drawer extends React.Component {
    initShapes(shapes) {
        this['shapes'] = shapes ? shapes : {
            points: [],
            lines: [],
            polygons: [],
            polygonsBackground: {
                polygons: [],
                bounds: []
            },
            polyLIne: [],
            maskPolyline: [],
            polygonIsOpened: false,
            polyLineIsOpened: false,
            cursorPoint: [],
            polygonCloseStatus: false,
            polyLineCloseStatus: false,
            selectedPoint: null,
            selectedPointMoved: false,
            cursorMoved: false,
            selectedShape: null,
            stepItems: [],
            xCoor: 0,
            yCoor: 0,
            statusContext: false,
            statusContextPoly: false,
            indexI: 0,
            indexJ: 0,
            seconds: 0,
            statusPolygons: '',
            polygonsObj: {
                polygons_in: [],
                polygons_out: [],
                poligons_bounds: []
            },
            namePolygons:[],
            coorXPoint: 0,
            coorYPoint:0,
            gridData: null,
            scaleGrid: 0,
            arrIndexPoly:[],
            retailName: [],
            globalScale: 0,
            nextProps: [],
            statusEnnterPoints: false,
            statusShowGrid: false,
            checkLis: [],
            svgElements: [],
            svgElementsDraw: [],
            statusSvgCloseElem: true,
            markerItem: null,
            coordArr: [0,0]
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            image: props.image,
            width: props.width,
            polyLIne: [],
            polygon:[],
            height: props.height,
            dataForHolstDraw: {
                box: null,
                boxSvg: null,
                svg: null
            }
        }
        this.initShapes(props.shapes);
    }

    componentWillMount() {
        document.onkeydown = this.deletePoints.bind(this);
        document.onclick = this.onClickBox.bind(this);
        this.shapes.statusSvgCloseElem = true;
    }


    componentWillReceiveProps(nextProps) {
        this.shapes.statusSvgCloseElem = true;
        if (nextProps.statusShowGrid !== undefined) {
            this.shapes.statusShowGrid = nextProps.statusShowGrid;
        }
        if (this.state.image != nextProps.image) {
            this.initShapes();
        }
        if (nextProps.points !== undefined) {
            this.shapes.points = nextProps.points;
        }
        // console.log('!@#!@#!@#!@#!@#!@', nextProps.statusSelectMode, nextProps.onSelectPolygons)
        if (nextProps.statusSelectMode === true) {
            if (nextProps.onSelectPolygons !== null) {
                if (this.shapes.selectedShape !== null) {
                    this.shapes.selectedShape = null;
                    nextProps.onSelectPolygons(0, false);
                    this.shapes.statusSvgCloseElem = true;

                }
            }
        }
        // console.log('!@#!@#!@#!@#!@#!@', this.shapes.selectedShape)
        if (nextProps.markerItem !== undefined) {
            this.shapes.markerItem = nextProps.markerItem;
        }
        if (nextProps.namesPoints !== undefined) {
            this.shapes.nextProps = nextProps.namesPoints
        }
        if (nextProps.retailName !== undefined) {
            this.shapes.retailName = nextProps.retailName;
            if (JSON.stringify(this.shapes.retailName) !== JSON.stringify(nextProps.retailName)) {
                this.shapes.statusSvgCloseElem = true;
            }
        }
        // this.state = {
        //     image: nextProps.image,
        //     width: nextProps.width,
        //     height: nextProps.height,
        // }
        this.setState({
            image: nextProps.image,
            width: nextProps.width,
            height: nextProps.height
        });

        if (nextProps.grid !== undefined) {
            this.shapes.gridData = nextProps.grid;
            if (JSON.stringify(this.shapes.gridData) !== JSON.stringify(nextProps.grid)) {
                this.shapes.statusSvgCloseElem = true;
            }
        }
        if (this.shapes.scaleGrid !== undefined) {
            // console.log('for>>>>>>>>>>>>', nextProps.gridScale);
            this.shapes.scaleGrid = nextProps.gridScale;
            if (JSON.stringify(this.shapes.scaleGrid) !== JSON.stringify(nextProps.gridScale)) {
                this.shapes.statusSvgCloseElem = true;
            }
        }
        if (nextProps.globaScale !== undefined){
            this.shapes.globalScale = nextProps.globaScale;
        }
        // console.log('for>>>>>>>>>>>>', nextProps.statusMode);
        if (nextProps.tracks !== undefined) {
            if (nextProps.namePolygons !== undefined) {
                this.shapes.namePolygons = nextProps.namePolygons;
                if (JSON.stringify(this.shapes.namePolygons) !== JSON.stringify(nextProps.namePolygons)) {
                    this.shapes.statusSvgCloseElem = true;
                }
            }
            if (JSON.stringify(nextProps.tracks) !== JSON.stringify(this.shapes.polyLIne)) {
                this.shapes.polyLIne = nextProps.tracks;
                this.shapes.statusSvgCloseElem = true;

            }
            this.shapes.polygonsObj = nextProps.polygonsObj;
            // console.log('!@#!!@#', nextProps.polygonsObj);
            if (JSON.stringify(this.shapes.polygonsObj) !== JSON.stringify(nextProps.polygonsObj)) {
                this.shapes.statusSvgCloseElem = true;
            }
            // this.shapes.polygons = nextProps.tracks;
            if (nextProps.statusMode === 'pointPoly') {

                this.shapes.polyLIne = [];
                // console.log('for1');
                if (JSON.stringify(nextProps.tracks) !== JSON.stringify(this.shapes.polygons)) {
                    this.shapes.polygons = nextProps.tracks;
                    this.shapes.statusSvgCloseElem = true;
                }
                this.shapes.polygonsBackground = nextProps.backgroundElem;
                this.shapes.statusPolygons = nextProps.statusPolygons
                // console.log('for>>>>>>>>>>>>', nextProps.backgroundElem);
            }
            // console.log('for', nextProps.data);
            if (nextProps.statusMode === 'point') {
                // console.log('for2', this.refs);
                if (JSON.stringify(nextProps.tracks) !== JSON.stringify(this.props.tracks)) {
                    this.shapes.polyLIne = nextProps.tracks;
                    this.shapes.statusSvgCloseElem = true;
                }
                this.shapes.maskPolyline = nextProps.data;
                // Holst.homeSetState();
                this.shapes.polygons = nextProps.polygonsTraks;
                if (nextProps.retailName !== undefined) {
                    this.shapes.retailName = nextProps.retailName;
                }
                if (nextProps.namePolygons !== undefined) {
                    this.shapes.namePolygons = nextProps.namePolygons;
                }

                // console.log('for2', this.shapes.retailName, this.shapes.namePolygons);
                this.shapes.polygonsBackground = {
                    polygons: [],
                    bounds: []
                };
            }
            // for (var i = 0; i < nextProps.tracks.length; i++) {
            //     this.shapes.maskPolyline.push(1);
            // }
            if (nextProps.statusMode === 'steps') {
                this.shapes.seconds = nextProps.secondsTimer;
                this.shapes.stepItems = nextProps.stepItems;
                this.shapes.maskPolyline = nextProps.data;
                this.shapes.polyLIne = nextProps.tracks;
                this.shapes.statusSvgCloseElem = true;
                // console.log('for', this.shapes.stepItems);
            }
            // this.shapes.polygonIsOpened = false;
        }
        if (nextProps.checkList !== undefined) {
            this.shapes.checkLis = nextProps.checkList;
            this.setState({});

        }
        // console.log('for>>>>>>>>>>>>', this.shapes.checkLis);
    }
    removeOpenedShapes(mode) {
        var points = this.shapes.points;
        var lines = this.shapes.lines;
        var polygons = this.shapes.polygons;
        var polyLIne = this.shapes.polyLIne;
        if (mode != 'LINE') {
            if (lines.length > 0 && lines[lines.length-1].length < 2) {
                lines.splice(lines.length-1, 1);
            }
        }
        if (mode != 'POLY') {
            if (polygons.length > 0 && this.shapes.polygonIsOpened) {
                polygons.splice(polygons.length-1, 1);
                this.shapes.polygonIsOpened = false;
            }
        }
        if (mode != 'ZONE_SET') {
            // console.log('!@#!@#!@#@')
            if (polygons.length > 0 && this.shapes.polygonIsOpened) {
                polygons.splice(polygons.length-1, 1);
                this.shapes.polygonIsOpened = false;
            }
        }
        if (mode != 'POLYLINE') {
            if (polyLIne.length > 0 && this.shapes.polyLineIsOpened) {
                polyLIne.splice(polyLIne.length-1, 1);
                this.shapes.maskPolyline.splice(this.shapes.maskPolyline.length-1, 1)
                this.shapes.polyLineIsOpened = false;
            }
        }
    }
    holstOnClick(x, y, scale, event) {
        // console.log('>>>>>>>', event.target)
        // this.shapes.xCoor = x/scale;
        // this.shapes.yCoor = y/scale;
        // $(e.target).parents().filter('#boxContext').length === 0 && $(e.target).parents().filter('#selectShowGetOut').length === 0
        if (this.shapes.markerItem === null) {
            if (event.nativeEvent.which === 1 && $(event.target).parents().filter('#boxContext').length === 0) {
                this.shapes.statusContextPoly = false;
                if (this.shapes.gridData !== null) {
                    // this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null);
                    // console.log('>>>>>>>', scale)
                    x = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[0];
                    y = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[1];
                }
                var mode = this.props.getMode();
                // console.log('holst', this.shapes.polygons);
                var p = [x/scale, y/scale];

                if (this.shapes.selectedPointMoved) {
                    return;
                }

                if (this.shapes.selectedShape) {
                    return;
                }

                var points = this.shapes.points;
                var lines = this.shapes.lines;
                var polygons = this.shapes.polygons;

                // this.removeOpenedShapes(mode);

                if (mode == 'POINT') {
                    points.push(p);
                    this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.points)), [[]], false, null);
                } else if (mode == 'LINE') {
                    if (lines.length==0 || lines[lines.length-1].length==2) {
                        lines.push([p]);
                    } else if (lines[lines.length-1].length==1) {
                        lines[lines.length-1].push(p)
                    }
                }
                else if (mode == 'POLY') {
                    var isOpened = this.shapes.polygonIsOpened;
                    var polygons = this.shapes.polygons;
                    if (isOpened) {
                        var lastPolygon = polygons[polygons.length-1];
                        var p0 = lastPolygon[0];
                        var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
                        if (d < this.state.height * 0.013) {
                            if (polygons[polygons.length-1].length > 2) {
                                this.shapes.polygonIsOpened = false;
                                this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
                                this.shapes.statusSvgCloseElem = true;
                            }
                        } else {
                            polygons[polygons.length-1].push(p)
                        }
                    } else {
                        polygons.push([p])
                        this.shapes.polygonIsOpened = true;
                    }
                    this.setState({
                        polygon:polygons
                    });
                    // console.log('poly', polygons);
                    // this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]]);

                    // this.shapes.polygons = polygons;
                }
                else if (mode == 'ZONE_SET') {
                    var isOpened = this.shapes.polygonIsOpened;
                    var polygons = this.shapes.polygons;
                    // console.log('holst', this.shapes.polygons, this.shapes.polygonIsOpened);
                    if (isOpened) {
                        var lastPolygon = polygons[polygons.length-1];
                        var p0 = lastPolygon[0];
                        var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
                        // console.log('poly!!!!!!', polygons, this.shapes.polygonIsOpened);
                        if (d < this.state.height * 0.013) {
                            if (polygons[polygons.length-1].length > 2) {
                                this.shapes.polygonIsOpened = false;
                                this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
                                this.shapes.statusSvgCloseElem = true;
                            }
                        } else {
                            // console.log('poly!!!!!!', polygons);
                            polygons[polygons.length-1].push(p)
                        }
                    } else {
                        // console.log('poly', polygons);
                        polygons.push([p]);
                        this.shapes.polygonIsOpened = true;
                    }
                    this.setState({
                        polygon:polygons
                    });
                    // this.shapes.polygons = polygons;
                    // console.log('poly>>>>>>>', this.shapes.polygons, this.shapes.polygonIsOpened);
                    // this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]]);

                    // this.shapes.polygons = polygons;
                }
                else if (mode == 'PLACES') {
                    var isOpened = this.shapes.polygonIsOpened;
                    var polygons = this.shapes.polygons;
                    // console.log('holst', this.shapes.polygons, this.shapes.polygonIsOpened);
                    if (isOpened) {
                        var lastPolygon = polygons[polygons.length-1];
                        var p0 = lastPolygon[0];
                        var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
                        // console.log('poly!!!!!!', polygons, this.shapes.polygonIsOpened);
                        if (d < this.state.height * 0.013) {
                            if (polygons[polygons.length-1].length > 2) {
                                this.shapes.polygonIsOpened = false;
                                this.shapes.statusSvgCloseElem = true;
                            }
                            // this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]]);
                        } else {
                            // console.log('poly!!!!!!', polygons);
                            polygons[polygons.length-1].push(p)
                        }
                    } else {
                        // console.log('poly', polygons);
                        polygons.push([p]);
                        this.shapes.polygonIsOpened = true;
                    }
                    this.setState({
                        polygon:polygons
                    });
                    this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
                    // this.shapes.polygons = polygons;
                    // console.log('poly>>>>>>>', this.shapes.polygons, this.shapes.polygonIsOpened);
                    // this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]]);

                    // this.shapes.polygons = polygons;
                }
                else if (mode === 'POLYLINE') {
                    if (this.props.statusMode ==='point') {
                        var isOpened = this.shapes.polyLineIsOpened;
                        var polyLIne = this.shapes.polyLIne;
                        // console.log('for', isOpened);
                        if (isOpened) {
                            // console.log('foropen',  polyLIne[polyLIne.length-1].length);
                            var lastPolyLIne = polyLIne[polyLIne.length-1];
                            var p0 = lastPolyLIne[lastPolyLIne.length - 1];
                            var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
                            if (d < this.state.height * 0.013) {
                                if (polyLIne[polyLIne.length-1].length > 1) {
                                    this.shapes.polyLineIsOpened = false;
                                    this.shapes.statusSvgCloseElem = true;
                                }
                                // console.log('!!!!!!!!!');
                            } else {
                                polyLIne[polyLIne.length-1].push(p);
                                // console.log('>>>>>>>>>', this.shapes.maskPolyline, polyLIne);
                                this.shapes.maskPolyline[this.shapes.maskPolyline.length - 1].push(1);
                            }
                        } else {
                            // console.log('for', polyLIne[polyLIne.length-1].length);
                            // polyLIne = [];
                            polyLIne.push([p]);
                            this.shapes.maskPolyline.push([1]);
                            this.shapes.polyLineIsOpened = true;
                        }
                        // console.log('!!!!!!!!!', this.shapes.maskPolyline, polyLIne);
                        // console.log('!!!!!!!!!');
                        this.setState({
                            polyLIne: polyLIne
                        });
                        this.props.getRouters(polyLIne, this.shapes.maskPolyline, false, null);
                        // console.log('for', p, polyLIne);
                        // console.log('poly', polygons);
                        // this.shapes.polygons = polygons;
                    }

                }
            }
        }

    }
    holstMouseMove(x, y, scale) {
        // console.log(x, y)
        this.shapes.coordArr = [Math.round(x/scale),Math.round(y/scale)]
        this.setState({});

        if (this.shapes.gridData !== null) {
            // this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null);
            // console.log('>>>>>>>', scale)
            x = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[0];
            y = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[1];
            this.shapes.coorXPoint = this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[0];
            this.shapes.coorYPoint =  this.netRoundCoordinates([x,y],this.shapes.gridData.scale,this.shapes.gridData !== null, scale, this.shapes.scaleGrid)[1];
        }
        else if (this.shapes.gridData === null) {
            this.shapes.coorXPoint = x;
            this.shapes.coorYPoint = y;
        }
        var mode = this.props.getMode();
        var p = [x/scale, y/scale];
        this.shapes.cursorPoint = p;

        if (this.shapes.selectedPoint) {
            this.shapes.selectedPointMoved = true;
        }

        this.shapes.cursorMoved = true;

        if (this.shapes.selectedPoint && this.shapes.selectedPointMoved) {
            if (this.shapes.selectedPoint.shapeType=='POINT') {
                var i = this.shapes.selectedPoint.is[0];
                this.shapes.points[i] = p;
                this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.points)), [[]], false, null);
            } else if (this.shapes.selectedPoint.shapeType=='LINE') {
                var i = this.shapes.selectedPoint.is[0];
                var j = this.shapes.selectedPoint.is[1];
                this.shapes.lines[i][j] = p;
            } else if (this.shapes.selectedPoint.shapeType=='POLY') {
                var i = this.shapes.selectedPoint.is[0];
                var j = this.shapes.selectedPoint.is[1];
                this.shapes.polygons[i][j] = p;
                this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
            }
            else if (this.shapes.selectedPoint.shapeType=='POLYLINE') {
                // console.log('poly!!!!!');
                var i = this.shapes.selectedPoint.is[0];
                var j = this.shapes.selectedPoint.is[1];
                this.shapes.polyLIne[i][j] = p;
            }
        }

        var polygons = this.shapes.polygons;
        this.shapes.polygonCloseStatus = false;
        this.shapes.polyLineCloseStatus = false;
        if (mode=='POLY' && polygons.length > 0 && this.shapes.polygonIsOpened) {
            var lastPolygon = polygons[polygons.length-1];
            var p0 = lastPolygon[0];
            var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
            if (d < this.state.height * 0.013) {
                if (polygons[polygons.length-1].length > 2) {
                    this.shapes.polygonCloseStatus = true;
                }
            }
        }
        var polyLIne = this.shapes.polyLIne;
        if (mode=='POLYLINE' && polyLIne.length > 0 && this.shapes.polyLineIsOpened) {
            // console.log('onClick');
            var firstPolyLine = polyLIne[polyLIne.length-1];
            var p0 = firstPolyLine[firstPolyLine.length - 1];
            var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
            // console.log('poly', firstPolyLine);
            if (d < this.state.height * 0.013) {
                // console.log('poly', d, this.state.height * 0.02);
                if (polyLIne[polyLIne.length-1].length > 1) {
                    this.shapes.polyLineCloseStatus = true;
                }
            }
        }
        if (mode=='ZONE_SET' && polygons.length > 0 && this.shapes.polygonIsOpened) {
            // console.log('onClick');
            var lastPolygon = polygons[polygons.length-1];
            var p0 = lastPolygon[0];
            var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
            if (d < this.state.height * 0.013) {
                if (polygons[polygons.length-1].length > 2) {
                    this.shapes.polygonCloseStatus = true;
                }
            }
        }
        if (mode=='PLACES' && polygons.length > 0 && this.shapes.polygonIsOpened) {
            // console.log('onClick');
            var lastPolygon = polygons[polygons.length-1];
            var p0 = lastPolygon[0];
            var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
            if (d < this.state.height * 0.013) {
                if (polygons[polygons.length-1].length > 2) {
                    this.shapes.polygonCloseStatus = true;
                }
            }
        }
    }
    selectPoint(shapeType, is) {
        console.log('selectPoint');
        this.shapes.selectedPoint = {
            shapeType: shapeType,
            is: is
        }
        this.setState({});

    }
    selectShape(shapeType, i) {
        this.shapes.selectedShape = {
            shapeType: shapeType,
            i: i
        }
        if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
            if (shapeType === 'POLY' || shapeType === 'POINT') {
                this.props.onSelectPolygons(i, true);
            }
            // else if (shapeType === 'POINT') {
            //     this.props.onSelectPolygons(i, true);
            // }
        }
        this.shapes.cursorMoved = false;
    }
    holstMouseUp(x, y, scale) {
        this.shapes.selectedPoint = null;
        this.shapes.selectedPointMoved = false;
        if (this.shapes.selectedShape && this.shapes.cursorMoved) {
            if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
                if (this.shapes.selectedShape.shapeType === 'POLY') {
                    this.props.onSelectPolygons(0, false);
                }
                if (this.shapes.selectedShape.shapeType === 'POINT') {
                    this.props.onSelectPolygons(0, false);
                }
            }
            this.shapes.selectedShape = null;
        }
        if (this.props.updateShapes) {
            this.shapes = this.props.updateShapes(this.shapes);
        }
    }
    trashClick() {
        if (this.shapes.selectedShape) {
            // console.log('!@#!@#@!');
            var shape = this.shapes.selectedShape;
            if (shape.shapeType == 'POINT') {
                this.shapes.points.splice(shape.i, 1);
                this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.points)), [[]], true,shape.i);
            } else if (shape.shapeType == 'LINE') {
                this.shapes.lines.splice(shape.i, 1);
            } else if (shape.shapeType == 'POLY') {
                this.shapes.polygons.splice(shape.i, 1);
                this.props.getRouters(this.shapes.polygons, [], true, shape.i);
            }
            if (this.props.onSelectPolygons !== undefined && this.props.onSelectPolygons !== null) {
                if (this.shapes.selectedShape.shapeType === 'POLY') {
                    this.props.onSelectPolygons(0, false);
                }
                if (this.shapes.selectedShape.shapeType === 'POINT') {
                    this.props.onSelectPolygons(0, false);
                }
            }
            else if (shape.shapeType == 'POLYLINE') {
                this.props.deleteTrack(shape.i);
                this.shapes.polyLIne.splice(shape.i, 1);
                this.shapes.maskPolyline.splice(shape.i, 1);
                this.props.getRouters(this.shapes.polyLIne, this.shapes.maskPolyline, true, shape.i);
            }
            this.shapes.selectedShape = null;
            if (this.props.updateShapes) {
                this.shapes = this.props.updateShapes(this.shapes);
            }
        }
        // console.log('!!!!', this.shapes.polyLIne)
    }
    contextMenu(e, e1){
        console.log('context', e1[0], e1[1]);
        e.preventDefault();
        this.shapes.statusContext = true;
        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        this.shapes.indexI = e1[0];
        this.shapes.indexJ = e1[1];
        this.setState({
        });

        // console.log('context', e1, e2);
    }
    _getCoords(e) {
        var holst = document.getElementById('holst_svg')
        // var holst = ReactDOM.findDOMNode(this.refs.holst);
        var dim = holst.getBoundingClientRect();
        var x = (e.clientX - dim.left);
        var y = (e.clientY - dim.top);
        return [x, y];
    }
    onClickContext(e, data){
        // this.shapes.maskPolyline[this.shapes.indexI][this.shapes.indexJ]
        for (var i = 0; i < this.shapes.polyLIne.length; i++) {
            for (var j = 0; j < this.shapes.polyLIne[i].length; j++) {
                if (this.shapes.indexI === this.shapes.polyLIne[i][j][0] && this.shapes.indexJ === this.shapes.polyLIne[i][j][1]) {
                    // console.log('context>>>>');
                    this.shapes.maskPolyline[i][j] = data;
                }
            }
        }
        this.shapes.statusContext = false;
        this.props.getRouters(this.shapes.polyLIne, this.shapes.maskPolyline, false, null);
        this.shapes.polyLineIsOpened = false;
        e.stopPropagation()
        // console.log('context',this.shapes.maskPolyline[this.shapes.indexI][this.shapes.indexJ][0]);
    }
    netRoundCoordinates(arrCoords, scale, gridStatus, scaleHolst, paramGrid){
        if (gridStatus === false) {
            return arrCoords;
        }
        else if (gridStatus === true) {
            var x = Math.round(arrCoords[0] / (scale *  paramGrid * scaleHolst)) * (scale *  paramGrid * scaleHolst);
            var y = Math.round(arrCoords[1] / (scale *  paramGrid * scaleHolst)) * (scale *  paramGrid * scaleHolst);
            // console.log('scale', scale);
            return [x,y];
        }
    }
    deletePoints(event){
        // console.log(event.which)
        if (event.which === 8) {
            if (this.shapes.polygonIsOpened === true) {
                // console.log(event.which, this.shapes.polygons[this.shapes.polygons.length - 1]);
                if (this.shapes.polygons[this.shapes.polygons.length - 1].length === 1) {
                    // console.log(event.which, this.shapes.polygons[this.shapes.polygons.length - 1]);
                    this.shapes.polygons.splice(this.shapes.polygons.length - 1, 1);
                    this.shapes.polygonIsOpened = false;
                    this.setState({});
                    // console.log(event.which, this.shapes.polygons);
                    return;
                }
                this.shapes.polygons[this.shapes.polygons.length - 1].splice(this.shapes.polygons[this.shapes.polygons.length - 1].length - 1, 1);
                this.setState({});

            }
            else if (this.shapes.polyLineIsOpened === true) {
                // console.log(event.which, this.shapes.polygons[this.shapes.polygons.length - 1]);
                if (this.shapes.polyLIne[this.shapes.polyLIne.length - 1].length === 1) {
                    // console.log(event.which, this.shapes.polygons[this.shapes.polygons.length - 1]);
                    this.shapes.polyLIne.splice(this.shapes.polyLIne.length - 1, 1);
                    this.shapes.polyLineIsOpened = false;
                    this.setState({});
                    // console.log(event.which, this.shapes.polygons);
                    return;
                }
                this.shapes.polyLIne[this.shapes.polyLIne.length - 1].splice(this.shapes.polyLIne[this.shapes.polyLIne.length - 1].length - 1, 1);
                this.setState({});

            }
        }
        if (event.which === 27) {
            var shape = this.shapes.selectedShape;
            if (this.shapes.selectedShape.shapeType === 'POLY') {
                this.shapes.polygons.splice(shape.i, 1);
                this.props.getRouters(this.shapes.polygons, [], false, null);

            }
            else if (this.shapes.selectedShape.shapeType === 'POLYLINE') {
                this.trashClick()
                // this.props.deleteTrack(shape.i);
                // this.shapes.polyLIne.splice(shape.i, 1);
                // this.shapes.maskPolyline.splice(shape.i, 1);
                // this.props.getRouters(this.shapes.polyLIne, this.shapes.maskPolyline, false);
            }
        }
    }
    onClickPointPoly(e, data, indexArr){
        e.preventDefault();
        this.shapes.arrIndexPoly = indexArr;
        this.shapes.statusContextPoly = true;
        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        this.setState({});
    }
    onClickContextMenuPoly(e,status){
        if (status === 'delete') {
            this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1], 1);
            if (this.shapes.polygons[this.shapes.arrIndexPoly[0]].length <= 1) {
                this.shapes.polygons.splice(this.shapes.arrIndexPoly[0] , 1);
            }
        }
        else if (status === 'addRight') {
            if (this.shapes.arrIndexPoly[1] < this.shapes.polygons[this.shapes.arrIndexPoly[0]].length - 1) {
                if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] < this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) {
                    var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                        this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) / 2;
                    var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                        this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1]) / 2;
                    this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1] + 1, 0, [XCoord, YCoord]);
                }
                else if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] > this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) {
                    // var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                    //     this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) / 2;
                    // var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                    //     this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1]) / 2;
                    // this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1] + 1, 0, [XCoord, YCoord]);
                    if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1] !== undefined) {
                        if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0] > this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0]) {
                            var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0]) / 2;
                            var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][1]) / 2;
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1], 0, [XCoord, YCoord]);
                        }
                        else if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] > this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0] &&
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0] < this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0]
                        ) {
                            if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][1] > this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] &&
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][1] > this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1]
                            ) {
                                var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0]) / 2;
                                var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][1]) / 2;
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1], 0, [XCoord, YCoord]);
                            }
                            else {
                                var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) / 2;
                                var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1]) / 2;
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1] + 1, 0, [XCoord, YCoord]);
                            }
                        }
                    }
                    else if (this.shapes.arrIndexPoly[1] === 0) {
                        var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.polygons[this.shapes.arrIndexPoly[0]].length - 1][0]) / 2;
                        var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.polygons[this.shapes.arrIndexPoly[0]].length - 1][1]) / 2;
                        this.shapes.polygons[this.shapes.arrIndexPoly[0]].push([XCoord, YCoord]);
                    }
                }
            }
            else if (this.shapes.arrIndexPoly[1] === this.shapes.polygons[this.shapes.arrIndexPoly[0]].length - 1) {
                var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][0][0]) / 2;
                var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][0][1]) / 2;
                this.shapes.polygons[this.shapes.arrIndexPoly[0]].push([XCoord, YCoord]);
            }
        }
        else if (status === 'addLeft') {
            if (this.shapes.arrIndexPoly[1] > 0) {
                if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] > this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0]) {
                    var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                        this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0]) / 2;
                    var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                        this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][1]) / 2;
                    this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1], 0, [XCoord, YCoord]);
                }
                else if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] < this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0]) {
                    // var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                    //     this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) / 2;
                    // var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                    //     this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1]) / 2;
                    // this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1] + 1, 0, [XCoord, YCoord]);
                    if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1] !== undefined) {
                        if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0] < this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0]) {
                            var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) / 2;
                            var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1]) / 2;
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1] + 1, 0, [XCoord, YCoord]);
                        }
                        else if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] < this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0] &&
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0] > this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0]
                        ) {
                            if (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1] < this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] &&
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1] < this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][1]
                            ) {
                                var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][0]) / 2;
                                var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] + 1][1]) / 2;
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1] + 1, 0, [XCoord, YCoord]);
                            }
                            else {
                                var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][0]) / 2;
                                var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1] - 1][1]) / 2;
                                this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(this.shapes.arrIndexPoly[1], 0, [XCoord, YCoord]);
                            }
                        }
                    }
                    else if (this.shapes.arrIndexPoly[1] === this.shapes.polygons[this.shapes.arrIndexPoly[0]].length - 1) {

                        var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]][0][0]) / 2;
                        var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                            this.shapes.polygons[this.shapes.arrIndexPoly[0]][0][1]) / 2;
                        // this.shapes.polygons[this.shapes.arrIndexPoly[0]].push([XCoord, YCoord]);
                        this.shapes.polygons[this.shapes.arrIndexPoly[0]].splice(0, 0, [XCoord, YCoord]);
                    }
                }
            }
            else if (this.shapes.arrIndexPoly[1] === 0) {
                var XCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][0] +
                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.polygons[this.shapes.arrIndexPoly[0]].length - 1][0]) / 2;
                var YCoord = (this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]][1] +
                    this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.polygons[this.shapes.arrIndexPoly[0]].length - 1][1]) / 2;
                this.shapes.polygons[this.shapes.arrIndexPoly[0]].push([XCoord, YCoord]);
            }
        }
        // console.log(this.shapes.polygons[this.shapes.arrIndexPoly[0]][this.shapes.arrIndexPoly[1]]);
        // this.shapes.polygons.splice(shape.i, 1);
        this.props.getRouters(JSON.parse(JSON.stringify(this.shapes.polygons)), [[]], false, null);
        this.shapes.statusContextPoly = false;
        e.stopPropagation()
        this.setState({});
    }
    onEnterPoint(){
        // console.log('!!!!!!!');
        this.shapes.statusEnnterPoints = true;
    }
    onLeavePOint(){
        this.shapes.statusEnnterPoints = false;
    }
    getSvgElementsForDraw(scale){
        // console.log('getSvgElementsForDraw');
        if (this.props.changeShapes) {
            this.shapes = this.props.changeShapes(this.shapes);
        }

        var stylePoint = {
            cursor: 'move'
        }
        var styleLine = {
            cursor: 'pointer'
        }

        var elements = [];
        var elementsDraw = [];
        var cp = this.shapes.cursorPoint;
        var mode = this.props.getMode();


        // if (mode == 'POINT') {
        //     elements.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="4" fill="rgba(0,0,0,0.2)"
        //                           key={elements.length} style={stylePoint} />);
        // }

        if (this.shapes.polygonCloseStatus==true) {
            elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
                                      key={elements.length} style={stylePoint} />);
        }
        if (this.shapes.polyLineCloseStatus==true) {
            elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
                                      key={elements.length} style={stylePoint} />);
        }

        var fx = function(type, is) {
            return function(e) {
                if (e.nativeEvent.which === 1) {
                    this.selectPoint(type, is);
                    if (type == 'POINT') {
                        this.selectShape(type, is[0]);
                    }
                    e.stopPropagation()
                }
            };
        }
        var fx2 = function(type, is) {
            // console.log('!@!@!@!@', e.nativeEvent.which)
            return function(e) {
                // console.log('!@!@!@!@', e.nativeEvent.which)
                if (e.nativeEvent.which === 1) {
                    this.selectPoint(type, is);
                    if (type == 'POINT') {
                        this.selectShape(type, is[0]);
                    }
                    e.stopPropagation()
                }
            };
            // if (e.nativeEvent.which === 1) {
            //     return function(e) {
            //         this.selectPoint(type, is);
            //         if (type == 'POINT') {
            //             this.selectShape(type, is[0]);
            //         }
            //         e.stopPropagation()
            //     };
            // }
        }

        var fg = function (p) {
            return function (e) {
                this.contextMenu(e,p);
            }
        }

        var funcPoint = function (p, arr) {
            return function (e) {
                this.onClickPointPoly(e, p, arr)
            }
        }

        var fshape = function(type, i) {
            return function(e) {
                this.selectShape(type, i);
            }
        }
        var polygons = this.shapes.polygons;
        if (this.shapes.polygonIsOpened) {
            var lastPolygon = polygons[polygons.length-1];
            for (var j = 0; j < lastPolygon.length-1; j++) {
                var p1 = lastPolygon[j];
                var p2 = lastPolygon[j+1];
                elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                        stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
                elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                          key={elementsDraw.length} />);
            }
            var p1 = lastPolygon[lastPolygon.length-1];
            var p2 = cp;
            elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                    stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
            elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                      key={elementsDraw.length} />);

        }
        var polyLIne = this.shapes.polyLIne;
        // console.log('for', polyLIne);
        if (this.shapes.polyLineIsOpened) {
            // console.log('for!!!!!!');
            var lastPolygon = polyLIne[polyLIne.length-1];
            for (var j = 0; j < lastPolygon.length-1; j++) {
                var p1 = lastPolygon[j];
                var p2 = lastPolygon[j+1];
                elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                        stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
                elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                          key={elementsDraw.length} />);
            }
            var p1 = lastPolygon[lastPolygon.length-1];
            var p2 = cp;
            elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                    stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
            elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                      key={elementsDraw.length} />);

        }
        if (mode !== 'POINT' && mode !== 'POLYLINE') {
            for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
                // console.log('!!!!!!!!!!!!>>>', polygons[i][0]);
                if (polygons[i][0] !==  undefined) {
                    var path = 'M ';
                    var p0 = polygons[i][0];
                    var minCoorX = p0[0];
                    var minCoorY = p0[1];
                    var maxCoorX = p0[0];
                    var maxCoorY = p0[1];
                    path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                    for (var j = 1; j < polygons[i].length; j++) {
                        var p = polygons[i][j];
                        if (minCoorX > p[0]) {
                            minCoorX = p[0];
                        }
                        if (maxCoorX < p[0]) {
                            maxCoorX = p[0];
                        }
                        if (minCoorY > p[1]) {
                            minCoorY = p[1];
                        }
                        if (maxCoorY < p[1]) {
                            maxCoorY = p[1];
                        }
                        var middleX = (maxCoorX + minCoorX) / 2;
                        var middleY = (minCoorY + maxCoorY) / 2;
                        path += ' ' + p[0]*scale + ',' + p[1]*scale;
                    }
                    path += ' Z'

                    var componentName = null
                    var componentRetailName = null;

                    var alpha = 0.4;
                    if (this.shapes.selectedShape &&
                        this.shapes.selectedShape.shapeType == 'POLY' &&
                        this.shapes.selectedShape.i == String(i)) {
                        alpha = 0.6;

                    }
                    var fill2 = 'rgba(51,71,80,'+ alpha +')';
                    if (this.shapes.statusPolygons === 'poligons_in') {
                        fill2 = 'rgba(255,255,255,0.6)'
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            fill2 = 'rgba(255,255,255,0.9)'

                        }
                    }
                    else if (this.shapes.statusPolygons === 'poligons_out') {
                        fill2 = 'rgba(0,0,0,0.6)'
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            fill2 = 'rgba(0,0,0,0.9)'

                        }
                    }
                    if (mode == 'ZONE_SET') {
                        componentName = <g>
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solid1">
                                        <feFlood floodColor="rgba(0,0,0,.5)"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <text filter="url(#solid1)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={100} stroke="#fff" fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale}>{this.shapes.namePolygons[i]}</text>
                            </g>
                        fill2 = 'rgba(0,0,0,0.6)'
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            fill2 = 'rgba(0,0,255,0.6)'

                        }
                    }
                    if (mode == 'PLACES') {
                        componentRetailName = <g>
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solid">
                                    <feFlood floodColor="rgba(0,0,0,.5)"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{this.shapes.retailName[i]}</text>
                        </g>
                        componentName = <g>
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solid">
                                    <feFlood floodColor="rgba(0,0,0,.5)"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={200} fontSize={10} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`(${this.shapes.namePolygons[i]})`}</text>
                        </g>
                        fill2 = 'rgba(0,0,0,0.6)'
                        // console.log('holstholst', this.shapes.checkLis[i], this.shapes.checkLis)

                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            fill2 = 'rgba(0,0,255,0.3)'
                        }
                    }
                    if (this.shapes.selectedShape &&
                        this.shapes.selectedShape.shapeType == 'POLY' &&
                        this.shapes.selectedShape.i == String(i)) {
                        stylePoint = {
                            cursor: 'move'
                        }
                        elementsDraw.push(<path stroke="rgba(255,0,0,0.6)" strokeWidth="1" fill={fill2}
                                                d={path} key={elements.length} onMouseDown={fshape('POLY', i).bind(this)} style={styleLine}/>)
                        for (var j = 1; j < polygons[i].length; j++) {
                            var p = polygons[i][j];
                            elementsDraw.push(<circle onContextMenu={funcPoint(p, [i,j]).bind(this)} cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                                      key={elements.length} style={stylePoint} onMouseDown={fx('POLY', [i, j]).bind(this)}/>);
                        }
                        elementsDraw.push(<circle onContextMenu={funcPoint(p0, [i,0]).bind(this)} cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                                  key={elements.length} style={stylePoint}
                                                  onMouseDown={fx('POLY', [i, 0]).bind(this)}/>);
                        elementsDraw.push(componentRetailName)
                        elementsDraw.push(componentName)
                    }

                }
            }
        }
        else if (mode === 'POLYLINE'){
            // console.log('!!!!!!!!!!!!>>>', polygons);
            for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
                // console.log('!!!!!!!!!!!!>>>', polygons[i][0]);
                if (polygons[i][0] !==  undefined) {
                    var path = 'M ';
                    var p0 = polygons[i][0];
                    var minCoorX = p0[0];
                    var minCoorY = p0[1];
                    var maxCoorX = p0[0];
                    var maxCoorY = p0[1];
                    path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                    for (var j = 1; j < polygons[i].length; j++) {
                        var p = polygons[i][j];
                        if (minCoorX > p[0]) {
                            minCoorX = p[0];
                        }
                        if (maxCoorX < p[0]) {
                            maxCoorX = p[0];
                        }
                        if (minCoorY > p[1]) {
                            minCoorY = p[1];
                        }
                        if (maxCoorY < p[1]) {
                            maxCoorY = p[1];
                        }
                        var middleX = (maxCoorX + minCoorX) / 2;
                        var middleY = (minCoorY + maxCoorY) / 2;
                        path += ' ' + p[0]*scale + ',' + p[1]*scale;
                    }
                    path += ' Z'

                    var componentName = null
                    var componentRetailName = null;

                    var alpha = 0.4;
                    if (this.shapes.selectedShape &&
                        this.shapes.selectedShape.shapeType == 'POLY' &&
                        this.shapes.selectedShape.i == String(i)) {
                        alpha = 0.6;
                    }
                    var fill2 = 'rgba(51,71,80,'+ alpha +')';
                    this.shapes.retailName
                    componentRetailName = <g>
                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solid">
                                <feFlood floodColor="rgba(0,0,0,.5)"/>
                                <feComposite in="SourceGraphic"/>
                            </filter>
                        </defs>
                        <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.4)" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{this.shapes.retailName[i]}</text>
                    </g>
                    componentName = <g>
                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solid">
                                <feFlood floodColor="rgba(0,0,0,.5)"/>
                                <feComposite in="SourceGraphic"/>
                            </filter>
                        </defs>
                        <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.4)" fontWeight={200}  fontSize={10} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`(${this.shapes.namePolygons[i]})`}</text>
                    </g>
                    fill2 = 'rgba(0,0,0,0.3)'
                    if (this.shapes.checkLis[i] === true) {
                        fill2 = 'rgba(0,255,0,.3)'
                    }
                    // console.log('>>>>>>>>>>>>>>>', this.shapes.checkLis[i])
                    if (this.shapes.selectedShape &&
                        this.shapes.selectedShape.shapeType == 'POLY' &&
                        this.shapes.selectedShape.i == String(i)) {
                        fill2 = 'rgba(0,0,255,0.6)'
                        elementsDraw.push(<path stroke="rgba(255,0,0,0.6)" strokeWidth="1" fill={fill2}
                                            d={path} key={elements.length}  style={styleLine}/>)
                        elementsDraw.push(componentRetailName)
                        elementsDraw.push(componentName)
                    }
                }
            }
        }
        else if (mode === 'POINT') {
            for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
                // console.log('!!!!!!!!!!!!>>>', polygons[i][0]);
                if (polygons[i][0] !==  undefined) {
                    var path = 'M ';
                    var p0 = polygons[i][0];
                    var minCoorX = p0[0];
                    var minCoorY = p0[1];
                    var maxCoorX = p0[0];
                    var maxCoorY = p0[1];
                    path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                    for (var j = 1; j < polygons[i].length; j++) {
                        var p = polygons[i][j];
                        if (minCoorX > p[0]) {
                            minCoorX = p[0];
                        }
                        if (maxCoorX < p[0]) {
                            maxCoorX = p[0];
                        }
                        if (minCoorY > p[1]) {
                            minCoorY = p[1];
                        }
                        if (maxCoorY < p[1]) {
                            maxCoorY = p[1];
                        }
                        var middleX = (maxCoorX + minCoorX) / 2;
                        var middleY = (minCoorY + maxCoorY) / 2;
                        path += ' ' + p[0]*scale + ',' + p[1]*scale;
                    }
                    path += ' Z'

                    var componentName = null
                    var componentRetailName = null;

                    var alpha = 0.4;
                    if (this.shapes.selectedShape &&
                        this.shapes.selectedShape.shapeType == 'POLY' &&
                        this.shapes.selectedShape.i == String(i)) {
                        alpha = 0.6;
                    }
                    var fill2 = 'rgba(51,71,80,'+ alpha +')';
                    if (mode == 'POINT') {
                        this.shapes.retailName
                        componentRetailName = <g>
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solid">
                                    <feFlood floodColor="rgba(0,0,0,.5)"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.8)" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{this.shapes.retailName[i]}</text>
                        </g>
                        componentName = <g>
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solid">
                                    <feFlood floodColor="rgba(0,0,0,.5)"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.8)" fontWeight={200}  fontSize={10} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`(${this.shapes.namePolygons[i]})`}</text>
                        </g>
                        fill2 = 'rgba(0,0,0,0.3)'
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            fill2 = 'rgba(0,0,255,0.6)'
                            elementsDraw.push(<path stroke="rgba(255,0,0,0.6)" strokeWidth="1" fill={fill2}
                                                d={path} key={elements.length}  style={styleLine}/>)
                            elementsDraw.push(componentRetailName)
                            elementsDraw.push(componentName)
                        }
                    }

                }
            }
        }
        var polyLineMasks = this.shapes.maskPolyline;
        // console.log('getSvgElementsForDraw', polyLineMasks);
        if (this.shapes.stepItems.length > 0) {
            if (this.props.statusMode === 'steps') {
                this.shapes.maskPolyline;


                if (this.shapes.polyLIne.length > 0) {
                    for (var h = 0; h < this.shapes.polyLIne[0].length; h++) {
                        let p = this.shapes.polyLIne[0][h];
                        if (polyLineMasks[h] > 1) {
                            // console.log('FOR');
                            var timeStop = '';
                            if (polyLineMasks[h] <= 30) {
                                timeStop = polyLineMasks[h]
                            }
                            else if (this.shapes.maskPolyline[h] >= 30) {
                                timeStop = polyLineMasks[h] / 60
                            }
                            // console.log('getSvgElementsForDraw', timeStop);
                            elementsDraw.push(
                                <g key={elementsDraw.length}>
                                    <circle cx={p[0]*scale} cy={p[1]*scale} r="15" stroke="rgb(255,255,255)" fill="rgba(0,0,0,.5)" strokeWidth="1"
                                            key={elementsDraw.length} style={stylePoint} />
                                    <text fontSize={10} fill="rgb(255,255,255)" textAnchor='middle' x={p[0]*scale} y={p[1]*scale + 3}>{timeStop}</text>
                                </g>);
                        }
                        // console.log('>>>>>>>>',this.shapes.stepItems[0][f][0], this.shapes.stepItems[0][f][1], this.shapes.stepItems);

                    }
                }
                for (var f = 0; f < this.shapes.stepItems.length; f++) {
                    // console.log('for', this.shapes.stepItems[f]);
                    let p = this.shapes.stepItems[f];
                    var color = 'red';
                    if (f === this.shapes.stepItems.length - 1) {
                        color = 'green';
                    }
                    if (polyLineMasks[f] === 1) {
                        elementsDraw.push(<circle cx={p[0]*scale} cy={p[1]*scale} r="5" stroke="rgb(255,255,0)" fill={color} strokeWidth="1"
                                              key={elementsDraw.length} style={stylePoint} onMouseDown={fx('POLYLINE', [i, j]).bind(this)}/>);
                    }

                    // console.log('>>>>>>>>',this.shapes.stepItems[0][f][0], this.shapes.stepItems[0][f][1], this.shapes.stepItems);

                }
                for (var k = 0; k < this.shapes.stepItems.length; k++) {
                    // console.log('for', this.shapes.stepItems[f]);
                    let p = this.shapes.stepItems[k];
                    // console.log('for', this.shapes.stepItems);
                    if (polyLineMasks[k] > 1) {
                        var timeStop = '';
                        if (polyLineMasks[k] <= 30) {
                            var rad = 15;
                            timeStop = this.shapes.seconds[k]
                        }
                        else if (this.shapes.maskPolyline[k] >= 30 && this.shapes.maskPolyline[k] <= 60) {
                            var rad = 15;
                            timeStop = this.shapes.seconds[k]
                        }
                        else if (this.shapes.maskPolyline[k] > 60) {
                            var rad = 30;
                            timeStop = this.shapes.seconds[k]
                        }
                        var color = 'red';
                        if (k === this.shapes.stepItems.length - 1) {
                            color = 'green';
                        }
                        // console.log('getSvgElementsForDraw', timeStop);
                        elementsDraw.push(
                            <g key={elementsDraw.length} onMouseDown={fx('POLYLINE', [i, j]).bind(this)}>
                                <circle cx={p[0]*scale} cy={p[1]*scale} r={rad} stroke="rgb(255,255,0)" fill={color} strokeWidth="1"
                                        key={elementsDraw.length} style={stylePoint} />
                                <text fontSize={10} fill="rgb(255,255,255)" textAnchor='middle' x={p[0]*scale} y={p[1]*scale + 3}>{timeStop}</text>
                            </g>);
                    }

                    // console.log('>>>>>>>>',this.shapes.stepItems[0][f][0], this.shapes.stepItems[0][f][1], this.shapes.stepItems);

                }
            }
        }
        for (var i = 0; i < polyLIne.length - (this.shapes.polyLineIsOpened ? 1 : 0); i++) {
            var path = 'M ';
            var p0 = polyLIne[i][0];
            // console.log('for', p0);
            path += p0[0] * scale + ',' + p0[1] * scale + ' L ';
            for (var j = 1; j < polyLIne[i].length; j++) {
                var p = polyLIne[i][j];
                path += ' ' + p[0] * scale + ',' + p[1] * scale;
            }
            path += ' ';
            // console.log('>>>>>>>>', i);
            var alpha = 2;
            if (this.shapes.selectedShape &&
                this.shapes.selectedShape.shapeType == 'POLYLINE' &&
                this.shapes.selectedShape.i == String(i)) {
                alpha = 6;
                elementsDraw.push(<path pointerEvents="stroke" stroke={`rgba(255,0,0,.6)`} strokeWidth={alpha}
                                    fill={'rgba(51,71,80,' + 0 + ')'}
                                    d={path} key={elements.length} onMouseDown={fshape('POLYLINE', i).bind(this)}
                                    style={styleLine}/>)
            }

        }
        //фоновые элементы начало
        //конец>>>>>>>>>>>>>>>>>>>>>>>>>>


        // console.log('!!!!!!!!!!!!>>>', polygons, mode);
        // console.log('!!!!!!!<<<<<<', polygons);



        //<<<<<<<<<<<<<<<kirdro dev >>>>>>>>>>>>>>>>>>>>>>>>>>>>

        // console.log('for', elements);
        //<<<<<<<<<<<<<<kirdro dev >>>>>>>>>>>>>>>>>>>>>>>>>>>>


        stylePoint = {
            pointerEvents: 'none',
            cursor: 'default'
        };
        if (this.shapes.stepItems.length === 0) {
            if (this.shapes.coorXPoint !== 0 && this.shapes.coorYPoint !== 0) {
                if (mode === 'POINT') {
                    // console.log(stylePoint)
                    if (this.shapes.statusEnnterPoints === false) {
                        var r = this.shapes.globalScale * 1 * scale;
                        elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r={r} stroke="rgba(100,183,122,.5)" fill="rgba(255,255,255,.5)" strokeWidth="3"
                                                  key={elements.length} style={stylePoint} />);
                        elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r={5} stroke="rgba(255,255,255,.5)" fill="rgba(0,0,0,.5)" strokeWidth="1"
                                                  key={elements.length} style={stylePoint} />);
                    }
                    else {
                        elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r="4" fill="rgba(0,0,0,.5)" stroke="rgb(255,255,255)" strokeWidth="1.5"
                                                  key={elements.length} style={stylePoint} />);
                    }
                }
                else {
                    elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r="4" fill="rgba(0,0,0,.5)" stroke="rgb(255,255,255)" strokeWidth="1.5"
                                              key={elements.length} style={stylePoint} />);
                }
            }
        }
        this.shapes.svgElementsDraw = elementsDraw;
        return {elem:elements, elemDraw: elementsDraw};
    }
    getSvgElements(scale) {
        var obj = {
            polygons: this.shapes.polygons,
            polyLIne:  this.shapes.polyLIne,
            gridData: this.shapes.gridData,
            polygonCloseStatus: this.shapes.polygonCloseStatus,
            polyLineCloseStatus: this.shapes.polyLineCloseStatus,
            stepItems: this.shapes.stepItems,
            polygonsObj: this.shapes.polygonsObj,
            points: this.shapes.points,
            lines: this.shapes.lines
        };
        if (JSONSvgElements !== JSON.stringify(obj)) {
            this.shapes.statusSvgCloseElem = true;
            setTimeout(() => {
                this.shapes.statusSvgCloseElem = true;
                setTimeout(() => {
                    this.shapes.statusSvgCloseElem = true;
                }, 100)
            }, 100)
            JSONSvgElements = JSON.stringify(obj)
        }
        if (this.shapes.polygonIsOpened === false && this.shapes.polyLineIsOpened === false && this.shapes.statusSvgCloseElem === true ||
            JSONScale !== scale) {
            JSONScale = scale;
            this.shapes.statusSvgCloseElem = false;
            // console.log('getSvgElements');

            // console.log('poly!!!!!!', this.shapes.polygonIsOpened);
            if (this.props.changeShapes) {
                this.shapes = this.props.changeShapes(this.shapes);
            }

            var stylePoint = {
                cursor: 'move'
            }
            var styleLine = {
                cursor: 'pointer'
            }

            var elements = [];
            var elementsDraw = [];
            var cp = this.shapes.cursorPoint;
            var mode = this.props.getMode();

            if (this.shapes.statusShowGrid === true) {
                if (this.shapes.gridData !== null) {
                    let x = 0
                    for (var u = 0; u < this.shapes.gridData.countX; u++) {
                        x = x + this.shapes.gridData.scale * this.shapes.scaleGrid;
                        elements.push(<line x1={x*scale} y1="0" x2={x*scale} y2="100%" style={{stroke:`rgba(0,0,0,.05)`,strokeWidth:1}} />)
                    }
                    let y = 0
                    for (var r = 0; r < this.shapes.gridData.countY; r++) {
                        y = y + this.shapes.gridData.scale * this.shapes.scaleGrid;
                        elements.push(<line x1={0} y1={y*scale} x2={`100%`} y2={y*scale} style={{stroke:`rgba(0,0,0,.05)`,strokeWidth:1}} />)
                    }
                }
            }

            // if (mode == 'POINT') {
            //     elements.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="4" fill="rgba(0,0,0,0.2)"
            //                           key={elements.length} style={stylePoint} />);
            // }

            if (this.shapes.polygonCloseStatus==true) {
                elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
                                          key={elements.length} style={stylePoint} />);
            }
            if (this.shapes.polyLineCloseStatus==true) {
                elementsDraw.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)"
                                          key={elements.length} style={stylePoint} />);
            }

            var fx = function(type, is) {
                return function(e) {
                    if (e.nativeEvent.which === 1) {
                        this.selectPoint(type, is);
                        if (type == 'POINT') {
                            this.selectShape(type, is[0]);
                        }
                        e.stopPropagation()
                    }
                };
            }
            var fx2 = function(type, is) {
                // console.log('!@!@!@!@', e.nativeEvent.which)
                return function(e) {
                    // console.log('!@!@!@!@', e.nativeEvent.which)
                    if (e.nativeEvent.which === 1) {
                        this.selectPoint(type, is);
                        if (type == 'POINT') {
                            this.selectShape(type, is[0]);
                        }
                        e.stopPropagation()
                    }
                };
                // if (e.nativeEvent.which === 1) {
                //     return function(e) {
                //         this.selectPoint(type, is);
                //         if (type == 'POINT') {
                //             this.selectShape(type, is[0]);
                //         }
                //         e.stopPropagation()
                //     };
                // }
            }

            var fg = function (p) {
                return function (e) {
                    this.contextMenu(e,p);
                }
            }

            var funcPoint = function (p, arr) {
                return function (e) {
                    this.onClickPointPoly(e, p, arr)
                }
            }

            var fshape = function(type, i) {
                return function(e) {
                    this.selectShape(type, i);
                }
            }
            for (var i in this.shapes.points) {
                var p = this.shapes.points[i];
                // console.log('for', this.shapes.globalScale);
                var r = this.shapes.globalScale * 1 * scale;

                if (this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POINT' &&
                    this.shapes.selectedShape.i == String(i)) {
                    r = this.shapes.globalScale * 1 * scale + 5;
                }
                if (mode !== 'POINT') {
                    stylePoint = {
                        cursor: 'default',
                        pointerEvents: 'none',
                    }
                    componentName = <g fill="#000">
                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solid">
                                <feFlood floodColor="rgba(0,0,0,.5)"/>
                                <feComposite in="SourceGraphic"/>
                            </filter>
                        </defs>
                        <text filter="url(#solid)" style={stylePoint} fill="rgba(255,255,255,.4)" fontWeight={200} fontSize={10} textAnchor='middle' x={p[0]*scale} y={p[1]*scale + 18}>{`${this.shapes.nextProps[i]}`}</text>
                    </g>

                    elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r={r} stroke="rgba(100,183,122,.4)" fill="rgba(255,255,255,.4)" strokeWidth="3"
                                          key={elements.length} style={stylePoint} />);
                    elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r={5} stroke="rgba(255,255,255,.4)" fill="rgba(0,0,0,.4)" strokeWidth="1"
                                          key={elements.length} style={stylePoint} />);
                    elements.push(componentName);
                }

            }
            var polygons = this.shapes.polygons;
            if (this.shapes.polygonIsOpened) {
                var lastPolygon = polygons[polygons.length-1];
                for (var j = 0; j < lastPolygon.length-1; j++) {
                    var p1 = lastPolygon[j];
                    var p2 = lastPolygon[j+1];
                    elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                            stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
                    elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                              key={elementsDraw.length} />);
                }
                var p1 = lastPolygon[lastPolygon.length-1];
                var p2 = cp;
                elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                        stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
                elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                          key={elementsDraw.length} />);

            }
            var polyLIne = this.shapes.polyLIne;
            // console.log('for', polyLIne);
            if (this.shapes.polyLineIsOpened) {
                // console.log('for!!!!!!');
                var lastPolygon = polyLIne[polyLIne.length-1];
                for (var j = 0; j < lastPolygon.length-1; j++) {
                    var p1 = lastPolygon[j];
                    var p2 = lastPolygon[j+1];
                    elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                            stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
                    elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                              key={elementsDraw.length} />);
                }
                var p1 = lastPolygon[lastPolygon.length-1];
                var p2 = cp;
                elementsDraw.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                        stroke="rgb(255,0,0)" key={elementsDraw.length}/>);
                elementsDraw.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                          key={elementsDraw.length} />);

            }
            //фоновые элементы начало
            var polygonsBackground =  this.shapes.polygonsBackground;
            // console.log('for', this.shapes.polygonsBackground);
            for (var t = 0; t < polygonsBackground.polygons.length; t++) {
                var path = 'M ';
                var p0 = polygonsBackground.polygons[t][0];
                path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                for (var j = 1; j < polygonsBackground.polygons[t].length; j++) {
                    var p = polygonsBackground.polygons[t][j];
                    path += ' ' + p[0]*scale + ',' + p[1]*scale;
                }
                path += ' Z'
                // console.log('for', this.shapes.polygonsBackground, path);
                var fill = 'rgba(51,71,80,0.2)';
                if (this.shapes.statusPolygons === 'poligons_in') {
                    fill = 'rgba(0,0,0,0.2)'
                }
                else if (this.shapes.statusPolygons === 'poligons_out') {
                    fill = 'rgba(255,255,255,0.2)'
                }
                else if (this.shapes.statusPolygons === 'poligons_bounds') {
                    fill = 'rgba(255,255,0,0.2)'
                }

                elements.push(<path stroke="rgba(255,0,0,0.2)" strokeWidth="1" fill={fill}
                                    d={path} key={elements.length}  />)
                for (var j = 1; j < polygonsBackground.polygons[t].length; j++) {
                    var p = polygonsBackground.polygons[t][j];
                    elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                          key={elements.length}  />);
                }
                elements.push(<circle cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                      key={elements.length}
                />);
            }
            for (var t = 0; t < polygonsBackground.bounds.length; t++) {
                var path = 'M ';
                var p0 = polygonsBackground.bounds[t][0];
                path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                for (var j = 1; j < polygonsBackground.bounds[t].length; j++) {
                    var p = polygonsBackground.bounds[t][j];
                    path += ' ' + p[0]*scale + ',' + p[1]*scale;
                }
                path += ' Z'
                // console.log('for', this.shapes.polygonsBackground, path);
                var fill = 'rgba(51,71,80,0.2)';
                if (this.shapes.statusPolygons === 'poligons_in') {
                    fill = 'rgba(0,255,0,0.2)'
                }
                else if (this.shapes.statusPolygons === 'poligons_out') {
                    fill = 'rgba(0,255,0,0.2)'
                }
                else if (this.shapes.statusPolygons === 'poligons_bounds') {
                    fill = 'rgba(255,255,0,0.2)'
                }

                elements.push(<path stroke="rgba(255,0,0,0.2)" strokeWidth="1" fill={fill}
                                    d={path} key={elements.length}  />)
                for (var j = 1; j < polygonsBackground.bounds[t].length; j++) {
                    var p = polygonsBackground.bounds[t][j];
                    elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                          key={elements.length}  />);
                }
                elements.push(<circle cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                      key={elements.length}
                />);
            }
            //конец>>>>>>>>>>>>>>>>>>>>>>>>>>

            for (var g = 0; g < this.shapes.polygonsObj.polygons_in.length; g++) {
                var path = 'M ';
                var p0 = this.shapes.polygonsObj.polygons_in[g][0];
                path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                for (var j = 1; j < this.shapes.polygonsObj.polygons_in[g].length; j++) {
                    var p = this.shapes.polygonsObj.polygons_in[g][j];
                    path += ' ' + p[0]*scale + ',' + p[1]*scale;
                }
                path += ' Z'

                var alpha = 0.4;
                var fill3 = 'rgba(51,71,80,'+ alpha +')';
                fill3 = 'rgba(255,255,255,0.2)';
                elements.push(<path stroke="rgba(0,0,0,0.6)" strokeWidth="1" fill={fill3}
                                    d={path} key={elements.length} />)
            }

            for (var h = 0; h < this.shapes.polygonsObj.polygons_out.length; h++) {
                var path = 'M ';
                var p0 = this.shapes.polygonsObj.polygons_out[h][0];
                path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                for (var j = 1; j < this.shapes.polygonsObj.polygons_out[h].length; j++) {
                    var p = this.shapes.polygonsObj.polygons_out[h][j];
                    path += ' ' + p[0]*scale + ',' + p[1]*scale;
                }
                path += ' Z'

                var alpha = 0.4;
                var fill3 = 'rgba(51,71,80,'+ alpha +')';
                fill3 = 'rgba(0,0,0,0.2)';
                elements.push(<path stroke="rgba(0,0,0,0.6)" strokeWidth="1" fill={fill3}
                                    d={path} key={elements.length}  />)
            }
            for (var h = 0; h < this.shapes.polygonsObj.poligons_bounds.length; h++) {
                var path = 'M ';
                var p0 = this.shapes.polygonsObj.poligons_bounds[h][0];
                path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                for (var j = 1; j < this.shapes.polygonsObj.poligons_bounds[h].length; j++) {
                    var p = this.shapes.polygonsObj.poligons_bounds[h][j];
                    path += ' ' + p[0]*scale + ',' + p[1]*scale;
                }
                path += ' Z'

                var alpha = 0.4;
                var fill3 = 'rgba(51,71,80,'+ alpha +')';
                fill3 = 'rgba(0,255,0,0.2)';
                elements.push(<path stroke="rgba(0,0,0,0.6)" strokeWidth="1" fill={fill3}
                                    d={path} key={elements.length}  />)
            }
            // console.log('!!!!!!!!!!!!>>>', polygons, mode);
            // console.log('!!!!!!!<<<<<<', polygons);
            if (mode !== 'POINT' && mode !== 'POLYLINE') {
                for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
                    // console.log('!!!!!!!!!!!!>>>', polygons[i][0]);
                    if (polygons[i][0] !==  undefined) {
                        var path = 'M ';
                        var p0 = polygons[i][0];
                        var minCoorX = p0[0];
                        var minCoorY = p0[1];
                        var maxCoorX = p0[0];
                        var maxCoorY = p0[1];
                        path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                        for (var j = 1; j < polygons[i].length; j++) {
                            var p = polygons[i][j];
                            if (minCoorX > p[0]) {
                                minCoorX = p[0];
                            }
                            if (maxCoorX < p[0]) {
                                maxCoorX = p[0];
                            }
                            if (minCoorY > p[1]) {
                                minCoorY = p[1];
                            }
                            if (maxCoorY < p[1]) {
                                maxCoorY = p[1];
                            }
                            var middleX = (maxCoorX + minCoorX) / 2;
                            var middleY = (minCoorY + maxCoorY) / 2;
                            path += ' ' + p[0]*scale + ',' + p[1]*scale;
                        }
                        path += ' Z'

                        var componentName = null
                        var componentRetailName = null;

                        var alpha = 0.4;
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            alpha = 0.6;
                        }
                        var fill2 = 'rgba(51,71,80,'+ alpha +')';
                        if (this.shapes.statusPolygons === 'poligons_in') {
                            fill2 = 'rgba(255,255,255,0.6)'
                            if (this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType == 'POLY' &&
                                this.shapes.selectedShape.i == String(i)) {
                                // fill2 = 'rgba(255,255,255,0.9)'
                            }
                        }
                        else if (this.shapes.statusPolygons === 'poligons_out') {
                            fill2 = 'rgba(0,0,0,0.6)'
                            if (this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType == 'POLY' &&
                                this.shapes.selectedShape.i == String(i)) {
                                // fill2 = 'rgba(0,0,0,0.9)'
                            }
                        }
                        else if (this.shapes.statusPolygons === 'poligons_bounds') {
                            fill2 = 'rgba(0,255,0,0.6)'
                            if (this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType == 'POLY' &&
                                this.shapes.selectedShape.i == String(i)) {
                                // fill2 = 'rgba(0,0,0,0.9)'
                            }
                        }
                        if (mode == 'ZONE_SET') {
                            componentName = <g>
                                    <defs>
                                        <filter x="0" y="0"  width="1" height="1" id="solid1">
                                            <feFlood floodColor="rgba(0,0,0,.5)"/>
                                            <feComposite in="SourceGraphic"/>
                                        </filter>
                                    </defs>
                                <text filter="url(#solid1)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={100} stroke="#fff" fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale}>{this.shapes.namePolygons[i]}</text>
                                </g>
                            fill2 = 'rgba(0,0,0,0.6)'
                            if (this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType == 'POLY' &&
                                this.shapes.selectedShape.i == String(i)) {
                                // fill2 = 'rgba(0,0,255,0.6)'
                            }
                        }
                        if (mode == 'PLACES') {
                            componentRetailName = <g>
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solid">
                                        <feFlood floodColor="rgba(0,0,0,.5)"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{this.shapes.retailName[i]}</text>
                            </g>
                            componentName = <g>
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solid">
                                        <feFlood floodColor="rgba(0,0,0,.5)"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={200} fontSize={10} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`(${this.shapes.namePolygons[i]})`}</text>
                            </g>
                            fill2 = 'rgba(0,0,0,0.6)'
                            // console.log('holstholst', this.shapes.checkLis[i], this.shapes.checkLis)
                            if (this.shapes.checkLis[i] === true) {
                                fill2 = 'rgba(0,255,0,.6)'
                            }
                            if (this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType == 'POLY' &&
                                this.shapes.selectedShape.i == String(i)) {
                                // fill2 = 'rgba(0,0,255,0.6)'
                            }
                            // console.log("forfor", this.shapes.markerItem);
                            if (this.shapes.markerItem !== null) {
                                if (this.shapes.markerItem.type === 'Places') {
                                    if (this.shapes.markerItem.marker === this.shapes.namePolygons[i]) {
                                        fill2 = 'rgba(255,0,0,.6)';
                                        fshape('POLY', i).bind(this)
                                    }
                                }
                            }
                        }
                        stylePoint = {
                            cursor: 'move'
                        }
                        elements.push(<path stroke="rgba(255,0,0,0.6)" strokeWidth="1" fill={fill2}
                                            d={path} key={elements.length} onMouseDown={fshape('POLY', i).bind(this)} style={styleLine}/>)
                        for (var j = 1; j < polygons[i].length; j++) {
                            var p = polygons[i][j];
                            elements.push(<circle onContextMenu={funcPoint(p, [i,j]).bind(this)} cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                                  key={elements.length} style={stylePoint} onMouseDown={fx('POLY', [i, j]).bind(this)}/>);
                        }
                        elements.push(<circle onContextMenu={funcPoint(p0, [i,0]).bind(this)} cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                              key={elements.length} style={stylePoint}
                                              onMouseDown={fx('POLY', [i, 0]).bind(this)}/>);
                        elements.push(componentRetailName)
                        elements.push(componentName)
                    }
                }
            }
            else if (mode === 'POLYLINE'){
                // console.log('!!!!!!!!!!!!>>>', polygons);
                for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
                    // console.log('!!!!!!!!!!!!>>>', polygons[i][0]);
                    if (polygons[i][0] !==  undefined) {
                        var path = 'M ';
                        var p0 = polygons[i][0];
                        var minCoorX = p0[0];
                        var minCoorY = p0[1];
                        var maxCoorX = p0[0];
                        var maxCoorY = p0[1];
                        path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                        for (var j = 1; j < polygons[i].length; j++) {
                            var p = polygons[i][j];
                            if (minCoorX > p[0]) {
                                minCoorX = p[0];
                            }
                            if (maxCoorX < p[0]) {
                                maxCoorX = p[0];
                            }
                            if (minCoorY > p[1]) {
                                minCoorY = p[1];
                            }
                            if (maxCoorY < p[1]) {
                                maxCoorY = p[1];
                            }
                            var middleX = (maxCoorX + minCoorX) / 2;
                            var middleY = (minCoorY + maxCoorY) / 2;
                            path += ' ' + p[0]*scale + ',' + p[1]*scale;
                        }
                        path += ' Z'

                        var componentName = null
                        var componentRetailName = null;

                        var alpha = 0.4;
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            alpha = 0.6;
                        }
                        var fill2 = 'rgba(51,71,80,'+ alpha +')';
                        this.shapes.retailName
                        componentRetailName = <g>
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solid">
                                    <feFlood floodColor="rgba(0,0,0,.5)"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.4)" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{this.shapes.retailName[i]}</text>
                        </g>
                        componentName = <g>
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solid">
                                    <feFlood floodColor="rgba(0,0,0,.5)"/>
                                    <feComposite in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.4)" fontWeight={200}  fontSize={10} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`(${this.shapes.namePolygons[i]})`}</text>
                        </g>
                        fill2 = 'rgba(0,0,0,0.3)'
                        // console.log('getsvg&&&', this.shapes.checkLis[i])
                        if (this.shapes.checkLis[i] === true) {
                            fill2 = 'rgba(0,255,0,.3)'
                        }
                        // console.log('>>>>>>>>>>>>>>>', this.shapes.checkLis[i])
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            fill2 = 'rgba(0,0,255,0.6)'
                        }
                        elements.push(<path stroke="rgba(255,0,0,0.6)" strokeWidth="1" fill={fill2}
                                            d={path} key={elements.length}  style={styleLine}/>)
                        // console.log('!!!!!!!!!!!!>>>', polygons, elements);
                        // for (var j = 1; j < polygons[i].length; j++) {
                        //     var p = polygons[i][j];
                        //     elements.push(<circle onContextMenu={funcPoint(p, [i,j]).bind(this)} cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                        //                           key={elements.length} style={stylePoint} onMouseDown={fx('POLY', [i, j]).bind(this)}/>);
                        // }
                        // elements.push(<circle onContextMenu={funcPoint(p0, [i,0]).bind(this)} cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                        //                       key={elements.length} style={stylePoint}
                        //                       onMouseDown={fx('POLY', [i, 0]).bind(this)}/>);
                        elements.push(componentRetailName)
                        elements.push(componentName)
                    }
                }
            }
            else if (mode === 'POINT') {
                for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
                    // console.log('!!!!!!!!!!!!>>>', polygons[i][0]);
                    if (polygons[i][0] !==  undefined) {
                        var path = 'M ';
                        var p0 = polygons[i][0];
                        var minCoorX = p0[0];
                        var minCoorY = p0[1];
                        var maxCoorX = p0[0];
                        var maxCoorY = p0[1];
                        path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                        for (var j = 1; j < polygons[i].length; j++) {
                            var p = polygons[i][j];
                            if (minCoorX > p[0]) {
                                minCoorX = p[0];
                            }
                            if (maxCoorX < p[0]) {
                                maxCoorX = p[0];
                            }
                            if (minCoorY > p[1]) {
                                minCoorY = p[1];
                            }
                            if (maxCoorY < p[1]) {
                                maxCoorY = p[1];
                            }
                            var middleX = (maxCoorX + minCoorX) / 2;
                            var middleY = (minCoorY + maxCoorY) / 2;
                            path += ' ' + p[0]*scale + ',' + p[1]*scale;
                        }
                        path += ' Z'

                        var componentName = null
                        var componentRetailName = null;

                        var alpha = 0.4;
                        if (this.shapes.selectedShape &&
                            this.shapes.selectedShape.shapeType == 'POLY' &&
                            this.shapes.selectedShape.i == String(i)) {
                            alpha = 0.6;
                        }
                        var fill2 = 'rgba(51,71,80,'+ alpha +')';
                        if (mode == 'POINT') {
                            this.shapes.retailName
                            componentRetailName = <g>
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solid">
                                        <feFlood floodColor="rgba(0,0,0,.5)"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.8)" fontWeight={200}  fontSize={11} textAnchor='middle' x={middleX*scale} y={middleY*scale - 10}>{this.shapes.retailName[i]}</text>
                            </g>
                            componentName = <g>
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solid">
                                        <feFlood floodColor="rgba(0,0,0,.5)"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="rgba(255,255,255,.8)" fontWeight={200}  fontSize={10} textAnchor='middle' x={middleX*scale} y={middleY*scale + 10}>{`(${this.shapes.namePolygons[i]})`}</text>
                            </g>
                            fill2 = 'rgba(0,0,0,0.3)'
                            if (this.shapes.selectedShape &&
                                this.shapes.selectedShape.shapeType == 'POLY' &&
                                this.shapes.selectedShape.i == String(i)) {
                                fill2 = 'rgba(0,0,255,0.6)'
                            }
                        }

                        elements.push(<path stroke="rgba(255,0,0,0.6)" strokeWidth="1" fill={fill2}
                                            d={path} key={elements.length}  style={styleLine}/>)
                        // for (var j = 1; j < polygons[i].length; j++) {
                        //     var p = polygons[i][j];
                        //     elements.push(<circle onContextMenu={funcPoint(p, [i,j]).bind(this)} cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                        //                           key={elements.length} style={stylePoint} onMouseDown={fx('POLY', [i, j]).bind(this)}/>);
                        // }
                        // elements.push(<circle onContextMenu={funcPoint(p0, [i,0]).bind(this)} cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                        //                       key={elements.length} style={stylePoint}
                        //                       onMouseDown={fx('POLY', [i, 0]).bind(this)}/>);
                        elements.push(componentRetailName)
                        elements.push(componentName)
                    }
                }
            }


            //<<<<<<<<<<<<<<<kirdro dev >>>>>>>>>>>>>>>>>>>>>>>>>>>>
            var polyLIne = this.shapes.polyLIne;
            var polyLineMasks = this.shapes.maskPolyline;
            // console.log('getSvgElements', polyLineMasks);
            // console.log('for', polyLIne);
            // console.log('onClick', this.shapes.polyLineIsOpened);
            for (var i = 0; i < polyLIne.length - (this.shapes.polyLineIsOpened ? 1 : 0); i++) {
                var path = 'M ';
                var p0 = polyLIne[i][0];
                // console.log('for', p0);
                path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
                for (var j = 1; j < polyLIne[i].length; j++) {
                    var p = polyLIne[i][j];
                    path += ' ' + p[0]*scale + ',' + p[1]*scale;
                }
                path += ' ';
                // console.log('>>>>>>>>', i);
                var alpha = 2;
                if (this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POLYLINE' &&
                    this.shapes.selectedShape.i == String(i)) {
                    alpha = 6;
                }
                // console.log('>>>>>>>>', alpha);
                elements.push(<path pointerEvents="stroke" stroke={`rgba(255,0,0,.6)`} strokeWidth={alpha} fill={'rgba(51,71,80,'+ 0 +')'}
                                    d={path} key={elements.length} onMouseDown={fshape('POLYLINE', i).bind(this)} style={styleLine}/>)
                for (var j = 1; j < polyLIne[i].length; j++) {
                    var p = polyLIne[i][j];
                    if (polyLineMasks.length > 0) {
                        // console.log('>>>>>>>>',polyLineMasks, polyLIne);
                        // console.log('>>>>>>>>',polyLineMasks, polyLIne,i, j);
                        if (polyLineMasks[i][j] <= 30) {
                            var timeStop = polyLineMasks[i][j]
                        }
                        else if (polyLineMasks[i][j] >= 30) {
                            var timeStop = polyLineMasks[i][j] / 60
                        }
                        if (polyLineMasks[i][j] === 1) {
                            var index = i - 1,
                                indexj = j;
                            elements.push(<circle
                                onContextMenu={fg(p).bind(this)}
                                cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                                key={elements.length} style={stylePoint} onMouseDown={fx2('POLYLINE', [i, j]).bind(this)}
                            />);
                        }
                        else if (polyLineMasks[i][j] > 1) {
                            var index = i - 1,
                                indexj = j - 1;
                            elements.push(<g
                                onContextMenu={fg(p).bind(this)}
                                onMouseDown={fx2('POLYLINE', [i, j]).bind(this)}
                                style={stylePoint}
                                key={elements.length}
                            >
                                <circle
                                    cx={p[0]*scale} cy={p[1]*scale} r="10" stroke="rgb(255,255,255)" strokeWidth="1"
                                    key={elements.length} style={stylePoint}
                                />
                                <text fill="rgb(255,255,255)" fontSize={10} textAnchor='middle' x={p[0]*scale} y={p[1]*scale + 3}>{timeStop}</text>
                            </g>);
                        }
                    }
                }
                if (polyLineMasks.length > 0) {
                    // console.log('>>>>>>>>',polyLineMasks, polyLIne);
                    // console.log('>>>>>>>>', this.shapes.maskPolyline[i][0][0], i, j);
                    if (polyLineMasks[i][0] === 1) {
                        var index = i,
                            indexj = j
                        elements.push(<circle
                            onContextMenu={fg(p0).bind(this)}
                            cx={p0[0]*scale}
                            cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
                            key={elements.length} style={stylePoint}
                            onMouseDown={fx2('POLYLINE', [i, 0]).bind(this)}
                        />);
                    }
                    else if (polyLineMasks[i][0] > 1) {
                        if (polyLineMasks[i][0] <= 30) {
                            var timeStop = polyLineMasks[i][0]
                        }
                        else if (this.shapes.maskPolyline[i][0] >= 30) {
                            var timeStop = polyLineMasks[i][0] / 60
                        }
                        var index = i,
                            indexj = j
                        elements.push(<g
                            onContextMenu={fg(p0).bind(this)}
                            onMouseDown={fx2('POLYLINE', [i, 0]).bind(this)}
                            style={stylePoint}
                            key={elements.length}
                        >
                            <circle
                                cx={p0[0]*scale}
                                cy={p0[1]*scale} r="10" stroke="rgb(255,255,255)" strokeWidth="1"
                                key={elements.length} style={stylePoint}
                            />
                            <text fontSize={10} fill="rgb(255,255,255)" textAnchor='middle' x={p0[0]*scale} y={p0[1]*scale + 3}>{timeStop}</text>
                        </g>);
                    }
                }
            }
            if (this.shapes.stepItems.length > 0) {
                if (this.props.statusMode === 'steps') {
                    // this.shapes.maskPolyline;

                    if (this.shapes.polyLIne.length > 0) {
                        for (var g = 0; g < this.shapes.polyLIne[0].length; g++) {
                            let p = this.shapes.polyLIne[0][g];
                            if (polyLineMasks[g] === 1) {
                                // console.log('for');
                                elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r="5" stroke="rgb(255,255,255)" fill="black" strokeWidth="1"
                                                      key={elements.length} style={stylePoint} />);
                            }

                        }
                        // for (var h = 0; h < this.shapes.polyLIne[0].length; h++) {
                        //     let p = this.shapes.polyLIne[0][h];
                        //     if (polyLineMasks[h] > 1) {
                        //         // console.log('FOR');
                        //         var timeStop = '';
                        //         if (polyLineMasks[h] <= 30) {
                        //             timeStop = polyLineMasks[h] + 'c'
                        //         }
                        //         else if (this.shapes.maskPolyline[h] >= 30) {
                        //             timeStop = polyLineMasks[h] / 60 + 'м'
                        //         }
                        //         console.log('for', timeStop);
                        //         elements.push(
                        //             <g key={elements.length}>
                        //                 <circle cx={p[0]*scale} cy={p[1]*scale} r="15" stroke="rgb(255,255,255)" fill="rgba(0,0,0,.5)" strokeWidth="1"
                        //                         key={elements.length} style={stylePoint} />
                        //                 <text fontSize={10} fill="rgb(255,255,255)" textAnchor='middle' x={p[0]*scale} y={p[1]*scale + 3}>{timeStop}</text>
                        //             </g>);
                        //     }
                        //     // console.log('>>>>>>>>',this.shapes.stepItems[0][f][0], this.shapes.stepItems[0][f][1], this.shapes.stepItems);
                        //
                        // }
                    }
                    // for (var f = 0; f < this.shapes.stepItems.length; f++) {
                    //     // console.log('for', this.shapes.stepItems[f]);
                    //     let p = this.shapes.stepItems[f];
                    //     var color = 'red';
                    //     if (f === this.shapes.stepItems.length - 1) {
                    //         color = 'green';
                    //     }
                    //     if (polyLineMasks[f] === 1) {
                    //         elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r="5" stroke="rgb(255,255,0)" fill={color} strokeWidth="1"
                    //                               key={elements.length} style={stylePoint} onMouseDown={fx('POLYLINE', [i, j]).bind(this)}/>);
                    //     }
                    //
                    //     // console.log('>>>>>>>>',this.shapes.stepItems[0][f][0], this.shapes.stepItems[0][f][1], this.shapes.stepItems);
                    //
                    // }
                    // for (var k = 0; k < this.shapes.stepItems.length; k++) {
                    //     // console.log('for', this.shapes.stepItems[f]);
                    //     let p = this.shapes.stepItems[k];
                    //     // console.log('for', this.shapes.stepItems);
                    //     if (polyLineMasks[k] > 1) {
                    //         if (polyLineMasks[k] <= 30) {
                    //             var rad = 15;
                    //             var timeStop = this.shapes.seconds[k] + 'c'
                    //         }
                    //         else if (this.shapes.maskPolyline[k] >= 30 && this.shapes.maskPolyline[k] <= 60) {
                    //             var rad = 15;
                    //             var timeStop = this.shapes.seconds[k] + 'c'
                    //         }
                    //         else if (this.shapes.maskPolyline[k] > 60) {
                    //             var rad = 30;
                    //             var timeStop = this.shapes.seconds[k] + 'c'
                    //         }
                    //         var color = 'red';
                    //         if (k === this.shapes.stepItems.length - 1) {
                    //             color = 'green';
                    //         }
                    //         elements.push(
                    //             <g key={elements.length} onMouseDown={fx('POLYLINE', [i, j]).bind(this)}>
                    //                 <circle cx={p[0]*scale} cy={p[1]*scale} r={rad} stroke="rgb(255,255,0)" fill={color} strokeWidth="1"
                    //                         key={elements.length} style={stylePoint} />
                    //                 <text fontSize={10} fill="rgb(255,255,255)" textAnchor='middle' x={p[0]*scale} y={p[1]*scale + 3}>{timeStop}</text>
                    //             </g>);
                    //     }
                    //
                    //     // console.log('>>>>>>>>',this.shapes.stepItems[0][f][0], this.shapes.stepItems[0][f][1], this.shapes.stepItems);
                    //
                    // }
                }
            }
            // console.log('for', elements);
            //<<<<<<<<<<<<<<kirdro dev >>>>>>>>>>>>>>>>>>>>>>>>>>>>

            for (var i in this.shapes.points) {
                var p = this.shapes.points[i];
                // console.log('for', this.shapes.globalScale);
                var r = this.shapes.globalScale * 1 * scale;

                if (this.shapes.selectedShape &&
                    this.shapes.selectedShape.shapeType == 'POINT' &&
                    this.shapes.selectedShape.i == String(i)) {
                    r = this.shapes.globalScale * 1 * scale + 5;
                }
                if (mode === 'POINT') {
                    // var ctx = document.getElementById("the-svg"),
                    //     textElm = ctx.getElementById("the-text"),
                    //     SVGRect = textElm.getBBox();
                    //
                    // var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                    // rect.setAttribute("x", SVGRect.x);
                    // rect.setAttribute("y", SVGRect.y);
                    // rect.setAttribute("width", SVGRect.width);
                    // rect.setAttribute("height", SVGRect.height);
                    // rect.setAttribute("fill", "yellow");
                    // ctx.insertBefore(rect, textElm);
                    componentName = <g fill="#000">
                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solid">
                                <feFlood floodColor="rgba(0,0,0,.5)"/>
                                <feComposite in="SourceGraphic"/>
                            </filter>
                        </defs>
                        <text filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={900} fontSize={10} textAnchor='middle' x={p[0]*scale} y={p[1]*scale + 18}>{`${this.shapes.nextProps[i]}`}</text>
                    </g>
                    var fillIPoint = 'rgba(255,255,255,.8)';
                    // console.log("forfor", this.shapes.markerItem);
                    if (this.shapes.markerItem !== null) {
                        if (this.shapes.markerItem.type === 'IPoint') {
                            if (this.shapes.markerItem.marker === this.shapes.nextProps[i]) {
                                fillIPoint = 'rgba(255,0,0,.8)';
                                r = 10;
                            }
                        }
                    }
                    elements.push(<circle onMouseLeave={this.onLeavePOint.bind(this)} onMouseEnter={this.onEnterPoint.bind(this)} cx={p[0]*scale} cy={p[1]*scale} r={r} stroke="rgb(100,183,122)" fill={fillIPoint} strokeWidth="3"
                                          key={elements.length} style={stylePoint} onMouseDown={fx('POINT', [i]).bind(this)}/>);
                    elements.push(<circle  cx={p[0]*scale} cy={p[1]*scale} r={5} stroke="rgb(255,255,255)" fill="rgba(0,0,0,1)" strokeWidth="1"
                                           key={elements.length} style={{pointerEvents: 'none'}} onMouseDown={fx('POINT', [i]).bind(this)}/>);
                    elements.push(componentName);
                }

            }

            for (var i in this.shapes.lines) {
                var l = this.shapes.lines[i];
                var p1 = l[0];
                if (l.length == 2) {
                    var p2 = l[1];

                    var lw = 2;
                    if (this.shapes.selectedShape &&
                        this.shapes.selectedShape.shapeType == 'LINE' &&
                        this.shapes.selectedShape.i == String(i)) {
                        lw = 5
                    }
                    elements.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale}
                                        stroke="rgba(255,165,70,0.9)" key={elements.length} strokeWidth={lw} style={styleLine}
                                        onMouseDown={fshape('LINE', i).bind(this)}/>);
                    elements.push(<circle cx={p2[0]*scale} cy={p2[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1.5"
                                          key={elements.length} style={stylePoint} onMouseDown={fx('LINE', [i, 1]).bind(this)} />);
                    elements.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1.5"
                                          key={elements.length} style={stylePoint} onMouseDown={fx('LINE', [i, 0]).bind(this)} />);
                } else if (l.length == 1) {
                    elements.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={cp[0]*scale} y2={cp[1]*scale}
                                        stroke="rgb(255,0,0)" key={elements.length}/>);
                    elements.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1.5"
                                          key={elements.length} />);
                }
            }
            stylePoint = {
                pointerEvents: 'none',
                cursor: 'default'
            };
            if (this.shapes.stepItems.length === 0) {
                if (this.shapes.coorXPoint !== 0 && this.shapes.coorYPoint !== 0) {
                    if (mode === 'POINT') {
                        // console.log(stylePoint)
                        if (this.shapes.statusEnnterPoints === false) {
                            var r = this.shapes.globalScale * 1 * scale;
                            elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r={r} stroke="rgba(100,183,122,.5)" fill="rgba(255,255,255,.5)" strokeWidth="3"
                                                      key={elements.length} style={stylePoint} />);
                            elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r={5} stroke="rgba(255,255,255,.5)" fill="rgba(0,0,0,.5)" strokeWidth="1"
                                                      key={elements.length} style={stylePoint} />);
                        }
                        else {
                            elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r="4" fill="rgba(0,0,0,.5)" stroke="rgb(255,255,255)" strokeWidth="1.5"
                                                      key={elements.length} style={stylePoint} />);
                        }
                    }
                    else {
                        elementsDraw.push(<circle cx={this.shapes.coorXPoint} cy={this.shapes.coorYPoint} r="4" fill="rgba(0,0,0,.5)" stroke="rgb(255,255,255)" strokeWidth="1.5"
                                                  key={elements.length} style={stylePoint} />);
                    }
                }
            }
            this.shapes.svgElements = elements;

        }
        return {elem:this.shapes.svgElements, elemDraw: elementsDraw};


    }
    onChangeHolst(data) {
        if (JSON.stringify(data) !== JSON.stringify(JSONData)) {
            JSONData = data;
            // console.log('!!!!!!',data, this.state.dataForHolstDraw);
            this.setState({
                dataForHolstDraw: data
            });

        }
    }
    onClickBox(e,r){
        this.shapes.statusContext = false;
        this.shapes.statusContextPoly = false;
        this.shapes.markerItem = null;
        if (this.props.clearMarkerFind !== undefined) {
            this.props.clearMarkerFind(null);
        }
        this.setState({});
    }
    render() {
        // console.log('>>>>>>',this.shapes.selectedPoint);
        var mode = this.props.mode;
        var comonentContext = null;
        var funcOnScale = null;
        var contentContextPoly = null;
        if (this.shapes.statusContextPoly === true) {
            contentContextPoly = <div style={{
                position: 'absolute',
                top: this.shapes.yCoor + 'px',
                left:  `${this.shapes.xCoor}px`
            }} id="boxContext"
            >
                <div onClick={(e) => this.onClickContextMenuPoly(e, `delete`)}>Удалить</div>
                <div onClick={(e) => this.onClickContextMenuPoly(e, `addRight`)}>Добавить Правее</div>
                <div onClick={(e) => this.onClickContextMenuPoly(e, `addLeft`)}>Добавить левее</div>
            </div>
        }
        if (this.props.onScale !== undefined) {
            funcOnScale = this.props.onScale
        }
        if (this.shapes.statusContext === true) {
            comonentContext = <div style={{
                position: 'absolute',
                top: this.shapes.yCoor + 'px',
                left:  `${this.shapes.xCoor}px`
            }} id="boxContext"
            >
                <div onClick={(e) => this.onClickContext(e, 1)}>1сек</div>
                <div onClick={(e) => this.onClickContext(e, 20)}>20сек</div>
                <div onClick={(e) => this.onClickContext(e, 30)}>30сек</div>
                <div onClick={(e) => this.onClickContext(e, 60)}>1минута</div>
                <div onClick={(e) => this.onClickContext(e, 120)}>2минут</div>
                <div onClick={(e) => this.onClickContext(e, 180)}>3минут</div>
                <div onClick={(e) => this.onClickContext(e, 300)}>5минут</div>
            </div>
        }
        // console.log(this.shapes.coordArr)
        return (
            <div id="boxBox"
                 onClick={this.onClickBox.bind(this)}
            >
                <div className="boxCoors">
                    x = {this.shapes.coordArr[0]} <br/>
                    y = {this.shapes.coordArr[1]}
                </div>
                {comonentContext}
                {contentContextPoly}
                <Holst image={this.state.image}
                       ref='xnjnj'
                       elem={this.state.polyLIne}
                       onScale={funcOnScale}
                       button={this.props.button}
                       width={this.state.width} height={this.state.height}
                       holstOnClick={this.holstOnClick.bind(this)}
                       holstMouseMove={this.holstMouseMove.bind(this)}
                       holstMouseUp={this.holstMouseUp.bind(this)}
                       getSvgElements={this.getSvgElements.bind(this)}
                       getSvgElementsDraw={this.getSvgElementsForDraw.bind(this)}
                       trashClick={this.trashClick.bind(this)}
                       dataForHolstDraw={this.onChangeHolst.bind(this)}
                       selectSpeed={this.props.selectSpeed}
                       timeStepItem={this.props.timeStepItem}
                />

                {/*<HolstDraw*/}
                    {/*data={this.state.dataForHolstDraw}*/}
                {/*/>*/}

            </div>
        )
    }
}


var scaleJson = 0;
export class Holst extends React.Component {
    constructor(props) {
        super(props);

        var img = new Image();

        this.state = {
            imUrl: this.props.image ? this.props.image : '',
            imWidth: 0,
            imHeight: 0,
            width: this.props.width ? parseFloat(this.props.width) : 400,
            height: this.props.height ? parseFloat(this.props.height) : 400,
            scale: 0,
            clearId: 0,
            className: 'First',
            svgElements: this.props.getSvgElements ?
                this.props.getSvgElements(0) : null,
            getSvgElementsDraw: this.props.getSvgElementsDraw ? this.props.getSvgElementsDraw(0) : null
        }

        img.onload = function(){
            var imHeight = img.height;
            var imWidth = img.width;
            // this.setState({
            // // 	imWidth: imWidth,
            // // 	imHeight: imHeight,
            // // 	scale: Math.min(this.state.width, this.state.height) /
            // // 		Math.max(imWidth, imHeight) * 0.9
            // // });
            this.setState({
                imWidth: imWidth,
                imHeight: imHeight,
                scale: this.createScale(imHeight, imWidth, this.state.height, this.state.width)
            });

        }.bind(this);

        img.src = props.image;
    }
    createScale(imgHeight, imgWidth, boxHeight, boxWidth){
        var scaleCreate = 0;
        if ((imgWidth / imgHeight) > (boxWidth / boxHeight)) {
            scaleCreate = boxWidth / imgWidth * 0.9;
        }
        else if ((imgWidth / imgHeight) <= (boxWidth / boxHeight)) {
            scaleCreate = boxHeight / imgHeight * 0.9;
        }
        // console.log('>>>>>>>>>>>!!!!',boxWidth);
        return scaleCreate;
    }
    componentWillUpdate(nextProps, nextState) {
        // console.log('>>>>>>>>>>>!!!!HolstcomponentWillUpdate', nextState.scale);
        if (JSON.stringify(this.props.getSvgElements(nextState.scale))
            !== JSON.stringify(this.state.svgElements)
            && this.state.imUrl === this.props.image) {
            // console.log('>>>>>>>>>>>!!!!HolstcomponentWillUpdate1');
            // console.log('>>>>>>>>>>>!!!!', this.createScale(imHeight, imWidth, this.state.height, this.state.width), this.state.scale);
            // console.log('>>>>>>>>>>>!!!!HolstcomponentWillUpdate1111');
            this.setState({
                svgElements: nextProps.getSvgElements ?
                    nextProps.getSvgElements(nextState.scale) : null
            });
            scaleJson = this.state.scale;

        }
        if (JSON.stringify(this.props.getSvgElementsDraw(nextState.scale))
            !== JSON.stringify(this.state.getSvgElementsDraw)
            && this.state.imUrl === this.props.image) {
            // console.log('>>>>>>>>>>>!!!!HolstcomponentWillUpdate2', JSON.stringify(this.props.getSvgElementsDraw(nextProps.scale)), JSON.stringify(this.state.getSvgElementsDraw));
            // console.log('>>>>>>>>>>>!!!!HolstcomponentWillUpdate22222');
            scaleJson = this.state.scale;
            this.setState({
                getSvgElementsDraw: nextProps.getSvgElementsDraw ?
                    nextProps.getSvgElementsDraw(nextState.scale) : null
            });
        }
        var objForHolstDraw = {
            box: null,
            boxSvg: null,
            svg: null
        };
        if (document.getElementById('holst_box') !== null) {
            // console.log('>>>>>>>>>>>!!!!HolstcomponentWillUpdate3');
            var borderPadding = 3;
            var zoomPadding = Math.max(0, nextState.height - nextState.imHeight * nextState.scale) / 2. - borderPadding;
            objForHolstDraw = {
                box: {
                    padding: borderPadding + 'px',
                    width: '100%',
                    height: nextState.height + 'px',
                    top: `200px`,
                    left: `3px`,
                    position: 'absolute',
                    overflow: 'auto'
                },
                boxSvg: {
                    width: '100%',
                    height: nextState.height * borderPadding * 2 + "px",
                    textAlign: 'center',
                    overflow: 'auto',
                    padding: zoomPadding + 'px ' + 0 + 'px'
                },
                svg: {
                    width: nextState.imWidth * nextState.scale + "px",
                    height: nextState.imHeight * nextState.scale + "px",
                }
            }
        }
        // console.log('!@!#!@3123123', nextProps.image);
        this.props.dataForHolstDraw(objForHolstDraw);
        if (this.state.imUrl !== nextProps.image) {
            // console.log('>>>>>>>>>>>!!!!HolstcomponentWillUpdate4');
            var img = new Image();
            img.onload = function(){
                var imHeight = img.height;
                var imWidth = img.width;
                this.setState({
                    imWidth: imWidth,
                    imHeight: imHeight,
                    imUrl: nextProps.image,
                    scale: this.createScale(imHeight, imWidth, nextProps.height, nextProps.width),
                    svgElements: nextProps.getSvgElements ?
                        nextProps.getSvgElements(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null,
                    getSvgElementsDraw: nextProps.getSvgElementsDraw ?
                        nextProps.getSvgElementsDraw(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null
                });


                // console.log('>>>>>>>>>>>!!!!',imHeight,imWidth,nextProps.height, nextProps.width, this.createScale);

            }.bind(this);

            img.src = nextProps.image;
        }
    }
    componentDidUpdate(prevProps, prevState, prevContext) {
        // console.log('>>>>>>>>>>>!!!!HolstcomponentDidUpdate');
        if (this.props.width !== prevProps.width || this.props.height !== prevProps.height) {
            // console.log('holst')
            // console.log('>>>>>>>>>>>!!!!HolstcomponentDidUpdate111111');
            this.setState({
                width: this.props.width ? parseFloat(this.props.width): 400 ,
                height: this.props.height ? parseFloat(this.props.height): 400,
                svgElements: this.props.getSvgElements ?
                    this.props.getSvgElements(0) : null,
                getSvgElementsDraw: this.props.getSvgElementsDraw ?
                    this.props.getSvgElementsDraw(0): null
            });
        }
        // var objForHolstDraw = null;
        // if (document.getElementById('holst_box') !== null) {
        //     var objForHolstDraw = {
        //         width: this.state.imWidth * this.state.scale + "px",
        //         height: this.state.imHeight * this.state.scale + "px",
        //         top: document.getElementById('holst_box').pageY,
        //         left:document.getElementById('holst_box').pageX,
        //     }
        // }
        // this.props.dataForHolstDraw(objForHolstDraw);
        // if (this.props.showLabels !== prevProps.showLabels || this.props.showCounts !== prevProps.showCounts || prevProps.image === this.props.image) {
        //     this.setState({
        //         svgElements: this.props.getSvgElements ?
        //             this.props.getSvgElements(0) : null
        //     });
        //
        // }
        var img = new Image();
        img.src = this.props.image;
        var imHeight = img.height;
        var imWidth = img.width;
        // console.log('>>>>>>>>>>>!!!!', JSON.stringify(prevProps) === JSON.stringify(this.props));
        if (JSON.stringify(prevProps.getSvgElements(this.state.scale))
            !== JSON.stringify(this.props.getSvgElements(this.state.scale))
            && this.state.imUrl === this.props.image) {
            // console.log('>>>>>>>>>>>!!!!HolstcomponentDidUpdate2222');
            // console.log('>>>>>>>>>>>!!!!', this.createScale(imHeight, imWidth, this.state.height, this.state.width), this.state.scale);
            this.setState({
                svgElements: this.props.getSvgElements ?
                    this.props.getSvgElements(this.state.scale) : null
            });
            scaleJson = this.state.scale;

        }

    }

    componentWillReceiveProps(nextProps) {
        var img = new Image();
        img.onload = function(){
            var imHeight = img.height;
            var imWidth = img.width;
            if (this.state.svgElements === null) {
                // this.setState({
                //     imWidth: imWidth,
                //     imHeight: imHeight,
                //     imUrl: nextProps.image,
                //     scale: Math.min(nextProps.width, nextProps.height) /
                //     Math.max(imWidth, imHeight) * 0.9,
                //     svgElements: nextProps.getSvgElements ?
                //         nextProps.getSvgElements(Math.min(nextProps.width, nextProps.height) /
                //             Math.max(imWidth, imHeight) * 0.9) : null,
                //     className: 'First'
                // });
                this.setState({
                    imWidth: imWidth,
                    imHeight: imHeight,
                    imUrl: nextProps.image,
                    scale: this.createScale(imHeight, imWidth, nextProps.height, nextProps.width),
                    svgElements: nextProps.getSvgElements ?
                        nextProps.getSvgElements(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null,
                    className: 'First'
                });


            }
            if (this.state.getSvgElementsDraw === null) {
                this.setState({
                    imWidth: imWidth,
                    imHeight: imHeight,
                    imUrl: nextProps.image,
                    scale: this.createScale(imHeight, imWidth, nextProps.height, nextProps.width),
                    getSvgElementsDraw: nextProps.getSvgElementsDraw ?
                        nextProps.getSvgElementsDraw(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null,
                    className: 'First'
                });
            }
            if (this.state.imUrl != nextProps.image) {
                if (imageUrl !== nextProps.image) {
                    imageUrl = nextProps.image;
                    this.setState({
                        className: 'First'
                    })
                    // this.timeout = setTimeout(
                    //     () => {
                    //         // console.log('holst', this.props.getSvgElements);
                    //         this.setState({
                    //             imWidth: imWidth,
                    //             imHeight: imHeight,
                    //             imUrl: nextProps.image,
                    //             scale: this.createScale(imHeight, imWidth, nextProps.height, nextProps.width),
                    //             svgElements: nextProps.getSvgElements ?
                    //                 nextProps.getSvgElements(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null,
                    //             className: 'First'
                    //         });
                    //     },
                    //     300
                    // );
                }
                else {
                    this.timeout = setTimeout(
                        () => {
                            // console.log('holst', this.props.getSvgElements);
                            this.setState({
                                imWidth: imWidth,
                                imHeight: imHeight,
                                imUrl: nextProps.image,
                                scale: this.createScale(imHeight, imWidth, nextProps.height, nextProps.width),
                                svgElements: nextProps.getSvgElements ?
                                    nextProps.getSvgElements(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null,
                                className: 'First'
                            });
                        },
                        300
                    );
                }
            }
        }.bind(this);

        img.src = nextProps.image;
    }
    _getCoords(e) {
        // console.log()
        var holst = document.getElementById('holst_svg')
        var holst = ReactDOM.findDOMNode(this.refs.holst);
        var dim = holst.getBoundingClientRect();
        var x = (e.clientX - dim.left);
        var y = (e.clientY - dim.top);
        return [x, y];
    }
    svgOnClick(e) {
    }
    svgOnMouseMove(e) {
        if (this.props.holstMouseMove) {
            var p = this._getCoords(e);
            this.props.holstMouseMove(p[0], p[1], this.state.scale);
            this.setState({});
        }
        e.preventDefault()
    }
    svgOnMouseUp(e) {
        if (this.props.holstOnClick) {
            var p = this._getCoords(e);
            this.props.holstOnClick(p[0], p[1], this.state.scale, e);
            this.setState({});
        }
        if (this.props.holstMouseUp) {
            var p = this._getCoords(e);
            this.props.holstMouseUp(p[0], p[1], this.state.scale, e);
            this.setState({});
        }
    }
    svgOnMouseDown(e) {
        if (this.props.holstMouseDown) {
            var p = this._getCoords(e);
            this.props.holstMouseDown(p[0], p[1], this.state.scale);
            this.setState({});
        }
        e.preventDefault()
    }
    scalePlus(scale) {
        var data = this.state.scale * 1.1;
        // console.log('>>>>>>>>>');
        this.setState({
            scale: this.state.scale * 1.1,
            svgElements: this.props.getSvgElements ?
                this.props.getSvgElements(data) : null,
            getSvgElementsDraw: this.props.getSvgElementsDraw ?
                this.props.getSvgElementsDraw(data) : null
        })


    }
    scaleMinus(scale){
        var data = this.state.scale / 1.1;
        this.setState({
            scale: this.state.scale / 1.1,
            svgElements: this.props.getSvgElements ?
                this.props.getSvgElements(data) : null,
            getSvgElementsDraw: this.props.getSvgElementsDraw ?
                this.props.getSvgElementsDraw(data) : null
        })

    }
    render() {
        // console.log('render');
        var imW = this.state.imWidth;
        var imH = this.state.imHeight;
        var scale = this.state.scale;

        var borderPadding = 3;
        var zoomPadding = Math.max(0, this.state.height - imH * scale) / 2. - borderPadding;

        var svgElements = this.state.svgElements;
        var getSvgElementsDraw = this.state.getSvgElementsDraw
        // width: imW * scale + "px",
        //     height: imH * scale + "px",
        // console.log('holst');
        var componentButtom = null;
        if (this.props.button !== undefined) {
            // componentButtom = <g onClick={this.props.button.func} style={{cursor: 'pointer'}}>
            //     <rect rx='3' ry='3' width={100} stroke="rgba(0,0,0,.5)" height={36} fill="rgba(0,255,0,.5)" x={this.props.button.coorX * scale + 5} y={this.props.button.coorY * scale + 5}>
            //     </rect>
            //     <text textAnchor='middle' fontSize={20} x={this.props.button.coorX * scale + 50 + 5} y={this.props.button.coorY * scale + 25 + 5}>{this.props.button.nameButton}</text>
            // </g>
            // componentButtom =  <div className="buttonStartBox">
            //     <button className="plus" onClick={() => {this.props.selectSpeed('+')}}>+</button>
            //     <div>{`${this.props.timeStepItem} км/ч`}</div>
            //     <button className="minus" onClick={() => {this.props.selectSpeed('-')}}>-</button>
            //     <button className="start" onClick={this.props.button.func} >{this.props.button.nameButton}</button>
            // </div>

        }
        // var objForHolstDraw = {
        //     width: imW * scale + "px",
        //     height: imH * scale + "px"
        // }
        // this.props.dataForHolstDraw(objForHolstDraw);
        if (this.props.statusShowImageBackground === true) {
            var urlImage = this.state.imUrl
        }
        else if (this.props.statusShowImageBackground === false) {
            var urlImage = ''
        }
        // console.log('>>>>>>>>>>startTimer', imW);
        var holstBox = (
            <div id="holst_box" className={this.state.className} style={{
                backgroundImage: "url(" + urlImage + ")",
                backgroundSize: "contain",
                backgroundColor: "rgba(0,0,0,0.0)",
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                width: imW * scale + "px",
                height: imH * scale + "px",
                margin: 'auto',
                verticalAlign: 'middle',
                display: 'inline-block',
                border: '0.1px solid rgba(0,0,0,0.05)',
                position: 'relative'
            }}>
                {componentButtom}
                {/*<div className="buttonStartBox">*/}
                    {/*<button className="plus"></button>*/}
                    {/*<input type="text"/>*/}
                    {/*<button className="minus"></button>*/}
                    {/*<button className="start" onClick={this.props.button.func} >{this.props.button.nameButton}</button>*/}
                {/*</div>*/}

                {/*<svg ref="holst" id='holst_svg' height="100%" width="100%"*/}
                     {/*onMouseUp={this.svgOnMouseUp.bind(this)}*/}
                     {/*onMouseDown={this.svgOnMouseDown.bind(this)}*/}
                     {/*onMouseMove={this.svgOnMouseMove.bind(this)}*/}
                     {/*style={{*/}
                         {/*margin: 'auto'*/}
                     {/*}}>*/}
                    {/*<defs>*/}
                        {/*<filter id="MyFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="200" height="120">*/}
                            {/*<feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur"/>*/}
                            {/*<feOffset in="blur" dx="4" dy="4" result="offsetBlur"/>*/}
                            {/*<feSpecularLighting in="blur" surfaceScale="5" specularConstant=".75"*/}
                                                {/*specularExponent="20" lightingColor="#bbbbbb"*/}
                                                {/*result="specOut">*/}
                                {/*<fePointLight x="-5000" y="-10000" z="20000"/>*/}
                            {/*</feSpecularLighting>*/}
                            {/*<feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut"/>*/}
                            {/*<feComposite in="SourceGraphic" in2="specOut" operator="arithmetic"*/}
                                         {/*k1="0" k2="1" k3="1" k4="0" result="litPaint"/>*/}
                            {/*<feMerge>*/}
                                {/*<feMergeNode in="offsetBlur"/>*/}
                                {/*<feMergeNode in="litPaint"/>*/}
                            {/*</feMerge>*/}
                        {/*</filter>*/}
                    {/*</defs>*/}
                    {/*{svgElements}*/}
                    {/*{this.props.button === undefined ? null : componentButtom}*/}
                {/*</svg>*/}

                <Holst2
                    ref="holst"
                    svgElements={svgElements.elem}
                    // componentButtom={this.props.button === undefined ? null : componentButtom}
                    buttonData={this.props.button}
                    onMouseUp={this.svgOnMouseUp.bind(this)}
                    onMouseDown={this.svgOnMouseDown.bind(this)}
                    onMouseMove={this.svgOnMouseMove.bind(this)}
                />
                <HolstDraw
                    svgElements={getSvgElementsDraw.elemDraw}
                />

            </div>
        )

        var iconStyle = {
            color: 'rgba(255,255,255,1)',
            cursor: 'pointer'
        }

        var showTrash = this.props.trashClick ? true : false;


        if (this.props.statusEdit === false) {
            showTrash = false;
        }

        return (
            <div>
                <div
                    className="holstBox"
                    style={{
                        padding: borderPadding + 'px',
                        width: '100%',
                        height: this.state.height + 'px',
                        // boxShadow: 'inset 0px 0px 6px rgba(0,0,0,0.5)',
                        // backgroundColor: 'rgba(0,0,0,0.06)',
                        position: 'relative',
                        margin: 'auto'
                    }}>

                    <div style={{
                        position: 'absolute',
                        left: '12px',
                        top: '242px',
                        borderRadius: '6px',
                        padding: '0px 4px',
                        textShadow: '0px 0px rgba(0,0,0,0.4)',
                        background: `rgba(0,0,0,.2)`,
                        zIndex: 1
                    }}>

                        {showTrash ? (
                            <div onClick={function(e){
                                if (this.props.trashClick) {
                                    this.props.trashClick()
                                    this.setState({});
                                }
                            }.bind(this)}>
                                <i id="mobile" className="fa fa-trash-o fa-3x fa-inverse"
                                   style={iconStyle}></i>
                            </div>) : null}

                        <div onClick={this.scalePlus.bind(this)}>
                            <span id="mobile" className="iconButton"
                               style={iconStyle}>+</span>
                        </div>

                        <div onClick={this.scaleMinus.bind(this)}>
                            <span id="mobile" className="iconButton"
                               style={iconStyle}>-</span>
                        </div>

                    </div>

                    <div style={{
                        width: '100%',
                        height: this.state.height - borderPadding*2 + 'px',
                        textAlign: 'center',
                        overflow: 'auto',
                        padding: zoomPadding + 'px ' + 0 + 'px',
                        background: 'rgb(255,255,255)'
                    }}>

                        {this.state.scale > 0 ? holstBox : null}

                    </div>

                </div>
            </div>
        )
    }
}


//holstbaground
class Holst2 extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // console.log('Holst2shouldComponentUpdate', JSON.stringify(nextProps.svgElements) !== JSON.stringify(this.props.svgElements), JSON.stringify(this.props.buttonData) !== JSON.stringify(nextProps.buttonData))
        if (JSON.stringify(nextProps.svgElements) !== JSON.stringify(this.props.svgElements)) {
            // console.log('>>>>>>>>3', JSON.stringify(nextProps.svgElements), JSON.stringify(this.props.svgElements))
            return true;
        }
        if (JSON.stringify(this.props.buttonData) !== JSON.stringify(nextProps.buttonData)) {
            // console.log('>>>>>>>>6')
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        console.log('holst2');
        return (
            <svg ref="holst" id='holst_svg' height="100%" width="100%"
                 onMouseUp={this.props.onMouseUp.bind(this)}
                 onMouseDown={this.props.onMouseDown.bind(this)}
                 onMouseMove={this.props.onMouseMove.bind(this)}
                 style={{
                     margin: 'auto'
                 }}>
                <defs>
                    <filter id="MyFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="200" height="120">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur"/>
                        <feOffset in="blur" dx="4" dy="4" result="offsetBlur"/>
                        <feSpecularLighting in="blur" surfaceScale="5" specularConstant=".75"
                                            specularExponent="20" lightingColor="#bbbbbb"
                                            result="specOut">
                            <fePointLight x="-5000" y="-10000" z="20000"/>
                        </feSpecularLighting>
                        <feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut"/>
                        <feComposite in="SourceGraphic" in2="specOut" operator="arithmetic"
                                     k1="0" k2="1" k3="1" k4="0" result="litPaint"/>
                        <feMerge>
                            <feMergeNode in="offsetBlur"/>
                            <feMergeNode in="litPaint"/>
                        </feMerge>
                    </filter>
                </defs>
                {this.props.svgElements}
                {this.props.componentButtom}
            </svg>
        );
    }
}

// holst.propTypes = {};
// holst.defaultProps = {};


