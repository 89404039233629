/**
 * Created by kirilldrozdov on 03.02.17.
 */
var React = require('react');
var ListAuth_schemes = React.createClass({
    render: function () {
        return(
            <div>
                В отчете отображаются использованные вами за период схемы авторизации пользователей при подключении к сети WIFI.
                Каждый прямоугольник схемы является этапом процесса авторизации. В нашей системе доступны следующие способы авторизации:
                <ul>
                    <li>'sms' - СМС авторизация</li>
                    <li>'button' - кнопка “Войти в интернет</li>
                    <li>'vkontakte' - авторизация через Вконтакте</li>
                    <li>'facebook' - авторизация через Фейсбук</li>
                    <li>'odnoklassniki' - авторизачия через Одноклассники</li>
                    <li>'password' - авторизация по паролю</li>
                    <li>'external' - внешняя авторизация</li>
                </ul>
                На вкладке 'Управление страницами' вы можете настроить различные схемы авторизации, как одношаговые альтернативные (пользователю предлагается выбрать один способ авторизации из списка, например авторизоваться по какой-либо социальной сети, либо ввести номер телефона), так и сложные многошаговые авторизации (пользователь должен авторизоваться несколькими способами).
                На каждом этапе пользователь может прекратить авторизацию. Эта статистика как раз показана в этом отчете.
            </div>
        );
    }
});

var ListUnique_wifi_clients_per_day = React.createClass({
    render: function () {
        return(
            <div>
                Диаграмма показывает количество пользователей, которые инициировали подключение к сети WIFI
                (показатель “Все пользователи”). И из них количество пользователей, закончивших процесс авторизации
                (показатель “Прошедшие авторизацию”)
                .При этом если один пользователь подключался в течении дня несколько раз, в этой диаграмме он будет
                считаться все равно как один пользователь.
                Если же один и тот же пользователь подключался каждый день, он будет учтен в каждом дне.
            </div>
        );
    }
});

var ListConnections_per_day = React.createClass({
    render: function () {
        return(
            <div>
                Диаграмма показывает количество инициированных пользователями подключений
                (показатель “Запуски подключений”) к сети WIFI и сколько этих подключений завершились
                успешно пройденными авторизациями (показатель “Пройденные авторизации”). Под инициацией
                подключения подразумевается выбор пользователем сети WIFI заведения, за которым следует
                автоматическая загрузка стартовой страницы. На стартовой странице пользователь видит базовую
                информацию о вашей локации и ему предлагается пройти процесс авторизации.
                Одним пользователем в течении дня может быть запущена стартовая страница несколько раз.
                В том числе это может происходить из-за устанавливаемой вами максимально допустимой длины сессии пользователя в сети WIFI.
            </div>
        );
    }
});
var translation = {

    "connections": {
        "unique_wifi_clients_per_day": {
            "title": "ДИНАМИКА УНИКАЛЬНЫХ ПОЛЬЗОВАТЕЛЕЙ WIFI ПО ДНЯМ",
            "body":<ListUnique_wifi_clients_per_day/>
        },
        "connections_per_day": {
            "title": "ДИНАМИКА ПОДКЛЮЧЕНИЙ К СЕТИ WIFI",
            "body":<ListConnections_per_day/>
        },
        "auth_schemes": {
            "title": "СТАТИСТИКА ПО СХЕМАМ АВТОРИЗАЦИИ",
            "body": <ListAuth_schemes/>
        }


    }

};
module.exports = translation;
