var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var FixedDataTable = require('fixed-data-table');
var Table = FixedDataTable.Table;
var Column = FixedDataTable.Column;
var Cell = FixedDataTable.Cell;

Date.prototype.ddmmyyy = function() {
	var yyyy = this.getFullYear().toString();
	var mm = (this.getMonth() + 1).toString();
	var dd = this.getDate().toString();
	return (dd[1] ? dd : "0" + dd[0]) + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + yyyy;
};

var CellItem = React.createClass({
	render: function () {
		// var mode;
		if (this.props.mode === 'MallId') {
			var dataMode = this.props.data[this.props.rowIndex][0];
		}
		else if (this.props.mode === 'Phone') {
			var dataMode = this.props.data[this.props.rowIndex].phone;
		}
		else if (this.props.mode === 'sms') {
			return (
				<span className='radiusSmsCount'>{this.props.data[this.props.rowIndex].numberSms}</span>
			);
			// var dataMode = this.props.data[this.props.rowIndex].numberSms;
		}
		else if (this.props.mode === 'confirmed') {
			if (this.props.data[this.props.rowIndex].confirmed === true) {
				var className = 'confirmedTrue';
			}
			else if (this.props.data[this.props.rowIndex].confirmed === false) {
				var className = 'confirmedFalse';
			}
			return (
				<Cell>
			        <div className={className}></div>
            	</Cell>
			);
		}
		else if (this.props.mode === 'avatarSocial') {
			var className = null;
			if (this.props.data[this.props.rowIndex][8] === 'vkontakte') {
				className = 'radiusProvaderVkontakte';
			}
			else if (this.props.data[this.props.rowIndex][8] === 'facebook') {
				className = 'radiusProvaderFacebook';
			}
			else if (this.props.data[this.props.rowIndex][8] === 'odnoklassniki') {
				className = 'radiusProvaderOdnoklassniki';
			}
			return (
				<Cell>
              		<img style={{marginLeft: '11px', borderRadius: '50%'}} src={this.props.data[this.props.rowIndex][0]} />
              		<a target="_blank" className='radiusanaliticsAvatarLink' href={this.props.data[this.props.rowIndex][7]} >
              			<span className={className}></span>
              		</a>
            	</Cell>
			);
		}
		else if (this.props.mode === 'nameSocial') {
			return (
				<Cell>
              		<a target="_blank" href={this.props.data[this.props.rowIndex][7]} >{this.props.data[this.props.rowIndex][1]}</a>
            	</Cell>
			);
		}
		else if (this.props.mode === 'birthday') {
			var dataMode = this.props.data[this.props.rowIndex][2];
		}
		else if (this.props.mode === 'email') {
			var dataMode = this.props.data[this.props.rowIndex][3];
		}
		else if (this.props.mode === 'firstTimeSocial') {
			var firstTime;
			var minTime = +new Date(this.props.data[this.props.rowIndex][4][0]);
			for (var i = 0; i < this.props.data[this.props.rowIndex][4].length; i++) {
				if (minTime > +new Date(this.props.data[this.props.rowIndex][4][i])) {
					minTime = +new Date(this.props.data[this.props.rowIndex][4][i]);
				}
			}
			firstTime = new Date(minTime);

			var options = {
			  year: 'numeric',
			  month: 'long',
			  day: 'numeric',
			  weekday: 'long',
			  timezone: 'UTC',
			  hour: 'numeric',
			  minute: 'numeric',
			  second: 'numeric'
			};
			var dataMode = firstTime.toLocaleString("ru", options);
		}
		else if (this.props.mode === 'lastTimeSocial') {
			var lastTime;
			var maxTime = +new Date(this.props.data[this.props.rowIndex][4][0]);
			for (var i = 0; i < this.props.data[this.props.rowIndex][4].length; i++) {
				if (maxTime < +new Date(this.props.data[this.props.rowIndex][4][i])) {
					maxTime = +new Date(this.props.data[this.props.rowIndex][4][i]);
				}
			}
			lastTime = new Date(maxTime);

			var options = {
			  year: 'numeric',
			  month: 'long',
			  day: 'numeric',
			  weekday: 'long',
			  timezone: 'UTC',
			  hour: 'numeric',
			  minute: 'numeric',
			  second: 'numeric'
			};

			var dataMode = lastTime.toLocaleString("ru", options);
		}
		else if (this.props.mode === 'numberOfConnections') {
			var dataMode = this.props.data[this.props.rowIndex][4].length;
		}
		else if (this.props.mode === 'friendsCount') {
			var dataMode = this.props.data[this.props.rowIndex][5];
		}
		return (
			<Cell className='radusAnaliticsCellTable' >
              {dataMode}
            </Cell>
		);
	}
});

module.exports = CellItem;