var AppDispatcher = require('../dispatcher/dispatcher.js');
var AppConstants = require('../constants/constants.js');
var Api = require('../api/api.js');
var MainStore = require('../../../../mainStores/Store.js');
var AppStores = require('../stores/stores.js');
var DateYYYY_MM_DD = require('../tools/date.js');
var GeometryVSDataManager = require('../../../tools/GeometryVSDataManager');
// import GeometryVSDataManager from '../../../tools/GeometryVSDataManager';

Date.prototype.yyyymmdd = function() {
	var yyyy = this.getFullYear().toString();
	var mm = (this.getMonth() + 1).toString();
	var dd = this.getDate().toString();
	return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]);
};

var selectObjects = 0;
var JSONState = 0;
var JSONStateItem = 0;
var MapsJson = 0;
var TransitionJson = 0;
var AreaJson = 0;
var IdMall = 0;
var globalState;

var obj = {
    isSetZone: false,
    isSetFloor: false,
    isSetRoom: false
}
var dataGlobal1;


var AppActions = {
	setMaps: function (state) {
        // console.log('actions-setMaps');
        if (MapsJson !== state) {
            MapsJson = JSON.stringify(state);
            Api.createFloorsLink(state,
                function (data) {
                    // console.log('actions?????????');
                    // console.log('actions-setMaps-load');
                    AppDispatcher.handleViewAction({
                        actionType:AppConstants.SET_MAPS,
                        data: data
                    })
                },
                function (err) {
                    console.error(err);
                }
            );
        }
	},
	setTransition: function (state) {
        // console.log('actions-setTransition');
        var status = true;
        // console.log('actions!@#!@@#!@#!@');
        Api.createTransition(state,
            function (data) {
                // console.log('actions-setTransition-load');
                // console.log('!@#!@@#!@#!@', data);
                AppDispatcher.handleViewAction({
                    actionType:AppConstants.SET_TRNSITION,
                    data: data,
                    status: status
                })
            }.bind(AppActions),
            function (err) {
                console.error('error',err);
                if (err !== 'abort' && err !== '') {
                    // alert(err);
                }
            }
        );
	},
	setArea: function (state) {
        // console.log('actions-setArea');
		// console.log('actionsArea', state.selectedObjects);
        AreaJson;
        AppDispatcher.handleViewAction({
            actionType:AppConstants.CLEAR_DATA_ROUTE
        })
        Api.getArea(state,
            function (data) {
                // console.log('actions-setArea-load');
                AppDispatcher.handleViewAction({
                    actionType:AppConstants.SET_AREA,
                    data: data
                })
            }.bind(AppActions),
            function (err) {
                console.error('error',err);
                if (err !== 'abort' && err !== '') {
                    alert(err);
                }
            }
        );
	},
	getFloorId: function (id) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.GET_FLOOR_ID,
			id: id
		})
	},
	getSelectZone: function (data) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SET_SELECT_ZONE,
			data: data
		})
	},
	getSelectZoneInRouter: function (data) {
        AppDispatcher.handleViewAction({
            actionType:AppConstants.GET_SELECT_ZONE_IN_ROUTER,
            data: data
        })
    },
	selectedNewMall: function (id) {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.SELECTED_NEW_MALL,
			id: id
		})
	},
	deactivate: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.DEACTIVE_SELECT_ZONE
		})
	},
	getStatusLoadTransition: function () {
		AppDispatcher.handleViewAction({
			actionType:AppConstants.GET_STATUS_LOAD_TRANSITION
		})
	},
	update: function (state) {
        // console.log('actions>>>>', state);
        globalState = state;
        if (state.commonState.insetName !== undefined) {
            // console.log('actions>>>>');
            if (AppStores.getState().mainState.commonState === null) {
                // 1 сначала
                AppDispatcher.handleViewAction({
                    actionType:AppConstants.UPDATE_MAIN_STATE,
                    state:state
                });
                AppActions.getSelectZone({indexZone: null});
                AppActions.getPLId(state)
                // AppActions.setRooms(state)
                // AppActions.setFloors(state);
                // AppActions.setZones(state)
                AppActions.setMaps(state);
                AppActions.getStatusLoadTransition();
                AppActions.setTransition(state);
                AppActions.getThreshold(state);
                AppActions.setArea(state);
                // if (IdMall !== state.panelState.dataMall[0].id) {
                //     IdMall = state.panelState.dataMall[0].id;
                //     // console.log('actions>>>>', state.panelState.dataMall[0].id);
                //     AppActions.selectedNewMall(state.panelState.dataMall[0].id);
                // }
                // AppActions.selectedNewMall(false);
            }
            else if (AppStores.getState().mainState.commonState !== null && AppStores.getState().mainState.max_timestamp !== undefined && AppStores.getState().mainState.min_timestamp !== undefined) {
                if (AppStores.getState().mainState.max_timestamp.yyyymmdd() !== state.commonState.max_timestamp.yyyymmdd()) {
                    AppDispatcher.handleViewAction({
                        actionType:AppConstants.UPDATE_MAIN_STATE,
                        state:state
                    });
                    AppActions.getSelectZone({indexZone: null});
                    AppActions.getPLId(state)
                    // AppActions.setRooms(state)
                    // AppActions.setFloors(state);
                    // AppActions.setZones(state)
                    AppActions.setMaps(state);
                    AppActions.getStatusLoadTransition();
                    AppActions.setTransition(state);
                    AppActions.getThreshold(state);
                    AppActions.setArea(state);
                    // if (IdMall !== state.panelState.dataMall[0].id) {
                    //     IdMall = state.panelState.dataMall[0].id;
                    //     // console.log('actions>>>>', state.panelState.dataMall[0].id);
                    //     AppActions.selectedNewMall(state.panelState.dataMall[0].id);
                    // }
                    // AppActions.selectedNewMall(false);
                }
                if (AppStores.getState().mainState.min_timestamp.yyyymmdd() !== state.commonState.min_timestamp.yyyymmdd()) {
                    AppDispatcher.handleViewAction({
                        actionType:AppConstants.UPDATE_MAIN_STATE,
                        state:state
                    });
                    AppActions.getSelectZone({indexZone: null});
                    AppActions.getPLId(state)
                    // AppActions.setRooms(state)
                    // AppActions.setFloors(state);
                    // AppActions.setZones(state)
                    AppActions.setMaps(state);
                    AppActions.getStatusLoadTransition();
                    AppActions.setTransition(state);
                    AppActions.getThreshold(state);
                    AppActions.setArea(state);
                    // if (IdMall !== state.panelState.dataMall[0].id) {
                    //     IdMall = state.panelState.dataMall[0].id;
                    //     // console.log('actions>>>>', state.panelState.dataMall[0].id);
                    //     AppActions.selectedNewMall(state.panelState.dataMall[0].id);
                    // }
                    // AppActions.selectedNewMall(false);
                }
                if (AppStores.getState().mainState.selectedObjects !== state.commonState.selectedObjects) {
                    AppDispatcher.handleViewAction({
                        actionType:AppConstants.UPDATE_MAIN_STATE,
                        state:state
                    });
                    AppActions.getSelectZone({indexZone: null});
                    AppActions.getPLId(state)
                    // AppActions.setRooms(state)
                    // AppActions.setFloors(state);
                    // AppActions.setZones(state)
                    AppActions.setMaps(state);
                    AppActions.getStatusLoadTransition();
                    AppActions.setTransition(state);
                    AppActions.getThreshold(state);
                    AppActions.setArea(state);
                    // if (IdMall !== state.panelState.dataMall[0].id) {
                    //     IdMall = state.panelState.dataMall[0].id;
                    //     // console.log('actions>>>>', state.panelState.dataMall[0].id);
                    //     AppActions.selectedNewMall(state.panelState.dataMall[0].id);
                    // }
                }
                if (state.commonState.insetName !== AppStores.getState().mainState.insetName) {
                    AppDispatcher.handleViewAction({
                        actionType:AppConstants.UPDATE_MAIN_STATE,
                        state:state
                    });
                    // AppActions.getSelectZone({indexZone: null});
                    // AppActions.setMaps(state.commonState);
                    // AppActions.getStatusLoadTransition();
                    // AppActions.setTransition(state.commonState);
                    // AppActions.selectedNewMall(false);
                }
            }
        }
	},
    getPLId: function (state) {
        Api.getPLId(state,
            function (data1) {
                // console.log('FSFSDFSDFSDFSD',data1);
                globalState['idPL'] = data1
                AppActions.getPlInfo(data1)

            }.bind(AppActions),
            function (err) {
                console.error(err);
                if (err !== 'abort' && err !== '') {
                    alert(err);
                }
            }
        );
    },
    getPlInfo: function (state) {
	    // console.log('getPlInfo', state)
        obj = {
            isSetZone: false,
            isSetFloor: false,
            isSetRoom: false
        }
        Api.getPlInfo(state,
            function (data1) {
                // console.log('FSFSDFSDFSDFSD',data1);
                // globalState['idPL'] = data1
                AppDispatcher.handleViewAction({
                    actionType: AppConstants.GET_UUID_PLAN_SET,
                    data: data1
                });
                if (data1.plan_set_uuid !== null) {
                    globalState['planSetUuid'] = data1.plan_set_uuid;
                    AppActions.getDataPlanSet(globalState)
                    // AppActions.setRooms(globalState)
                    // AppActions.setFloors(globalState);
                    // AppActions.setZones(globalState)
                }

            }.bind(AppActions),
            function (err) {
                console.error(err);
                if (err !== 'abort' && err !== '') {
                    alert(err);
                }
            }
        );
    },
	showAnalitiscMethod: function (status) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SHOW_ANALITICSmETHOD,
			status: status
		});
	},
	selectedOptions: function (data) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SELECTED_OPTIONS,
			data: data
		});
	},
	selectedOptionsExclusion: function (data) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SELECTED_OPTIONS_EXCLUSION,
			data: data
		});
	},
	onCheck: function (data) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.ONCHECK_OPTIONS,
			data: data
		});
	},
	getStapsOptions: function (status) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.GET_STEPS_OPTIONS,
			status: status
		});
	},
	getStatusFilter: function (status) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.GET_STATUS_FILTER,
			status: status
		});
	},
	getFilterSelectA: function (data) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.GET_FILTER_SELECT,
			data: data
		});
	},
	getFilterSelectB: function (data) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.GET_FILTER_SELECT_B,
			data: data
		});
	},
	getDataPlanSet: function (data) {
	    // console.log('getDataPlanSet');
        AppDispatcher.handleViewAction({
            actionType: AppConstants.STATUS_LOAD_ANALITIC
        })

        // console.log('FSFSDFSDFSDFSD1',data);


        Api.createSendPlanSet(data,
            function (data1) {
                // console.log('getDataPlanSet@@',data1);
                // console.log('setZones1',data1);
                dataGlobal1 = data1
                AppActions.setRooms(globalState);
                AppActions.setFloors(globalState);
                AppActions.setZones(globalState);
                AppDispatcher.handleViewAction({
                    actionType: AppConstants.GET_DATA_PLAN_SET,
                    data: GeometryVSDataManager.setPlanSet(data1)
                })
                // AppActions.getUnekUsers(data1)
            }.bind(AppActions),
            function (xhr, status, err) {
                console.error(xhr, status, err);
                if (err !== 'abort' && err !== '') {
                    alert(err);
                }
            }
        );
    },
    getUnekUsers: function (data) {
	    // console.log('GeometryVSDataManager2getUnekUsers')
        var arr = [];
        var arrZones = [];
        var idObjOfZones = {};
        // console.log('FSFSDFSDFSDFSD>getUnekUsers',data.plans);
        for(var i = 0; i < data.plans.length; i++){
	        for(var j = 0; j < data.plans[i].places.length; j++){
                // console.log('getUnekUsers', data.plans[i].places[j].name, GeometryVSDataManager.reportObjWithPlace(data.plans[i].places[j].name))
                if (GeometryVSDataManager.reportObjWithPlace(data.plans[i].places[j].name) !== undefined) {
                    arr.push(GeometryVSDataManager.reportObjWithPlace(data.plans[i].places[j].name))
                }
            }
            for(var h = 0; h < data.plans[i].zone_groups.length; h++){
                console.log('getUnekUsers', data.plans[i])
                for (var g = 0; g < data.plans[i].zone_groups[h].zones.length; g++) {
                    if (GeometryVSDataManager.reportObjWithZone(data.plans[i].zone_groups[h].zones[g].zone_marker) !== undefined) {
                        // arrZones.push(GeometryVSDataManager.reportObjWithZone(data.plans[i].zone_groups[h].zones[g].zone_marker))
                        idObjOfZones[data.plans[i].zone_groups[h].zones[g].zone_marker] = GeometryVSDataManager.reportObjWithZone(data.plans[i].zone_groups[h].zones[g].zone_marker);
                    }
                }
            }
        }
        for (var key in idObjOfZones) {
            arrZones.push(idObjOfZones[key]);
        }
        var obj = {
            secondString:arr.join(','),
            state: globalState
        }
        var objZones = {
            secondString:arrZones.join(','),
            state: globalState
        }
        // console.log('FSFSDFSDFSDFSD!!!!!!',arrZones);
        AppDispatcher.handleViewAction({
            actionType: AppConstants.STATUS_LOAD_ANALITIC
        })
        if (arr.length > 0) {
            Api.getUnekUsers(obj,
                function (data1) {
                    // console.log('FSFSDFSDFSDFSD>getUnekUsers',data1);

                    AppDispatcher.handleViewAction({
                        actionType: AppConstants.SET_UNEK_USERS,
                        data: GeometryVSDataManager.setUneqUser(data1)
                    })
                }.bind(AppActions),
                function (xhr, status, err) {
                    console.error(xhr, status, err);
                    if (err !== 'abort' && err !== '') {
                        alert(err);
                    }
                }
            );
        }
        if (arrZones.length > 0) {
            Api.getUnekUsersOfZones(objZones,
                function (data1) {
                    // console.log('FSFSDFSDFSDFSD>getUnekUsers',data1);
                    // console.log('GeometryVSDataManager2getUnekUsers')
                    AppDispatcher.handleViewAction({
                        actionType: AppConstants.SET_UNEK_USERS_OF_ZONES,
                        data: GeometryVSDataManager.setUneqUserZones(data1)
                    })
                }.bind(AppActions),
                function (xhr, status, err) {
                    console.error(xhr, status, err);
                    if (err !== 'abort' && err !== '') {
                        alert(err);
                    }
                }
            );
        }
        // var firstId = GeometryVSDataManager.reportObjWithPlace(data);
    },
    setRooms: function (data) {
        // console.log('FSFSDFSDFSDFSD2',data);
        Api.setRooms(data,
            function (data1) {
                // console.log('FSFSDFSDFSDFSD2>setRooms',data1);
                // console.log('setRooms',data1);

                obj.isSetRoom = true;
                AppDispatcher.handleViewAction({
                    actionType: AppConstants.SET_ROOM,
                    data: GeometryVSDataManager.setRooms(data1)
                })
                if (obj.isSetRoom === true && obj.isSetFloor === true && obj.isSetZone === true) {
                    // console.log('GeometryVSDataManagersetZones1',dataGlobal1);
                    AppActions.getUnekUsers(dataGlobal1)
                }
            }.bind(AppActions),
            function (xhr, status, err) {
                console.error(xhr, status, err);
                if (err !== 'abort' && err !== '') {
                    alert(err);
                }
            }
        );
    },
    setFloors: function (data) {
        // console.log('FSFSDFSDFSDFSD3',data);
        Api.setFloors(data,
            function (data1) {
                // console.log('setRooms',data1);
                obj.isSetFloor = true;

                if (data1 !== null) {
                    AppDispatcher.handleViewAction({
                        actionType: AppConstants.SET_FLOOR,
                        data: GeometryVSDataManager.setFloors(data1)
                    })
                }
                else {
                    AppDispatcher.handleViewAction({
                        actionType: AppConstants.SET_FLOOR,
                        data: null
                    })
                }
                if (obj.isSetRoom === true && obj.isSetFloor === true && obj.isSetZone === true) {
                    // console.log('GeometryVSDataManagersetZones2',dataGlobal1);
                    AppActions.getUnekUsers(dataGlobal1)
                }
                // console.log('FSFSDFSDFSDFSD3>setFloors',data1);
            }.bind(AppActions),
            function (xhr, status, err) {
                console.error(xhr, status, err);
                if (err !== 'abort' && err !== '') {
                    alert(err);
                }
            }
        );
    },
    setZones: function (data) {
        // console.log('GeometryVSDataManagersetZones',data);
        Api.setZones(data,
            function (data1) {
                // console.log('setRooms',data1, GeometryVSDataManager);
                // console.log('setZones',data1);
                obj.isSetZone = true;
                // if (obj.isSetRoom === true && obj.isSetFloor === true && obj.isSetZone === true) {
                //     console.log('GeometryVSDataManagersetZones3',dataGlobal1);
                //     AppActions.getUnekUsers(dataGlobal1)
                // }
                // console.log('GeometryVSDataManagersetZones',data);
                AppDispatcher.handleViewAction({
                    actionType: AppConstants.SET_ZONE,
                    data: GeometryVSDataManager.setZones(data1)
                })
                if (obj.isSetRoom === true && obj.isSetFloor === true && obj.isSetZone === true) {
                    // console.log('GeometryVSDataManagersetZones3',dataGlobal1);
                    AppActions.getUnekUsers(dataGlobal1)
                }
            }.bind(AppActions),
            function (xhr, status, err) {
                console.error(xhr, status, err);
                if (err !== 'abort' && err !== '') {
                    alert(err);
                }
            }
        );
    },
	getTop: function (status) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.GET_TOP,
			status:status
		});
	},
	getNumSteps: function (status) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.GET_NUM_STEPS,
			status:status
		});
	},
	setRequestRoute: function (state) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.GET_LOAD_STATUS
		})
		Api.getRequestRoute(state,
			function (data) {
				// console.log(data);
				AppDispatcher.handleViewAction({
					actionType: AppConstants.SET_REQUEST_ROUTE,
					data: data
				})
			}.bind(AppActions),
			function (err) {
				console.error(err);
				if (err !== 'abort' && err !== '') {
						alert(err);
					}
			}
		);
	},
	setTypeSelect: function (state) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SET_TYPE_SELECT,
			data: state
		});
	},
	setStatusInvolvement: function (state) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SET_STATUS_INVOLVEMENT,
			data: state
		});
	},
	setValueMac: function (state) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SET_VALUE_MAC,
			data: state
		});
	},
	setDataSelectTopDetail: function (status) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SET_SELECT_TOP_DETAIL,
			status: status
		});
	},
	getThreshold: function (state) {
		AppDispatcher.handleViewAction({
			actionType: AppConstants.SET_LOAD_THERSHOLD
		})
		Api.getRequestThreshold(state,
			function (data) {
				// console.log(data);
				AppDispatcher.handleViewAction({
					actionType: AppConstants.GET_THERSHOLD_DATA,
					data: data
				})
			}.bind(AppActions),
			function (err) {
				console.error(err);
				if (err !== 'abort' && err !== '') {
						alert(err);
					}
			}
		);
	},
    getPointStatus: function (e) {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.GET_STATUS_POINT,
            status: e
        });
    },
    getDataRouter: function (data) {
	    // console.log(">>>>>>>>>>")
        AppDispatcher.handleViewAction({
            actionType: AppConstants.GET_ROUTER_DATA,
            data: data
        });
    },
	getDataSelectRouter: function (data) {
        // console.log("!!!!!!!!")
        AppDispatcher.handleViewAction({
            actionType: AppConstants.GET_SELECT_ROUTER,
            data: data
        });
    },
	selectPolygons: function (data, string, status, type) {
	    if (type === 'places') {
            AppDispatcher.handleViewAction({
                actionType: AppConstants.SELECT_POLYGON,
                status: true
            })
            // var firstId = GeometryVSDataManager.reportObjWithPlace(data);
            var obj = {
                firstId: GeometryVSDataManager.reportObjWithPlace(data),
                secondString:string,
                state: globalState
            }
            if (status === false) {
                AppDispatcher.handleViewAction({
                    actionType: AppConstants.SET_DATA_SELECT_POLYGON,
                    data: null,
                    item: null
                })
                // AppDispatcher.handleViewAction({
                //     actionType: AppConstants.SELECT_POLYGON,
                //     status: false
                // })
            }
            else if (status === true) {

                Api.setTransition(obj,
                    function (data1) {
                        // console.log(data1);
                        AppDispatcher.handleViewAction({
                            actionType: AppConstants.SET_DATA_SELECT_POLYGON,
                            data: data1,
                            item: data
                        })
                    }.bind(AppActions),
                    function (err) {
                        console.error(err);
                        if (err !== 'abort' && err !== '') {
                            alert(err);
                        }
                    }
                );
            }
        }
        else if (type === 'zones') {
            AppDispatcher.handleViewAction({
                actionType: AppConstants.SELECT_POLYGON,
                status: true
            })
            // var firstId = GeometryVSDataManager.reportObjWithPlace(data);
            console.log('>>>>>>>>>>>>>>>>>>!!!!',string);
            var obj = {
                firstId: data,
                secondString:string,
                state: globalState
            }
            if (status === false) {
                AppDispatcher.handleViewAction({
                    actionType: AppConstants.SET_DATA_SELECT_POLYGON,
                    data: null,
                    item: null
                })
                // AppDispatcher.handleViewAction({
                //     actionType: AppConstants.SELECT_POLYGON,
                //     status: false
                // })
            }
            else if (status === true) {
                Api.setTransition(obj,
                    function (data1) {
                        console.log('>>>>>>>>>>>>>>>>>>',data1);
                        AppDispatcher.handleViewAction({
                            actionType: AppConstants.SET_DATA_SELECT_POLYGON,
                            data: data1,
                            item: data
                        })
                    }.bind(AppActions),
                    function (err) {
                        console.error(err);
                        if (err !== 'abort' && err !== '') {
                            alert(err);
                        }
                    }
                );
            }
        }

        // console.log('selectPolygons', obj)
        // globalState
    },
    onChangeSelectTypeNewMaps: function (data) {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.ON_CHANGE_SELECT_TYPE_NEW_MAPS,
            data: data
        })
    },
    showColorCount: function (status) {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.SHOW_COLOR_COUNT,
            status: status
        })
    },
    onCheckBackground: function (status) {
        AppDispatcher.handleViewAction({
            actionType: AppConstants.ON_CHECK_BACKGROUND,
            status: status
        })
    }
};

module.exports = AppActions;
