var $ = require('jquery');

// import hostName from '../../../../api/hostName'
var hostName = require('../../../../api/hostName');

Date.prototype.yyyymmdd = function() {
	var yyyy = this.getFullYear().toString();
	var mm = (this.getMonth() + 1).toString();
	var dd = this.getDate().toString();
	return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]);
};



var Api = {
	internalState: {
		state: null,
		floors: null,
		headers: {},
		idMall: null,
		maxTime: null,
		minTime: null, 
		requestHeaders: null,
		requestHeadersMapLink: null,
		requestHeadersArea: null,
		oldRequest: null
	},
	constantUrl: {
		floors: {
			root: 'https://api.getshopster.net/v3/floor?mall_id='
		},
		maps: {
			root: 'https://api.getshopster.net/v3/floor/map?',
			mallId: 'mall_id=',
			floor: '&floor='
		},
		transition: {
			root: 'https://api.getshopster.net/v3/mall/',
			transition: '/inner_intersections?',
			from: 'from=',
			to: '&to='
		},
		stateMalls: null,
		route: {
			root: 'https://api.getshopster.net/v3/reports/',
			macRoot: 'route_mac?',
			objects: 'object=',
			from: 'from=',
			to: 'to=',
			steps: 'num_steps=',
			top: 'top=',
			type: 'type=',
			include: 'include_objects=',
			exclude: 'exclude_objects=',
			mac: '&mac=',
			engagement: 'engagement_threshold='
		}
	},
	// getRequest: function (id, url, success, error) {
	// 	var data;
	// 	$.ajax({
	// 		url: url,
	// 		data: data,
	// 		success: function(data) {
	// 			if (this.internalState.idMall == null || this.internalState.idMall == id) {
	// 				success(data);
	// 			}
	//         }.bind(this),
	//         error: function(xhr, status, err) {
	//    
	//                      error(err);
	//             console.error(err);
	//         }.bind(this)
	// 	});
	// },
	getRequestHeadersMapLink: function (state, id, url, success, error) {
		if (this.internalState.requestHeadersMapLink !== null) {
			this.internalState.requestHeadersMapLink.abort();
			// this.internalState.requestHeaders = null;
		}
        // console.log('><><><><><><><>');
		var data;
		this.internalState.requestHeadersMapLink = $.ajax({
			url: url,
			headers:  this.internalState.headers,
			data: data,
			success: function(data) {
				// console.log('><><><><><><><>');
            	success(data);
	        }.bind(this),
	        error: function(xhr, status, err) {
	            // error(err);
	            console.error(err);
	        }.bind(this)
		});
	},
	getRequestHeaders: function (type,state, url, success, error) {
		if (this.internalState.requestHeaders !== null) {
			this.internalState.requestHeaders.abort();
			// this.internalState.requestHeaders = null;
		}
		var data;
		this.internalState.requestHeaders = $.ajax({
			url: url,
			headers:  this.internalState.headers,
			data: data,
			success: function(data1) {
                success(data1);
	        }.bind(this),
	        error: function(xhr, status, err) {
	            error(err);
	            // console.error(err);
	        }.bind(this)
		});
	},
	getRequestHeadersRouter: function (url, success, error) {
		var data;
		$.ajax({
			url: url,
			headers:  this.internalState.headers,
			data: data,
			type: 'GET',
			success: function(data) {
				success(data);
    //         	if (this.internalState.idMall == null || this.internalState.idMall == id) {
				// 	success(data);
				// }
	        }.bind(this),
	        error: function(xhr, status, err) {
	            error(err);
	            console.error(err);
	        }.bind(this)
		});
	},
	getRequestHeadersThreshold: function (url, success, error) {
		var data;
		$.ajax({
			url: url,
			headers:  this.internalState.headers,
			data: data,
			type: 'GET',
			success: function(data) {
				success(data);
    //         	if (this.internalState.idMall == null || this.internalState.idMall == id) {
				// 	success(data);
				// }
	        }.bind(this),
	        error: function(xhr, status, err) {
	            error(err);
	            console.error(err);
	        }.bind(this)
		});
	},
	getRequestArea: function (url, success, error) {
		if (this.internalState.requestHeadersArea !== null) {
			this.internalState.requestHeadersArea.abort();
			// this.internalState.requestHeaders = null;
		}
		var data;
		this.internalState.requestHeadersArea = $.ajax({
			url: url,
			headers:  this.internalState.headers,
			data: data,
			type: 'GET',
			success: function(data) {
				success(data);
    //         	if (this.internalState.idMall == null || this.internalState.idMall == id) {
				// 	success(data);
				// }
	        }.bind(this),
	        error: function(xhr, status, err) {
	            // error(err);
	            console.error(err);
	        }.bind(this)
		});
	},
    getRequestPlanSet: function (url, success, error) {
		// console.log('getRequestPlanSet',this.internalState.headers)
		// debugger
        // if (this.internalState.requestHeadersArea !== null) {
        //     this.internalState.requestHeadersArea.abort();
        //     // this.internalState.requestHeaders = null;
        // }
        var data;
		$.ajax({
            url: url,
            headers:  this.internalState.headers,
            data: data,
            type: 'GET',
            success: function(data) {
            	// console.log(data)
                success(data);
                //         	if (this.internalState.idMall == null || this.internalState.idMall == id) {
                // 	success(data);
                // }
            }.bind(this),
            error: function(xhr, status, err) {
                error(xhr, status, err);
                // console.log('err',err);
            }.bind(this)
        });
    },
    getRequestPLInfo: function (url, success, error) {
        // console.log('getRequestPLInfo',this.internalState.headers)
        // debugger
        // if (this.internalState.requestHeadersArea !== null) {
        //     this.internalState.requestHeadersArea.abort();
        //     // this.internalState.requestHeaders = null;
        // }
        var data;
        $.ajax({
            url: url,
            type: 'GET',
            headers:  this.internalState.headers,
            success: function(data) {
                // console.log(data)
                success(data);
                //         	if (this.internalState.idMall == null || this.internalState.idMall == id) {
                // 	success(data);
                // }
            }.bind(this),
            error: function(xhr, status, err) {
                error(err);
                // console.log('err',err);
            }.bind(this)
        });
    },

    createFloorsLink: function (state, success, error) {
        this.internalState.headers['x-token'] = state.headers['x-token'];
        this.constantUrl.stateMalls = state;
        // console.log("errorrorroror", state);
        var username = encodeURIComponent(state.username);
        var password = encodeURIComponent(state.password);
        var url = this.constantUrl.floors.root
            + state.commonState.selectedObjects;
        // console.log("errorrorroror", createMapsLink);
        this.getRequestHeadersMapLink('floors', state.selectedObjects, url, function(data) {
            this.createMapsLink(data, success)
        }.bind(Api), error);
    },
    createMapsLink: function  (data, success) {
        var arrLinksMap = [];
        var dataOld = {};
        dataOld.value = data;
        dataOld.status = 'ok';
        var successObj = {
            data: dataOld,
            links: null
        };
        if (data != undefined && dataOld.status == 'ok') {
            dataOld.value = dataOld.value.sort(function (a, b) {
                if (a.floor > b.floor) {
                    return 1;
                }
                if (a.floor < b.floor) {
                    return -1;
                }
                return 0;
            });
            var headers = this.internalState.headers;
            // console.log('dssddsdsdsds', this.constantUrl.stateMalls)
            for (var i = 0; i < dataOld.value.length; i++) {
                var username = encodeURIComponent(this.constantUrl.stateMalls.username);
                var password = encodeURIComponent(this.constantUrl.stateMalls.password);
                var objLink = {
                    link:this.constantUrl.maps.root
                    + this.constantUrl.maps.mallId
                    + this.constantUrl.stateMalls.commonState.selectedObjects
                    + this.constantUrl.maps.floor + dataOld.value[i].floor + '&'
                    + 'token=' + this.internalState.headers['x-token'],
                    idLink: dataOld.value[i].floor,
                    index: i
                }
                // getUrl(objLink.link, dataOld.value[i].floor);
                arrLinksMap.push(objLink);
            }
            // getUrl(arrLinksMap[0].link, dataOld.value[0].floor);
            this.internalState.floors = arrLinksMap;
            successObj.links = arrLinksMap;
            success(successObj);
        }
    },
    getRequestMap: function (state, success, error) {
        this.createFloorsLink(state, success, error);
    },
    getSort: function (data, success) {
        var newArea = [];
        var areaobj = [];
        var areaFloor = {};
        if (this.internalState.floors != null) {
            for (var i = 0; i < this.internalState.floors.length; i++ ) {
                for (var j = 0; j < data.value.length; j++ ) {
                    if (data.value[j].floor == this.internalState.floors[i].idLink) {
                        areaobj.push(data.value[j]);
                    }
                }
                areaFloor = {
                    floor: this.internalState.floors[i].idLink,
                    value: areaobj
                }
                newArea.push(areaFloor);
                areaobj = [];
            }
            success(newArea);
        }
    },
    createTransition: function (state, success, error) {
	    // console.log('createTransition', state)
        this.internalState.headers['x-token'] = state.headers['x-token'];
        // this.internalState.headers['x-username'] = state.username;
        var dMax = new Date(state.commonState.max_timestamp);
        var dMin = new Date(state.commonState.min_timestamp);
        Date.prototype.yyyymmdd = function() {
            var yyyy = this.getFullYear().toString();
            var mm = (this.getMonth()+1).toString();
            var dd  = this.getDate().toString();
            return yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]);
        };
        // console.log('apiapi', dMin.yyyymmdd());
        if (state.commonState.max_timestamp != undefined) {
            var username = encodeURIComponent(state.username);
            var password = encodeURIComponent(state.password);
            var url = this.constantUrl.transition.root
                + state.commonState.selectedObjects
                + this.constantUrl.transition.transition
                + this.constantUrl.transition.from
                + dMin.yyyymmdd()
                + this.constantUrl.transition.to
                + dMax.yyyymmdd();
            this.getRequestHeaders('transition', state.selectedObjects, url, function(data) {
                this.sotrTransition(data, success)
            }.bind(Api), error);
        }
    },
    sotrTransition: function (data, success) {
        data = data.sort(function (a, b) {
            if (a.floor > b.floor) {
                return 1;
            }
            if (a.floor < b.floor) {
                return -1;
            }
            return 0;
        });
        success(data);
    },
    getArea: function (state, success, error) {
	    // console.log('getArea', state)
        var url = 'https://api.getshopster.net/v3/mall_object_list?mall_id=' + state.commonState.selectedObjects;
        this.getRequestArea(url, success, error);
    },
    getRequestRoute: function (state, success, error) {
        // console.log('getRequestRoute', state)
        var arrSelected = [];
        for (var i = 0; i < state.optionsSelected.length; i++) {
            arrSelected.push(state.optionsSelected[i].id);
        }
        var arrSelectedExclusion = [];
        for (var j = 0; j < state.optionsSelectedExclusion.length; j++) {
            arrSelectedExclusion.push(state.optionsSelectedExclusion[j].id);
        }
        // console.log('api', state.filterObj);
        if (state.filterObj.fromA === true) {
            var url = this.constantUrl.route.root +
                'route_in_out?' +
                this.constantUrl.route.objects +
                state.optionsFilterValueA.id + '&' +
                this.constantUrl.route.from +
                state.minDate.yyyymmdd() + '&' +
                this.constantUrl.route.to +
                state.maxDate.yyyymmdd() + '&' +
                this.constantUrl.route.steps +
                state.numSteps + '&' +
                this.constantUrl.route.top +
                state.statusTop + '&' +
                this.constantUrl.route.type +
                'out' + '&' +
                this.constantUrl.route.include +
                arrSelected.join(',') + '&' +
                this.constantUrl.route.exclude +
                arrSelectedExclusion.join(',') + '&' +
                this.constantUrl.route.engagement +
                state.statusInvolvement;
        }
        else if (state.filterObj.mac === true) {
            var url = this.constantUrl.route.root +
                'route_mac?' +
                'object_type=' +
                state.statusTypeSelect + '&' +
                this.constantUrl.route.from +
                state.minDate.yyyymmdd() + '&' +
                this.constantUrl.route.to +
                state.maxDate.yyyymmdd() + '&' +
                this.constantUrl.route.steps +
                state.numSteps + '&' +
                this.constantUrl.route.top +
                state.statusTop + '&' +
                this.constantUrl.route.include +
                arrSelected.join(',') + '&' +
                this.constantUrl.route.exclude +
                arrSelectedExclusion.join(',') +
                this.constantUrl.route.mac +
                state.macAdress + '&' +
                'mall_id=' +
                state.selectedObjects + '&' +
                this.constantUrl.route.engagement +
                state.statusInvolvement;
        }
        else if (state.filterObj.toA === true) {
            var url = this.constantUrl.route.root +
                'route_in_out?' +
                this.constantUrl.route.objects +
                state.optionsFilterValueA.id + '&' +
                this.constantUrl.route.from +
                state.minDate.yyyymmdd() + '&' +
                this.constantUrl.route.to +
                state.maxDate.yyyymmdd() + '&' +
                this.constantUrl.route.steps +
                state.numSteps + '&' +
                this.constantUrl.route.top +
                state.statusTop + '&' +
                this.constantUrl.route.type +
                'in' + '&' +
                this.constantUrl.route.include +
                arrSelected.join(',') + '&' +
                this.constantUrl.route.exclude +
                arrSelectedExclusion.join(',')+ '&' +
                this.constantUrl.route.engagement +
                state.statusInvolvement;
        }
        else if (state.filterObj.fromAToB === true) {
            var url = this.constantUrl.route.root +
                'route_start_finish?' +
                'object_start=' +
                state.optionsFilterValueA.id +
                '&object_finish=' +
                state.optionsFilterValueB.id + '&' +
                this.constantUrl.route.from +
                state.minDate.yyyymmdd() + '&' +
                this.constantUrl.route.to +
                state.maxDate.yyyymmdd() + '&' +
                this.constantUrl.route.steps +
                state.numSteps + '&' +
                this.constantUrl.route.top +
                state.statusTop +  '&' +
                this.constantUrl.route.include +
                arrSelected.join(',') + '&' +
                this.constantUrl.route.exclude +
                arrSelectedExclusion.join(',')+ '&'
                +
                this.constantUrl.route.engagement +
                state.statusInvolvement;
        }
        if (state.statusStepsOptions === 'arbitrarily') {
            if (state.filterObj.fromA === true) {
                var url = this.constantUrl.route.root +
                    'route_in_out?' +
                    this.constantUrl.route.objects +
                    state.optionsFilterValueA.id + '&' +
                    this.constantUrl.route.from +
                    state.minDate.yyyymmdd() + '&' +
                    this.constantUrl.route.to +
                    state.maxDate.yyyymmdd() + '&' +
                    this.constantUrl.route.top +
                    state.statusTop + '&' +
                    this.constantUrl.route.type +
                    'out' + '&' +
                    this.constantUrl.route.include +
                    arrSelected.join(',') + '&' +
                    this.constantUrl.route.exclude +
                    arrSelectedExclusion.join(',')+ '&' +
                    this.constantUrl.route.engagement +
                    state.statusInvolvement;
            }
            else if (state.filterObj.mac === true) {
                if (state.macAdress === '') {
                    var url = this.constantUrl.route.root +
                        'route_mac?' +
                        'object_type=' +
                        state.statusTypeSelect + '&' +
                        this.constantUrl.route.from +
                        state.minDate.yyyymmdd() + '&' +
                        this.constantUrl.route.to +
                        state.maxDate.yyyymmdd() + '&' +
                        this.constantUrl.route.top +
                        state.statusTop + '&' +
                        this.constantUrl.route.include +
                        arrSelected.join(',') + '&' +
                        this.constantUrl.route.exclude +
                        arrSelectedExclusion.join(',') +'&' +
                        'mall_id=' +
                        state.selectedObjects + '&' +
                        this.constantUrl.route.engagement +
                        state.statusInvolvement;
                }
                else if (state.macAdress !== '') {
                    var url = this.constantUrl.route.root +
                        'route_mac?' +
                        'object_type=' +
                        state.statusTypeSelect + '&' +
                        this.constantUrl.route.from +
                        state.minDate.yyyymmdd() + '&' +
                        this.constantUrl.route.to +
                        state.maxDate.yyyymmdd() + '&' +
                        this.constantUrl.route.top +
                        state.statusTop + '&' +
                        this.constantUrl.route.include +
                        arrSelected.join(',') + '&' +
                        this.constantUrl.route.exclude +
                        arrSelectedExclusion.join(',') +
                        this.constantUrl.route.mac +
                        state.macAdress + '&' +
                        'mall_id=' +
                        state.selectedObjects + '&' +
                        this.constantUrl.route.engagement +
                        state.statusInvolvement;
                }
            }
            else if (state.filterObj.toA === true) {
                var url = this.constantUrl.route.root +
                    'route_in_out?' +
                    this.constantUrl.route.objects +
                    state.optionsFilterValueA.id + '&' +
                    this.constantUrl.route.from +
                    state.minDate.yyyymmdd() + '&' +
                    this.constantUrl.route.to +
                    state.maxDate.yyyymmdd() + '&' +
                    this.constantUrl.route.top +
                    state.statusTop + '&' +
                    this.constantUrl.route.type +
                    'in' + '&' +
                    this.constantUrl.route.include +
                    arrSelected.join(',') + '&' +
                    this.constantUrl.route.exclude +
                    arrSelectedExclusion.join(',')+ '&' +
                    this.constantUrl.route.engagement +
                    state.statusInvolvement;
            }
            else if (state.filterObj.fromAToB === true) {
                var url = this.constantUrl.route.root +
                    'route_start_finish?' +
                    'object_start=' +
                    state.optionsFilterValueA.id +
                    '&object_finish=' +
                    state.optionsFilterValueB.id + '&' +
                    this.constantUrl.route.from +
                    state.minDate.yyyymmdd() + '&' +
                    this.constantUrl.route.to +
                    state.maxDate.yyyymmdd() + '&' +
                    this.constantUrl.route.top +
                    state.statusTop +  '&' +
                    this.constantUrl.route.include +
                    arrSelected.join(',') + '&' +
                    this.constantUrl.route.exclude +
                    arrSelectedExclusion.join(',')+ '&' +
                    this.constantUrl.route.engagement +
                    state.statusInvolvement;
            }
        }
        else if (state.filterObj.top === true) {
            var url = this.constantUrl.route.root +
                'route_popular?' +
                this.constantUrl.route.engagement +
                state.statusInvolvement + '&' +
                'object_type=' +
                state.statusTypeSelect + '&' +
                'mall_id=' + state.selectedObjects + '&' +
                'num_steps=' + state.numSteps + '&' +
                this.constantUrl.route.from +
                state.minDate.yyyymmdd() + '&' +
                this.constantUrl.route.to +
                state.maxDate.yyyymmdd() +
                '&edge=' + state.dataSelect + '&' +
                this.constantUrl.route.top +
                state.statusTop +  '&' +
                this.constantUrl.route.include +
                arrSelected.join(',') + '&' +
                this.constantUrl.route.exclude +
                arrSelectedExclusion.join(',');
        }
        // console.log('api', usernamerl);
        // console.log('getRequestRoute', url)

        this.getRequestHeadersRouter(url, success, error);
        // route: {
        // 	root: 'http://reports/',
        // 	objects: 'object=',
        // 	from: 'from=',
        // 	to: 'to=',
        // 	steps: 'num_steps=',
        // 	top: 'top=',
        // 	type: 'type=',
        // 	include: 'include_objects=',
        // 	include: 'exclude_objects='
        // }

        // var url =
    },
    getRequestThreshold: function (state, success, error) {
        var url = 'https://api.getshopster.net/v3/reports/route_threshold_list';
        this.getRequestHeadersThreshold(url, success, error);
    },
	createSendPlanSet: function (state, success, error) {
		var url = `${hostName.maps}/geometry/plan_set_info/${state.planSetUuid}/`;
        // console.log('3223232323', url)
		this.getRequestPlanSet(url, success, error)
    },
    setRooms: function (state, success, error) {
        // console.log('3223232323', state.commonState.selectedObjects)
        this.internalState.headers['x-token'] = state.headers['x-token'];
        var url = `https://api.getshopster.net/v3/room?mall_id=${state.commonState.selectedObjects}`;
        // console.log('3223232323', state)
        this.getRequestPlanSet(url, success, error)
    },
    setFloors: function (state, success, error) {
        this.internalState.headers['x-token'] = state.headers['x-token'];
        // console.log('setFloors', state.idPL)
        // console.log('setRooms2', state.commonState.selectedObjects)

        var url = `https://api.getshopster.net/v3/floor?mall_id=${state.commonState.selectedObjects}`;
        // console.log('3223232323', url)
        this.getRequestPlanSet(url, success, error)
    },
    setZones: function (state, success, error) {
        this.internalState.headers['x-token'] = state.headers['x-token'];
        // console.log('3223232323', state.commonState.selectedObjects)
        var url = `https://api.getshopster.net/v3/zone?mall_id=${state.commonState.selectedObjects}`;
        // console.log('3223232323', url)
        this.getRequestPlanSet(url, success, error)
    },
	setTransition: function (data, success, error) {
		// console.log('setTransition', data)
		var url = `${hostName.api}/reports/inner_intersections_custom?from=${data.state.panelState.minDate}&to=
		${data.state.panelState.maxDate}&objects_first=${data.firstId}&objects_second=${data.secondString}`;
        this.getRequestPlanSet(url, success, error)
    },
    getUnekUsers: function (data, success, error) {
	    // console.log('getUnekUsers', data.state.panelState)
        var url = `${hostName.api}/reports/unique_clients_agg?from=${data.state.panelState.minDate}&to=${data.state.panelState.maxDate}&objects=${data.secondString}&client_type=visitor`;
        // console.log('getUnekUsers', data.secondString);
        this.getRequestPlanSet(url, success, error)
    },
    getUnekUsersOfZones: function (data, success, error) {
        // console.log('getUnekUsers', data.state.panelState)
        var url = `${hostName.api}/reports/unique_clients_agg?from=${data.state.panelState.minDate}&to=${data.state.panelState.maxDate}&objects=${data.secondString}&client_type=visitor`;
        // console.log('getUnekUsers', data.secondString);
        this.getRequestPlanSet(url, success, error)
    },
	getPLId: function (state, success, error) {
        this.internalState.headers['x-token'] = state.headers['x-token'];
        // console.log('getPLId', state)
		// debugger
        var url = `https://api.getshopster.net/v3/pl_by_mall?mall_id=${state.commonState.selectedObjects}`;
        this.getRequestPlanSet(url, success, error)
    },
    getPlInfo: function (state, success, error) {
        // console.log('getPlInfo', state)
        var url = `https://admin-service.getshopster.net/project_locations/${state}/`;
        this.getRequestPLInfo(url, success, error)
    }
};


module.exports = Api;
