import React from 'react';
import ReactDOM from 'react-dom';
import {ButtonToolbar, ButtonGroup, Button} from 'react-bootstrap';

var imageUrl = 0;

export class DrawManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: 'DEFAULT',
		}
	}
	setMode(newMode) {
		this.setState({
			mode: newMode,
		})
	}
	getMode() {
		return this.state.mode;
	}
	updateShapes(shapes) {
		return shapes;
	}
	render() {
		var mode = this.state.mode;

		return (
			<div style={{

			}}>
				<ButtonToolbar>
					<ButtonGroup bsSize="xsmall">
						{mode=='POINT' ? <Button bsStyle='primary'
							onClick={() => this.setMode('DEFAULT')}>Point</Button> :
							<Button onClick={() => this.setMode('POINT')}>Point</Button>}
						{mode=='LINE' ? <Button bsStyle='primary'
							onClick={() => this.setMode('DEFAULT')}>Line</Button> :
							<Button onClick={() => this.setMode('LINE')}>Line</Button>}
						{mode=='POLY' ? <Button bsStyle='primary'
							onClick={() => this.setMode('DEFAULT')}>Polygon</Button> :
							<Button onClick={() => this.setMode('POLY')}>Polygon</Button>}
					</ButtonGroup>
				</ButtonToolbar>			

				<Drawer {...this.props} getMode={this.getMode.bind(this)} updateShapes={this.updateShapes.bind(this)}/>

			</div>
		)
	}
}

export class Drawer extends React.Component {
	initShapes(shapes) {
		this['shapes'] = shapes ? shapes : {
			points: [],
			lines: [],
			polygons: [],
			polygonIsOpened: false,
			cursorPoint: [],
			polygonCloseStatus: false,

			selectedPoint: null,
			selectedPointMoved: false,
			cursorMoved: false,
			selectedShape: null,
		};
	}
	constructor(props) {
		super(props);
		this.state = {
			image: props.image,
			width: props.width,
			height: props.height,
		}
		this.initShapes(props.shapes);
	}
	componentWillReceiveProps(nextProps) {

		if (this.state.image != nextProps.image) {
			this.initShapes();
		}
		this.state = {
			image: nextProps.image,
			width: nextProps.width,
			height: nextProps.height,
		}
	}
	removeOpenedShapes(mode) {
		var points = this.shapes.points;
		var lines = this.shapes.lines;
		var polygons = this.shapes.polygons;
		if (mode != 'LINE') {
			if (lines.length > 0 && lines[lines.length-1].length < 2) {
				lines.splice(lines.length-1, 1);
			}
		}
		if (mode != 'POLY') {
			if (polygons.length > 0 && this.shapes.polygonIsOpened) {
				polygons.splice(polygons.length-1, 1);
				this.shapes.polygonIsOpened = false;
			}
		}
	}
	holstOnClick(x, y, scale) {
		var mode = this.props.getMode();
		var p = [x/scale, y/scale];

		if (this.shapes.selectedPointMoved) {
			return;
		}

		if (this.shapes.selectedShape) {
			return;
		}

		var points = this.shapes.points;
		var lines = this.shapes.lines;
		var polygons = this.shapes.polygons;

		this.removeOpenedShapes(mode);

		if (mode == 'POINT') {
			points.push(p);
		} else if (mode == 'LINE') {
			if (lines.length==0 || lines[lines.length-1].length==2) {
				lines.push([p]);
			} else if (lines[lines.length-1].length==1) {
				lines[lines.length-1].push(p)
			}
		} else if (mode == 'POLY') {
			var isOpened = this.shapes.polygonIsOpened;
			var polygons = this.shapes.polygons;
			if (isOpened) {
				var lastPolygon = polygons[polygons.length-1];
				var p0 = lastPolygon[0];
				var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
				if (d < this.state.height * 0.02) {
					this.shapes.polygonIsOpened = false;
				} else {
					polygons[polygons.length-1].push(p)
				}
			} else {
				polygons.push([p])
				this.shapes.polygonIsOpened = true;
			}
		}
	}
	holstMouseMove(x, y, scale) {
		var mode = this.props.getMode();
		var p = [x/scale, y/scale];
		this.shapes.cursorPoint = p;

		if (this.shapes.selectedPoint) {
			this.shapes.selectedPointMoved = true;
		}
		this.shapes.cursorMoved = true;

		if (this.shapes.selectedPoint && this.shapes.selectedPointMoved) {
			if (this.shapes.selectedPoint.shapeType=='POINT') {
				var i = this.shapes.selectedPoint.is[0];
				this.shapes.points[i] = p;
			} else if (this.shapes.selectedPoint.shapeType=='LINE') {
				var i = this.shapes.selectedPoint.is[0];
				var j = this.shapes.selectedPoint.is[1];
				this.shapes.lines[i][j] = p;
			} else if (this.shapes.selectedPoint.shapeType=='POLY') {
				var i = this.shapes.selectedPoint.is[0];
				var j = this.shapes.selectedPoint.is[1];
				this.shapes.polygons[i][j] = p;
			}
		}

		var polygons = this.shapes.polygons;
		this.shapes.polygonCloseStatus = false;
		if (mode=='POLY' && polygons.length > 0 && this.shapes.polygonIsOpened) {
			var lastPolygon = polygons[polygons.length-1];
			var p0 = lastPolygon[0];
			var d = Math.max(Math.abs(p[0]-p0[0]),Math.abs(p[1]-p0[1]));
			if (d < this.state.height * 0.02) {
				this.shapes.polygonCloseStatus = true;
			}
		}
	}
	selectPoint(shapeType, is) {
		this.shapes.selectedPoint = {
			shapeType: shapeType,
			is: is
		}
	}
	selectShape(shapeType, i) {
		this.shapes.selectedShape = {
			shapeType: shapeType,
			i: i
		}
		this.shapes.cursorMoved = false;
	}
	holstMouseUp(x, y, scale) {
		this.shapes.selectedPoint = null;
		this.shapes.selectedPointMoved = false;
		if (this.shapes.selectedShape && this.shapes.cursorMoved) {
			this.shapes.selectedShape = null;
		}
		if (this.props.updateShapes) {
			this.shapes = this.props.updateShapes(this.shapes);
		}
	}
	trashClick() {
		if (this.shapes.selectedShape) {
			var shape = this.shapes.selectedShape;
			if (shape.shapeType == 'POINT') {
				this.shapes.points.splice(shape.i, 1);
			} else if (shape.shapeType == 'LINE') {
				this.shapes.lines.splice(shape.i, 1);
			} else if (shape.shapeType == 'POLY') {
				this.shapes.polygons.splice(shape.i, 1);
			}
			this.shapes.selectedShape = null;
			if (this.props.updateShapes) {
				this.shapes = this.props.updateShapes(this.shapes);
			}
		}
	}
	getSvgElements(scale) {

		if (this.props.changeShapes) {
			this.shapes = this.props.changeShapes(this.shapes);
		}

		var stylePoint = {
			cursor: 'move'
		}
		var styleLine = {
			cursor: 'pointer'
		}

		var elements = [];
		var cp = this.shapes.cursorPoint;
		var mode = this.props.getMode();

		if (mode == 'POINT') {
			elements.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="4" fill="rgba(0,0,0,0.2)" 
				key={elements.length} style={stylePoint} />);
		}

		if (this.shapes.polygonCloseStatus==true) {
			elements.push(<circle cx={cp[0]*scale} cy={cp[1]*scale} r="6" fill="rgba(255,0,0,1)" 
				key={elements.length} style={stylePoint} />);
		}
		
		var fx = function(type, is) {
			return function(e) {
				this.selectPoint(type, is);
				if (type == 'POINT') {
					this.selectShape(type, is[0]);
				}
				e.stopPropagation()
			};
		}

		var fshape = function(type, i) {
			return function(e) {
				this.selectShape(type, i);
			}
		}

		var polygons = this.shapes.polygons;
		if (this.shapes.polygonIsOpened) {
			var lastPolygon = polygons[polygons.length-1];
			for (var j = 0; j < lastPolygon.length-1; j++) {
				var p1 = lastPolygon[j];
				var p2 = lastPolygon[j+1];
				elements.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale} 
					stroke="rgb(255,0,0)" key={elements.length}/>);
				elements.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
					key={elements.length} />);
			}
			var p1 = lastPolygon[lastPolygon.length-1];
			var p2 = cp;
			elements.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale} 
				stroke="rgb(255,0,0)" key={elements.length}/>);
			elements.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
				key={elements.length} />);

		}

		for (var i = 0; i < polygons.length - (this.shapes.polygonIsOpened ? 1 : 0); i++) {
			var path = 'M ';
			var p0 = polygons[i][0];
			path += p0[0]*scale + ',' + p0[1]*scale + ' L ';
			for (var j = 1; j < polygons[i].length; j++) {
				var p = polygons[i][j];
				path += ' ' + p[0]*scale + ',' + p[1]*scale;
			}
			path += ' Z'

			var alpha = 0.4;
			if (this.shapes.selectedShape && 
				this.shapes.selectedShape.shapeType == 'POLY' && 
				this.shapes.selectedShape.i == String(i)) {
				alpha = 0.6;
			}

			elements.push(<path stroke="rgba(255,0,0,0.6)" strokeWidth="1" fill={'rgba(51,71,80,'+ alpha +')'} 
				d={path} key={elements.length} onMouseDown={fshape('POLY', i).bind(this)} style={styleLine}/>)
			for (var j = 1; j < polygons[i].length; j++) {
				var p = polygons[i][j];
				elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
					key={elements.length} style={stylePoint} onMouseDown={fx('POLY', [i, j]).bind(this)}/>);
			}
			elements.push(<circle cx={p0[0]*scale} cy={p0[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1"
				key={elements.length} style={stylePoint} 
				onMouseDown={fx('POLY', [i, 0]).bind(this)}/>);
		}

		for (var i in this.shapes.points) {
			var p = this.shapes.points[i];
			var r = '4';

			if (this.shapes.selectedShape && 
				this.shapes.selectedShape.shapeType == 'POINT' && 
				this.shapes.selectedShape.i == String(i)) {
				r = '8'
			}
			elements.push(<circle cx={p[0]*scale} cy={p[1]*scale} r={r} stroke="rgb(255,255,255)" strokeWidth="1.5"
				key={elements.length} style={stylePoint} onMouseDown={fx('POINT', [i]).bind(this)}/>);
		}

		for (var i in this.shapes.lines) {
			var l = this.shapes.lines[i];
			var p1 = l[0];
			if (l.length == 2) {
				var p2 = l[1];

				var lw = 2;
				if (this.shapes.selectedShape && 
					this.shapes.selectedShape.shapeType == 'LINE' && 
					this.shapes.selectedShape.i == String(i)) {
					lw = 5
				}
				elements.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={p2[0]*scale} y2={p2[1]*scale} 
					stroke="rgba(255,165,70,0.9)" key={elements.length} strokeWidth={lw} style={styleLine}
					onMouseDown={fshape('LINE', i).bind(this)}/>);
				elements.push(<circle cx={p2[0]*scale} cy={p2[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1.5"
					key={elements.length} style={stylePoint} onMouseDown={fx('LINE', [i, 1]).bind(this)} />);
				elements.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1.5"
					key={elements.length} style={stylePoint} onMouseDown={fx('LINE', [i, 0]).bind(this)} />);
			} else if (l.length == 1) {
				elements.push(<line x1={p1[0]*scale} y1={p1[1]*scale} x2={cp[0]*scale} y2={cp[1]*scale} 
					stroke="rgb(255,0,0)" key={elements.length}/>);
				elements.push(<circle cx={p1[0]*scale} cy={p1[1]*scale} r="4" stroke="rgb(255,255,255)" strokeWidth="1.5"
					key={elements.length} />);
			}
		}

		return elements;
	}
	render() {
		var mode = this.props.mode;

		return (
			<div>

				<Holst image={this.state.image} 
					width={this.state.width} height={this.state.height}
					holstOnClick={this.holstOnClick.bind(this)} 
					holstMouseMove={this.holstMouseMove.bind(this)}
					holstMouseUp={this.holstMouseUp.bind(this)}
					getSvgElements={this.getSvgElements.bind(this)}
					trashClick={this.trashClick.bind(this)}/>

			</div>
		)
	}
}

export class Holst extends React.Component {
	constructor(props) {
		super(props);

		var img = new Image();

		this.state = {
			imUrl: this.props.image ? this.props.image : '',
			imWidth: 0,
			imHeight: 0,
			width: this.props.width ? parseFloat(this.props.width) : 400,
			height: this.props.height ? parseFloat(this.props.height) : 400,
			scale: 0,
            clearId: 0,
			className: 'First',
            svgElements: this.props.getSvgElements ?
                this.props.getSvgElements(0) : null
		}

		img.onload = function(){
			var imHeight = img.height;
			var imWidth = img.width;
			// this.setState({
			// // 	imWidth: imWidth,
			// // 	imHeight: imHeight,
			// // 	scale: Math.min(this.state.width, this.state.height) /
			// // 		Math.max(imWidth, imHeight) * 0.9
			// // });
            this.setState({
                imWidth: imWidth,
                imHeight: imHeight,
                scale: this.createScale(imHeight, imWidth, this.state.height, this.state.width)
            });
		}.bind(this);

		img.src = props.image;
	}
	createScale(imgHeight, imgWidth, boxHeight, boxWidth){
	    var scaleCreate;
		if ((imgWidth / imgHeight) > (boxWidth / boxHeight)) {
            scaleCreate = boxWidth / imgWidth * 0.9;
        }
        else if ((imgWidth / imgHeight) < (boxWidth / boxHeight)) {
            scaleCreate = boxHeight / imgHeight * 0.9;
        }
        return scaleCreate;
	}
    componentDidUpdate(prevProps, prevState, prevContext) {
		if (this.props.width !== prevProps.width || this.props.height !== prevProps.height) {
		    // console.log('holst')
            this.setState({
                width: this.props.width ? parseFloat(this.props.width): 400 ,
                height: this.props.height ? parseFloat(this.props.height): 400,
                svgElements: this.props.getSvgElements ?
                    this.props.getSvgElements(0) : null
            });
		}
        // if (this.props.showLabels !== prevProps.showLabels || this.props.showCounts !== prevProps.showCounts || prevProps.image === this.props.image) {
        //     this.setState({
        //         svgElements: this.props.getSvgElements ?
        //             this.props.getSvgElements(0) : null
        //     });
        //
        // }
        if (JSON.stringify(this.props.getSvgElements(0)) !== JSON.stringify(this.state.svgElements) && this.state.imUrl === this.props.image) {
            this.setState({
                svgElements: this.props.getSvgElements ?
                    this.props.getSvgElements(0) : null
            });

        }

    }

    componentWillReceiveProps(nextProps) {
		var img = new Image();
		img.onload = function(){
			var imHeight = img.height;
			var imWidth = img.width;
			if (this.state.svgElements === null) {
                // this.setState({
                //     imWidth: imWidth,
                //     imHeight: imHeight,
                //     imUrl: nextProps.image,
                //     scale: Math.min(nextProps.width, nextProps.height) /
                //     Math.max(imWidth, imHeight) * 0.9,
                //     svgElements: nextProps.getSvgElements ?
                //         nextProps.getSvgElements(Math.min(nextProps.width, nextProps.height) /
                //             Math.max(imWidth, imHeight) * 0.9) : null,
                //     className: 'First'
                // });
                this.setState({
                    imWidth: imWidth,
                    imHeight: imHeight,
                    imUrl: nextProps.image,
                    scale: this.createScale(imHeight, imWidth, nextProps.height, nextProps.width),
                    svgElements: nextProps.getSvgElements ?
                        nextProps.getSvgElements(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null,
                    className: 'First'
                });
            }
			if (this.state.imUrl != nextProps.image) {
			    if (imageUrl !== nextProps.image) {
                    imageUrl = nextProps.image;
                    this.setState({
                        className: 'Second'
                    })
                }
                else {
                    this.timeout = setTimeout(
                        () => {
                            // console.log('holst', this.props.getSvgElements);
                            this.setState({
                                imWidth: imWidth,
                                imHeight: imHeight,
                                imUrl: nextProps.image,
                                scale: this.createScale(imHeight, imWidth, nextProps.height, nextProps.width),
                                svgElements: nextProps.getSvgElements ?
                                    nextProps.getSvgElements(this.createScale(imHeight, imWidth, nextProps.height, nextProps.width)) : null,
                                className: 'First'
                            });
                        },
                        300
                    );
                }
			}
		}.bind(this);

		img.src = nextProps.image;
	}
	_getCoords(e) {
		var holst = document.getElementById('holst_svg')
		var holst = ReactDOM.findDOMNode(this.refs.holst);
		var dim = holst.getBoundingClientRect();
		var x = (e.clientX - dim.left);
		var y = (e.clientY - dim.top);
		return [x, y];
	}
	svgOnClick(e) {
	}
	svgOnMouseMove(e) {
		if (this.props.holstMouseMove) {
			var p = this._getCoords(e);
			this.props.holstMouseMove(p[0], p[1], this.state.scale);
			this.setState({});
		}
	}
	svgOnMouseUp(e) {
		if (this.props.holstOnClick) {
			var p = this._getCoords(e);
			this.props.holstOnClick(p[0], p[1], this.state.scale, e);
			this.setState({});
		}
		if (this.props.holstMouseUp) {
			var p = this._getCoords(e);
			this.props.holstMouseUp(p[0], p[1], this.state.scale);
			this.setState({});
		}
	}
	svgOnMouseDown(e) {
		if (this.props.holstMouseDown) {
			var p = this._getCoords(e);
			this.props.holstMouseDown(p[0], p[1], this.state.scale);
			this.setState({});
		}
	}
	render() {

		var imW = this.state.imWidth;
		var imH = this.state.imHeight;
		var scale = this.state.scale;

		var borderPadding = 3;
		var zoomPadding = Math.max(0, this.state.height - imH * scale) / 2. - borderPadding;

		var svgElements = this.state.svgElements;
        // width: imW * scale + "px",
        //     height: imH * scale + "px",

		var holstBox = (
			<div id="holst_box" className={this.state.className} style={{
				backgroundImage: "url(" + this.state.imUrl + ")",
				backgroundSize: "contain",
				backgroundColor: "rgba(0,0,0,0.0)",
				backgroundPosition: "50% 50%",
				backgroundRepeat: "no-repeat",
                width: imW * scale + "px",
                height: imH * scale + "px",
				margin: 'auto',
				verticalAlign: 'middle',
				display: 'inline-block',
				border: '0.1px solid rgba(0,0,0,0.05)'
			}}>

				<svg ref="holst" id='holst_svg' height="100%" width="100%"
					onMouseUp={this.svgOnMouseUp.bind(this)}
					onMouseDown={this.svgOnMouseDown.bind(this)}
					onMouseMove={this.svgOnMouseMove.bind(this)}
					style={{
						margin: 'auto'
					}}>
					{svgElements}
				</svg>

			</div>
		)

		var iconStyle = {
			color: 'rgba(255,255,255,0.5)',
			cursor: 'pointer'
		}

		var showTrash = this.props.trashClick ? true : false;

		return (
			<div>
				<div
                    className="holstBox"
                    style={{
					padding: borderPadding + 'px',
					width: '100%',
					height: this.state.height + 'px',
					boxShadow: 'inset 0px 0px 6px rgba(0,0,0,0.5)',
					backgroundColor: 'rgba(0,0,0,0.06)',
					position: 'relative',
					margin: 'auto'
				}}>

					<div style={{
						position: 'absolute',
						right: '4px',
						top: '10px',
						borderRadius: '6px',
						padding: '0px 4px',
						textShadow: '0px 0px rgba(0,0,0,0.4)',
					}}>

						{showTrash ? (
							<div onClick={function(e){
								if (this.props.trashClick) {
									this.props.trashClick()
									this.setState({});
								}
							}.bind(this)}>
								<i id="mobile" className="fa fa-trash-o fa-3x fa-inverse"
									style={iconStyle}></i>
							</div>) : null}

						<div onClick={function(e){
								this.setState({
									scale: this.state.scale * 1.1
								})
						}.bind(this)}>
							<i id="mobile" className="fa fa-plus-square-o fa-3x fa-inverse"
								style={iconStyle}></i>
						</div>

						<div onClick={function(e){
								this.setState({
									scale: this.state.scale / 1.1
								})
						}.bind(this)}>
							<i id="mobile" className="fa fa-minus-square-o fa-3x fa-inverse"
								style={iconStyle}></i>
						</div>

					</div>

					<div style={{
						width: '100%',
						height: this.state.height - borderPadding*2 + 'px',
						textAlign: 'center',
						overflow: 'auto',
						padding: zoomPadding + 'px ' + 0 + 'px',
					}}>

						{this.state.scale > 0 ? holstBox : null}

					</div>

				</div>

			</div>
		)
	}
}
