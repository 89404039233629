var AppDispatcher = require('../dispatcher/dispatcher.js');
var AppConstants = require('../constants/constants.js');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var MainStore = require('../../../mainStores/Store.js');
var __under = require('underscore');

var state = {
	mainState: {},
	analiticsMethod: 'connect',
	funnels: {},
	dataCharts: [{key: 'Подключения', values: []}, {key: 'Пройденные авторизации', values: []}],
	globalSum: {
		totalSum: 0,
		successSum: 0,
		defeatSum: 0
	},
	dataSms: {},
	uniqueNumbers: {},
	socialData: [],
	statusCreateUnic: false,
	statusCreateSms: false,
	dataTableSms: [],
	dataChartSocial: [],
	sumSms: 0
};

function update(action) {
	state.mainState.insetName = action.state.commonState.insetName;
	state.mainState.selectedObjects = action.state.commonState.selectedObjects;
	state.mainState.max_timestamp = action.state.commonState.max_timestamp;
	state.mainState.min_timestamp = action.state.commonState.min_timestamp;
	state.mainState.password = action.state.commonState.password;
	state.mainState.token = action.state.commonState.token;
	state.mainState.username = action.state.commonState.username;
	state.mainState.commonState = action.state.commonState.username;
};

Date.prototype.daysInMonth = function() {
	return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
};

Date.prototype.yyyymmdd = function() {
	var yyyy = this.getFullYear().toString();
	var mm = (this.getMonth() + 1).toString();
	var dd = this.getDate().toString();
	return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]);
};

function selectAnaliticsMethod(action) {
	state.analiticsMethod = action.method;
};

function getFunnel(action) {
	state.funnels = {};
	state.funnels.data = action.data;
	for (var i = 0; i < state.funnels.data.length; i++) {
		state.funnels.data[i].sumTotal = 0;
		for (var key in state.funnels.data[i].details) {
			state.funnels.data[i].sumTotal = state.funnels.data[i].sumTotal + state.funnels.data[i].details[key].sessions_started
		}
	}
	var sum = state.funnels.data.sort(function (a, b) {
		if (a.sumTotal > b.sumTotal) {
			return -1;
		}
		if (a.sumTotal < b.sumTotal) {
			return 1;
		}
	  return 0;
	});

	state.funnels.data = sum;
	// console.log('store.js', state.funnels);
};

Date.prototype.ddmmyyy = function() {
	var yyyy = this.getFullYear().toString();
	var mm = (this.getMonth() + 1).toString();
	var dd = this.getDate().toString();
	return (dd[1] ? dd : "0" + dd[0]) + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + yyyy;
};

function getChart(action) {
	state.dataCharts[0].values = [];
	state.dataCharts[1].values = [];
	state.globalSum.totalSum = 0;
	state.globalSum.successSum = 0;
	state.globalSum.errorSum = 0;
	var index = state.mainState.statePanel.panelState.dataMall[0].id;
	// console.log('store.js', index, action.data[index]);
	if (action.data[index] !== undefined) {
		for (var i = 0; i < action.data[index].length; i++) {
			var sumConnect = 0;
			var sumSuccessfull = 0;
			for (var key in action.data) {
				state.globalSum.totalSum = state.globalSum.totalSum + action.data[key][i].sessions;
				state.globalSum.successSum = state.globalSum.successSum + action.data[key][i].successful_sessions;
				sumConnect = sumConnect + action.data[key][i].sessions;
				sumSuccessfull = sumSuccessfull + action.data[key][i].successful_sessions;
			}
			var dataConnect = {
				label: +new Date (action.data[index][i].datetime),
				value: sumConnect
			};
			var dataConnectSuccessfull = {
				label: sumConnect - sumSuccessfull,
				value: sumSuccessfull
			};
			state.dataCharts[0].values.push(dataConnect);
			state.dataCharts[1].values.push(dataConnectSuccessfull);
		}
	}
	// var sumConnect = 0;
	// var sumSuccessfull = 0;
	// for (var key in action.data) {
	// 	for (var i = 0; i < action.data[key].length; i++) {
	// 		state.globalSum.totalSum = state.globalSum.totalSum + action.data[key][i].sessions;
	// 		state.globalSum.successSum = state.globalSum.successSum + action.data[key][i].successful_sessions;
	// 		sumConnect = sumConnect + action.data[key][i].sessions;
	// 		sumSuccessfull = sumSuccessfull + action.data[key][i].successful_sessions;
	// 		// console.log('store.js', action.data[key]);
	// 		var dataConnect = {
	// 			label: new Date (action.data[key][i].datetime).ddmmyyy(),
	// 			value: sumConnect
	// 		};
	// 		var dataConnectSuccessfull = {
	// 			label: sumConnect - sumSuccessfull,
	// 			value: sumSuccessfull
	// 		};
	// 	}
	// 	state.dataCharts[0].values.push(dataConnect);
	// 	state.dataCharts[1].values.push(dataConnectSuccessfull);
	// }
	state.globalSum.defeatSum = state.globalSum.totalSum - state.globalSum.successSum;
};

function getSms(action) {
	state.dataSms = {};
	// console.log('store.js', state.uniqueNumbers);
	// state.dataSms = [];
	for (var key in action.data) {
		var arr = [];
		var arr2 = [];
		for (var i = 0; i < action.data[key].length; i++) {
			// console.log('store.js23432', action.data[key].length);
			// for (var j = 0; j < state.uniqueNumbers[key].length; j++) {
			// 	var numberPhone = state.uniqueNumbers[key][j].phone.substring(0, 6)
			// 	+ ' '
			// 	+ state.uniqueNumbers[key][j].phone.substring(6, 9)
			// 	+ ' '
			// 	+ state.uniqueNumbers[key][j].phone.substring(9, 14);
			// 	var numberPhoneSms = '+' + action.data[key][i].phone.substring(0, 1)
			// 	 + ' '
			// 	 + action.data[key][i].phone.substring(1, 4)
			// 	 + ' '
			// 	 + action.data[key][i].phone.substring(4, 7)
			// 	 + ' '
			// 	 + action.data[key][i].phone.substring(7, 11);
			// 	if (numberPhone === numberPhoneSms) {
			// 		console.log('store.js', state.uniqueNumbers[key][j]);
			// 	}
			// }
			
			var objSms = {};
			objSms.key = key;
			var phoneStr = action.data[key][i].phone.replace(/ /g,"");
			var phoneNotPlus = phoneStr.replace("+","");
			objSms.phone = '+' + phoneNotPlus.substring(0, 1)
			 + ' '
			 + phoneNotPlus.substring(1, 4)
			 + ' '
			 + phoneNotPlus.substring(4, 7)
			 + ' '
			 + phoneNotPlus.substring(7, 11);
			objSms.date = +new Date(action.data[key][i].datetime);
			if (state.dataSms.hasOwnProperty(objSms.phone) === false) {
				state.dataSms[objSms.phone] = {
					phone: objSms.phone,
					id: objSms.key,
					date: objSms.date,
					smsNumber: [objSms.phone]
				};
				// console.log('store.jsfalse', state.dataSms);
				// arr2 = [state.dataSms[objSms.phone]];
				// state.dataSms[objSms.phone] = arr2;
			}
			else if (state.dataSms.hasOwnProperty(objSms.phone) === true) {
				// arr = state.dataSms[objSms.phone];
				// var obj = {
				// 	phone: objSms.phone,
				// 	id: objSms.key,
				// 	date: objSms.date
				// };
				state.dataSms[objSms.phone].smsNumber.push(objSms.phone);
				// state.dataSms[objSms.phone] = arr;
				// console.log('store.jstrue', state.dataSms);
			}
			// state.dataSms.push(objSms);
		}
	}
	// state.dataSms = state.dataSms.sort(function (a, b) {
	// 	if (a[2] > b[2]) {
	// 		return 1;
	// 	}
	// 	if (a[2] < b[2]) {
	// 		return -1;
	// 	}
	//   return 0;
	// });
	state.statusCreateSms = true;
	if (state.statusCreateUnic === true || state.statusCreateSms === true) {
		createSmsAnalitics();
		// state.statusCreate = true;
	}
};

function getUniqueNamber(action) {
	// state.uniqueNumbers = [];
	state.uniqueNumbers = {};
	for (var key in action.data) {
		var arr = [];
		for (var i = 0; i < action.data[key].length; i++) {
			var namberPhone = action.data[key][i].phone.substring(0, 6)
			+ ' '
			+ action.data[key][i].phone.substring(6, 9)
			+ ' '
			+ action.data[key][i].phone.substring(9, 14);
			// state.uniqueNumbers.push(namberPhone);
			if (state.uniqueNumbers.hasOwnProperty(namberPhone) === false) {
				state.uniqueNumbers[namberPhone] = {
					phone: namberPhone
				};
			}
			else if (state.uniqueNumbers.hasOwnProperty(namberPhone) === true) {
				arr = [state.uniqueNumbers[namberPhone]];
				var obj = {
					phone: namberPhone
				}
				arr.push(obj);
				state.uniqueNumbers[namberPhone] = arr;
			}
		}
	}
	state.statusCreateUnic = true;
	if (state.statusCreateUnic === true || state.statusCreateSms === true) {
		createSmsAnalitics();
		// state.statusCreate = true;
	}
};

function createSmsAnalitics() { 
	state.dataTableSms = [];
	state.sumSms = 0;
	for (var key in state.dataSms) {
		if (state.uniqueNumbers.hasOwnProperty(key) === false) {
			if (state.dataSms[key].smsNumber instanceof Array === false) {
				var namberSms = 1;
				var phone = state.dataSms[key].phone;
			}
			else if (state.dataSms[key].smsNumber instanceof Array === true) {
				var namberSms = state.dataSms[key].smsNumber.length;
				var phone = state.dataSms[key].phone;
			}
			var obj = {
				phone: phone,
				numberSms: namberSms,
				confirmed: false 
			};
		}
		else if (state.uniqueNumbers.hasOwnProperty(key) === true) {
			if (state.dataSms[key].smsNumber instanceof Array === false) {
				var namberSms = 1;
				var phone = state.dataSms[key].phone;
			}
			else if (state.dataSms[key].smsNumber instanceof Array === true) {
				var namberSms = state.dataSms[key].smsNumber.length;
				var phone = state.dataSms[key].phone;
			}
			var obj = {
				phone: phone,
				numberSms: namberSms,
				confirmed: true 
			};
		}
		state.sumSms = state.sumSms + namberSms;
		state.dataTableSms.push(obj);
	}
};

function getSocialAnalitics(action) {
	state.socialData = [];
	for (var key in action.data) {
		for (var i = 0; i < action.data[key].length; i++) {
			var objSocial = [];
			// objSocial[0] = action.data[key][i].avatar;
			// objSocial[1] = action.data[key][i].birthday;
			// objSocial[2] = action.data[key][i].email;
			// objSocial[3] = action.data[key][i].name;
			// objSocial[4] = action.data[key][i].gender;
			// objSocial[5] = action.data[key][i].profile_url;
			// objSocial[6] = action.data[key][i].provider;
			// objSocial[7] = action.data[key][i].provider_id;
			// objSocial[8] = action.data[key][i].login_dates;
			// objSocial[9] = action.data[key][i].friends_count;

			objSocial[0] = action.data[key][i].avatar;
			objSocial[1] = action.data[key][i].name;
			objSocial[2] = action.data[key][i].birthday;
			objSocial[3] = action.data[key][i].email;
			objSocial[4] = action.data[key][i].login_dates;
			objSocial[5] = action.data[key][i].friends_count;
			objSocial[6] = action.data[key][i].gender;
			objSocial[7] = action.data[key][i].profile_url;
			objSocial[8] = action.data[key][i].provider;
			objSocial[9] = action.data[key][i].provider_id;
			state.socialData.push(objSocial);
		}
	}
}

function updatePanelState(action) {
	state.mainState.statePanel = action.state;
};

function getChartsSocialAnalitics(action) {
	state.dataChartSocial = [];
	var valuess = __under.values(action.data);
	// for (var i = 0; i < valuess[0].length; i++) {
	// 	for (var)
	// }
	var keyss = [];
	 __under.each(valuess, function (elem, index, list) {
		__under.each(elem, function (obj, index, list) {
			keyss.push(__under.keys(obj));
		})
	})
	 var uniqueKeys = __under.without(__under.unique(__under.flatten(keyss)), "datetime")

	if (uniqueKeys.length > 0) {
		for (var i = 0; i < uniqueKeys.length; i++) {
			var dataSourseItems = [];
			for (var j = 0; j < valuess[0].length; j++) {
				if (valuess[0][j].hasOwnProperty(uniqueKeys[i]) === true) {
					var count;
					if (valuess[0][j][uniqueKeys[i]] === 0) {
						count = 0;
					}
					else if (valuess[0][j][uniqueKeys[i]] > 0) {
						count = valuess[0][j][uniqueKeys[i]];
					}
					var obj = {
						label: +new Date(valuess[0][j].datetime),
						value: count
					};
				}
				else if(valuess[0][j].hasOwnProperty(uniqueKeys[i]) === false) {
					var obj = {
						label: +new Date(valuess[0][j].datetime),
						value: 0
					};
				}
				dataSourseItems.push(obj);
			}
			var globalObj = {
				key: uniqueKeys[i],
				values: dataSourseItems
			};
			state.dataChartSocial.push(globalObj);
		}
		// console.log('store.js', state.dataChartSocial);
	}
	else if (uniqueKeys.length === 0) {
		var dataSourseItems = [];
		for (var j = 0; j < valuess[0].length; j++) {
			var obj = {
				label: +new Date(valuess[0][j].datetime),
				value: 0
			};
			dataSourseItems.push(obj);
		}
		var globalObj = {
				key: 'datetime',
				values: dataSourseItems
			};
		state.dataChartSocial.push(globalObj);
	}
	// console.log('store.js12', state.dataChartSocial, uniqueKeys);
}


EventEmitter.prototype._maxListeners = 300

var CHANGE_EVENT = 'change';

var AppStore = assign({}, EventEmitter.prototype, {
	getState: function() {
		return state;
	},
	emitChange: function() {
		this.emit(CHANGE_EVENT);
	},
	addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },
    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },
    route: function(actionType) {
    	switch (actionType) {
    		case AppConstants.UPDATE:
			return update;
			case AppConstants.SELECT_ANALITICS_METHOD:
			return selectAnaliticsMethod;
			case AppConstants.GET_FUNNEL:
			return getFunnel;
			case AppConstants.GET_CHART:
			return getChart;
			case AppConstants.GET_SMS:
			return getSms;
			case AppConstants.GET_UNIQUE_NUMBER:
			return getUniqueNamber;
			case AppConstants.GET_SOCIAL_ANALITICS:
			return getSocialAnalitics;
			case AppConstants.UPDATE_SIDE_PANEL:
			return updatePanelState;
			case AppConstants.GET_CHARTS_SOCIAL_ANALITICS:
			return getChartsSocialAnalitics;
    	}
    }
});

AppDispatcher.register(function(payload){
	var func = AppStore.route(payload.action.actionType);
	if (func != null) {
		func(payload.action);
		AppStore.emitChange();
	};
	return true;
});


module.exports = AppStore; 