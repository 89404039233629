var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var ControlPanekObjectsOfMalls = require('./objectsOfMalls.jsx');
var PeriodObjects = require('./period.jsx');
var Interval = require('./interval.jsx');
var List = require('./objectsList.jsx');

var ControlPanel = React.createClass({
	getInitialState: function () {
		return{
			statusShowList: false
		};
	},
	// _onChange: function() {
	// 	console.log('controlPanel.jsx');
	// },
	// componentDidMount: function () {
 //        AppStore.addChangeListener(this._onChange);
 //        AppStore.addChangeListener(MainActions.getStateControlPanel);
 //    },
 //    componentWillUnmount: function () {
 //        AppStore.removeChangeListener(this._onChange);
 //        AppStore.removeChangeListener(MainActions.getStateControlPanel);
 //    },
	render: function() {
		return (
			<div className='sidebar-container controlPanelContainer'>
				<ControlPanekObjectsOfMalls prop={this.props.prop} />
				<PeriodObjects />
				<Interval />
				<hr />
				<div className='sidebar-techsupport'>
					<a href="mailto:support@getshopster.net" className='sidebar-link' >
						<span className='sdb sdb-mail'></span>
						<span>Поддержка</span>
					</a>
				</div>
			</div>
		);
	}
});

module.exports = ControlPanel;