var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var FixedDataTable = require('fixed-data-table');
var Table = FixedDataTable.Table;
var Column = FixedDataTable.Column;
var Cell = FixedDataTable.Cell;
var CellItem = require('./cell.jsx');
var C3Chart = require("c3-react");

var type = 'barStacked' // {"line","bar","pie", "multiBar","lineBar", 'barStacked'}

var data = [
  {
    key: "dataSource1",
    values: [
      {label: "A", value: 3},
      {label: "B", value: 4},
      {label: "A", value: 5},
      {label: "A", value: 6},
      {label: "A", value: 7},
      {label: "A", value: 7},
      {label: "A", value: 7},
      {label: "A", value: 7},
    ]
  },
  {
    key: "dataSource2",
    values: [
      {label: "X", value: 7},
      {label: "Y", value: 8},
      {label: "X", value: 7},
      {label: "X", value: 10},
      {label: "X", value: -7},
      {label: "X", value: -7},
      {label: "X", value: -7},
      {label: "X", value: -7},
    ]
  }
]

var Social = React.createClass({
	getInitialState: function () {
		return {
			dataCharts: AppStore.getState().dataChartSocial,
			options: {
				padding: {
					top: 20,
					bottom: 20,
					left: 40,
					right: 10
				},
				size: {
					width: 1050,
					height: 300
				},
				subchart: false,
				zoom: false,
				grid: {
					x: false,
					y: true
				},
				labels: false,
				axisLabel: {
					x: "",
					y: "Кол-во"
				},
				onClick: function(d) {
					this.onClick(d);
				}
			},
			data: AppStore.getState().socialData
		};
	},
	internalState: {
        isMounted: null
    },
	_onChange: function () {
		if (this.internalState.isMounted === true) {
			this.setState({
				dataCharts: AppStore.getState().dataChartSocial,
				data: AppStore.getState().socialData
			});
		}
	},
	componentDidMount: function () {
		this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	render: function () {
		// console.log('social.jsx', this.state.data);
		var chartsComponent;
		if (this.state.dataCharts.length === 0) {
			chartsComponent - null;
		}
		else if (this.state.dataCharts.length > 0) {
			chartsComponent = <C3Chart data={this.state.dataCharts} type={type} options={this.state.options}/>
		}
		return (
			<div className='socialBox'>
				{chartsComponent}
				<div style={{marginLeft: '20px', marginTop: '20px', marginBottom: '10px'}}>
					<Table
			        rowsCount={this.state.data.length}
			        rowHeight={80}
			        width={1008}
			        maxHeight={500}
			        headerHeight={50}
			        >
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>Avatar</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'avatarSocial'} />}
			          width={88}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>Имя</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'nameSocial'} />}
			          width={100}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>Дата рождения</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'birthday'} />}
			          width={100}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>Email</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'email'} />}
			          width={170}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>время первого подключения</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'firstTimeSocial'} />}
			          width={150}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>время последнего подключения</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'lastTimeSocial'} />}
			          width={150}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>количество подключений</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'numberOfConnections'} />}
			          width={150}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>число друзей</Cell>}
			          cell={<CellItem data={this.state.data}  mode={'friendsCount'} />}
			          width={100}
			        />
			      </Table>
				</div>
			</div>
		);
	}
});

module.exports = Social;

