var AppDispatcher = require('../mainDispatcher/Dispatcher.js');
var AppConstants = require('../constants/constants.js');
var ControlPanelStore = require('./../modules/controlPanel/stores/store.js');
var AppStore = require('../mainStores/Store.js');


var Actions = {
	setCommonState: function(state) {
		// AppDispatcher.handleViewAction({
		// 	actionType:AppConstants.SET_COMMON_STATE,
		// 	state: state
		// })
		// console.log('Actions.js', state);
		AppStore.setCommonState(state);
	},
	setStateControlPanel: function (state) {
		AppStore.setStateControlPanel(state);
	}
};

module.exports = Actions;