var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var Calendar = require('rc-calendar');
var GregorianCalendar = require('gregorian-calendar');
var dateMin = new GregorianCalendar(require('gregorian-calendar/lib/locale/ru_RU'));
var dateMax = new GregorianCalendar(require('gregorian-calendar/lib/locale/ru_RU'));
var DateTimeFormat = require('gregorian-calendar-format');
var dateFormatter = new DateTimeFormat('yyyy-MM-dd');

Date.prototype.ddmmyyy = function() {
	var yyyy = this.getFullYear().toString();
	var mm = (this.getMonth() + 1).toString();
	var dd = this.getDate().toString();
	return (dd[1] ? dd : "0" + dd[0]) + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + yyyy;
};


var PeriodObjects = React.createClass({
	getInitialState: function () {
		return{
			statusShowCalendarMinDate: false,
			statusShowCalendarMaxDate: false,
			dateShow: null,
			minDate: AppStore.getState().minDate,
			maxDate: AppStore.getState().maxDate
		};
	},
	internalState: {
		statusShowCalendar: false,
		isMounted: null
	},
	setStatusShowCalendarDate: function (e) {
		if (e.target === document.getElementById('min')) {
			if (this.state.statusShowCalendarMinDate === false) {
				this.setState({
					statusShowCalendarMinDate: true,
					statusShowCalendarMaxDate: false
				});
				this.internalState.statusShowCalendar = true;
			}
			else if (this.state.statusShowCalendarMinDate === true) {
				this.setState({
					statusShowCalendarMinDate: false,
					statusShowCalendarMaxDate: false
				});
				this.internalState.statusShowCalendar = false;
			}
		}
		else if (e.target === document.getElementById('max')) {
			if (this.state.statusShowCalendarMaxDate === false) {
				this.setState({
					statusShowCalendarMinDate: false,
					statusShowCalendarMaxDate: true
				});
				this.internalState.statusShowCalendar = true;
			}
			else if (this.state.statusShowCalendarMaxDate === true) {
				this.setState({
					statusShowCalendarMinDate: false,
					statusShowCalendarMaxDate: false
				});
				this.internalState.statusShowCalendar = false;
			}
		}
		AppActions.setStatusShowCalendar(this.internalState.statusShowCalendar);
	},
	_onChange: function () {
		if (this.internalState.isMounted === true) {
			if (AppStore.getState().statusShowCalendar === false) {
				this.setState({
					statusShowCalendarMinDate: false,
					statusShowCalendarMaxDate: false
				});
			}
			this.setState({
				minDate: AppStore.getState().minDate,
				maxDate: AppStore.getState().maxDate
			});
		}
	},
	componentDidMount: function () {
		this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	onChange: function (e) {
		// if (this.state.statusShowCalendarMinDate === true) {
		// 	AppActions.getMinDate(new Date(e.time));
		// 	console.log('onSelectMin', e.time);
		// }
		// else if (this.state.statusShowCalendarMaxDate === true) {
		// 	AppActions.getMaxDate(new Date(e.time));
		// 	console.log('onSelectMax', e.time);
		// }
	},
	onSelect: function (e) {
		if (this.state.statusShowCalendarMinDate === true) {
			AppActions.getMinDate(new Date(e.time));
		}
		else if (this.state.statusShowCalendarMaxDate === true) {
			AppActions.getMaxDate(new Date(e.time));
		}
		AppActions.hideCalendar();
	},
	getToday: function () {
		AppActions.selectToday();
	},
	getWeek: function () {
		AppActions.selectLastWeek();
	},
	getMonth: function () {
		AppActions.selectLastMonth();
	},
	getFullTime: function () {
		AppActions.selectFullTime();
	},
	render: function () {
		dateMin.setTime(new Date(this.state.minDate));
		dateMax.setTime(new Date(this.state.maxDate));
		var titleMinDate = new Date(this.state.minDate).ddmmyyy();
		var titleMaxDate = new Date(this.state.maxDate).ddmmyyy();
		var calendarMin;
		var calendarMax
		var stylaButtonMax;
		var stylaButtonMin;
		if (this.state.statusShowCalendarMinDate === false) {
			calendarMin = null;
			stylaButtonMin = {};
		}
		else if(this.state.statusShowCalendarMinDate === true) {
			calendarMin = <div id='minBox' className='boxCalendarMin'>
							<Calendar showDateInput={false}
								defaultValue={dateMin}
								defaultSelectedValue={dateMin}
								onSelect={this.onSelect}
								onChange={this.onChange}
								formatter={dateFormatter}
								showToday={false}
							 />
						</div>
			stylaButtonMin = {
				boxShadow: '0px 0px 3px white'
			};
		}
		if (this.state.statusShowCalendarMaxDate === false) {
			calendarMax = null;
			stylaButtonMax = {};
		}
		else if(this.state.statusShowCalendarMaxDate === true) {
			calendarMax = <div id='maxBox' className='boxCalendarMax'>
							<Calendar showDateInput={false}
								defaultValue={dateMax}
								defaultSelectedValue={dateMax}
								onSelect={this.onSelect}
								onChange={this.onChange}
								formatter={dateFormatter}
								showToday={false}
							 />
						</div>
			stylaButtonMax = {
				boxShadow: '0px 0px 3px white'
			};
		}
		return (
			<div className='sidebar-period'>
				<section className='controlPanelPeriod'>
					<span className='icon'></span>
					<h2>Период</h2>
					<div style={{clear: 'both'}}></div>
					<span style={stylaButtonMin} id='min' onClick={this.setStatusShowCalendarDate} className='btn calendar min'>{titleMinDate}</span>
					{calendarMin}
					<span style={stylaButtonMax} id='max' onClick={this.setStatusShowCalendarDate} className='btn calendar max'>{titleMaxDate}</span>
					{calendarMax}
					<div className='dropdown'>
						<button data-toggle="dropdown" className='btn dropdown-toggle'>
							Быстрый обзор
							<span className='caret'></span>
						</button>
						<ul role='menu' className='dropdown-menu'>
							<li>
								<a onClick={this.getToday} data-interval="today">Сегодня</a>
							</li>
							<li>
								<a onClick={this.getWeek} data-interval="week">Последняя неделя</a>
							</li>
							<li>
								<a onClick={this.getMonth} data-interval="month">Последний месяц</a>
							</li>
							<li>
								<a onClick={this.getFullTime} data-interval="evrytiem">За все время</a>
							</li>
						</ul>
					</div>
				</section>
			</div>
		);
	}
});

module.exports = PeriodObjects;