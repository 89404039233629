'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _YearPanel = require('../year/YearPanel');

var _YearPanel2 = _interopRequireDefault(_YearPanel);

var _MonthTable = require('./MonthTable');

var _MonthTable2 = _interopRequireDefault(_MonthTable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function goYear(direction) {
  var next = this.state.value.clone();
  next.addYear(direction);
  this.setAndChangeValue(next);
}

function noop() {}

var MonthPanel = _react2["default"].createClass({
  displayName: 'MonthPanel',

  propTypes: {
    onChange: _react.PropTypes.func,
    disabledDate: _react.PropTypes.func,
    onSelect: _react.PropTypes.func
  },

  getDefaultProps: function getDefaultProps() {
    return {
      onChange: noop,
      onSelect: noop
    };
  },
  getInitialState: function getInitialState() {
    var props = this.props;
    // bind methods
    this.nextYear = goYear.bind(this, 1);
    this.previousYear = goYear.bind(this, -1);
    this.prefixCls = props.rootPrefixCls + '-month-panel';
    return {
      value: props.value || props.defaultValue
    };
  },
  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    if ('value' in nextProps) {
      this.setState({
        value: nextProps.value
      });
    }
  },
  onYearPanelSelect: function onYearPanelSelect(current) {
    this.setState({
      showYearPanel: 0
    });
    this.setAndChangeValue(current);
  },
  setAndChangeValue: function setAndChangeValue(value) {
    this.setValue(value);
    this.props.onChange(value);
  },
  setAndSelectValue: function setAndSelectValue(value) {
    this.setValue(value);
    this.props.onSelect(value);
  },
  setValue: function setValue(value) {
    if (!('value' in this.props)) {
      this.setState({
        value: value
      });
    }
  },
  showYearPanel: function showYearPanel() {
    this.setState({
      showYearPanel: 1
    });
  },
  render: function render() {
    var props = this.props;
    var value = this.state.value;
    var locale = props.locale;
    var year = value.getYear();
    var prefixCls = this.prefixCls;
    var yearPanel = void 0;
    if (this.state.showYearPanel) {
      yearPanel = _react2["default"].createElement(_YearPanel2["default"], {
        locale: locale,
        value: value,
        rootPrefixCls: props.rootPrefixCls,
        onSelect: this.onYearPanelSelect
      });
    }
    return _react2["default"].createElement(
      'div',
      { className: prefixCls, style: props.style },
      _react2["default"].createElement(
        'div',
        null,
        _react2["default"].createElement(
          'div',
          { className: prefixCls + '-header' },
          _react2["default"].createElement(
            'a',
            {
              className: prefixCls + '-prev-year-btn',
              role: 'button',
              onClick: this.previousYear,
              title: locale.previousYear
            },
            '«'
          ),
          _react2["default"].createElement(
            'a',
            {
              className: prefixCls + '-year-select',
              role: 'button',
              onClick: this.showYearPanel,
              title: locale.yearSelect
            },
            _react2["default"].createElement(
              'span',
              { className: prefixCls + '-year-select-content' },
              year
            ),
            _react2["default"].createElement(
              'span',
              { className: prefixCls + '-year-select-arrow' },
              'x'
            )
          ),
          _react2["default"].createElement(
            'a',
            {
              className: prefixCls + '-next-year-btn',
              role: 'button',
              onClick: this.nextYear,
              title: locale.nextYear
            },
            '»'
          )
        ),
        _react2["default"].createElement(
          'div',
          { className: prefixCls + '-body' },
          _react2["default"].createElement(_MonthTable2["default"], {
            disabledDate: props.disabledDate,
            onSelect: this.setAndSelectValue,
            locale: locale,
            value: value,
            prefixCls: prefixCls
          })
        )
      ),
      yearPanel
    );
  }
});

exports["default"] = MonthPanel;
module.exports = exports['default'];