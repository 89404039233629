

var React = require('react');
var ReactDOM = require('react-dom');
var AppActions = require('./actions/actions.js');
var AppStore = require('./stores/store.js');
var GlobalStore = require('../../mainStores/Store.js');

var ControlPanel = require('./components/controlPanel.jsx');
var List = require('./components/objectsList.jsx');
var MainActions = require('./../../mainActions/Actions.js');

var stateJSON = 0;

function setStateAppStore(state) {
    if (JSON.stringify(state) !== stateJSON) {
        MainActions.setStateControlPanel(state);
        stateJSON = JSON.stringify(state);
    }
};

var AppControlPanel = React.createClass({
    getInitialState: function () {
        return{
            statusShowList: false,
            listMals: []
        };
    },
    _onChange: function() {
        var listMalls = [];
        // console.log('main.jsx', AppStore.getState());
        for (var i = 0; i < AppStore.getState().malls.length; i++) {
            if (AppStore.getState().malls[i].status === true) {
                var obj = {
                    style: {
                        style: {background: '#45b976'}
                    },
                    value: AppStore.getState().malls[i]
                }
            }
            else if (AppStore.getState().malls[i].status === false) {
                var obj = {
                    style: {
                        style: {background: '#2f3949'}
                    },
                    value: AppStore.getState().malls[i]
                }
            }
            if (AppStore.getState().search === '') {
                obj.indexSearch = 1;
            }
            else if (AppStore.getState().search !== '') {
                var name = AppStore.getState().malls[i].data.name.toLowerCase();
                var search = AppStore.getState().search.toLowerCase();
                obj.indexSearch = name.indexOf(search);
            }
            listMalls.push(obj);
            if (AppStore.getState().search !== '') {
                listMalls = listMalls.sort(function (a, b) {
                    if (a.indexSearch > b.indexSearch) {
                        return 1;
                    }
                    if (a.indexSearch < b.indexSearch) {
                        return -1;
                    }
                  return 0;
                });
            }
        }
        this.setState({
            statusShowList: AppStore.getState().statusShowMalls,
            listMals: listMalls
        });
    },
    componentDidMount: function () {
        AppStore.addChangeListener(this._onChange);
        AppStore.addChangeStoreModuleListener(setStateAppStore);
    },
    componentWillUnmount: function () {
        AppStore.removeChangeListener(this._onChange);
        AppStore.removeChangeStoreModuleListener(setStateAppStore);
    },
    render: function() {
        var panel;
        var panelControl;
        if (AppStore.getState().mainState.insetName === 'HOTSPOT') {
            if (this.state.statusShowList === true) {
                panel = <List  prop={this.state.listMals} />
            }
            else if (this.state.statusShowList === false) {
                panel = <ControlPanel prop={this.state.listMals} />
            }
             return (
                <div className='sidebar controlPanel'>
                                {panel}
                            </div>
            );
        }
        else if (AppStore.getState().mainState.insetName !== 'HOTSPOT') {
            return (
                    null
            );
        }
    }
});


module.exports = AppControlPanel;