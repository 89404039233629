

var React = require('react');
var ReactDOM = require('react-dom');
var AppActions = require('./mainActions/Actions.js');
var AppStore = require('./mainStores/Store.js');

var MainMapComponent = require('./modules/maps/js/main.jsx');
var MapActions = require('./modules/maps/js/actions/actions.js');
var HotSpotActions = require('./modules/radius_analytics/actions/actions.js');
var ControlPanelActions = require('./modules/controlPanel/actions/actions.js');
var MapStore = require('./modules/maps/js/stores/stores.js');

var ControlPanel = require('./modules/controlPanel/main.jsx');
var HotSpot = require('./modules/radius_analytics/main.jsx');
var ControlPanelStore = require('./modules/controlPanel/stores/store.js');
var lang = require('./modules/lang/func.js');

var stateJSON = 0;




function setCommonStateStore(state) {
    if (JSON.stringify(state) !== stateJSON) {
        ControlPanelActions.update(state);
        stateJSON = JSON.stringify(state);
    }
}

var GlobalApp = React.createClass({
    getInitialState: function () {
        return {
            show: false,
            commonState: {}
        };
    },
    _onChangeMainState: function () {
        console.log('mainmain', AppStore.getState());
    },
    _onChangeCommonState: function(state) {
        // console.log('_onChangeCommonState', state)
        if (state.selectedObjects != undefined) {
            if (state.selectedObjects[0] != undefined) {
                if (state.hasOwnProperty('insetName') === true) {
                    AppActions.setCommonState(state);
                } 
            }
        }
    },
    componentDidMount: function () {
        // langFunc.updateCoockie(this.props.params);
        // var cooc2 = document.cookie.split(';');
        // langFunc.getCoockie(cooc2);
        // console.log('mainmain', document.URL.split('/'), lang);
        if (document.URL.split('/')[3] === 'lang') {
            lang.updateCoockie({language: document.URL.split('/')[4]});
        }
        else if (document.URL.split('/')[3] !== 'lang') {
            lang.updateCoockie({language: undefined});
        }
        var cooc2 = document.cookie.split(';');
        lang.getCoockie(cooc2);
        // AppStore.addChangeListener(this._onChangeMainState);
        AppStore.addChangeStoreModuleListener(MapActions.update);
        AppStore.addChangeStoreModuleListener(setCommonStateStore);
        AppStore.addChangeStoreModuleListener(HotSpotActions.update);
        CommonState.addChangeListener(this._onChangeCommonState);
        // ControlPanelStore.addChangeListener(AppActions.getStateControlPanel);
    },
    componentWillMount: function () {
        var cooc2 = document.cookie.split(';');
        if (document.URL.split('/')[3] === 'lang') {
            lang.updateCoockie({language: document.URL.split('/')[4]});
        }
        else if (document.URL.split('/')[3] !== 'lang') {
            lang.updateCoockie({language: undefined});
        }
        lang.getCoockie(cooc2);
        // console.log('componentWillMount', document.cookie);
    },


    hideCalendar: function (e) {
        if (e.target !== document.getElementById('min') && e.target !== document.getElementById('max') && e.target !== document.getElementById('maxBox') && e.target !== document.getElementById('minBox')) {
            if (document.getElementById('maxBox') !== null) {
                if (document.getElementById('maxBox').compareDocumentPosition(e.target) !== 20) {
                    ControlPanelActions.hideCalendar();
                    return;
                }
                return;
            }
            else if (document.getElementById('minBox') !== null) {
                if (document.getElementById('minBox').compareDocumentPosition(e.target) !== 20) {
                    ControlPanelActions.hideCalendar();
                    return;
                }
                return;
            }
            ControlPanelActions.hideCalendar();
        }
    },
    componentWillUnmount: function () {
        AppStore.removeChangeStoreModuleListener(MapActions.update);
        AppStore.removeChangeStoreModuleListener(setCommonStateStore);
        AppStore.removeChangeStoreModuleListener(HotSpotActions.update);
        CommonState.removeChangeListener(this._onChangeCommonState);
        // AppStore.removeChangeListener(this._onChangeMainState);
        // ControlPanelStore.removeChangeListener(AppActions.getStateControlPanel);
    },
    render: function() { 
        return (
            <div id='MainComponent' onClick={this.hideCalendar}>
                <ControlPanel />
                <MainMapComponent />
                <HotSpot />
            </div>
        );
        
    }
});

ReactDOM.render(
    <GlobalApp />,
    document.getElementById('ReactBox')
);
