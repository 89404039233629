var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var RouterItem = require('./RouterItem.jsx');
var RouterArrow = require('./RouterItemArrow.jsx');
var lang = require('../../../lang/func.js');

var Router = React.createClass({
	getInitialState: function () {
		return ({
			filterObj: AppStore.getState().filterObj,
            idSelectRouter: AppStore.getState().idSelectRouter
		});
	},
    componentDidMount: function () {
		AppStore.addChangeListener(this._onChange)
    },
    componentWillUnmount: function () {
		AppStore.removeChangeListener(this._onChange)
    },


    _onChange: function(store) {
		// console.log('run', AppStore.getState().mapPreviewItem);
        this.setState({
			filterObj: AppStore.getState().filterObj,
            idSelectRouter: AppStore.getState().idSelectRouter
            });
    },
	render: function () {
		// console.log('router', this.props.prop[this.props.prop.length - 1]);
		var componentRouterItem, componentArrowItem;
		var filterObj = this.state.filterObj, lengthRouter = this.props.prop.length;
		var idSelectRouter = this.state.idSelectRouter;
		componentRouterItem = this.props.prop.map(function (prop, id) {
			return (
				<RouterItem idSelectRouter={idSelectRouter} id={id} prop={prop} key={id} />
				);
		})
		componentArrowItem = this.props.prop.map(function (prop, id) {
			return (
				<RouterArrow lengthRouter={lengthRouter} filter={filterObj} id={id} prop={prop} key={id} />
				);
		})
		// console.log('router', lang.getState().maps.route.routes.people_at_end_route);
		return (
			<div className='routerSvgBox'>
				<div className='routerTitle'>
					<span>{this.props.id  + 1}</span>
					<span>{lang.getState().maps.route.routes.total_conversion + ' ' + Math.round(this.props.prop[this.props.prop.length - 1].persent * 100) + '%'}</span>
					<span>{lang.getState().maps.route.routes.steps + ': ' + (this.props.prop.length - 1)}</span>
					<span>{lang.getState().maps.route.routes.people_at_end_route + ': ' + this.props.prop[this.props.prop.length - 1].value}</span>
				</div>
				<div className='arrowBox' style={{width: this.props.prop.length * 147 + 'px'}}>
					{componentArrowItem}
				</div>
				<svg width={this.props.prop.length * 147 + 'px'} minWidth='300' height="80">
					{componentRouterItem}
				</svg>
			</div>
			);
	}
});

module.exports = Router;
