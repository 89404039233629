import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Holst} from './holst.jsx';
import DrowerHolst from './drawerHolst.jsx';
import MapAndSvg from "../../../components/mapAndSvg";
// import Store from "../store/store";

var JSONData = 0;
var JSONSvgElements = 0;
var JSONScale = 0;

class InterfaceHolst extends Component {
    //перевести в стайте
    initShapes(shapes) {
        this['shapes'] = shapes ? shapes : {

        };
        this.props.listDrower.edit.initShapes(this.shapes)
    }
    constructor(props) {
        super(props);
        this.state = {
            image: props.image,
            width: props.width,
            xCoor: 0,
            yCoor: 0,
            statusContext: false,
            statusContextPoly: false,
            indexI: 0,
            indexJ: 0,
            statusSvgCloseElem: true,
            markerItem: null,
            coordArr: [0,0],
            height: props.height,
            indexI: 0,
            indexJ:0,
            // убрать
            dataForHolstDraw: {
                box: null,
                boxSvg: null,
                svg: null
            },
            mode: ''
        }
        this.initShapes(props.shapes);

    }


    componentWillMount() {
        document.onkeydown = this.keyDown.bind(this);
        document.onclick = this.onClickBox.bind(this);
        // this.shapes.statusSvgCloseElem = true;
        this.setState({
            statusSvgCloseElem:true,
        });

        //переименновать drowers
        this.props.listDrower.edit.initShapes(this.shapes)
        this.props.listDrower.edit.initProps(this.props)
        this.props.listDrower.edit.initState(this.state)
    }
    updateDraw(shapes){
        var mode = this.props.getMode;
        var shape = shapes.selectedShape;

        if (mode === 'SCALE') {
            if (shapes.statusDelete ===  true) {
                if (shapes.detailStatusScale === "poligons") {
                    this.props.getRouters(shapes.polygons, [], true, null);
                }
                else if (shapes.detailStatusScale === "line") {
                    this.props.getRouters(shapes.lines, [], true, null);
                }
            }
            else if (shapes.statusDelete ===  false) {
                if (shapes.detailStatusScale === "poligons") {
                    this.props.getRouters(shapes.polygons, [], false, null);
                }
                else if (shapes.detailStatusScale === "line") {
                    this.props.getRouters(shapes.lines, [], false, null);
                }
            }
        }

        // console.log('#@#@#@#@#@#@',shapes, mode, shapes.detailStatusScale)
    }


    componentWillReceiveProps(nextProps) {
        // console.log('#@#@#@#@#@#@!!!!!!', nextProps)

        if (nextProps.listDrower.edit !== this.props.listDrower.edit) {
            // nextProps['statusEdit'] = true;
            nextProps.listDrower.edit.initShapes(this.shapes)
            nextProps.listDrower.edit.initProps(nextProps, this.setStatusMenu.bind(this), this.getStatusContextMenu.bind(this), this.onClickPointPoly.bind(this))
            nextProps.listDrower.edit.initState(this.state)
        }
        // console.log('#@#@#@#@#@#@!!!!!!')
        nextProps.listDrower.edit.setStatusEdit(true)
        nextProps.listDrower.edit.componentWillReceiveProps(nextProps)
        for (var i = 0; i < nextProps.listDrower.background.length; i++) {
            // nextProps['statusEdit'] = false;
            // console.log('#@#@#@#@#@#@!!!!!!',this.props.listDrower.background[i])
            nextProps.listDrower.background[i].initShapes(this.shapes)
            nextProps.listDrower.background[i].initProps(nextProps)
            nextProps.listDrower.background[i].initState(this.state)
            nextProps.listDrower.background[i].setStatusEdit(false)
            nextProps.listDrower.background[i].componentWillReceiveProps(nextProps)
        }
        this.setState({
            mode:nextProps.getMode,
        });


    }

    getSvgElements(scale){
        // console.log('getSvgElements', scale)
        var arr = [];
        var obj = {}
        for (var i = 0; i < this.props.listDrower.background.length; i++) {
            arr = arr.concat(this.props.listDrower.background[i].getSvgElements(scale).elem)
            // console.log('getSvgElements',this.props.listDrower.background[i].getSvgElements(scale))
        }
        arr = arr.concat(this.props.listDrower.edit.getSvgElements(scale).elem)
        obj = {
            elem: arr,
            elemDraw: this.props.listDrower.edit.getSvgElements(scale).elemDraw
        }
        // console.log('getSvgElements',obj)
        return obj
    }

    getSvgElementsForDraw(scale){
        // console.log('getSvgElementsForDraw')
        return this.props.listDrower.edit.getSvgElementsForDraw(scale)
    }

    holstOnClick(x, y, scale, event) {
        this.props.listDrower.edit.holstOnClick(x, y, scale, event)

    }
    holstMouseMove(x, y, scale) {
        // console.log('getSvgElementsForDraw')
        this.props.listDrower.edit.holstMouseMove(x, y, scale)
        this.setState({
            coordArr: [Math.round(x/scale),Math.round(y/scale)]
        });
    }
    holstMouseUp(x, y, scale, e) {
        this.props.listDrower.edit.holstMouseUp(x, y, scale, e)
    }
    trashClick() {
        this.props.listDrower.edit.trashClick()
    }
    contextMenu(e, e1){
        console.log('context', e1[0], e1[1]);
        e.preventDefault();
        this.shapes.statusContext = true;
        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        this.shapes.indexI = e1[0];
        this.shapes.indexJ = e1[1];
        this.setState({
        });

        // console.log('context', e1, e2);
    }
    // _getCoords(e) {
    //     var holst = document.getElementById('holst_svg')
    //     // var holst = ReactDOM.findDOMNode(this.refs.holst);
    //     var dim = holst.getBoundingClientRect();
    //     var x = (e.clientX - dim.left);
    //     var y = (e.clientY - dim.top);
    //     return [x, y];
    // }

    getStatusContextMenu(status){
        this.setState({
            statusContext:status,
        });

    }

    onClickContext(e, data){
        this.props.listDrower.edit.onClickContext(e, data)
    }

    keyDown(event){
        this.props.listDrower.edit.keyDown(event)

    }
    onClickPointPoly(e, data, indexArr){
        e.preventDefault();
        this.shapes.arrIndexPoly = indexArr;
        this.shapes.statusContext = true;

        this.shapes.xCoor = e.pageX;
        this.shapes.yCoor = e.pageY - 48;
        this.setState({

        });
    }
    setStatusMenu(status, e, e1,arr){
        // console.log('ddssdcfsfqew')
        // this.shapes.statusContextPoly = status;
        this.setState({
            xCoor:e.pageX,
            yCoor: e.pageY - 48,
            indexI: e1[0],
            indexJ: e1[1],
            arrIndexPoly: arr,
            statusContext: status
        });

    }
    onClickContextMenuPoly(e,status){
        this.props.listDrower.edit.onClickContextMenuPoly(e,status)
    }
    // onEnterPoint(){
    //     // console.log('!!!!!!!');
    //     this.shapes.statusEnnterPoints = true;
    // }
    // onLeavePOint(){
    //     this.shapes.statusEnnterPoints = false;
    // }
    onClickBox(e,r){
        console.log('onClickBox')
        this.setState({
            statusContext:false,
            statusContextPoly:false,
            markerItem: null
        });

        if (this.props.clearMarkerFind !== undefined) {
            this.props.clearMarkerFind(null);
        }
    }
    onChangeHolst(data) {
        if (JSON.stringify(data) !== JSON.stringify(JSONData)) {
            JSONData = data;
            // console.log('!!!!!!',data, this.state.dataForHolstDraw);
            this.setState({
                dataForHolstDraw: data
            });

        }
    }

    render() {
        // console.log('!!!!!!!', this.props.width)
        var mode = this.props.mode;
        var comonentContext = null;
        var funcOnScale = null;
        var contentContextPoly = null;
        if (this.state.statusContext === true && this.state.mode !== 'POLYLINE') {
            contentContextPoly = <div style={{
                position: 'absolute',
                top: this.state.yCoor + 'px',
                left:  `${this.state.xCoor}px`
            }} id="boxContext"
            >
                <div onClick={(e) => this.onClickContextMenuPoly(e, `delete`)}>Удалить</div>
                <div onClick={(e) => this.onClickContextMenuPoly(e, `addRight`)}>Добавить Правее</div>
                <div onClick={(e) => this.onClickContextMenuPoly(e, `addLeft`)}>Добавить левее</div>
            </div>
        }
        if (this.props.onScale !== undefined) {
            funcOnScale = this.props.onScale
        }
        if (this.state.statusContext === true && this.state.mode === 'POLYLINE') {
            comonentContext = <div style={{
                position: 'absolute',
                top: this.state.yCoor + 'px',
                left:  `${this.state.xCoor}px`
            }} id="boxContext"
            >
                <div onClick={(e) => this.onClickContext(e, 1)}>1сек</div>
                <div onClick={(e) => this.onClickContext(e, 20)}>20сек</div>
                <div onClick={(e) => this.onClickContext(e, 30)}>30сек</div>
                <div onClick={(e) => this.onClickContext(e, 60)}>1минута</div>
                <div onClick={(e) => this.onClickContext(e, 120)}>2минут</div>
                <div onClick={(e) => this.onClickContext(e, 180)}>3минут</div>
                <div onClick={(e) => this.onClickContext(e, 300)}>5минут</div>
            </div>
        }
        var funcOnScale = null;
        if (this.props.onScale !== undefined) {
            funcOnScale = this.props.onScale
        }
        return (
            <div id="boxBox"
                 onClick={this.onClickBox.bind(this)}
            >
                {/*<div className="boxCoors">*/}
                    {/*x = {this.state.coordArr[0]} <br/>*/}
                    {/*y = {this.state.coordArr[1]}*/}
                {/*</div>*/}
                {comonentContext}
                {contentContextPoly}
                <Holst
                    image={this.props.image}
                    statusEdit={this.props.statusEdit}
                    ref='xnjnj'
                    elem={this.state.polyLIne}
                    onScale={funcOnScale}
                    button={this.props.button}
                    width={this.props.width} height={this.props.height}
                    holstOnClick={this.holstOnClick.bind(this)}
                    holstMouseMove={this.holstMouseMove.bind(this)}
                    holstMouseUp={this.holstMouseUp.bind(this)}
                    getSvgElements={this.getSvgElements.bind(this)}
                    getSvgElementsDraw={this.getSvgElementsForDraw.bind(this)}
                    trashClick={this.trashClick.bind(this)}
                    dataForHolstDraw={this.onChangeHolst.bind(this)}
                    selectSpeed={this.props.selectSpeed}
                    timeStepItem={this.props.timeStepItem}
                    statusShowImageBackground={this.props.statusShowImageBackground}
                />
            </div>
        );
    }
}

export default InterfaceHolst;
module.exports = InterfaceHolst
