var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var $ = require('jquery');

var ImgPreview = require('./imgPreview.jsx');

var PreviewMap = React.createClass({
	getInitialState: function  () {
		return {
			previewItem: AppStore.getState(). mapPreviewItem,
            roomsTransition: AppStore.getState().roomsTransition,
            uuidPlanSet: AppStore.getState().uuidPlanSet
		};
	},
	internalState: {
		isMounted: null
	},
	_onChange: function () {
		if (this.internalState.isMounted === true) {
			this.setState({
	            previewItem: AppStore.getState().mapPreviewItem,
                roomsTransition: AppStore.getState().roomsTransition,
                uuidPlanSet: AppStore.getState().uuidPlanSet
	        });
		}
    },
    componentDidMount: function () {
    	this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	render: function () {

		// console.log('@@@@@@', this.state.previewItem)
		var count = -1;
		var mode = this.props.mode;
		var roomsTransition = this.state.roomsTransition
		var uuidPlanSet = this.state.uuidPlanSet
		if (this.state.previewItem != null) {
			var previewNodes = this.state.previewItem.map(function(prop, id) {
				count += 1;
				return (
					<ImgPreview uuidPlanSet={uuidPlanSet} mode={mode} roomsTransition={roomsTransition} id={prop.idFloors} index={prop.index} key={id} prop={prop} />
				);
			})
		}
		else if (this.state.previewItem == null) {
			var previewNodes = null;
		}
		if (uuidPlanSet === null) {
            return (
                <div className="previewMapOld">
                    <div className="previewNodeOld">
                        {previewNodes}
                    </div>
                </div>
            );
		}
		return (
			<div className="previewMap">
				<div className="previewNode">
					{previewNodes}
				</div>
			</div>
		);
	}
});



module.exports = PreviewMap;
