import React, {
    Component,
    PropTypes,
} from 'react';

class HolstDraw extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(nextProps.svgElements) !== JSON.stringify(this.props.svgElements)) {
            return true;
        }
        else {
            return false;
        }
    }
    render() {
        // console.log('!@!@');
        // console.log('holst3', this.props.svgElements);
        return (
            <div id="HolstDraw" style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                pointerEvents: 'none'
            }}>
                <svg style={{
                    margin: 'auto'
                }}
                     height="100%" width="100%"
                     id='holstSvgDraw'
                >
                    {this.props.svgElements}
                </svg>
            </div>
        );
    }
}

HolstDraw.propTypes = {};
HolstDraw.defaultProps = {};

export default HolstDraw;
