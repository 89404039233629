module.exports = {
	LOAD_MALL_ERROR: 'LOAD_MALL_ERROR',
	GET_FLOOR_ID: 'GET_FLOOR_ID',
	SET_MOLLS: 'SET_MOLLS',
	SET_MAPS: 'SET_MAPS',
	SET_AREA: 'SET_AREA',
	SET_MONITOR: 'SET_MONITOR',
	SET_TRNSITION: 'SET_TRNSITION',
	CREATE_STATISTICS: 'CREATE_STATISTICS',
	SET_SELECT_ZONE: 'SET_SELECT_ZONE',
	SELECTED_NEW_MALL: 'SELECTED_NEW_MALL',
	DEACTIVE_SELECT_ZONE: 'DEACTIVE_SELECT_ZONE',
	GET_STATUS_LOAD_TRANSITION: 'GET_STATUS_LOAD_TRANSITION',
	UPDATE_MAIN_STATE: 'UPDATE_MAIN_STATE',
	SHOW_ANALITICSmETHOD: 'SHOW_ANALITICSmETHOD',
	SELECTED_OPTIONS: 'SELECTED_OPTIONS',
	SELECTED_OPTIONS_EXCLUSION: 'SELECTED_OPTIONS_EXCLUSION',
	ONCHECK_OPTIONS: 'ONCHECK_OPTIONS',
	GET_STEPS_OPTIONS: 'GET_STEPS_OPTIONS',
	GET_STATUS_FILTER: 'GET_STATUS_FILTER',
	GET_FILTER_SELECT: 'GET_FILTER_SELECT',
	GET_FILTER_SELECT_B: 'GET_FILTER_SELECT_B',
	SET_REQUEST_ROUTE: 'SET_REQUEST_ROUTE',
	GET_TOP: 'GET_TOP',
	GET_NUM_STEPS: 'GET_NUM_STEPS',
	GET_LOAD_STATUS: 'GET_LOAD_STATUS',
	SET_TYPE_SELECT: 'SET_TYPE_SELECT',
	SET_STATUS_INVOLVEMENT: 'SET_STATUS_INVOLVEMENT',
	SET_VALUE_MAC: 'SET_VALUE_MAC',
	SET_LOAD_THERSHOLD: 'SET_LOAD_THERSHOLD',
	GET_THERSHOLD_DATA: 'GET_THERSHOLD_DATA',
	CLEAR_DATA_ROUTE: 'CLEAR_DATA_ROUTE',
	SET_SELECT_TOP_DETAIL: 'SET_SELECT_TOP_DETAIL',
	GET_STATUS_POINT:'GET_STATUS_POINT',
	GET_ROUTER_DATA:'GET_ROUTER_DATA',
	GET_SELECT_ZONE_IN_ROUTER: 'GET_SELECT_ZONE_IN_ROUTER',
	GET_SELECT_ROUTER: 'GET_SELECT_ROUTER',
	GET_DATA_PLAN_SET: 'GET_DATA_PLAN_SET',
	SET_ROOM: 'SET_ROOM',
	SET_FLOOR: 'SET_FLOOR',
	SET_ZONE: 'SET_ZONE',
	SELECT_POLYGON:'SELECT_POLYGON',
	SET_DATA_SELECT_POLYGON:'SET_DATA_SELECT_POLYGON',
	SET_UNEK_USERS:'SET_UNEK_USERS',
    SET_UNEK_USERS_OF_ZONES:'SET_UNEK_USERS_OF_ZONES',
    STATUS_LOAD_ANALITIC:'STATUS_LOAD_ANALITIC',
	GET_UUID_PLAN_SET:'GET_UUID_PLAN_SET',
	ON_CHANGE_SELECT_TYPE_NEW_MAPS:'ON_CHANGE_SELECT_TYPE_NEW_MAPS',
	SHOW_COLOR_COUNT: 'SHOW_COLOR_COUNT',
	ON_CHECK_BACKGROUND:'ON_CHECK_BACKGROUND'
};
