var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');

var RouterArrow = React.createClass({
	render: function () {
		// console.log('routerItem', this.props.filter);
		if (this.props.filter.toA === true) {
			if (this.props.id === this.props.lengthRouter - 1) {
				return (null);
			}
			else if (this.props.id >= 0) {
				return (
					<div>
						<span>{Math.round(this.props.prop.persent * 100) + '%'}</span>
					</div>
					);
			}
		}
		else if (this.props.filter.toA === false) {
			if (this.props.id === 0) {
				return (null);
			}
			else if (this.props.id > 0) {
				return (
					<div>
						<span>{Math.round(this.props.prop.persent * 100) + '%'}</span>
					</div>
					);
			}
		}
	}
});

module.exports = RouterArrow;