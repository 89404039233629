var React = require('react');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');

var ListName = React.createClass({

	render: function() {
		if (this.props.prop.value.status === true) {
			return (
				<li className={this.props.nameClass} style={this.props.propStyle}>{this.props.prop.value.data.name}</li>
			);
		}
		else if(this.props.prop.value.status === false) {
			return(null);
		}
		// console.log('nameListMalls.jsx');
		
	}

});

module.exports = ListName;