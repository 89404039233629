var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var PreviewMap = require('./previewMap.jsx');
var React = require('react');
var $ = require('jquery');
var Zone = require('./svgZone.jsx');
var Icons = require('./svgIcons.jsx');
var StatisticPanel = require('./statisticPanel.jsx');
var getHeatColor = require('../tools/color.js');
var SpinerLoader = require('./spinerLoader.jsx');
var lang = require('../../../lang/func.js');
// import MapAndSvg from './mapAndSvg';
var MapAndSvg = require('./mapAndSvg');
var MapsPlaces = require('../../../mapsZones/Main');
var InterfaceHolst = require('../lib/shopster-lib-js-maps/lib/interfaceHolst');
// import InterfaceHolst from '../lib/shopster-lib-js-maps/lib/interfaceHolst';
// import DrawerPlaces from './places';
import Select from 'react-select';
var DrawerPlaces = require('./places');
var DrowerZones = require('./zones');
var GeometryVSDataManager = require('../../../tools/GeometryVSDataManager');



var MapComponent = React.createClass({
    getInitialState: function () {
        return {
            mode: 'PLACES',
            show: false,
            test: null,
            status: false,
            floor: null,
            mapLink: null,
            style: null,
            styleMap: {
                height: null
            },
            numberStyle: null,
            defaultHeight: null,
            svg: [],
            styleSvgBox: {
                width: null,
                height: null        
            },
            oldStyleSvgBox: {
                width: null,
                height: null 
            },
            iconX: null,
            iconY: null,
            showLabels: false,
            showCounts: false,
            statusLoad: true,
            isMounted: null,
            statusNotData: AppStore.getState().statusNotData,
            typeMapsShow: 'new_maps',
            dataPlaceMaps: AppStore.getState().dataPlaceMaps,
            places: AppStore.getState().places,
            roomsTransition: AppStore.getState().roomsTransition,
            statusLoadSelectPolygons: AppStore.getState().statusLoadSelectPolygons,
            statusSelectPolygons: false,
            rooms: AppStore.getState().rooms,
            markers: AppStore.getState().markers,
            floors: AppStore.getState().floors,
            dataUneqUsers: AppStore.getState().dataUneqUsers,
            uuidPlanSet: AppStore.getState().uuidPlanSet,
            optionTypeNewMaps: AppStore.getState().optionTypeNewMaps,
            typeNewMaps: AppStore.getState().typeNewMaps,
            zonesData: AppStore.getState().zonesData,
            zones: AppStore.getState().zones,
            dataUneqUsersOfZones: AppStore.getState().dataUneqUsersOfZones,
            statusColorCount: AppStore.getState().statusColorCount,
            zonesObjData: AppStore.getState().zonesObjData,
            statusCheckBackground: AppStore.getState().statusCheckBackground
        };
    },
    getMode: function() {
        // console.log('1')
        return this.state.mode;
    },
    internalState: {
        width: null,
        height: null,
        heightMapBox: null,
        oldStyleSvgBox: null,
        retio: null,
        widthRatio: null,
        heightRatio: null,
        persentBox: null,
        isMounted: null,
        wifthMapsBox: 0
    },
    calculateScale: function(inputScalePercent) {
        var scalePercent = Math.max(80, inputScalePercent)
        var component = this;
        return function() {
            var coorectWidth = component.internalState.widthRatio * scalePercent / 100;
            var coorectHeigth = component.internalState.heightRatio * scalePercent / 100;
            component.internalState.width = coorectWidth;
            component.internalState.height = coorectHeigth;
            component.setState({styleMap: {
                height: coorectHeigth,
                width: coorectWidth,
                left: "calc((100% - " + coorectWidth + "px)/2)"
            },
                numberStyle: scalePercent,
                styleSvgBox: {
                    width: coorectWidth,
                    height: coorectHeigth,
                    left: "calc((100% - " + coorectWidth + "px)/2)"
                }
            });
        }
    },
    correctHeight: function () {
        var Image2 = new Image()
        Image2.src = this.props.prop.mapLink;
        if (this.props.prop.mapLink != null &&  window.innerHeight >= 450) {
            var coorPerent = window.innerHeight - (document.getElementById('mapBox').offsetTop + 70);
            this.internalState.height = coorPerent;
            var img = document.getElementById("map");
            var differenceWidth = Image2.naturalWidth - document.getElementById('mapBox').getBoundingClientRect().width;
            var differenceHeight = Image2.naturalHeight - (window.innerHeight - (document.getElementById('mapBox').offsetTop + 70));
            if (differenceWidth < 0 && differenceHeight < 0) {
                if (differenceWidth > differenceHeight) {
                    var coor = window.innerHeight - (document.getElementById('mapBox').offsetTop + 170);
                    this.internalState.persentBox = coor * 0.98;
                    this.internalState.widthRatio = this.internalState.persentBox * (Image2.naturalWidth / Image2.naturalHeight);
                    this.internalState.heightRatio = this.internalState.persentBox;

                    var style = {
                        height: coor
                    };
                    this.internalState.width = this.internalState.widthRatio;
                    this.internalState.height = this.internalState.heightRatio;
                    var stylesvg = {
                            width: this.internalState.widthRatio,
                            height: this.internalState.heightRatio,
                            left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                        };
                    this.setState({style: style, styleMap:{
                        height: this.internalState.heightRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    },
                        numberStyle: 98,
                        styleSvgBox: stylesvg
                    });
                    this.createSvg();
                    return;
                }
                else if (differenceWidth < differenceHeight) {
                    var coor = document.getElementById('mapBox').getBoundingClientRect().width;
                    var coorY = document.getElementById('mapBox').getBoundingClientRect().height;
                    this.internalState.persentBox = coor * 0.98;
                    this.internalState.heightRatio = this.internalState.persentBox * (Image2.naturalHeight / Image2.naturalWidth) + 2;
                    this.internalState.widthRatio = this.internalState.persentBox;
                    this.internalState.width = this.internalState.widthRatio;
                    this.internalState.height = this.internalState.heightRatio;
                    var stylesvg = {
                            width: this.internalState.widthRatio,
                            height: this.internalState.heightRatio,
                            left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                        };

                    this.setState({
                        style: {
                            height: coorPerent
                        },
                        styleMap: {
                            width: this.internalState.widthRatio,
                            left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                        },
                        numberStyle: 98,
                        styleSvgBox: stylesvg
                    });
                    this.createSvg();
                    return;
                }  
            }
            else if (differenceWidth > differenceHeight) {
                var coor = document.getElementById('mapBox').getBoundingClientRect().width;
                var coorY = document.getElementById('mapBox').getBoundingClientRect().height;
                this.internalState.persentBox = coor * 0.98;
                this.internalState.heightRatio = this.internalState.persentBox * (Image2.naturalHeight / Image2.naturalWidth) + 2;
                this.internalState.widthRatio = this.internalState.persentBox;
                this.internalState.width = this.internalState.widthRatio;
                this.internalState.height = this.internalState.heightRatio;
                var stylesvg = {
                        width: this.internalState.widthRatio,
                        height: this.internalState.heightRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    };

                this.setState({
                    style: {
                        height: coorPerent
                    },
                    styleMap: {
                        width: this.internalState.widthRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    },
                    numberStyle: 98,
                    styleSvgBox: stylesvg
                });
                this.createSvg();
                return;
            }
            else if (differenceWidth < differenceHeight) {
                var coor = window.innerHeight - (document.getElementById('mapBox').offsetTop + 70);
                this.internalState.persentBox = coor * 0.98;
                this.internalState.widthRatio = this.internalState.persentBox * (Image2.naturalWidth / Image2.naturalHeight);
                this.internalState.heightRatio = this.internalState.persentBox;

                var style = {
                    height: coor
                };
                this.internalState.width = this.internalState.widthRatio;
                this.internalState.height = this.internalState.heightRatio;
                var stylesvg = {
                        width: this.internalState.widthRatio,
                        height: this.internalState.heightRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    };
                this.setState({style: style, styleMap:{
                    height: this.internalState.heightRatio,
                    left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                },
                    numberStyle: 98,
                    styleSvgBox: stylesvg
                });
                this.createSvg();
                return;
            }
        }

        if (window.innerHeight < 450) {
            var img = document.getElementById("map");
            var differenceWidth = img.naturalWidth - document.getElementById('mapBox').getBoundingClientRect().width;
            var differenceHeight = img.naturalHeight - 300;
            if (differenceWidth < 0 && differenceHeight < 0) {
                if (differenceWidth > differenceHeight) {
                    var coor = window.innerHeight - (document.getElementById('mapBox').offsetTop + 70);
                    this.internalState.persentBox = 300 * 0.98;
                    this.internalState.widthRatio = this.internalState.persentBox * (Image2.naturalWidth / Image2.naturalHeight);
                    this.internalState.heightRatio = this.internalState.persentBox;

                    var style = {
                        height: 300
                    };
                    this.internalState.width = this.internalState.widthRatio;
                    this.internalState.height = this.internalState.heightRatio;
                    var stylesvg = {
                            width: this.internalState.widthRatio,
                            height: this.internalState.heightRatio,
                            left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                        };
                    this.setState({style: style, styleMap:{
                        height: this.internalState.heightRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    },
                        numberStyle: 98,
                        styleSvgBox: stylesvg
                    });
                    this.createSvg();
                    return;
                }
                else if (differenceWidth < differenceHeight) {
                    var coor = document.getElementById('mapBox').getBoundingClientRect().width;
                    this.internalState.persentBox = coor * 0.98;
                    this.internalState.heightRatio = this.internalState.persentBox * (Image2.naturalHeight / Image2.naturalWidth) + 2;
                    this.internalState.widthRatio = this.internalState.persentBox;
                    this.internalState.width = this.internalState.widthRatio;
                    this.internalState.height = this.internalState.heightRatio;
                    var stylesvg = {
                            width: this.internalState.widthRatio,
                            height: this.internalState.heightRatio,
                            left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                        };

                    this.setState({
                        style: {
                            height: 300
                        },
                        styleMap: {
                            width: this.internalState.widthRatio,
                            left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                        },
                        numberStyle: 98,
                        styleSvgBox: stylesvg
                    });
                    this.createSvg();
                    return;
                }  
            }
            if (differenceWidth > differenceHeight) {
                var coor = document.getElementById('mapBox').getBoundingClientRect().width;
                this.internalState.height = 290;
                this.internalState.persentBox = coor * 0.98;
                this.internalState.heightRatio = this.internalState.persentBox * (Image2.naturalHeight / Image2.naturalWidth) + 2;
                this.internalState.widthRatio = this.internalState.persentBox;
                var style = {
                            height: 300
                        };
                this.internalState.width = this.internalState.widthRatio;
                this.internalState.height = this.internalState.heightRatio;
                var stylesvg = {
                        width: this.internalState.widthRatio,
                        height: this.internalState.heightRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    };

                this.internalState.height = coor;
                this.setState({
                    style: style,
                    styleMap: {
                        width: this.internalState.widthRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    },
                    numberStyle: 98,
                    styleSvgBox: stylesvg
                });
            }
            else if (differenceWidth < differenceHeight) {
                this.internalState.height = 290;
                var img = document.getElementById("map");
                this.internalState.persentBox = 300 * 0.98;
                this.internalState.widthRatio = this.internalState.persentBox * (Image2.naturalWidth / Image2.naturalHeight);
                this.internalState.heightRatio = this.internalState.persentBox;
                var style = {
                        height: 300
                    };
                this.internalState.width = this.internalState.widthRatio;
                this.internalState.height = this.internalState.heightRatio;
                var stylesvg = {
                        width: this.internalState.widthRatio,
                        height: this.internalState.heightRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    };
                this.setState({
                    style: style,
                    styleMap: {
                        height: this.internalState.heightRatio,
                        left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                    },
                    numberStyle: 98,
                    styleSvgBox: this.internalState.oldStyleSvgBox
                });
            }
        }
        this.createSvg();
    },
    createSvg: function () {
        // console.log('createSvg', this.internalState.widthRatio);

        var img = document.getElementById("map");
        var Image2 = new Image()
        Image2.src = this.props.prop.mapLink;
        // console.log('createSvg', Image2.naturalWidth, Image2.naturalHeight);
        var arrSvg =[];
        // console.log("Width",document.getElementById('mapBox').getBoundingClientRect().width, this.internalState.widthRatio);
        // console.log("Height",img.naturalHeight);
        if (document.getElementById('mapBox') != null) {
            // var img = document.getElementById("map");
            // this.internalState.persentBox = document.getElementById('mapBox').getBoundingClientRect().height * 0.98;
            // this.internalState.ratio = img.naturalHeight / this.internalState.persentBox;
            // this.internalState.widthRatio = img.naturalWidth / this.internalState.ratio;
            // var persentBox = document.getElementById('mapBox').getBoundingClientRect().height * 0.98;
            // var ratio = img.naturalHeight / persentBox;
            // var widthRatio = img.naturalWidth / ratio;
            if (document.getElementById('mapBox').getBoundingClientRect().width > this.internalState.widthRatio) {
                var style = {
                    width: this.internalState.widthRatio,
                    height: this.internalState.heightRatio,
                    left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
                };
            }
            else if (document.getElementById('mapBox').getBoundingClientRect().width <= this.internalState.widthRatio) {
                var style = {
                    width: this.internalState.widthRatio,
                    height: this.internalState.heightRatio,
                    left: "0px"
                };
            }
        }
        var style = {
            width: this.internalState.widthRatio,
            height: this.internalState.heightRatio,
            left: "calc((100% - " + this.internalState.widthRatio + "px)/2)"
        };
        // this.internalState.width = this.internalState.widthRatio;
        this.internalState.heightMapBox = style.height;
        // this.setState({
        //     styleSvgBox: style,
        //     oldStyleSvgBox: style
        // });
        this.internalState.oldStyleSvgBox = style;
        // console.log('mapConcreateSVG', AppStore.getState().mapSvg.length);
        if (AppStore.getState().mapSvg !== undefined) {
            if (AppStore.getState().mapSvg.length > 0) {
                for (var i = 0; i < AppStore.getState().mapSvg.length; i++) {
                    if (AppStore.getState().mapSvg[i].point != null && AppStore.getState().mapSvg[i].persent != null) {
                        var img = document.getElementById("map");
                        var objSvg = {
                            idRouter: i,
                            data:AppStore.getState().mapSvg[i].data,
                            color: AppStore.getState().mapSvg[i].color,
                            pointX: (AppStore.getState().mapSvg[i].point[0] * AppStore.getState().scale[AppStore.getState().floorIndex] / Image2.naturalWidth) * 100,
                            pointY: (AppStore.getState().mapSvg[i].point[1] * AppStore.getState().scale[AppStore.getState().floorIndex] / Image2.naturalHeight) * 100,
                            persentR: AppStore.getState().mapSvg[i].persent,
                            selectStatus: AppStore.getState().mapSvg[i].selectStatus,
                            zoneId: AppStore.getState().mapSvg[i].id
                        };
                        arrSvg.push(objSvg);
                    }
                    else if (AppStore.getState().mapSvg[i].point != null || AppStore.getState().mapSvg[i].persent != null) {
                        var objSvg = {
                            idRouter: i,
                            data: null,
                            color: null,
                            pointX: null,
                            pointY: null,
                            persentR: null,
                            selectStatus: AppStore.getState().mapSvg[i].selectStatus
                        };
                        arrSvg.push(objSvg);
                    }
                }
                this.setState({
                    test: AppStore.getState().test,
                    status:AppStore.getState().status,
                    floor: AppStore.getState().mallFloors,
                    mapLink: AppStore.getState().mapLink,
                    statusLoad: AppStore.getState().loadStatusStatisitcs,
                    svg: arrSvg,
                    styleSvgBox: style,
                    oldStyleSvgBox: style
                });
                // this.setState({svg: arrSvg});
            }
            else if (AppStore.getState().mapSvg.length == 0) {
                this.setState({svg: []});
            }
        }

        // else {
        //     arrSvg = [];
        // }
        // this.setState({svg: arrSvg});
        // this.calculateEqual();
    },
    _onChange: function () {
        if (this.internalState.isMounted === true) {
            // console.log('mapComponentONchange', AppStore.getState().status);
            if (this.props.prop.mapLink != AppStore.getState().mapLink && document.getElementById("map") != null) {
                this.correctHeight();
            }
            var Image2 = new Image()
            Image2.src = this.props.prop.mapLink;
            // this.internalState.width = null;
            // this.internalState.heightMapBox = null;
            this.setState({
                test: AppStore.getState().test,
                status:AppStore.getState().status,
                floor: AppStore.getState().mallFloors,
                mapLink: AppStore.getState().mapLink,
                statusLoad: AppStore.getState().loadStatusStatisitcs,
                svg: [],
                statusNotData: AppStore.getState().statusNotData,
                dataPlaceMaps: AppStore.getState().dataPlaceMaps,
                places: AppStore.getState().places,
                roomsTransition: AppStore.getState().roomsTransition,
                statusLoadSelectPolygons: AppStore.getState().statusLoadSelectPolygons,
                rooms: AppStore.getState().rooms,
                markers: AppStore.getState().markers,
                floors: AppStore.getState().floors,
                dataUneqUsers: AppStore.getState().dataUneqUsers,
                uuidPlanSet: AppStore.getState().uuidPlanSet,
                optionTypeNewMaps: AppStore.getState().optionTypeNewMaps,
                typeNewMaps: AppStore.getState().typeNewMaps,
                zonesData: AppStore.getState().zonesData,
                zones: AppStore.getState().zones,
                dataUneqUsersOfZones: AppStore.getState().dataUneqUsersOfZones,
                statusColorCount: AppStore.getState().statusColorCount,
                zonesObjData: AppStore.getState().zonesObjData,
                statusCheckBackground: AppStore.getState().statusCheckBackground
            });
            // this.setState({svg: []});
            if (Image2.naturalHeight != 0) {
                this.createSvg();
                this.calculateScale(this.state.numberStyle)();
            }
        }
    },
    componentDidMount: function () {
        this.setState({isMounted: true});
        this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },


    componentWillUnmount: function () {
        this.setState({isMounted: false});
        this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
    mouseEnter: function (e) {
    },
    hadeActiveZone: function (e) {
        if (e.target == document.getElementById('mapSvg')) {
            AppActions.deactivate();
        }
    },
    onClickMaps: function (e) {
        // console.log('mapComponent', e.target);
        if (e.target === document.getElementById('holst_svg')) {
            AppActions.deactivate();
        }
    },
    onSelectPolygons: function (index, status) {
        // console.log('onSelectPolygons', status)
        if (this.state.typeNewMaps.value === 'places') {
            var arr = [];
            for(var i = 0; i < this.state.markers.length; i++) {
                // console.log('onSelectPolygons', GeometryVSDataManager.reportObjWithPlace(this.state.places.name[i]))
                if (i !== index) {
                    if (GeometryVSDataManager.reportObjWithPlace(this.state.markers[i]) !== undefined) {
                        arr.push(GeometryVSDataManager.reportObjWithPlace(this.state.markers[i]))
                    }
                }
            }
            for(var j = 0; j < this.state.floors.length; j++) {
                arr.push(GeometryVSDataManager.reportObjWithFloor(this.state.floors[j]))
                // console.log('onSelectPolygons', GeometryVSDataManager.reportObjWithFloor(this.state.floors[j]))
            }
            this.setState({
                statusSelectPolygons:status
            });
            // console.log('onSelectPolygons', arr)

            // console.log(this.state.places.name)
            AppActions.selectPolygons(this.state.places.name[index], arr.join(','), status, 'places');
        }
        else {
            // console.log('onSelectPolygons', this.state.zones, index)
            var arr = [];
            // for(var i = 0; i < this.state.markers.length; i++) {
            //     // console.log('onSelectPolygons', GeometryVSDataManager.reportObjWithPlace(this.state.places.name[i]))
            //     if (i !== index) {
            //         if (GeometryVSDataManager.reportObjWithPlace(this.state.markers[i]) !== undefined) {
            //             arr.push(GeometryVSDataManager.reportObjWithPlace(this.state.markers[i]))
            //         }
            //     }
            // }
            if (this.state.floors.length > 1) {
                for(var j = 0; j < this.state.floors.length; j++) {
                    if (GeometryVSDataManager.reportObjWithFloor(this.state.floors[j]) !== undefined) {
                        arr.push(GeometryVSDataManager.reportObjWithFloor(this.state.floors[j]))
                    }
                    // console.log('onSelectPolygons', GeometryVSDataManager.reportObjWithFloor(this.state.floors[j]))
                }
            }
            // for (var g = 0; g < this.state.zones.length; g++) {
            //     if (g !== index) {
            //         arr.push(this.state.zones[g].id);
            //     }
            // }
            var firstId = 0;
            for (var i1 = 0; i1 < this.state.zonesData.length; i1++) {
                if (this.state.typeNewMaps.value === this.state.zonesData[i1].group_marker) {
                    for (var i2 = 0;i2 < this.state.zonesData[i1].zones.length; i2++) {
                        console.log('onSelectPolygons', i2, index)
                        // if (i2 !== index) {
                        //     console.log('onSelectPolygons11', this.state.zones, this.state.zonesData[i1].zones[i2].zone_marker, '>>>', this.state.zones[this.state.zonesData[i1].zones[i2].zone_marker].id)
                        //     arr.push(this.state.zones[this.state.zonesData[i1].zones[i2].zone_marker].id);
                        // }
                        if (i2 === index) {
                            firstId = this.state.zones[this.state.zonesData[i1].zones[i2].zone_marker].id
                        }
                    }
                }

                // console.log('onSelectPolygons22', arr)
            }
            for (var s = 0; s < this.state.zonesObjData[this.state.typeNewMaps.value].length; s++) {
                // console.log('onSelectPolygons1', GeometryVSDataManager.reportObjWithZone(this.state.zonesObjData[this.state.typeNewMaps.value][s].zone_marker));
                if (GeometryVSDataManager.reportObjWithZone(this.state.zonesObjData[this.state.typeNewMaps.value][s].zone_marker) !== firstId) {
                    arr.push(GeometryVSDataManager.reportObjWithZone(this.state.zonesObjData[this.state.typeNewMaps.value][s].zone_marker));
                }
                // arr.push(this.state.zones[this.state.zonesData[i1].zones[i2].zone_marker].id);
            }

            // console.log('onSelectPolygons22', arr)
            this.setState({
                statusSelectPolygons:status
            });
            // console.log('onSelectPolygons', arr)

            // console.log(this.state.places.name)
            // console.log('onSelectPolygons', firstId, arr)
            AppActions.selectPolygons(firstId, arr.join(','), status, 'zones');
        }

    },
    updateValue: function(value) {
        // console.log('updateValue', value);
        AppActions.onChangeSelectTypeNewMaps(value);
    },
    showColorCount: function (e) {
        AppActions.showColorCount(!this.state.statusColorCount);
    },
    onCheckBackground: function (e) {
        // this.setState({
        //     statusCheckBackground:!this.state.statusCheckBackground,
        // });
        AppActions.onCheckBackground(!this.state.statusCheckBackground);

    },
    render: function() {
        // console.log('render', this.props)
        var wifthMapsBox = 100;
        var heigthMapsBox = 100;
        var componentNotData = null;
        var statisticPanel = null;
        var styleMapsComponent = {width: 'calc(100% - 272px)'};
        var componentDrower = null;
        var objToDrower = {
            traksPlace: [],
            retailNamePlace: [],
            namePolygonsPlace: [],
            checkListPlace: [],
            rooms: {},
            dataUneqUsers: {}
        };
        if (this.state.typeNewMaps.value === 'places') {
            objToDrower = {
                traksPlace: this.state.places.coords,
                retailNamePlace: this.state.places.retail,
                namePolygonsPlace: this.state.places.name,
                checkListPlace: this.state.places.is_opened,
                rooms: this.state.rooms,
                dataUneqUsers: this.state.dataUneqUsers
            };
            componentDrower = DrawerPlaces;
        }
        else if (this.state.typeNewMaps.value !== 'places') {
            // console.log('DrowerZones', this.state.dataUneqUsersOfZones, AppStore.getState().dataUneqUsersOfZones)
            componentDrower = DrowerZones;
            var objInternal = {
                traksPlace: [],
                retailNamePlace: [],
                namePolygonsPlace: [],
                checkListPlace: [],
                rooms: this.state.zones,
                dataUneqUsers: this.state.dataUneqUsersOfZones
            }
            for (var i1 = 0; i1 < this.state.zonesData.length; i1++) {
                if (this.state.typeNewMaps.value === this.state.zonesData[i1].group_marker) {
                    for (var i2 = 0;i2 < this.state.zonesData[i1].zones.length; i2++) {
                        objInternal.traksPlace.push(this.state.zonesData[i1].zones[i2].coords);
                        objInternal.retailNamePlace.push(this.state.zonesData[i1].zones[i2].zone_marker);
                        objInternal.namePolygonsPlace.push(this.state.zonesData[i1].zones[i2].name);
                    }
                }
                // console.log('DrowerZones', this.state.zonesData[i1], this.state.typeNewMaps.value)
            }
            objToDrower = objInternal;
        }

        // console.log('DrowerZones', objInternal, this.state.typeNewMaps.value)
        if (this.props.panelAnalitics === true) {
            statisticPanel = <StatisticPanel statusSelectPolygons={this.state.statusSelectPolygons} />
            styleMapsComponent = null;
        }
        if (this.props.mode === 'router') {
            styleMapsComponent = {width: '100%'};
        }
        if (document.getElementById('mapZone') !== null) {
            if (this.props.panelAnalitics === true) {
                this.internalState.wifthMapsBox = window.innerWidth - (248 + 272 + 24 + 27);
                heigthMapsBox = window.innerHeight - (document.getElementById('mapZone').offsetTop + 330);
            }
            else {
                this.internalState.wifthMapsBox = window.innerWidth - (248 + 24 + 27);
                heigthMapsBox = 300;
            }
        }
        if (this.state.statusNotData === true) {
            componentNotData = <div className="mapsNotDate">
                <h2>Нет Данных</h2>
            </div>
            heigthMapsBox = 10;
            styleMapsComponent = {width: 'calc(100% - 272px)', minHeight: '139px'};
        }
        var component = this;
        var floorNodes;
        var map;    
        var previewMap;
        var iconsXY = this.state.styleSvgBox;
        if (this.props.prop.floor == null) {
            map = null;
            previewMap = null;
            var svgNode = null;
        }
        else if (this.props.prop.floor != null) {
            if (this.state.svg.length > 0) {
                var widthMap = this.internalState.heightMapBox;
                var svgNode = this.state.svg.map(function (prop, index) {
                    var rad;
                    if (widthMap * prop.persentR / 100 <= 32) {
                        rad = widthMap * (prop.persentR / 100) + 10;
                    }
                    else if (widthMap * prop.persentR / 100 > 32) {
                        rad = widthMap * (prop.persentR / 100) + 10; 
                    }
                    else if(prop.persentR == 0) {
                        rad = 10;
                    }
                    var isSelected = (AppStore.getState().selectedAreaIndex == index);
                    
                    var percentWithSelected = null;
                    if (AppStore.getState().selectedAreaIndex != null) {
                        var selectedAreaDistribution = AppStore.getState().mapSvg[AppStore.getState().selectedAreaIndex].data.distribution;
                        var currentId = AppStore.getState().mapSvg[index].id;

                        var percentWithSelected = 0.0;
                        try {
                            percentWithSelected = selectedAreaDistribution[currentId].percent;
                        } catch (err) {}
                    }
                    if (prop.pointX != null && prop.pointY != null && prop.persentR != null) {
                       return (
                            <g key={index} >
                                <Zone index={index} className='zone' 
                                    prop={prop} cx={prop.pointX + '%'} cy={prop.pointY + '%'} 
                                    isSelected={isSelected} r={rad} percentWithSelected={percentWithSelected} />
                            </g>
                        ); 
                    }
                    else if (prop.pointX == null && prop.pointY == null && prop.persentR == null) {
                        return (
                            <g key={index} >
                            </g>
                        );
                    }
                }); 

                var svgIconsNode = this.state.svg.map(function (prop, index) {
                    if (prop.pointX != null && prop.pointY != null && prop.persentR != null) {
                       return (
                            <g key={index}>

                                <Icons  className='icons'
                                    index={index}
                                    prop={prop}
                                    cx={prop.pointX + '%'}
                                    cy={prop.pointY + '%'}
                                    hrefLink={"/img/icons/map-device-icon.svg"}
                                    r='8' />

                            </g>
                        );
                    }
                    else if (prop.pointX == null && prop.pointY == null && prop.persentR == null) {
                        return (
                            <g key={index} >
                            </g>
                        );
                    }
                }); 

                var svgLabelNode = this.state.svg.map(function (prop, index) {
                    if (prop.pointX != null && prop.pointY != null && prop.persentR != null && AppStore.getState().mapSvg.length > 0) {
                        // console.log('svg', prop);
                       return (
                            <g key={index}>

                                {component.state.showCounts ? (
                                    <text x={prop.pointX + '%'} y={prop.pointY + '%'}
                                        fill='#424242'
                                        fontSize='13'
                                        textAnchor="middle"
                                        transform="translate(0 -10)">
                                        {AppStore.getState().mapSvg[index].data.count}
                                    </text>
                                ) : <text x={prop.pointX + '%'} y={prop.pointY + '%'}
                                          fill='#424242'
                                          fontSize='13'
                                          textAnchor="middle"
                                          transform="translate(0 -10)">
                                    {AppStore.getState().mapSvg[index].data.count}
                                </text>}
                                {component.state.showLabels ? (
                                    <text x={prop.pointX + '%'} y={prop.pointY + '%'}
                                        fill='#424242'
                                        fontSize='13'
                                        textAnchor="middle"
                                        transform="translate(0 20)">
                                        {AppStore.getState().mapSvg[index].data.name + ' ('+ AppStore.getState().mapSvg[index].data.ipoint_marker +')'}
                                    </text>
                                ) : <text x={prop.pointX + '%'} y={prop.pointY + '%'}
                                          fill='#424242'
                                          fontSize='13'
                                          textAnchor="middle"
                                          transform="translate(0 20)">
                                    {AppStore.getState().mapSvg[index].data.name + ' ('+ AppStore.getState().mapSvg[index].data.ipoint_marker +')'}
                                </text>}
                            </g>
                        );
                    }
                    else if (prop.pointX == null && prop.pointY == null && prop.persentR == null && AppStore.getState().mapSvg.length == 0) {
                        return (
                            <g key={index} >
                            </g>
                        );
                    }
                });
            }
            else if (this.state.svg.length == 0) {
                var svgNode = null;
                var svgIconsNode = null;
                var svgLabelNode = null;
            }
            // console.log(',apcomponent', this.state.style.height);
            map = <div style={this.state.style} id="mapBox" className="mapBox">
                    <img id="map" className="map" style={this.state.styleMap} onLoad={this.correctHeight} src={this.props.prop.mapLink} />
                    {/*<div id="svg" onClick={this.hadeActiveZone} className='svg' style={this.state.styleSvgBox} >*/}
                        {/*<svg id='mapSvg'  >*/}
                            {/*{svgNode}*/}
                            {/*{svgLabelNode}*/}
                            {/*{svgIconsNode}*/}
                        {/*</svg>*/}
                    {/*</div>         */}
                </div>
            previewMap = <PreviewMap mode={this.props.mode} />
        }
        var heightMaps = 100;
        if (this.state.style !== null) {
            heightMaps = this.state.style.height;
        }
        // console.log('mapmapmapma', heightMaps);
        var component = this;
        var componentButtonName = null;
        if (this.state.uuidPlanSet === null) {
            componentButtonName = <button className="buttonDataToo" onClick={function(e){component.setState({showLabels: !component.state.showLabels})}}>
            {this.state.showLabels ? lang.getState().maps.intersections.button_hide_names : lang.getState().maps.intersections.button_show_names}
            </button>
        }
        var showLabelInputs = null;

        var spiner = <SpinerLoader />
        // var statisticPanel = null;
        // var styleMapsComponent = null;
        // if (this.props.panelAnalitics === true) {
        //     statisticPanel = <StatisticPanel />
        //     styleMapsComponent = {width: '100%'};
        // }
        var componentMapsNew = null;
        var widthbox = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        var heightbox = (window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height) - 143;

        var styleStepBox = null;
        var widthStepBox = 0;
        var heughtBox = 0;
        // if (this.state.dataFloorItem !== null) {
        //     // this.state.styleStepBox.width
        //     styleStepBox = {
        //         width: this.state.dataFloorItem.width / this.state.dataFloorItem.scale * 20,
        //         height: this.state.dataFloorItem.height / this.state.dataFloorItem.scale * 20,
        //     }
        //     heughtBox = this.state.dataFloorItem.height / this.state.dataFloorItem.scale * 20
        //     widthStepBox = this.state.dataFloorItem.width / this.state.dataFloorItem.scale * 20
        //     // console.log('main', widthStepBox, heughtBox, this.state.dataFloorItem.scale);
        // }
        // console.log('><><><><><><><>', this.props.prop.mapLink)
        var componentSpinerLoader
        if (this.state.statusLoadSelectPolygons === true) {
            componentSpinerLoader = <div className={`boxSpinerLoaderMaps`}>
                <SpinerLoader />
            </div>
        }
        else if (this.state.statusLoadSelectPolygons === false) {
            componentSpinerLoader = null
        }
        var scaleComponents = null;
        var componentPanelBottom = null;
        if (this.state.uuidPlanSet === null) {
            // if (this.state.typeMapsShow === 'new_maps') {
            //     var NewMapsPlace = this.props.componentMapsPlace;
            //     componentMapsNew = <MapsPlaces
            //         planSetUuid={this.state.dataPlaceMaps}
            //         image={this.props.prop.mapLink}
            //         widthMaps={this.internalState.wifthMapsBox}
            //         height={heigthMapsBox}
            //     />
            //
            // }
            // else if (this.state.typeMapsShow === 'old_maps') {
            //     componentMapsNew = <MapAndSvg
            //         heightMapBox={this.internalState.heightMapBox}
            //         getSvgElements={this.state.svg}
            //         width={this.internalState.wifthMapsBox}
            //         height={heigthMapsBox}
            //         onClickMaps={this.onClickMaps}
            //         image={this.props.prop.mapLink}
            //         component = {this}
            //         showLabels={this.state.showLabels}
            //         showCounts={this.state.showCounts}
            //         mode={this.props.mode}
            //     />
            // }
            scaleComponents = <div className="buttonBox" >
            <button onClick={this.calculateScale(this.state.numberStyle + 10.0)} className="increase"></ button>
            <button onClick={this.calculateScale(this.state.numberStyle - 10.0)} className="decrease"></ button>
            </div>
            map = <div style={this.state.style} id="mapBox" className="mapBoxOld">
                <img id="map" className="map" style={this.state.styleMap} onLoad={this.correctHeight} src={this.props.prop.mapLink} />
                <div id="svg" onClick={this.hadeActiveZone} className='svg' style={this.state.styleSvgBox} >
                <svg id='mapSvg'  >
                {svgNode}
                {svgLabelNode}
                {svgIconsNode}
                </svg>
                </div>
            </div>
        }
        else if (this.state.uuidPlanSet !== null) {
            componentMapsNew = <InterfaceHolst
                getMode={this.getMode}
                traksPlace={objToDrower.traksPlace}
                retailNamePlace={objToDrower.retailNamePlace}
                namePolygonsPlace={objToDrower.namePolygonsPlace}
                checkListPlace={objToDrower.checkListPlace}
                onSelectPolygons={this.onSelectPolygons}
                {...this.props}
                statusColorCount={this.state.statusColorCount}
                // width={widthbox}
                // height={heightbox}
                statusShowImageBackground={this.state.statusCheckBackground}
                roomsTransition={this.state.roomsTransition}
                rooms={objToDrower.rooms}
                dataUneqUsers={objToDrower.dataUneqUsers}
                showCounts={this.state.showCounts}
                statusEdit={false}
                listDrower={{
                    edit: componentDrower,
                    background: []
                }}
            />
            componentPanelBottom = <div className={'bottomPanel'}>
                {/*<select className={'selectAnalizis'} name="q" id="q"></select>*/}
                <div className={'selectAnalizis select-up'}>
                    <Select
                        id="state-select"
                        // ref={(ref) => { this.select = ref; }}
                        // onBlurResetsInput={false}
                        // onSelectResetsInput={false}
                        // autoFocus
                        options={this.state.optionTypeNewMaps}
                        simpleValue
                        clearable={false}
                        name="selected-state"
                        onChange={this.updateValue}
                        // disabled={this.state.disabled}
                        value={this.state.typeNewMaps}
                        // onChange={this.updateValue}
                        // rtl={this.state.rtl}
                        // searchable={this.state.searchable}
                    />
                </div>
                {this.state.showCounts ? <div onClick={function(e){component.setState({showCounts: !component.state.showCounts})}} className={'buttonAnalizis twoElement active'}></div> :
                    <div onClick={function(e){component.setState({showCounts: !component.state.showCounts})}} className={'buttonAnalizis twoElement'}></div>}

                {/*<div className={'buttonAnalizis twoElement'}></div>*/}
                {this.state.statusColorCount ? <div onClick={this.showColorCount} className={'buttonAnalizis threeElement active'}></div> : <div onClick={this.showColorCount} className={'buttonAnalizis threeElement'}></div>}
                <input checked={this.state.statusCheckBackground} onChange={this.onCheckBackground} className={'inputShowBackground'} id={'showbackground'} type="checkbox"/>
                <label htmlFor="showbackground">{lang.getState().maps.intersections.background}</label>
            </div>
        }

        // console.log('render', componentSpinerLoader, this.state.statusLoadSelectPolygons)
        var o = (
            <div style={{position: 'relative'}}>
                <div style={styleMapsComponent} className='mapZone' id="mapZone">
                    {previewMap}
                    {showLabelInputs}
                    {map}
                    {scaleComponents}
                    {componentMapsNew}
                    {componentPanelBottom}
                </div>
                {statisticPanel}
                <div style={{clear: 'both'}} ></div>
                {this.props.prop.statusLoad? null : spiner}
                {/*{componentNotData}*/}
            </div>
        )
        // console.log('mapComponent.jsx',heigthMapsBox);
        return (
            <div>
                {o}
                {componentSpinerLoader}
            </div>
        )
    }
});

module.exports = MapComponent;

