import React, {
    Component
} from 'react';
import {Holst} from './holst';
import GeometryVSDataManager from "../../../tools/GeometryVSDataManager";
var AppStore = require('../stores/stores.js');

var Zone = require('./svgZone.jsx');
var Icons = require('./svgIcons.jsx');
var lang = require('../../../lang/func.js');

var JsonStateSvg = 0;

class MapAndSvg extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showLabels: false,
            showCounts: false,
            svgNode: null
        }
        // console.log('mapand!!!!', this);
    }

    onClickMap(e1, e2, p, event){
        this.props.onClickMaps(event);
        // console.log('mapand', this);
    }
    getElementsSvg(scale) {
        // console.log('mapsvg', this);
        if (this.props.getSvgElements.length > 0) {
            var component = this.props.component;
            var widthMap = this.props.heightMapBox;
            var modeItem = this.props.mode;
            var svgNode = this.props.getSvgElements.map(function (prop, index) {
                var rad;
                if (widthMap * prop.persentR / 100 <= 32) {
                    rad = widthMap * (prop.persentR / 100) + 10;
                }
                else if (widthMap * prop.persentR / 100 > 32) {
                    rad = widthMap * (prop.persentR / 100) + 10;
                }
                else if(prop.persentR == 0) {
                    rad = 10;
                }
                var isSelected = (AppStore.getState().selectedAreaIndex == index);

                var percentWithSelected = null;
                if (AppStore.getState().selectedAreaIndex != null) {
                    var selectedAreaDistribution = AppStore.getState().mapSvg[AppStore.getState().selectedAreaIndex].data.distribution;
                    var currentId = AppStore.getState().mapSvg[index].id;

                    var percentWithSelected = 0.0;
                    try {
                        percentWithSelected = selectedAreaDistribution[currentId].percent;
                    } catch (err) {}
                }
                if (AppStore.getState().idSelectRouter === prop.zoneId) {
                    if (prop.pointX != null && prop.pointY != null && prop.persentR != null) {
                        // console.log(prop)
                        return (
                            <g key={index} >
                                <Zone
                                    mode={modeItem}
                                    selectRouter={true}
                                    index={index} className='zone'
                                    prop={prop} cx={prop.pointX + '%'} cy={prop.pointY + '%'}
                                    isSelected={isSelected} r={rad} percentWithSelected={percentWithSelected} />
                            </g>
                        );
                    }
                    else if (prop.pointX == null && prop.pointY == null && prop.persentR == null) {
                        return (
                            <g key={index} >
                            </g>
                        );
                    }
                }
                else {
                    if (prop.pointX != null && prop.pointY != null && prop.persentR != null) {
                        // console.log(prop)
                        return (
                            <g key={index} >
                                <Zone
                                    mode={modeItem}
                                    selectRouter={false}
                                    index={index} className='zone'
                                    prop={prop} cx={prop.pointX + '%'} cy={prop.pointY + '%'}
                                    isSelected={isSelected} r={rad} percentWithSelected={percentWithSelected} />
                            </g>
                        );
                    }
                    else if (prop.pointX == null && prop.pointY == null && prop.persentR == null) {
                        return (
                            <g key={index} >
                            </g>
                        );
                    }
                }
            });
            var modeItem = this.props.mode;
            var svgIconsNode = this.props.getSvgElements.map(function (prop, index) {
                if (prop.pointX != null && prop.pointY != null && prop.persentR != null) {
                    return (
                        <g key={index}>

                            <Icons  className='icons'
                                    index={index}
                                    prop={prop}
                                    cx={prop.pointX + '%'}
                                    cy={prop.pointY + '%'}
                                    mode={modeItem}
                                    hrefLink={"/img/icons/map-device-icon.svg"}
                                    r='8' />

                        </g>
                    );
                }
                else if (prop.pointX == null && prop.pointY == null && prop.persentR == null) {
                    return (
                        <g key={index} >
                        </g>
                    );
                }
            });

            var svgLabelNode = this.props.getSvgElements.map(function (prop, index) {
                if (prop.pointX != null && prop.pointY != null && prop.persentR != null && AppStore.getState().mapSvg.length > 0) {
                    var nameItem;
                    if (AppStore.getState().mapSvg[index].data.name.length <= 11) {
                        nameItem = <tspan x={prop.pointX + '%'} dy={'1em'}
                                         fill='#424242'
                                         fontSize='11'
                                         textAnchor="middle"
                                         transform="translate(0 20)">
                            {AppStore.getState().mapSvg[index].data.name}
                        </tspan>
                    }
                    else {
                        var re=/(.{1,10})/gim;
                        var arrFirst = AppStore.getState().mapSvg[index].data.name.split(' ');
                        var stringNamesecond = '';
                        var arr1 = [];
                        for (var i = 0; i < arrFirst.length; i++) {
                            if (i === arrFirst.length - 1) {
                                if (stringNamesecond.length + arrFirst[i].length >= 12) {
                                    arr1.push(stringNamesecond);
                                    arr1.push(arrFirst[i]);
                                    stringNamesecond = '';
                                }
                                else {
                                    stringNamesecond = stringNamesecond + arrFirst[i] + ' ';
                                    arr1.push(stringNamesecond);
                                    stringNamesecond = '';
                                }
                            }
                            if (stringNamesecond.length + arrFirst[i].length >= 12) {
                                arr1.push(stringNamesecond);
                                stringNamesecond = arrFirst[i];
                            }
                            else {
                                stringNamesecond = stringNamesecond + arrFirst[i] + ' ';
                            }
                        }
                        nameItem = arr1.map((params, id) => {
                            return (
                                <tspan key={id} x={prop.pointX + '%'} dy={'1em'}
                                      fill='#424242'
                                      fontSize='9'
                                      textAnchor="middle"
                                      transform="translate(0 20)">
                                    {params}
                                </tspan>
                            )
                        })
                    }
                    return (
                        <g key={index}>

                            {component.state.showCounts ? (
                                    <text x={prop.pointX + '%'} y={prop.pointY + '%'}
                                          fill='#424242'
                                          fontSize='13'
                                          textAnchor="middle"
                                          transform="translate(0 -10)">
                                        {AppStore.getState().mapSvg[index].data.count}
                                    </text>
                                ) : null}
                            {component.state.showLabels ? <g>
                                    <text x={prop.pointX + '%'} y={prop.pointY + '%'}
                                          fill='#424242'
                                          fontSize='9'
                                          textAnchor="middle"
                                          transform="translate(0 10)">
                                        {nameItem}
                                    </text>
                                </g> : null}

                        </g>
                    );
                }
                else if (prop.pointX == null && prop.pointY == null && prop.persentR == null && AppStore.getState().mapSvg.length == 0) {
                    return (
                        <g key={index} >
                        </g>
                    );
                }
            });
            // console.log('mapandsvg', svgNode);
            return (<g>
                {svgNode}
                {svgIconsNode}
                {svgLabelNode}
            </g>)
        }
        else if (this.props.getSvgElements.length == 0) {
            return (null);
        }

    }
    // shouldComponentUpdate(nextProps, nextState){
    //     return this.state.svgNode !== nextState.svgNode;
    // }
    render() {
        // console.log('mapandsvg', this.props.image);
        return (
            <div>
                <Holst
                    holstOnClick={this.onClickMap.bind(this)}
                    width={this.props.width}
                    height={this.props.height}
                    image={this.props.image}
                    getSvgElements={this.getElementsSvg.bind(this)}
                    showLabels={this.props.showLabels}
                    showCounts={this.props.showCounts}
                />
            </div>
        );
    }
}

// MapAndSvg.propTypes = {};
// MapAndSvg.defaultProps = {};

// export default MapAndSvg;
module.exports = MapAndSvg
