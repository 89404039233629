var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var FixedDataTable = require('fixed-data-table');
var Table = FixedDataTable.Table;
var Column = FixedDataTable.Column;
var Cell = FixedDataTable.Cell;
var CellItem = require('./cell.jsx');

var dataTableList = {
  1170: [{'datetime': 'xxx', 'phone': '123'}, {'datetime': 'xxx', 'phone': '123'}],
  1123: [{'datetime': 'xxx', 'phone': '123'}, {'datetime': 'xxx', 'phone': '123'}]
};

var SmsAnalitics = React.createClass({
	getInitialState: function () {
		return{
			myTableDataList: AppStore.getState().dataTableSms,
		    myTableData: AppStore.getState().uniqueNumbers,
		    sumSms: AppStore.getState().sumSms
		};
	},
	internalState: {
        isMounted: null
    },
	_onChange: function () {
		if (this.internalState.isMounted === true) {
			this.setState({
				myTableDataList: AppStore.getState().dataTableSms,
		    	myTableData: AppStore.getState().uniqueNumbers,
		    	sumSms: AppStore.getState().sumSms
			});
		}
	},
	componentDidMount: function () {
		this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	render: function () {
		var mallId = 'MallId';
		var phone = 'Phone';
		var phoneUnique = 'PhoneUnique';
		var DateTime = 'Date';
		return (
			<div className='smsAnaliticsBox'>
				<div className='tableList'>
					<Table
			        rowsCount={this.state.myTableDataList.length}
			        rowHeight={38}
			        width={410}
			        maxHeight={400}
			        headerHeight={50}
			        >
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>Телефон</Cell>}
			          cell={<CellItem data={this.state.myTableDataList}  mode={phone} />}
			          width={200}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>Кол-во смс</Cell>}
			          cell={<CellItem data={this.state.myTableDataList} mode={'sms'} />}
			          width={100}
			        />
			        <Column
			          header={<Cell className='radusAnaliticsHeadTable'>подтвержден</Cell>}
			          cell={<CellItem data={this.state.myTableDataList} mode={'confirmed'} />}
			          width={110}
			        />
			      </Table>
				</div>
				<div className='sum'>
					<div>
						<span className='radiusNumber'>{this.state.sumSms}</span>
						<span className='radugaDenominationSum'>Всего смс</span>
					</div>
				</div>
			</div>
		);
	}
});


module.exports = SmsAnalitics;