var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var Select = require('react-select').default;
var Filter = require('./filterPanel.jsx');
var Router = require('./Router.jsx');
var $ = require('jquery');
var lang = require('../../../lang/func.js');
var MapComponent = require('./mapComponent');

var RouteRun = React.createClass({
	getInitialState: function () {
		return ({
			options: AppStore.getState().optionsSelect,
			optionsExclusion: AppStore.getState().optionsSelectExclusion,
			valueOptions: AppStore.getState().optionsSelected,
			valueExclusion: AppStore.getState().optionsSelectedExclusion,
			statusCheckBox: AppStore.getState().statusCheck,
			statusCheckBoxExclusion: AppStore.getState().statusCheckExclusion,
			statusStepsOptions: AppStore.getState().statusStepsOptions,
			numSteps: AppStore.getState().numSteps,
			runData: AppStore.getState().runData,
			statusShowRouterBoard: AppStore.getState().statusShowRouterBoard,
			stateTypeSelect: AppStore.getState().statusTypeSelect,
			filterObj: AppStore.getState().filterObj,
			statusInvolvement: AppStore.getState().statusInvolvement,
			mapPreviewItem: AppStore.getState().mapPreviewItem,
			dataThreshold: AppStore.getState().dataThreshold,
            uuidPlanSet: AppStore.getState().uuidPlanSet
		});
	},
	internalState: {
		isMounted: false
	},
	logChange: function (log) {
		// console.log('run', log);
		AppActions.selectedOptions(log);
	},
	log2Change: function (log) {
		AppActions.selectedOptionsExclusion(log);
	},
	onCheck: function(e) {
		// console.log('run', e.target.checked);
		AppActions.onCheck({check: e.target.checked, elem: 'check'})
	},
	onChangeSteps: function (e) {
		// console.log('run', e.target.value);
		AppActions.getStapsOptions(e.target.value);
	},
	onChangeNumSteps: function (e) {
		// console.log('run', e.target.value);
		AppActions.getNumSteps(e.target.value);
	},
	selectType: function (e) {
		// console.log('run', e.target.value);
		AppActions.setTypeSelect(e.target.value);
	},
	onCheckExclusion: function (e) {
		AppActions.onCheck({check: e.target.checked, elem: 'checkExclusion'})
	},
	_onChange: function(store) {
		// console.log('run', AppStore.getState().mapPreviewItem);
		if (this.internalState.isMounted === true) {
			this.setState({
                options: AppStore.getState().optionsSelect,
                optionsExclusion: AppStore.getState().optionsSelectExclusion,
                valueOptions: AppStore.getState().optionsSelected,
                valueExclusion: AppStore.getState().optionsSelectedExclusion,
                statusCheckBox: AppStore.getState().statusCheck,
			statusCheckBoxExclusion: AppStore.getState().statusCheckExclusion,
			statusStepsOptions: AppStore.getState().statusStepsOptions,
			numSteps: AppStore.getState().numSteps,
			runData: AppStore.getState().runData,
			statusShowRouterBoard: AppStore.getState().statusShowRouterBoard,
			stateTypeSelect: AppStore.getState().statusTypeSelect,
			filterObj: AppStore.getState().filterObj,
			statusInvolvement: AppStore.getState().statusInvolvement,
			mapPreviewItem: AppStore.getState().mapPreviewItem,
			dataThreshold: AppStore.getState().dataThreshold,
                uuidPlanSet: AppStore.getState().uuidPlanSet
            });
		}
    },
    onChangeTop: function (e) {
    	AppActions.getTop(e.target.value);
    },
    _handleWindowResize: function (a) {
    	console.log('run', a);
    },
    componentDidMount: function () {
    	this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
        // document.getElementById('extend-region').addEventListener('click', this._handleWindowResize);
    },
    onChangeInvolvement: function (e) {
    	// console.log('run', e.target.value);
    	AppActions.setStatusInvolvement(e.target.value);
    },
    onChangeThreshold: function (e) {
    	// body...
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	render: function () {
		// console.log('run', Select);
		var componentSelectOfStep, componentRouter, classNameRouter = 'routerBox notData', classNameLoader = 'notLoader', componentNotData, height = '0px', componentOptionNode, disabledType = 'block';
		if (this.state.runData.length === 0) {
			componentNotData = <span>{lang.getState().maps.route.no_data}</span>
		}
		else if (this.state.runData.length > 0) {
			componentNotData = null
		}
		if (this.state.statusShowRouterBoard === true) {
			classNameRouter = 'routerBox dataToo';
			classNameLoader = 'notLoader'
		}
		else if (this.state.statusShowRouterBoard === false) {
			classNameRouter = 'routerBox notData';
			classNameLoader = 'loader'
			componentNotData = null
		}
		componentRouter = this.state.runData.map(function (prop, id) {
			return (
				<Router id={id} key={id} prop={prop} />
				);
		})
		var componentImage = this.state.mapPreviewItem.map(function (prop, id) {
			// console.log('map', prop);
			if (+prop.idFloors < 0) {
				return (null);
			}
			return (
				<img key={id} src={prop.link} />
			);
		});
		componentOptionNode = this.state.dataThreshold.map(function (prop, id) {
			var name;
			if (lang.getState().bigCalendar === 'ru') {
				name = prop.name_ru;
			}
			else if (lang.getState().bigCalendar === 'en') {
				name = prop.threshold;
			}
			return (
				<option key={id} value={prop.threshold}>{name}</option>
			);
		});
		if (this.state.mapPreviewItem.length > 0) {
			height = '420px';
		}


		var componentListMaps = null;
		if (this.state.uuidPlanSet === null){
            componentListMaps = <div style={{height: height}} className='previewImgRouterBox'>
                <div className='previewImgRouter'>
                    {componentImage}
                </div>
            </div>
        }
		// console.log('run', this.state.uuidPlanSet)
		if (this.state.statusStepsOptions === 'exactly') {
			componentSelectOfStep = <select name='hoursFrom' onChange={this.onChangeNumSteps} defaultValue={this.state.numSteps}>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
							<option value={4}>4</option>
							<option value={5}>5</option>
							<option value={6}>6</option>
							<option value={7}>7</option>
							<option value={8}>8</option>
							<option value={9}>9</option>
							<option value={10}>10</option>
							<option value={11}>11</option>
							<option value={12}>12</option>
							<option value={13}>13</option>
							<option value={14}>14</option>
						</select>
						if (this.state.filterObj.top === true) {
				componentSelectOfStep = <select name='hoursFrom' onChange={this.onChangeNumSteps} defaultValue={this.state.numSteps}>
							<option value={0}>0</option>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
							<option value={4}>4</option>
							<option value={5}>5</option>
							<option value={6}>6</option>
							<option value={7}>7</option>
							<option value={8}>8</option>
							<option value={9}>9</option>
							<option value={10}>10</option>
							<option value={11}>11</option>
							<option value={12}>12</option>
							<option value={13}>13</option>
							<option value={14}>14</option>
						</select>
					}
			}
		else if (this.state.statusStepsOptions === 'range') {
			componentSelectOfStep = <div>
							<select name='hoursFrom' onChange={this.onChangeNumSteps} defaultValue={'09'}>
							<option value="01">от 2</option>
							<option value="01">от 3</option>
							<option value="01">от 4</option>
							<option value="01">от 5</option>
							<option value="01">от 6</option>
							<option value="01">от 7</option>
							<option value="01">от 8</option>
							<option value="01">от 9</option>
							<option value="01">от 10</option>
							<option value="01">от 11</option>
							<option value="01">от 12</option>
							<option value="01">от 13</option>
							<option value="01">от 14</option>
						</select>
						<select name='hoursFrom' onChange={this.onChangeNumSteps} defaultValue={'09'}>
							<option value="01">до 2</option>
							<option value="01">до 3</option>
							<option value="01">до 4</option>
							<option value="01">до 5</option>
							<option value="01">до 6</option>
							<option value="01">до 7</option>
							<option value="01">до 8</option>
							<option value="01">до 9</option>
							<option value="01">до 10</option>
							<option value="01">до 11</option>
							<option value="01">до 12</option>
							<option value="01">до 13</option>
							<option value="01">до 14</option>
						</select>
					</div>
		}
		else if (this.state.statusStepsOptions === 'arbitrarily') {
			componentSelectOfStep = null;
		}
		var statusDesabledArbitrarily = false;
		if (this.state.filterObj.fromA === true || this.state.filterObj.toA === true) {
			statusDesabledArbitrarily = true;
		}
		if (this.state.filterObj.top === true) {
			disabledType = 'none';
			statusDesabledArbitrarily = true;
		}
		// console.log('run', this.state.statusInvolvement);
		// console.log('run', lang.getState().maps.route.data_loading);
		return(
			<div className='routeBox'>
				<div className='headerSettings'>
					<select name='selectObjects' onChange={this.selectType} defaultValue={this.state.stateTypeSelect}>
						<option value="monitor">{lang.getState().maps.route.first_panel.type_point.point}</option>
						<option value="raw_zone">{lang.getState().maps.route.first_panel.type_point.zone}</option>
						<option value="floor">{lang.getState().maps.route.first_panel.type_point.floor}</option>
					</select>
					<select  name='hoursFrom' onChange={this.onChangeTop} defaultValue={'09'}>
						<option value="5">{lang.getState().maps.route.first_panel.type_top.top_5}</option>
						<option value="10">{lang.getState().maps.route.first_panel.type_top.top_10}</option>
						<option value="20">{lang.getState().maps.route.first_panel.type_top.top_20}</option>
					</select>
					<div className='steps'>
						{lang.getState().maps.route.first_panel.type_steps.title}
						<select name='hoursFrom' onChange={this.onChangeSteps} value={this.state.statusStepsOptions} defaultValue={this.state.statusStepsOptions}>
							<option disabled={statusDesabledArbitrarily} value="arbitrarily">{lang.getState().maps.route.first_panel.type_steps.arbitrarily}</option>
							<option value="exactly">{lang.getState().maps.route.first_panel.type_steps.exactly}</option>
							<option disabled value="range">{lang.getState().maps.route.first_panel.type_steps.range}</option>
						</select>
						{componentSelectOfStep}
					</div>
					<div className='steps'>
						{lang.getState().maps.route.engagement_threshold}
						<select onChange={this.onChangeInvolvement} value={this.state.statusInvolvement} defaultValue={this.state.statusInvolvement}>
							{componentOptionNode}
						</select>
					</div>
					<div style={{clear: 'both'}}></div>
					<div className='selectObjectsExclusion'>
						<div className='inputselect'>
							<input id='selectObjects' checked={this.state.statusCheckBox} onChange={this.onCheck} type='checkbox' />
							<label htmlFor='selectObjects'>{lang.getState().maps.route.first_panel.input_it_contains}</label>
						</div>
						<div id='objectsSelect1' className='objectsList'>
							<Select name="form-field-name"
							disabled={this.state.statusCheckBox === false}
							noResultsText={lang.getState().maps.route.the_list_is_loading}
							multi={true}
							placeholder={lang.getState().maps.route.select}
							inputProps={{'data-foo': 'bar'}}
							onChange={this.logChange}
	    					value={this.state.valueOptions} options={this.state.options}/>
						</div>
						{/*<div className='inputselect'>
							<input id='selectExclusion' checked={this.state.statusCheckBoxExclusion} onChange={this.onCheckExclusion} type='checkbox' />
							<label htmlFor='selectExclusion'>исключает</label>
						</div>
						<div style={{marginRight: '0px'}} className='objectsList'>
							<Select name="form-field-name"
							disabled={this.state.statusCheckBoxExclusion === false}
							noResultsText='Идет загрузка списка…'
							multi={true}
							placeholder='Выбрать..'
							onChange={this.log2Change}
	    					value={this.state.valueExclusion} options={this.state.optionsExclusion}/>
						</div>*/}
					</div>
				</div>
				<Filter prop={this.props.prop} />

                {componentListMaps}
                <div style={{position: 'relative', height: '10px'}}>
                    <div className={classNameLoader}>
                        <span>{lang.getState().maps.route.data_loading}</span>
                    </div>
                </div>
				<div className={classNameRouter}>
				{componentNotData}
					{componentRouter}
				</div>
			</div>
			);
	}
});

module.exports = RouteRun;
