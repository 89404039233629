var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var ListNameMalls = require('./nameListMalls.jsx');

var Objects = React.createClass({
	getInitialState: function () {
		return{
			data: AppStore.getState().dataMall,
			statusLoad: AppStore.getState().statusLoad
		};
	},
	internalState: {
		isMounted:null
	},
	_onChange: function () {
		if (this.internalState.isMounted === true) {
			this.setState({
				data: AppStore.getState().dataMall,
				statusLoad: AppStore.getState().statusLoad
			});
		}
	},
	componentDidMount: function () {
		this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	showListMalls: function() {
		AppActions.showListMalls();
	},
	render: function() {
		var nameMall;
		var styleBackground;
		var className;
		if (this.state.statusLoad === true) {			styleBackground = {
				background: 'rgb(127, 92, 214)'
			};
			className = null;
			nameMall = this.props.prop.map(function(prop, id) {
				return (
					<ListNameMalls key={id} nameClass={className} propStyle={styleBackground} prop={prop} />
				);
			});
		}
		else if (this.state.statusLoad === false) {
			styleBackground = {
				height: '28px',
				boxShadow: 'none'
			};
			className = 'load';
			nameMall = <li className={className} style={styleBackground} >{null}</li>;
		}
		return(
			<div className='boxObjectsOfMalls'>
				<section className='controlPanelObjectsOfMalls'>
					<span className='icon'></span>
					<h2>Объекты</h2>
					<div style={{clear: 'both'}}></div>
					<ul className='object-list ps-container'>
						{nameMall}
					</ul>
					<button onClick={this.showListMalls} className='btn selector'>Выбрать</button>
				</section>
			</div>
		);
	}
});

module.exports = Objects;