/*
 * ru-RU (Moscow) locale
 * @ignore
 * @author plandem@gmail.com
 */
"use strict";

module.exports = {
  // in minutes
  timezoneOffset: 3 * 60,
  firstDayOfWeek: 1,
  minimalDaysInFirstWeek: 1
};