var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var C3Chart = require("c3-react");
var FixedDataTable = require('fixed-data-table');
var Table = FixedDataTable.Table;
var Column = FixedDataTable.Column;
var Cell = FixedDataTable.Cell;
var CellItem = require('./cell.jsx');
var d3 = require('d3');
var Funnel = require('./funnel.jsx');


var type = "multiBar" // {"line","bar","pie", "multiBar","lineBar"}



var HotSpot = React.createClass({
	getInitialState: function () {
		return {
			options: {
				padding: {
					top: 20,
					bottom: 20,
					left: 40,
					right: 10
				},
				size: {
					width: 720,
					height: 300
				},
				subchart: false,
				zoom: false,
				grid: {
					x: false,
					y: true
				},
				labels: false,
				axisLabel: {
					x: "",
					y: "Кол-во"
				},
				onClick: function(d) {
					this.onClick(d);
				}
			},
			funnels: AppStore.getState().funnels.data,
			dataChart: AppStore.getState().dataCharts,
			globalConnectData: AppStore.getState().globalSum
		};
	},
	internalState: {
        isMounted: null
    },
	onClick: function (d) {
		var categories = this.categories(); //c3 function, get categorical labels
		console.log(d);
		console.log("you clicked {" + d.name + ": " + categories[d.x] + ": " + d.value + "}");
	},
	_onChange: function () {
		if (this.internalState.isMounted === true) {
			this.setState({
				funnels: AppStore.getState().funnels.data,
				dataChart: AppStore.getState().dataCharts,
				globalConnectData: AppStore.getState().globalSum
			});
		}
	},
	componentDidMount: function () {
		this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
	render:function () {
		var funnels;
		if (this.state.funnels !== undefined) {
			funnels = this.state.funnels.map(function(prop, id) {
		    	return (<Funnel key={id} prop={prop} />);
		    })
		}
		else if(this.state.funnels === undefined) {
			funnels = null;
		}
		return (
			<div className='hotSpot'>
				<C3Chart data={this.state.dataChart} type={type} options={this.state.options}/>
				<div className='sum'>
					<div>
						<span className='radiusNumber'>{this.state.globalConnectData.totalSum}</span>
						<span className='radugaDenominationStart'>Подключений</span>
					</div>
					<div>
						<span className='radiusNumber'>{this.state.globalConnectData.successSum}</span>
						<span className='radugaDenominationSuccess'>Успешных</span>
					</div>
					<div>
						<span className='radiusNumber'>{this.state.globalConnectData.defeatSum}</span>
						<span className='radugaDenominationError'>Неуспешных</span>
					</div>
				</div>
				<div className="radiusFunnelBox">
					<h4>Статистика по типам подключения hotspot wifi</h4>
					{funnels}
				</div>
			</div>
		);
	}
});

module.exports = HotSpot;
