var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var getHeatColor = require('../tools/color.js');

var Zone = React.createClass({
	mouseEnter: function () {
	},
	render: function () {
		// console.log('zone', this.props.mode);
		if (this.props.mode === 'router') {
            var color = 'rgba(74, 187, 137, .3)';
		}
		else if (this.props.mode !== 'router') {
            var color = (this.props.percentWithSelected != null) ? getHeatColor(this.props.percentWithSelected / 100.0) : 'rgba(74, 187, 137, .3)';
		}
		if (this.props.selectRouter === true) {
            return (
				<circle stroke={this.props.isSelected ? '#546e7a' : null}
						strokeWidth={this.props.isSelected ? '2' : null}
						className='zone'  cx={this.props.cx} cy={this.props.cy}
						fill={`rgba(0,0,0,.5)`}
						r={this.props.r} />
            );
		}
		else {
            return (
				<circle stroke={this.props.isSelected ? '#546e7a' : null}
						strokeWidth={this.props.isSelected ? '2' : null}
						className='zone'  cx={this.props.cx} cy={this.props.cy}
						fill={this.props.isSelected ? 'rgba(255, 255, 255, 1.0)' : color}
						r={this.props.r} />
            );
		}
	}
});

module.exports = Zone;
