/**
 * Created by kirilldrozdov on 30.03.17.
 */
var state = {
    image: '',
    uuid: '',
    globalUuid: '',
    dataTracks: null,
    dataPlans: null,
    floor: null,
    polygons_in: [],
    polygons_out: [],
    poligons_bounds: [],
    dataFloorItem: null,
    statusFinishSteps: false,
    setZoneList: [],
    nameZoneList: [],
    placeList:[],
    listBrand:[],
    listPoint:[],
    statusLoad:false,
    listMarkers:[]
};


var Store;
Store = {
    moduleChangeStoreListeners: [],
    addChangeStoreModuleListener: function(callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) == -1) {
            this.moduleChangeStoreListeners.push(callback);
        }
    },
    removeChangeStoreModuleListener: function (callback) {
        if (this.moduleChangeStoreListeners.indexOf(callback) != -1) {
            this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
        }
    },
    emitChangeToModuleListeners: function () {
        for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
            this.moduleChangeStoreListeners[i](state);
        }
    },
    successLoad(status){
        state.statusFinishSteps = status;
        this.emitChangeToModuleListeners();
    },
    getUuid(uuid){
        state.globalUuid = uuid;
        state.dataTracks = null;
        this.emitChangeToModuleListeners();
    },
    // deleteTracks(index){
    //     state.dataTracks.tracks.splice(index,1);
    //     this.emitChangeToModuleListeners();
    // },
    resetDate(){
        state = {
            image: '',
            uuid: '',
            globalUuid: '',
            dataTracks: null,
            dataPlans: null,
            floor: null,
            polygons_in: [],
            polygons_out: [],
            poligons_bounds: [],
            dataFloorItem: null,
            statusFinishSteps: false,
            setZoneList: [],
            nameZoneList: [],
            placeList:[],
            listBrand:[],
            listPoint:[],
            statusLoad:false,
            listMarkers:[]
        };
        this.emitChangeToModuleListeners();
    },
    setStatusLoad(status){
        state.statusLoad = status;
        this.emitChangeToModuleListeners();
    },
    update(data){
        console.log('store', data);
        state.listMarkers = [];
        for (var i = 0; i < data.plans.length; i++) {
            if (data.plans[i].places !== null) {
                for (var j = 0; j < data.plans[i].places.length; j++) {
                    var obj = {
                        floor: data.plans[i].floor,
                        marker: data.plans[i].places[j].title,
                        type: 'Places',
                        value: `${data.plans[i].places[j].title} (${data.plans[i].floor} Этаж Places)`,
                        label: `${data.plans[i].places[j].title} (${data.plans[i].floor} Этаж Places)`
                    }
                    state.listMarkers.push(obj);
                }
            }
            // if (data.plans[i].device_installations.zones !== undefined) {
            //     for (var g = 0; g < data.plans[i].device_installations.zones.length; g++) {
            //         var obj = {
            //             floor: data.plans[i].floor,
            //             marker: data.plans[i].device_installations.zones[g].name,
            //             type: 'IPoint',
            //             value: `${data.plans[i].device_installations.zones[g].name} (${data.plans[i].floor} Этаж IPoint)`,
            //             label: `${data.plans[i].device_installations.zones[g].name} (${data.plans[i].floor} Этаж IPoint)`
            //         }
            //         state.listMarkers.push(obj);
            //     }
            // }

        }
        // console.log(data.plans[0])
        if (state.floor ===  null) {
            state.floor = data.plans[0].floor;
            state.image = data.plans[0].image;
            state.dataTracks = data.plans[0];
            state.dataPlans = data;
            state.uuid = data.plans[0].uuid;
            if (data.plans[0].polygons_in !== undefined) {
                state.polygons_in = data.plans[0].polygons_in;
            }
            if (data.plans[0].polygons_out !== undefined) {
                state.polygons_out = data.plans[0].polygons_out;
            }
            if (data.plans[0].perimeter !== undefined) {
                state.poligons_bounds = data.plans[0].perimeter;
            }
            state.dataFloorItem = data.plans[0];
            state.setZoneList = data.plans[0].zone_groups;
            if (data.plans[0].places !== null) {
                state.placeList = data.plans[0].places;
            }
            else if (data.plans[0].places === null) {
                state.placeList = []
            }
            // if (data.plans[0].device_installations !== null) {
            //     // console.log("store", data.plans[0].device_installations.zones)
            //     if (data.plans[0].device_installations.zones !== undefined) {
            //         state.listPoint = data.dataTracksplans[0].device_installations.zones;
            //     }
            //     else {
            //         state.listPoint = [];
            //     }
            // }
            else if (data.plans[0].device_installations === null) {
                state.listPoint = [];
            }
        }
        else if (state.floor !==  null) {
            for (var i = 0; i < data.plans.length; i++) {
                if (state.floor === data.plans[i].floor) {
                    state.image = data.plans[i].image;
                    state.dataTracks = data.plans[i];
                    state.uuid = data.plans[i].uuid;
                    state.dataFloorItem = data.plans[i];
                    if (data.plans[i].polygons_in !== undefined) {
                        state.polygons_in = data.plans[i].polygons_in;
                    }
                    if (data.plans[i].polygons_out !== undefined) {
                        state.polygons_out = data.plans[i].polygons_out;
                    }
                    if (data.plans[i].perimeter !== undefined) {
                        state.poligons_bounds = data.plans[i].perimeter;
                    }
                    // state.polygons_in = data.plans[i].polygons_in;
                    // state.polygons_out = data.plans[i].polygons_out;
                    // state.poligons_bounds = data.plans[i].perimeter;
                    state.setZoneList = data.plans[i].zone_sets
                    if (data.plans[i].places !== null) {
                        state.placeList = data.plans[i].places;
                    }
                    else if (data.plans[i].places === null) {
                        state.placeList = []
                    }
                    // if (data.plans[i].device_installations !== null) {
                    //     if (data.plans[i].device_installations.zones !== undefined) {
                    //         state.listPoint = data.plans[i].device_installations.zones;
                    //     }
                    //     else {
                    //         state.listPoint = [];
                    //     }
                    // }
                    else if (data.plans[i].device_installations === null) {
                        state.listPoint = [];
                    }
                }
            }
            state.dataPlans = data;
        }
        // console.log('store', state.setZoneList);
        if (state.setZoneList[0] !== undefined) {
            state.setZoneList[0].statusSelect = true;
        }
        this.emitChangeToModuleListeners();
    },
    selectFloor(e){
        // console.log('!1!!!1111!!1!111', e);
        var temp = true;
        for (var i = 0; i < state.dataPlans.plans.length; i++) {
            if (state.dataPlans.plans[i].floor !== e) {
                if (temp === true) {
                    // console.log('!1!!!1111!!1!111>>>>>');
                    state.polygons_in = [];
                    state.polygons_out = [];
                    state.poligons_bounds = [];
                    state.placeList = [];
                    state.dataTracks = null;
                    state.dataFloorItem = null;

                }
            }
            if (state.dataPlans.plans[i].floor === e) {
                temp = false;
                // console.log(state.dataPlans.plans[i]);
                // console.log('!1!!!1111!!1!111', state.dataPlans.plans[i],state.dataPlans.plans);
                state.polygons_in = [];
                state.polygons_out = [];
                state.poligons_bounds = [];
                if (state.dataPlans.plans[i].polygons_in !== undefined) {
                    state.polygons_in = state.dataPlans.plans[i].polygons_in;
                }
                if (state.dataPlans.plans[i].polygons_out !== undefined) {
                    state.polygons_out = state.dataPlans.plans[i].polygons_out;
                }
                if (state.dataPlans.plans[i].perimeter !== undefined) {
                    state.poligons_bounds = state.dataPlans.plans[i].perimeter;
                }
                // state.polygons_in = state.dataPlans.plans[i].polygons_in;
                // state.polygons_out = state.dataPlans.plans[i].polygons_out;
                // state.poligons_bounds = state.dataPlans.plans[i].perimeter;
                state.image = state.dataPlans.plans[i].image;
                state.dataTracks = state.dataPlans.plans[i];
                // console.log(state.dataPlans.plans[i]);
                state.uuid = state.dataPlans.plans[i].uuid;
                state.dataFloorItem = state.dataPlans.plans[i];
                state.floor = e
                state.setZoneList = state.dataPlans.plans[i].zone_sets
                if (state.dataPlans.plans[i].places !== null) {
                    // console.log('storeeeee', state.dataPlans.plans[i].device_installations.zones)
                    state.placeList = state.dataPlans.plans[i].places;
                }
                else if (state.dataPlans.plans[i].places === null) {
                    state.placeList = []
                }
                // if (state.dataPlans.plans[i].device_installations !== null) {
                //     if (state.dataPlans.plans[i].device_installations.zones !== undefined) {
                //         state.listPoint = state.dataPlans.plans[i].device_installations.zones;
                //     }
                //     else {
                //         state.listPoint = [];
                //     }
                // }
                else if (state.dataPlans.plans[i].device_installations === null) {
                    state.listPoint = [];
                }
            }
        }
        // if (state.setZoneList[0] !== undefined) {
        //     state.setZoneList[0].statusSelect = true;
        // }
        this.emitChangeToModuleListeners();
    },
    getNameSetZone(name){
        if (state.setZoneList.length === 0) {
            var  status = true;
        }
        else if (state.setZoneList.length > 0) {
            var  status = false;
        }
        state.setZoneList.push({
            "name": name,
            "zones": [
                // {
                //     "uuid": '',
                //     "coords":[]
                // }
                ],
            statusSelect: status
        })
        for (var i = 0; i < state.dataPlans.plans.length; ++i) {
            if (state.dataPlans.plans[i].floor === state.floor) {
                state.dataPlans.plans[i].zone_sets = state.setZoneList;
            }
        }
        this.emitChangeToModuleListeners();
    },
    selectZoneSet(index){
        for (var i = 0; i < state.setZoneList.length; i++) {
            state.setZoneList[i].statusSelect = false;
        }
        state.setZoneList[index].statusSelect = true;
        this.emitChangeToModuleListeners();
    },
    saveNameZone(index, name) {
        for (var i = 0; i < state.setZoneList.length; i++) {
            if (state.setZoneList[i].statusSelect) {
                state.setZoneList[i].zones[index]['name'] = name;
            }
        }
        this.emitChangeToModuleListeners();
    },
    saveNamePlace(index, name, list){
        state.placeList = list;
        state.placeList[index]['name'] = name;
        this.emitChangeToModuleListeners();
    },
    saveMarkerPoint(index, name, list){
        state.listPoint = list;
        state.listPoint[index]['name'] = name;
        this.emitChangeToModuleListeners();
    },
    deleteZone(index){
        state.setZoneList.splice(index, 1);
        this.emitChangeToModuleListeners();
    },
    getListBrand(brand){
        state.listBrand = brand;
        this.emitChangeToModuleListeners();
    },
    saveBrandInPlace(index, data) {
        // console.log(state.placeList[index]);
        state.placeList[index].retail = data;
        this.emitChangeToModuleListeners();
    },
    updateListPlace(list){
        state.placeList = list;
        this.emitChangeToModuleListeners();
    },
    updateListPoint(list){
        state.listPoint = list;
        this.emitChangeToModuleListeners();
    },
    getCheckBoxIsOpen(index, status, list){
        // console.log(status)
        state.placeList = list;
        state.placeList[index]['is_opened'] = status;
        this.emitChangeToModuleListeners();
    },
    getState(){
        return state;
    }
};

export default Store;
