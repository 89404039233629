var AppDispatcher = require('../dispatcher/dispatcher.js');
var AppConstants = require('../constants/constants.js');
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var MainStore = require('../../../mainStores/Store.js');

var state = {
	mainState: {},
	malls: [],
	statusShowMalls: false,
	dataMall: [],
	temporarilyDataMall: [],
	statusLoad: false,
	statusSelectMall: [],
	idSelectMall: null,
	search: '',
	statusShowCalendar: false,
	maxDate: null,
	minDate: null,
	statusAllSelect: 'off',
	quantity: null,
	nameInterval: 'daily'
}

Date.prototype.daysInMonth = function() {
	return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
};

Date.prototype.yyyymmdd = function() {
	var yyyy = this.getFullYear().toString();
	var mm = (this.getMonth() + 1).toString();
	var dd = this.getDate().toString();
	return yyyy + '-' + (mm[1] ? mm : "0" + mm[0]) + '-' + (dd[1] ? dd : "0" + dd[0]);
};

function selectStatusLoadFalse() {
	state.statusLoad = false;
};

function update (action) {
	state.mainState.insetName = action.state.commonState.insetName;
	state.mainState.selectedObjects = action.state.commonState.selectedObjects;
	state.mainState.max_timestamp = action.state.commonState.max_timestamp;
	state.mainState.min_timestamp = action.state.commonState.min_timestamp;
	state.maxDate = new Date(action.state.commonState.max_timestamp).yyyymmdd();
	state.minDate = new Date(action.state.commonState.min_timestamp).yyyymmdd();
	state.mainState.password = action.state.commonState.password;
	state.mainState.token = action.state.commonState.token;
	state.mainState.username = action.state.commonState.username;
	state.mainState.commonState = action.state.commonState.username;
};

function getMallsList(action) {
	state.malls = [];
	state.dataMall = [];
	state.listMalls = [];
	state.statusLoad = true;
	// state.malls = action.data.value;
	state.dataMall[0] = action.data[0];
	state.listMalls.push(action.data[0]);
	for (var i = 0; i < action.data.length; i++) {
		var obj = {
			data: action.data[i],
			status: false
		}
		state.malls.push(obj);
	}
	state.malls[0].status = true;
	state.temporarilyDataMall[0] = {
		data: state.malls[0].data,
		status: true
	};
	state.statusAllSelect = 'average';
	// state.maxDate = new Date(Date.now()).yyyymmdd();
	// state.minDate = new Date('02.02.2016').yyyymmdd();
	// console.log('period.jsx', state.dataMall);
};

function setStatusShowListMalls() {
	state.statusShowMalls = true;
};

function selectedMall() {
	state.dataMall = [];
	for (var i = 0; i < state.temporarilyDataMall.length; i++) {
		if (state.temporarilyDataMall[i].data.hasOwnProperty('name') === true) {
			state.dataMall[i] = state.temporarilyDataMall[i].data;
			state.search = '';
			// if (state.indexElementOfMalls !== null) {
			// 	state.listMalls[state.indexElementOfMalls] = state.temporarilyDataMall;
			// }
			state.statusShowMalls = false;
		}
	}
	// console.log('store.js', state.dataMall, state.temporarilyDataMall);
};

function getTemporarilyData(action) {
	for(var i = 0; i < state.malls.length; i++) {
		// if (action.data.value.id !== state.malls[i].id) {
		// 	state.statusSelectMall[i] = false;
		// }
		var index;
		if (action.data.value.data.id === state.malls[i].data.id) {
			if (state.malls[i].status === true) {
				for (var j = 0; j < state.temporarilyDataMall.length; j++) {
					if (state.temporarilyDataMall[j].data.id === state.malls[i].data.id) {
						index = j;
					}
				}
				// state.statusSelectMall = false;
				state.malls[i].status = false;
				state.temporarilyDataMall.splice(index, 1);
			}
			else if(state.malls[i].status === false) {
				if (state.quantity === null) {
					state.malls[i].status = true;
					// state.statusSelectMall = true;
					var data = {
						data: action.data.value.data,
						status: true
					};
					state.temporarilyDataMall.push(data);
				}
				else if (state.quantity !== null) {
					if (state.temporarilyDataMall.length < state.quantity) {
						state.malls[i].status = true;
						// state.statusSelectMall = true;
						var data = {
							data: action.data.value.data,
							status: true
						};
						state.temporarilyDataMall.push(data);
					}
				}
			}
		}
		if (state.quantity === null) {
			if (state.temporarilyDataMall.length === state.malls.length) {
				state.statusAllSelect = 'on';
				state.statusSelectMall = true;
			}
			else if (state.temporarilyDataMall.length < state.malls.length && state.temporarilyDataMall.length > 0) {
				state.statusAllSelect = 'average';
				state.statusSelectMall = true;
			}
			else if (state.temporarilyDataMall.length === 0) {
				state.statusAllSelect = 'off';
				state.statusSelectMall = false;
			}
		}
		else if (state.quantity !== null) {
			if (state.temporarilyDataMall.length === state.quantity) {
				state.statusAllSelect = 'on';
				state.statusSelectMall = true;
			}
			else if (state.temporarilyDataMall.length < state.quantity && state.temporarilyDataMall.length > 0) {
				state.statusAllSelect = 'average';
				state.statusSelectMall = true;
			}
			else if (state.temporarilyDataMall.length === 0) {
				state.statusAllSelect = 'off';
				state.statusSelectMall = false;
			}
		}
	}
};

function setSearch(action) {
	state.search = action.data;
};

function getStatusShowCalendar(action) {
	state.statusShowCalendar = action.status;
};

function hideCalendar() {
	state.statusShowCalendar = false;	
};

function saveDateMax(action) {
	state.maxDate = action.date.yyyymmdd();
};

function saveDateMin(action) {
	state.minDate = action.date.yyyymmdd();
};

function selectOffMalls() {
	if (state.statusAllSelect !== 'off') {
		for (var i = 0; i < state.malls.length; i++) {
			state.malls[i].status = false;
			state.temporarilyDataMall = [];
		}
		state.statusAllSelect = 'off';
	}
};

function selectOnMalls() {
	if (state.statusAllSelect !== 'on') {
		state.temporarilyDataMall = [];
		for (var i = 0; i < state.malls.length; i++) {
			if (state.quantity !== null) {
				if (i >= state.quantity) {
					state.statusAllSelect ='on';
					return;
				}
			}
			state.malls[i].status = true;
			var data = {
				data: state.malls[i].data,
				status: true
			};

			state.temporarilyDataMall.push(data);
		}
		state.statusAllSelect ='on';	
	}
}

function getQuantity(action) {
	state.quantity = action.data;
};

function selectToday() {
	state.maxDate = new Date().yyyymmdd();
	state.minDate = new Date().yyyymmdd();
};

function setLastMonth() {
	state.maxDate = new Date().yyyymmdd();
	state.minDate =  new Date(new Date().getFullYear(), new Date().getMonth() + 1 - 2, new Date().getDate()).yyyymmdd();
};

function setLastWeek() {
	state.maxDate = new Date().yyyymmdd();
	state.minDate =  new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).yyyymmdd();
	console.log('store.js', state.minDate);
};

function setFullTime() {
	state.maxDate = new Date().yyyymmdd();
	state.minDate = new Date("2013-08-30").yyyymmdd();
};

function setNameInterval(action) {
	state.nameInterval = action.name;
};

EventEmitter.prototype._maxListeners = 300

var CHANGE_EVENT = 'change';

var AppStore = assign({}, EventEmitter.prototype, {
	moduleChangeStoreListeners: [],
	addChangeStoreModuleListener: function(callback) {
		if (this.moduleChangeStoreListeners.indexOf(callback) == -1) {
			this.moduleChangeStoreListeners.push(callback);
		}
	},
	removeChangeStoreModuleListener: function (callback) {
		if (this.moduleChangeStoreListeners.indexOf(callback) != -1) {
			this.moduleChangeStoreListeners.splice(this.moduleChangeStoreListeners.indexOf(callback), 1);
		}
	},
	emitChangeToModuleListeners: function () {
		for (var i = 0; i < this.moduleChangeStoreListeners.length; i++) {
			this.moduleChangeStoreListeners[i](state);
		}
	},
	getState: function() {
		return state;
	},
	emitChange: function() {
		this.emit(CHANGE_EVENT);
	},
	addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },
    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },
    route: function(actionType) {
    	switch (actionType) {
    		case AppConstants.UPDATE:
			return update;
			case AppConstants.GET_MALLS_LIST:
			return getMallsList;
			case AppConstants.SHOW_LIST_MALLS:
			return setStatusShowListMalls;
			case AppConstants.SELECTED_MALL:
			return selectedMall;
			case AppConstants.GET_SELECT_MALL:
			return getTemporarilyData;
			case AppConstants.GET_SEARCH:
			return setSearch;
			case AppConstants.SET_STATUS_SHOW_CALENDAR:
			return getStatusShowCalendar;
			case AppConstants.HIDE_CALENDAR:
			return hideCalendar;
			case AppConstants.GET_DATE_MAX:
			return saveDateMax;
			case AppConstants.GET_DATE_MIN:
			return saveDateMin;
			case AppConstants.SELECT_OFF_MALLS:
			return selectOffMalls;
			case AppConstants.SELECT_ON_MALLS:
			return selectOnMalls;
			case AppConstants.GET_QUANTITY:
			return getQuantity;
			case AppConstants.SELECT_TODAY:
			return selectToday;
			case AppConstants.SELECT_LAST_MONTH:
			return setLastMonth;
			case AppConstants.SELECT_LAST_WEEK:
			return setLastWeek;
			case AppConstants.SELECT_FULL_TIME:
			return setFullTime;
			case AppConstants.SELECT_INTERVAL:
			return setNameInterval;
			case AppConstants.SELECT_STATUS_LOAD_FALSE:
			return selectStatusLoadFalse;
    	}
    }
});

AppDispatcher.register(function(payload){
	var func = AppStore.route(payload.action.actionType);
	if (func != null) {
		func(payload.action);
		AppStore.emitChange();
		AppStore.emitChangeToModuleListeners();
	};
	return true;
});


module.exports = AppStore; 