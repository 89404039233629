var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');
var ListItem = require('./listItem.jsx');

var List = React.createClass({
	getInitialState: function () {
		return{
			statusSelect: 'on',
			value: ''
		};
	},
	_onChange: function () {
		this.setState({
			statusSelect: AppStore.getState().statusAllSelect
		});
	},
	clean: function () {
		this.setState({
			value: ''
		});
		AppActions.getSearch('');
	},
	getSearch: function (e) {
		this.setState({
			value: e.target.value
		});
		if (e.target.value === '') {
		}
		AppActions.getSearch(e.target.value);
	},
	componentDidMount: function () {
        AppStore.addChangeListener(this._onChange);
        if(this.props.quantity !== undefined) {
        	AppActions.getQuantity(this.props.quantity);
        }
    },
    componentWillUnmount: function () {
        AppStore.removeChangeListener(this._onChange);
    },
	selectMall: function () {
		AppActions.selectedMall();
	},
	selectAllMallsOn: function () {
		AppActions.selectOnMalls();
	},
	selectAllMallsOff: function () {
		AppActions.selectOffMalls();
	},
	render: function() {
		var styleSelectButtonOn;
		var styleSelectButtonOff;
		var styleButtonSelect;
		if (AppStore.getState().statusAllSelect === 'on') {
			styleSelectButtonOn = {
				background: 'rgba(69, 185, 118, 0.498039)',
				cursor: 'default',
				color: 'rgba(255, 255, 255, 0.498039)'
			};
			styleSelectButtonOff = {
				background: '#45b976',
				cursor: 'pointer',
				color: '#fff'
			};
			styleButtonSelect = {
				background: '#45b976',
					cursor: 'pointer',
					color: '#fff',
					outline: 'none'
			};
		}
		else if (AppStore.getState().statusAllSelect === 'average') {
			styleSelectButtonOn = {
				background: '#45b976',
					cursor: 'pointer',
					color: '#fff'
			};
			styleSelectButtonOff = {
				background: '#45b976',
					cursor: 'pointer',
					color: '#fff'
			};
			styleButtonSelect = {
				background: '#45b976',
					cursor: 'pointer',
					color: '#fff',
					outline: 'none'
			};
		}
		else if (AppStore.getState().statusAllSelect === 'off') {
			styleSelectButtonOn = {
				background: '#45b976',
					cursor: 'pointer',
					color: '#fff'
			};
			styleSelectButtonOff = {
				background: 'rgba(69, 185, 118, 0.498039)',
					cursor: 'default',
					color: 'rgba(255, 255, 255, 0.498039)'
			};
			styleButtonSelect = {
				background: 'rgba(69, 185, 118, .5)',
					cursor: 'default',
					color: 'rgba(255, 255, 255, .5)',
					outline: 'none'
			};
		}
		var list = this.props.prop.map(function (prop, id) {
			if(prop.indexSearch >= 0) {
				return(
					<ListItem key={id} className="mall" prop={prop} />
				);
			}
			else if (prop.indexSearch < 0) {
				return(null);
			}
		});
		return(
			<div className="object-selector">
				<div className="input-wrapper">
					<input value={this.state.value} onChange={this.getSearch} type="text" placeholder="Поиск" autofocus="autofocus" className="search form-control" />
					<span onClick={this.clean} className="close glyphicon glyphicon-remove"></span>
				</div>
				<section className="section-objects shadowed" style={{bottom: '98px'}}>
					<ul className="objects ps-container" style={{overflow: 'auto', maxHeight: '90%'}}>
						{list}
					</ul>
					<button style={styleSelectButtonOn} onClick={this.selectAllMallsOn} className='onAll'>Выделить все</button>
					<button style={styleSelectButtonOff} onClick={this.selectAllMallsOff} className='offAll'>Отменить</button>
				</ section>
				<section className="buttons">
					<button style={styleButtonSelect} onClick={this.selectMall}  className="selected selectedControlPanel">Выбрать</button>
				</section>
			</div>

		);
	}
});

module.exports = List;