var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/store.js');
var React = require('react');
var $ = require('jquery');

var Interval = React.createClass({
	getInitialState: function () {
		return {
			nameInterval: 'По дням'
		};
	},
	internalState: {
		isMounted: null
	},
	componentDidMount: function () {
		this.internalState.isMounted = true;
        AppStore.addChangeListener(this._onChange);
    },
    componentWillUnmount: function () {
    	this.internalState.isMounted = false;
        AppStore.removeChangeListener(this._onChange);
    },
    _onChange: function () {
    	if (this.internalState.isMounted === true) {
    		if (AppStore.getState().nameInterval === 'hourly') {
	    		this.setState({
	    			nameInterval: 'По часам'
	    		});
	    	}
	    	else if (AppStore.getState().nameInterval === 'daily') {
	    		this.setState({
	    			nameInterval: 'По дням'
	    		});
	    	}
	    	else if (AppStore.getState().nameInterval === 'weekly') {
	    		this.setState({
	    			nameInterval: 'По неделям'
	    		});
	    	}
	    	else if (AppStore.getState().nameInterval === 'monthly') {
	    		this.setState({
	    			nameInterval: 'По месяцам'
	    		});
	    	}
    	}
    },
	getInterval: function (e) {
		if(e.target === document.getElementById('hour')) {
			AppActions.selectInterval('hourly');
		}
		else if(e.target === document.getElementById('day')) {
			AppActions.selectInterval('daily');
		}
		else if(e.target === document.getElementById('week')) {
			AppActions.selectInterval('weekly');
		}
		else if(e.target === document.getElementById('month')) {
			AppActions.selectInterval('monthly');
		}
	},
	render: function () {
		return (
			<div className='sidebar-interval'>
				<section className='controlPanelInterval'>
					<span className='icon'></span>
					<h2>Детализация</h2>
					<div style={{clear: 'both'}}></div>
					<div className='dropdown'>
						<button data-toggle="dropdown" className='btn dropdown-toggle'>
							{this.state.nameInterval}
							<span className='caret'></span>
						</button>
						<ul role='menu' className='dropdown-menu'>
							<li>
								<a onClick={this.getInterval} id='hour' data-interval="hourly">По часам</a>
							</li>
							<li>
								<a onClick={this.getInterval} id='day' data-interval="daily">По дням</a>
							</li>
							<li>
								<a onClick={this.getInterval} id='week' data-interval="weekly">По неделям</a>
							</li>
							<li>
								<a onClick={this.getInterval} id='month' data-interval="monthly">По месяцам</a>
							</li>
						</ul>
					</div>
				</section>
			</div>
		);
	}
});

module.exports = Interval;