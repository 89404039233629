var AppActions = require('../actions/actions.js');
var AppStore = require('../stores/stores.js');
var React = require('react');
var lang = require('../../../lang/func.js');

var RouterItem = React.createClass({
	onLeave:function(e){
        // console.log('routerItem', this.props.prop);
        AppActions.getDataSelectRouter(this.props.prop)
	},
	render: function () {
		// console.log('routerItem', this.props.filter);
		var name;
		if (this.props.prop.name.length > 30) {
			name = this.props.prop.name.substring(0, 14) + '...' + this.props.prop.name.substring(this.props.prop.name.length - 14, this.props.prop.name.length);
		}
		else if (this.props.prop.name.length <= 30) {
			name = this.props.prop.name;
		}
        // console.log('routerItem', this.props.idSelectRouter,this.props.prop.id);
		if (this.props.idSelectRouter === this.props.prop.id) {
            return (
				<g onClick={this.onLeave} >
					<text x={this.props.id * (93 + 54)} y="15" >{name}</text>
					<rect x={this.props.id * (93 + 54)} y="20" width="144" stroke="rgba(0,0,0,.5)" height="20" style={{fill: 'rgba(29,173,251,' + (0.15 + 0.85 * this.props.prop.persent) + ')'}} />
					<text height="20" textAnchor="middle" x={this.props.id * 147 + 72} y="35" >{this.props.prop.value}</text>
					<text height="20" textAnchor="middle" x={this.props.id * 147 + 72} y={65 - (this.props.prop.floor * 2 + 4)} >{lang.getState().maps.route.routes.floor + ' ' + this.props.prop.floor}</text>
					<line x1={this.props.id * 147} y1={70 - (this.props.prop.floor * 2 + 4)} x2={this.props.id * 147 + 144} y2={70 - (this.props.prop.floor * 2 + 4)} style={{stroke: 'grey', strokeWidth: '2'}} />
				</g>
            );
		}
		else {
            return (
				<g onClick={this.onLeave} >
					<text x={this.props.id * (93 + 54)} y="15" >{name}</text>
					<rect x={this.props.id * (93 + 54)} y="20" width="144" height="20" style={{fill: 'rgba(29,173,251,' + (0.15 + 0.85 * this.props.prop.persent) + ')'}} />
					<text height="20" textAnchor="middle" x={this.props.id * 147 + 72} y="35" >{this.props.prop.value}</text>
					<text height="20" textAnchor="middle" x={this.props.id * 147 + 72} y={65 - (this.props.prop.floor * 2 + 4)} >{lang.getState().maps.route.routes.floor + ' ' + this.props.prop.floor}</text>
					<line x1={this.props.id * 147} y1={70 - (this.props.prop.floor * 2 + 4)} x2={this.props.id * 147 + 144} y2={70 - (this.props.prop.floor * 2 + 4)} style={{stroke: 'grey', strokeWidth: '2'}} />
				</g>
            );
		}

	}
});

54
144

module.exports = RouterItem;
