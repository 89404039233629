import ReactDOM from 'react-dom';
import React, {
    Component,
    PropTypes,
} from 'react';
import ComponentBoxHolst from './components/ComponentBoxHolst';
import Actions from './actions/Actions';
import Store from './store/store';
// import AirbrakeClient from 'airbrake-js';

// var airbrake = new AirbrakeClient({projectId: 149218, projectKey: '7a3d96dc6bea4aff58f90cbfcbe256c6'});


class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: Store.getState().image,
            dataFloorItem: null
        }
    }
    componentDidMount() {
        Store.addChangeStoreModuleListener(this.handelStore.bind(this));
    }

    componentWillUnmount() {
        Store.removeChangeStoreModuleListener(this.handelStore.bind(this));
    }

    handelStore(){
        this.setState({
            image: Store.getState().image,
            dataFloorItem: Store.getState().dataFloorItem
        });

    }
    render() {
        // console.log('main', this.props.widthMaps);
        var styleStepBox = null;
        var widthStepBox = 0;
        var heughtBox = 0;
        if (this.state.dataFloorItem !== null) {
            // this.state.styleStepBox.width
            styleStepBox = {
                width: this.state.dataFloorItem.width / this.state.dataFloorItem.scale * 20,
                height: this.state.dataFloorItem.height / this.state.dataFloorItem.scale * 20,
            }
            heughtBox = this.state.dataFloorItem.height / this.state.dataFloorItem.scale * 20
            widthStepBox = this.state.dataFloorItem.width / this.state.dataFloorItem.scale * 20
            // console.log('main', widthStepBox, heughtBox, this.state.dataFloorItem.scale);
        }
        return (
            <div className="boxContent">
                <ComponentBoxHolst image={this.props.image}
                                   getMode="POLYLINE"
                                   width={this.props.widthMaps}
                                   height={this.props.height}
                                   typetrack=""
                                   uuidForSet={this.props.planSetUuid}
                />
            </div>
        );
    }
}

// Main.propTypes = {};
// Main.defaultProps = {};
//
module.exports = Main;
// ReactDOM.render(
//     <Main/>,
//     document.getElementById('ReactComponent')
// );


